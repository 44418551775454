.tiers-explained {
  border-radius: 0 25.6px 25.6px 25.6px;
  background: var(--liquid-background-secondary);
  color: var(--white1);
  margin: 0 32px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 55px;
  padding: 46px 86px;
}

.tiers-explained__tier {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  min-height: 400px;
  background: var(--liquid-background-primary);
  border-radius: 37px;
  box-sizing: border-box;
  position: relative;
}

.tiers-explained__tier-animation-wrapper {
  height: 150px;
  width: calc(100% + 2px);
  border-bottom-left-radius: 36px;
  border-bottom-right-radius: 36px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  margin-top: -2px;
}

.tiers-explained__tier-animation {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tiers-explained__tier-animation--blurred {
  filter: blur(20px);
}

.tiers-explained__tierLockIcon {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 20px;
  left: calc(50% - 40px);
  z-index: 100;
}

.tiers-explained__tier-name {
  position: absolute;
  font-size: 17px;
  z-index: 5;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
}

.tiers-explained__tier-name-black {
  color: var(--black1);
}

.tiers-explained__fade {
  display: flex;
  width: calc(100% - 103px);
  height: 12px;
  margin: auto;
  margin-top: -28px;
  margin-bottom: 16px;
  background: #130024 0% 0% no-repeat padding-box;
  border: 2px solid #130024;
  border-radius: 16px;
  opacity: 0.16;
  filter: blur(5px);
}

.tiers-explained__tier-description {
  max-width: 360px;
  font-size: 16px;
  line-height: 20px;
  color: var(--liquid-primary-font-color);
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.tiers-explained__tier-disclaimer {
  color: var(--liquid-primary-font-color);
  font-size: 10px;
  position: absolute;
  right: 59px;
  bottom: 17px;
}

@media (max-width: 1700px) {
  .tiers-explained__tier-name {
    font-size: 16px;
  }
}

@media (max-width: 1490px) {
  .tiers-explained__tier-name {
    font-size: 14.4px;
  }
}

@media (max-width: 1350px) {
  .tiers-explained {
    grid-template-columns: 1fr 1fr;
  }

  .tiers-explained__tier:nth-last-child(1) {
    align-self: center;
    justify-self: center;
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .tiers-explained__tier-name {
    font-size: 16px;
  }
}

@media (max-width: 1200px) {
  .tiers-explained__tier-name {
    font-size: 14.4px;
  }
}

@media (max-width: 950px) {
  .tiers-explained {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
  }

  .tiers-explained__tier-name {
    font-size: 19.2px;
  }
  .tiers-explained__tier-disclaimer {
    bottom: 6px;
  }
}

@media (max-width: 850px) {
  .tiers-explained__tier-name {
    font-size: 16px;
  }
}

@media (max-width: 650px) {
  .tiers-explained__tier-name {
    font-size: 12px;
  }
  .tiers-explained__tier-disclaimer {
    font-size: 8px;
  }
}
