.tab__menu {
  display: flex;
  flex-shrink: 0;
  color: gray;
  background: var(--instrument-selector-tabs-background);
  gap: 40px;
}

.tab__tab-with-menu {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.tab__tab-with-menu-label {
  display: flex;
  align-items: center;
}

.tab__tabs-label {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: var(--grey2);
  margin-right: 30px;
}

.tab__menu-item {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  white-space: nowrap;
  padding: 0px 18px;
  position: relative;
  margin-bottom: 3px;
  height: 36px;
  color: var(--instrument-selector-tab-color);
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  align-content: flex-start;
}

.tab__menu-item:last-of-type {
  border-right: 0;
}

.tab__menu-item:before,
.tab__menu-item:hover:before {
  content: '';
  position: absolute;
  width: 100%;
  bottom: 0;
  border-bottom: 5px solid #32245e;
  border-radius: 20px;
}

.tab__tab {
  height: 100%;
}

.tab__tab--side-item {
  height: 100%;
}

.tab__tab-content {
  height: calc(100% - 64px);
}

.tab__tab-content--side-item {
  height: calc(100% - 2.5rem);
}

.tab__menu-item--active:before,
.tab__menu-item--active:hover:before {
  border-bottom: 5px solid var(--purple8);
}

.tab__menu-item:hover,
.tab__menu-item--active,
.tab__menu-item--active:hover {
  color: var(--instrument-selector-active-tab-color);
}

.tab__menu-item--active:before,
.tab__menu-item--active:hover:before {
  content: '';
  position: absolute;
  width: 100%;
  bottom: 0;
  border-bottom: 5px solid var(--purple8);
}

/* BROWSER QUERIES */

/* IE10+ specific styles */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .tab__menu-item:hover:before,
  .tab__menu-item--active:before,
  .tab__menu-item--active:hover:before {
    border: none;
  }

  .tab__menu-item {
    border-bottom: 2px solid transparent;
  }

  .tab__menu-item:hover,
  .tab__menu-item--active,
  .tab__menu-item--active:hover {
    border-bottom: 2px solid purple;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 568px) {
  .tab__menu-item {
    white-space: pre-line;
    line-height: inherit;
    padding: 0 5px;
    font-size: 13px;
  }
}
