.search-input__container {
  height: 3rem;
  border: 1px solid white;
  background-color: black;
  padding-left: 2rem;
  width: 100%;
}

.search-input__input {
  height: 100%;
}

.search-input__button-wrapper {
  width: 100%;
  height: 100%;
  overflow: visible;
}

.search-input__list-row {
  display: flex;
  align-items: center;
  height: 30px;
  padding-left: 10px;
  padding-right: 15px;
  position: relative;
  z-index: 10000;
  cursor: pointer;
}

.search-input__list-row:first-child {
  padding-top: 10px;
}

.search-input__list-row:last-child {
  padding-bottom: 10px;
}

.search-input__list-row-result {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  padding-left: 20px;
  align-items: flex-end;
}

.search-input__all-results {
  height: 100%;
  display: flex;
  align-items: center;
}

.search-input__view-all-button {
  background: var(--blue1);
  border-radius: 24px 0 24px 0;
  width: 90px;
  height: 24px;
}

.search-input__default-icon {
  width: 20px;
  height: 20px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  text-align: center;
  font-size: 10px;
  line-height: 20px;
  background-color: lightskyblue;
}

.search-input__icon {
  width: 20px;
  height: 20px;
}

.search-input__list-label {
  width: 100%;
  margin-left: 10px;
  font-weight: 500;
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-cards-modal__search__view-all-button {
  border-radius: 12px 0 12px 0;
}

.search-input__highlighted-text {
  color: var(--grey1);
  background-color: var(--white1);
}

@media (max-width: 576px) {
  .search-input__list {
    max-height: 120px;
    overflow-y: scroll;
  }

  .search-input__list::-webkit-scrollbar {
    display: none;
  }
}
