.deposit-from-card {
  display: flex;
  flex-direction: column;
}

.deposit-from-card__reocurring-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  margin-right: 60px;
}

.deposit-from-card__reocurring-container {
  width: 100%;
  max-width: 320px;
  display: flex;
  flex-direction: row;
  margin: auto;
}

.deposit-from-card__switch-container {
  display: flex;
  flex-direction: row;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.deposit-from-card__switch-container--disabled {
  opacity: 0.5;
}

.deposit-from-card__switch {
  margin: 0 12px 0 12px;
  width: 40px;
}

.deposit-from-card .deposit-from-card__switch__switch {
  background: var(--deposit-recurring-switch-background);
  width: 40px;
  border-radius: 20px;
  border: none;
}

.deposit-from-card .deposit-from-card__switch__toggle {
  width: 16px;
  height: 16px;
  background: var(--deposit-recurring-switch-toogle);
  border: var(--deposit-recurring-switch-toogle-border);
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.25);
  left: 5px;
}

.deposit-from-card__subscription-active {
  max-width: 320px;
  margin: auto;
  margin-top: 25px;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
}

.deposit-from-card__frequency__wrapper {
  width: 320px;
  height: 37px;
  margin: auto;
  margin-top: 25px;
}

.deposit-from-card__frequency__select {
  justify-content: flex-start;
  padding-left: 16px;
}

.deposit-from-card__frequency__arrow {
  top: 15px;
  right: 15px;
}

.deposit-from-card__frequency__list-wrapper {
  top: 38px;
  width: 320px;
}

.deposit-from-card__amount {
  padding-top: 10px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.deposit-from-card__card-right {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.deposit-from-card__recurring-banner {
  background: linear-gradient(90.57deg, #7c5ced 0%, #53d660 100%);
  padding: 5px 10px;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: var(--white1);
}

.deposit-from-card__amountInputWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: 300;
  font-size: 12px;
  margin: auto;
  margin-top: 20px;
  color: var(--deposit-withdraw-font-primary);
}

.deposit-from-card__input-area {
  display: flex;
  width: 213px;
  height: 65px;
  border: 1px solid var(--deposit-withdraw-font-primary);
  background: var(--deposit-withdraw-background-secondary);
  border-radius: 0 20px 20px 20px;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.deposit-from-card__amount-input {
  display: block;
  height: 30px;
  margin: 20px 0;
  width: 100px;
  font-size: 24px;
  line-height: 30px;
  color: var(--deposit-withdraw-font-primary);
  border-radius: 5px;
  text-align: center;
  background: transparent;
}

.deposit-from-card__fee-warning,
.deposit-from-card__userAgreement {
  color: var(--deposit-withdraw-font-primary);
  font-weight: 300;
  font-size: 12px;
  margin-bottom: 3px;
  padding-left: 4px;
  text-align: center;
}

.deposit-from-card__agreement {
  display: flex;
  width: 100%;
  margin: auto;
  margin-top: 80px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.deposit-from-card__new-card {
  display: flex;
  width: 100%;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.deposit-from-card__checkbox {
  margin: auto;
  width: 220px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.deposit-from-card__agreement-description {
  font-weight: 300;
  font-size: 12px;
  color: var(--deposit-withdraw-font-primary);
}

.deposit-from-card__amountButtons {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}

.deposit-from-card__amountButton {
  height: 100%;

  display: flex;
  align-items: center;
}

.deposit-from-card__stored-cards-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 50px;
}

.deposit-from-card__stored-cards-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  margin-right: 60px;
}

.deposit-from-card__limit-reached-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 20px;
  gap: 20px;
  background: var(--deposit-limit-reached-background-color);
  border-radius: 20px;
  font-weight: 600;
  line-height: 1.5;
}

.deposit-from-card__info-icon,
.deposit-from-card__info-icon svg:not(:root) {
  width: 16px;
  height: 16px;
}

.deposit-from-card__info-icon .icon-symbol,
.deposit-from-card__info-icon .icon-dot {
  fill: var(--font__color-primary);
}

.deposit-from-card__info-icon .icon-border {
  stroke: var(--font__color-primary);
}

.deposit-from-card__cards-container {
  min-width: 260px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: auto;
}

.deposit-from-card__card {
  width: 100%;
  min-width: 300px;
  max-width: 420px;
  display: flex;
  flex-direction: row;
  padding: 0 15px 0 10px;
  justify-content: space-between;
  align-items: center;

  height: 63px;
  background: var(--deposit-withdraw-card-background-color);
  border: 1px solid var(--deposit-withdraw-card-border-color);
  border-radius: 10px;
  cursor: pointer;
}

.deposit-from-card__card--selected {
  background: var(--deposit-withdraw-card-background-color--selected);
  border-color: var(--deposit-withdraw-card-border-color--selected);
}

.deposit-from-card__card:not(.deposit-from-card__card--selected)
  .deposit-from-card__card-icon {
  opacity: 0.5;
}

.deposit-from-card__card-left {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: var(--deposit-withdraw-card-font-color);
}

.deposit-from-card__card-icon {
  display: flex;
  width: 60px;
  height: 40px;
  margin-right: 20px;
}

.deposit-from-card__delete-icon {
  stroke: var(--deposit-withdraw-card-font-color);
}

.deposit-from-card__icon {
  display: flex;
  align-items: center;
  width: 25px;
  stroke: var(--deposit-withdraw-font-primary);
}

.deposit-from-card__amount-button {
  width: 180px;
  height: 40px;
  margin: auto;
  margin-bottom: 15px;
  background: var(--purple8);
  color: var(--white1);
  border: 1px solid var(--white1);
  border-radius: 20px;
  padding: 12px 16px;
  font-weight: 600;
  font-size: 14px;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
}

.deposit-from-card__amount-button--disabled {
  color: var(--input__color--disabled);
  border: transparent;
  background: var(--input__bg-color--disabled);
}

.deposit-from-card__modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 500px;
}

.deposit-from-card__modal-description {
  font-size: 1.5rem;
  color: var(--font__color-secondary);
  margin-bottom: 10px;
}

.deposit-from-card__modal-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.deposit-from-card__modal-link {
  padding: 10px;
  font-size: 16px;
}

.deposit-from-card__modal-button {
  font-family: Arial, sans-serif;
  color: #ffffff;
  font-size: 1.5rem;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
  max-width: 150px;
  margin: 10px;
}

.deposit-from-card__modal-button--confirm {
  background: #6273e9;
  font-size: 1.5rem;
}

.deposit-from-card__modal-button--reject {
  background: #e73b3b;
}

.deposit-from-card__selected-card-text {
  font-size: 12px;
  color: var(--deposit-selected-card-text-color);
  text-align: center;
}

.deposit-from-card__spinner__spinner-container {
  position: relative;
}

@media (max-width: 576px) {
  .deposit-from-card__amount {
    margin-bottom: 40px;
  }
}
