.wallet-chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.wallet-chart__circle {
  width: 100%;
  max-width: 375px;
  border-radius: 50%;
  position: relative;
  box-sizing: border-box;
  background-clip: padding-box;
  border: solid 5px transparent;
  background: var(--wallet-chart-background);
  overflow: hidden;
  z-index: 100;
}

/* turns the element into a responsive square */
.wallet-chart__circle::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.wallet-chart__inner-circle {
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  transform: translate(0, 0);
  background: var(--wallet-chart-inner-circle-background);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wallet-chart__highlight {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 98;
}

.wallet-chart__highlight,
.wallet-chart__highlight svg:not(:root) {
  width: 100%;
  height: 100%;
}

.wallet-chart__stub-inner-circle {
  position: absolute;
  z-index: 10;
  transform: translate(0, 0);
  background: var(--wallet-chart-selected-circle-background);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  animation: selectedCurrencyCircle 0.5s;
  overflow: hidden;
}

.wallet-chart__water {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  transform: translate(0, 100%);
  background: var(--color);
}

.wallet-chart__water-wave {
  width: 200%;
  position: absolute;
  bottom: 100%;
  left: 0;
  fill: var(--color);
  margin-bottom: -2px;
  animation: wave 4s infinite linear;
}

.wallet-chart__labels-wrapper {
  height: 55px;
  overflow-x: auto;
  display: flex;
  align-items: center;
  gap: 10px;
  /* hide scrollbar but allow scrolling */
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
}

.wallet-chart__labels-wrapper::-webkit-scrollbar {
  /* for Chrome, Safari, and Opera */
  display: none;
}

.wallet-chart__label-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  min-width: max-content;
  height: 32px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
}

.wallet-chart__label-wrapper--selected {
  background-color: var(--wallet-chart-selected-label-background);
}

.wallet-chart__label-color {
  background: var(--color);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  filter: drop-shadow(0px 3px 6px rgba(130, 90, 246, 0.259));
}

.wallet-chart__label {
  font-size: 12px;
  line-height: 18px;
  color: var(--wallet-chart-label-color);
}

.wallet-chart__total-balance>div>div,
.wallet-chart__selected-total-balance>div>div {
  font-size: inherit;
}

.wallet-chart__total-balance,
.wallet-chart__selected-total-balance {
  width: 100%;
  font-weight: 600;
  line-height: 1.5;
  color: var(--white1);
  z-index: 99;
  animation: totalBalance 2s linear;
  text-align: center;
  padding: 0 20px;
}

.wallet-chart__selected-total-balance {
  z-index: 20;
  animation: totalBalance 2s linear;
}

.wallet-chart__total-balance-currency {
  font-weight: 600;
  font-size: 60%;
  padding-top: 6px;
}

.wallet-chart__slider {
  width: 100%;
  max-width: 400px;
  margin-top: 20px;
  border-radius: 15px;
  background-color: var(--wallet-chart-labels-background);
  padding: 0 10px;
}

.wallet-chart__slider--with-arrows {
  position: relative;
  padding: 0 32px;
}

.wallet-chart__arrow {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

.wallet-chart__arrow span,
.wallet-chart__arrow svg:not(:root) {
  width: 4.51px;
  height: 8.12px;
}

.wallet-chart__arrow--prev {
  left: 4px;
}

.wallet-chart__arrow--next {
  right: 4px;
}

.wallet-chart__arrow>button {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  opacity: 0.8;
}

.wallet-chart__arrow--prev>button {
  padding-right: 1px;
}

.wallet-chart__arrow--next>button {
  padding-left: 1px;
}

.wallet-chart__arrow .slider-arrow-path {
  fill: var(--wallet-chart-slider-arrow-color);
}

.wallet-chart__arrow>button:hover {
  opacity: 1;
}

.wallet-chart__arrow>button:disabled {
  opacity: 0.3;
  cursor: default;
}

@keyframes wave {
  100% {
    transform: translate(-50%, 0);
  }
}

@keyframes totalBalance {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes selectedCurrencyCircle {
  0% {
    left: 350px;
  }

  100% {
    left: 0px;
  }
}

@media (max-width: 576px) {
  .wallet-chart__label {
    font-size: 10px;
  }

  .wallet-chart__label-wrapper {
    gap: 5px;
  }
}
