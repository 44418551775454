.notification-modal__header {
  background: var(--purple8);
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: var(--white1);
}

.notification-modal__close-icon {
  color: var(--white1);
  top: 18px;
  right: 18px;
}

.notification-modal__content {
  overflow: hidden;
  background: var(--purple8);
  padding-top: 0;
  color: var(--white1);
}

.notification-modal__text {
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: var(--notification-center-row-text-color);
}

.notification-modal__date {
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  margin: 10px 0 40px 0;
  color: var(--notification-center-row-date-color);
  text-align: end;
}

.notification-modal__title-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 28.28px;
  min-height: 28.28px;
}

.notification-modal__title {
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: var(--notification-center-row-text-color);
}

.notification-modal__remove-title {
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: var(--notification-center-row-text-color);
}

.notification-modal__buttons {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  gap: 20px;
}

.ap-modal.notification-modal {
  padding: 10px 15px;
  min-width: 280px;
}

.notification-modal__title-icon {
  width: 16px;
  height: 16px;
  color: var(--notification-center-row-text-color);
}

.notification-modal--global__close-icon {
  color: var(--notification-center-row-text-color);
}

.notification-modal__title-icon--globalNotification .global-line {
  stroke: var(--notification-center-row-text-color);
}

.notification-modal__title-icon--specificUserNotification .specific-user-line {
  stroke: var(--notification-center-row-text-color);
}

.notification-modal__title-icon--reminderNotification .reminder-line {
  stroke: var(--notification-center-row-text-color);
}

.notification-modal__title-icon--rewardNotification .reward-line {
  stroke: var(--notification-center-row-text-color);
}

.notification-modal__cancel-button,
.notification-modal__confirm-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--grey2);
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: var(--white1);
  width: 100px;
  height: 28px;
  border-radius: 20px;
}

.notification-modal__cancel-button {
  border: 3px solid var(--white1);
}

.notification-modal__cancel-button:hover {
  color: var(--grey2);
  background-color: var(--white1);
}

.notification-modal__confirm-button {
  border: 3px solid var(--purple8);
}

.notification-modal__confirm-button:hover {
  background-color: var(--purple8);
}

.notification-modal__delete-button,
.notification-modal__mark-button,
.notification-modal__marked-button,
.all-notifications-modal__mark-button,
.all-notifications-modal__delete-button,
.all-notifications-modal__marked-button {
  display: flex;
  width: 100px;
  padding: 5px 15px;
  gap: 9px;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: var(--notification-action-button-color);
  background: var(--notification-action-button-background);
  border: 3px solid var(--yellow1);
  border-radius: 20px;
}

.all-notifications-modal__mark-button,
.notification-modal__mark-button {
  width: 145px;
}

.notification-modal__delete-button,
.notification-modal__mark-button,
.notification-modal__marked-button {
  max-height: 28px;
}

.notification-modal__mark-button:hover,
.all-notifications-modal__mark-button:hover {
  background: var(--yellow1);
  color: var(--white1);
}

.notification-modal__mark-button:hover .notification-modal__mark-icon,
.all-notifications-modal__mark-button:hover
  .all-notifications-modal__mark-icon {
  stroke: var(--white1);
}

.notification-modal__marked-button,
.all-notifications-modal__marked-button {
  background: var(--yellow1);
  color: var(--white1);
}

.notification-modal__delete-button,
.all-notifications-modal__delete-button {
  border: 3px solid var(--red2);
}

.notification-modal__delete-button:hover,
.all-notifications-modal__delete-button:hover {
  background: var(--red2);
  color: var(--white1);
  border: 3px solid var(--red2);
}

.notification-modal__delete-button:hover .notification-modal__delete-icon,
.all-notifications-modal__delete-button:hover
  .all-notifications-modal__delete-icon {
  fill: var(--notification-action-button-color);
}

.notification-modal__mark-icon,
.all-notifications-modal__mark-icon {
  stroke: var(--notification-action-button-color);
  fill: transparent;
  width: 13px;
  height: 13px;
}

.notification-modal__marked-icon,
.all-notifications-modal__marked-icon {
  width: 10px;
  min-width: 10px;
  height: 14px;
  fill: var(--white1);
}

.notification-modal__delete-icon,
.all-notifications-modal__delete-icon {
  width: 14px;
  height: 16px;
  min-width: 14px;
  fill: var(--notification-action-button-color);
}

.all-notifications-modal {
  width: 680px;
  border-radius: 20px;
  max-height: 721px;
}

.all-notifications-modal__content {
  background: var(--all-notifications-modal-background);
  color: var(--all-notifications-modal-font);
}

.all-notifications-modal__header-left {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding: 10px 20px 10px 20px;
  background: linear-gradient(135deg, #825af6 0%, #ff98e5 100%);
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--white1);
}

.all-notifications-modal__notification-icon {
  display: flex;
  align-items: center;
  width: 17px;
  height: 20px;
}

.all-notifications-modal__close-icon {
  top: 7px;
  color: var(--white1);
}

.all-notifications-modal__content {
  overflow: hidden;
  padding-left: 0;
  padding-right: 0;
  padding-top: 40px;
  padding-bottom: 0;
}

.all-notifications-modal__top {
  padding-left: 14px;
  padding-right: 10px;
}

.all-notifications-modal-pagination__content {
  padding-left: 14px;
  padding-right: 10px;
  padding-bottom: 0;
}

.liquid-pagination__container.all-notifications-modal-pagination__container {
  border: none;
  height: 30px;
  min-height: 30px;
  line-height: 30px;
  border-radius: 0px 0px 20px 20px;
  background-color: var(--purple14);
}

.all-notifications-modal__top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.all-notifications-modal__filters {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding-bottom: 5px;
  align-items: center;
}

.all-notifications-modal__filter {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  opacity: 0.4;
  cursor: pointer;
  outline: none;
}

.all-notifications-modal__filter--active {
  opacity: 1;
}

.all-notifications-modal__filter-border {
  margin-top: 5px;
  width: 80px;
  height: 5px;
  box-shadow: 0px 5px 10px rgba(255, 255, 255, 0.15);
  border-radius: 20px;
  background: var(--pink2);
}

.all-notifications-modal__search-container {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 240px;
  height: 45px;
  align-items: center;
}

.all-notifications-modal__search-icon-container {
  position: absolute;
  display: flex;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  background: var(--blue6);
  border-radius: 0 20px 20px 20px;
  z-index: 5;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.all-notifications-modal__search-icon {
  width: 20px;
  height: 20px;
}

.all-notifications-modal__search-input {
  width: 240px;
  height: 40px;
  border: 0.5px solid var(--all-notifications-modal-font);
  border-radius: 20px;
  background: var(--all-notifications-modal-search-background);
  padding-left: 60px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.all-notifications-modal__select-container {
  width: 120px;
  margin-top: 20px;
}

.all-notifications-modal__trash-reminder {
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 20px;
  color: var(--all-notifications-modal-font);
}

.all-notifications-modal__notifications-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  margin-right: 17px;
  max-height: 500px;
  min-height: 500px;
  overflow-y: auto;
  padding-right: 25.5px;
  padding-left: 20.5px;
  margin-bottom: 45px;
}

.all-notifications-modal__notifications-container--trash {
  max-height: 442px;
  min-height: 442px;
}

.all-notifications-modal-pagination__arrow--next,
.all-notifications-modal-pagination__arrow--previous {
  color: var(--pink1);
}

.liquid-pagination__page.all-notifications-modal-pagination__page {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: var(--white1);
}

.all-notifications-modal__notifications-container::-webkit-scrollbar {
  width: 18px;
  background: transparent;
  border-radius: 20px;
}

.all-notifications-modal__notifications-container::-webkit-scrollbar-track {
  border-radius: 20px;
  background: var(--all-notifications-modal-track-color);
  margin-bottom: 4px;
  margin-top: 4px;
}

.all-notifications-modal__notifications-container::-webkit-scrollbar-thumb {
  background: var(--all-notifications-modal-thumb-color);
  border-radius: 20px;
  background-clip: padding-box;
  border: 4px solid var(--all-notifications-modal-track-color);
  width: 10px;
}

.all-notifications-modal__notification {
  width: 100%;
  min-height: 147px;
  display: flex;
  flex-direction: column;
  background: var(--purple8);
  border-radius: 10px;
  padding: 10px;
  color: var(--white1);
  justify-content: space-between;
}

.all-notifications-modal__notification-title {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}

.all-notifications-modal__notification-text {
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  margin-top: 10px;
}

.all-notifications-modal__notification-bottom {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.all-notifications-modal__notification-buttons {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.all-notifications-modal__notification-date {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.all-notifications-modal__notification--global {
  background: var(--all-notifications-modal-global-background);
}

.all-notifications-modal__notification--specific-user {
  background: var(--all-notifications-modal-specific-user-background);
}

.all-notifications-modal__notification--reminder {
  background: var(--all-notifications-modal-reminder-background);
}

.all-notifications-modal__notification--reward {
  background: var(--all-notifications-modal-reward-background);
}

.notification-modal--global__content,
.notification-modal--specific-user__content,
.notification-modal--reminder__content,
.notification-modal--reward__content {
  width: 300px;
  overflow: hidden;
  padding: 0;
  padding-top: 10px;
}

.notification-modal--global,
.notification-modal--global__header {
  background: var(--notification-center-global-background);
}

.notification-modal--specific-user,
.notification-modal--specific-user__header {
  background: var(--notification-center-specific-user-background);
}

.notification-modal--reminder,
.notification-modal--reminder__header {
  background: var(--notification-center-reminder-background);
}

.notification-modal--reward,
.notification-modal--reward__header {
  background: var(--notification-center-reward-background);
}

.all-notifications-modal__sort-search-wrapper {
  display: flex;
  margin: 0 15px;
  height: 26px;
  align-items: center;
  margin-top: 20px;
  justify-content: space-between;
}

@media (max-width: 800px) {
  .all-notifications-modal {
    max-height: 690px;
    min-height: 690px;
  }
  .all-notifications-modal__content {
    padding-top: 35px;
  }
  .all-notifications-modal__notification {
    min-height: 189px;
    padding: 20px;
  }
  .all-notifications-modal__notifications-container {
    padding-left: 16px;
    padding-right: 4px;
    min-height: 300px;
    max-height: 502px;
    margin-bottom: 0;
    margin-right: 4px;
  }
  .all-notifications-modal__notification-date {
    justify-content: flex-start;
  }
  .all-notifications-modal__search-icon-container {
    width: 25px;
    height: 25px;
  }
  .all-notifications-modal__search-icon {
    width: 12.5px;
    height: 12.5px;
  }
  .all-notifications-modal__search-input {
    height: 25px;
    width: 150px;
    padding-left: 30px;
    font-size: 12px;
    line-height: 15px;
  }
  .all-notifications-modal__search-container {
    height: 25px;
    width: 150px;
  }
}

@media (max-width: 576px) {
  .notification-modal__delete-button,
  .all-notifications-modal__delete-button {
    border: 3px solid var(--red2);
  }
}
