.dashboard-my-assets {
  height: 100%;
  width: 100%;
}

.dashboard-my-assets__content {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
}

.dashboard-my-assets__noAssets {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--black2);
  height: 100%;
}

.dashboard-my-assets__no-assets-wrapper {
  height: 100%;
  width: 100%;
  background-color: var(--my-collectibles-no-assets-background);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 500px;
}

.dashboard-my-assets__no-assets-logo {
  position: absolute;
  z-index: 1;
}

.dashboard-my-assets__no-assets-text {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  z-index: 2;
  color: var(--my-collectibles-no-assets-color);
}

.dashboard-my-assets__cardPreview {
  position: relative;
  max-width: 281.6px;
  width: 33.460076%;
  height: 100%;
  padding: 12.8px;
}

.dashboard-my-assets__productName {
  font-size: 24px;
  line-height: 35px;
  font-weight: 600;
  letter-spacing: 0px;
  color: var(--green1);
  margin-bottom: 3.2px;
  position: relative;
  z-index: 15;
}

.dashboard-my-assets__properties {
  position: relative;
  z-index: 13;
}

.dashboard-my-assets__property {
  font-size: 12.8px;
  margin-bottom: 3.2px;
}

.dashboard-my-assets__propertyValue {
  font-weight: bold;
}

.dashboard-my-assets__previewFooter {
  position: absolute;
  bottom: 49px;
  left: 240px;
  width: 117.045454545%;
  display: flex;
  justify-content: space-between;
  gap: 52px;
  color: var(--black2);
  z-index: 14;
}

.dashboard-my-assets__prices {
  display: flex;
  justify-content: flex-end;
  flex-direction: row-reverse;
  white-space: nowrap;
  z-index: inherit;
}

.dashboard-my-assets__priceUSD {
  text-align: left;
  margin-left: 20px;
  font: normal normal 600 12.8px/20px Poppins;
  letter-spacing: 0px;
  color: var(--white4);
  z-index: 100;
}

.dashboard-my-assets__growth {
  display: flex;
  gap: 3.2px;
  align-items: center;
  text-align: left;
  font: normal normal 600 12.8px/20px Poppins;
  letter-spacing: 0px;
  color: var(--white1);
  z-index: 100;
}

.dashboard-my-assets__growthDown {
  color: var(--red1);
}

.dashboard-my-assets__growthArrowDown {
  transform: rotate(180deg);
  filter: invert(15%) sepia(91%) saturate(7057%) hue-rotate(360deg)
    brightness(108%) contrast(114%);
}

.dashboard-my-assets__buttons {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  z-index: inherit;
}

.dashboard-my-assets__nameSidewaysWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.dashboard-my-assets__nameSideways {
  position: absolute;
  width: 370px;
  top: -75px;
  right: -24px;
  font-style: normal;
  font-weight: 600;
  font-family: Poppins;
  line-height: 95px;
  color: var(--white1);
  opacity: 0.34;
  white-space: nowrap;
  -moz-transform: rotate(-270deg);
  -moz-transform-origin: bottom left;
  -webkit-transform: rotate(270deg);
  -webkit-transform-origin: bottom right;
  -o-transform: rotate(-270deg);
  -o-transform-origin: bottom left;
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
}

.dashboard-my-assets__nameSideways > div > div {
  font-size: inherit;
}

.dashboard-my-assets__slider {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  padding: 25.6px 0;
  overflow: hidden;
  z-index: 10;
  background: var(--my-collectibles-slider-background);
}

.dashboard-my-assets__sliderWrapper {
  display: flex;
  align-items: center;
  height: 100%;
}

.dashboard-my-assets__sliderWrapper .dashboard-my-assets__cards {
  height: 310.4px;
}

.dashboard-my-assets__wrapper {
  width: 100%;
}

.dashboard-my-assets__sliderNavigation {
  display: flex;
  gap: 3.2px;
}

.dashboard-my-assets__sliderPolygon {
  width: 6.4px;
  height: 7.2px;
  color: var(--purple4);
  position: relative;
  transition: all 0.3s ease;
}

.dashboard-my-assets__sliderPolygon--active {
  color: var(--purple1);
}

.dashboard-my-assets__logo {
  position: absolute;
  display: flex;
  margin: auto;
  top: 30px;
  right: 40px;
  z-index: 10;
  pointer-events: none;
  transform: scale(1.3);
}

@media (max-width: 1280px) {
  .dashboard-my-assets__noAssets {
    min-height: 320px;
  }

  .dashboard-my-assets__previewFooter {
    left: 200px;
  }
}

@media (max-width: 762px) {
  .dashboard-my-assets__previewFooter {
    left: 100px;
  }
}

@media (max-width: 576px) {
  .dashboard-my-assets__content {
    flex-direction: column;
  }

  .dashboard-my-assets__noAssets {
    min-height: 502.4px;
  }

  .dashboard-my-assets__cardPreview {
    width: 100%;
    max-width: 100%;
    height: 220px;
    padding: 0;
    padding-top: 20px;
    padding-left: 20px;
  }

  .dashboard-my-assets__productName {
    position: relative;
    font-size: 19.2px;
    line-height: 25.6px;
    margin-bottom: 6.4px;
    z-index: 10;
    max-width: 190px;
  }

  .dashboard-my-assets__properties {
    max-height: 57.6px;
    overflow: hidden;
  }

  .dashboard-my-assets__property {
    width: max-content;
    font-size: 11.2px;
    line-height: 12.8px;
    margin-bottom: 6.4px;
    position: relative;
    z-index: 12;
  }

  .dashboard-my-assets__previewFooter {
    position: static;
  }

  .dashboard-my-assets__prices {
    flex-direction: row-reverse;
    align-items: center;
    gap: 10.92px;
    z-index: 14;
  }

  .dashboard-my-assets__priceUSD {
    font-size: 11.2px;
    line-height: 12.8px;
    color: var(--white1);
  }

  .dashboard-my-assets__growth {
    font-size: 11.2px;
    line-height: 12.8px;
  }

  .dashboard-my-assets__nameSideways {
    display: none;
  }

  .dashboard-my-assets__viewAllWrapper {
    width: 76.8px;
    height: 32px;
    background-color: transparent;
    box-shadow: var(--black4) 0px 3.2px 9.6px;
  }

  .dashboard-my-assets__sliderWrapper {
    flex-direction: column;
  }

  .dashboard-my-assets__sliderWrapper .dashboard-my-assets__cards {
    height: 100%;
    width: 100%;
  }
  .dashboard-my-assets__slider {
    height: 361.6px;
    background-image: url('./my-assets-background-mobile.png');
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
  }
  .dashboard-my-assets__wrapper {
    height: 240px !important;
    min-width: 80px !important;
    padding: 0 0 56px 20px !important;
    background: transparent;
  }

  .dashboard-my-assets__logo {
    display: none;
  }
}

@media (max-width: 380px) {
  .dashboard-my-assets__buttons {
    width: 240px;
  }
}
