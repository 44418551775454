.mobile-page-header {
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--header-popover-background);
  color: var(--header-popover-color);
  border-radius: 0px 25.6px 25.6px 25.6px;
  margin: 0 19.2px 6.4px;
  box-shadow: 0px 2.4px 4.8px var(--black4);
  padding: 0px 19.2px;
}
