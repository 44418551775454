.availability-progress-bar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

.availability-progress-bar__progress-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  min-width: 0;
}

.availability-progress-bar__title {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: var(--availability-text-color);
}

.availability-progress-bar__value {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: var(--availability-text-color);
}

.availability-progress-bar__progress-line-wrapper {
  border-radius: 999px;
  padding: 3px 4px;
  border: 1px solid var(--availability-text-color);
  line-height: 0;
  display: flex;
  flex-direction: row;
  min-width: 0;
}

#availability-progress {
  flex-basis: 100px;
  max-width: 100px;
  height: 6px;
  border: 1px solid var(--availability-progress-border);
  border-radius: 999px;
  min-width: 0;
}

#availability-progress[value]::-webkit-progress-bar {
  border-radius: 999px;
  background: transparent;
}

#availability-progress[value]::-webkit-progress-value {
  background: var(--blue6);
  border-radius: 999px;
}
