.interior__container {
  display: flex;
  position: relative;
  flex-direction: column;
  background: var(--liquid-background-primary);
  flex-grow: 1;
}

/* this rule just disables scrolling when mobile nav bar is opened. for now
mobile nav bar takes entire width, so this content is not visible anyway.
if it changes, remove this rule and use "overflow"*/
.interior__container--mobile-nav > .interior__main-content {
  display: none;
}

.interior__main-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-height: 100%;
  transition: margin-left 0.5s;
  padding-top: 126.4px;
  background: var(--liquid-background-primary);
}

.interior__arrow-left {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0px 73px 34px 0;
  border-color: transparent #007bff transparent transparent;
}

.interior__arrow-right {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0px 0 34px 73px;
  border-color: transparent transparent transparent #007bff;
}

.interior__arrow-bottom {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 73px 0px 0 34px;
  border-color: #007bff transparent transparent transparent;
}

@media (max-width: 800px) {
  .interior__main-content {
    padding-top: 16px;
  }
}
