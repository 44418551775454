.section__container {
  width: 100%;
  height: 100%;
  border-radius: 0 20px 20px 20px;
  position: relative;
}

.section__title-container {
  display: flex;
  justify-content: space-between;
  padding-left: 25.6px;
  padding-bottom: 0;
}

.section__title {
  font-size: 22.4px;
  line-height: 31.2px;
  letter-spacing: 0px;
  color: var(--section-title);
  font-weight: 600;
  padding-top: 25.6px;
}

@media (max-width: 576px) {
  .section__title-container {
    padding-left: 19.2px;
    padding-bottom: 0;
  }

  .section__title {
    color: var(--section-title);
    font-size: 16px;
    line-height: 22.4px;
    padding-top: 11.2px;
  }
}
