.dots-withdraw__spinner {
  width: 100%;
  justify-content: center;
  display: flex;
}
.dots-withdraw__no-2fa-wrapper {
  height: 300px;
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 30px;
}
.dots-withdraw__no-2fa-text {
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.5px;
  color: var(--side-pane-details__header-bg-color);
}

.dots-withdraw__btn {
  width: 182px;
  margin: 0;
  height: 40px;
  border: 1px solid var(--black2);
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
  background: var(--white1);
  color: var(--black2);
}

.dots-withdraw__btn:hover,
.dots-withdraw__btn:focus {
  border: 1px solid var(--white1);
  color: var(--white1);
  background: var(--purple1);
}
#dots-flow {
  width: 100%;
  height: 800px;
  overflow-y: hidden;
}

#dots-flow html {
  overflow-y: hidden;
}

.dots-withdraw__frame {
  color: var(--deposit-withdraw-font-primary);
}

.dots-withdraw__error-message {
  position: relative;
  color: red;
  font-size: 1.4rem;
  margin-left: 20px;
  bottom: 25px;
}

.dots-withdraw__error-message::before {
  display: inline;
  content: '⚠ ';
}

.dots-withdraw__error-message__message {
  bottom: 15px;
}

.dots-withdraw__agreement {
  display: flex;
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.dots-withdraw__checkbox {
  margin: auto;
}

.dots-withdraw__agreement-description {
  font-weight: 300;
  font-size: 12px;
  color: var(--deposit-withdraw-font-primary);
}

.dots-withdraw__footer {
  padding: 0 2.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 10.6rem;
  height: auto;
}

.dots-withdraw__notice {
  font-size: 12px;
  line-height: 18px;
  font-weight: 300;
  text-align: center;
}

.dots-withdraw__input-wrapper {
  text-align: left;
}

.dots-withdraw__label {
  margin: 0 0 1.2rem;
  font-size: 1.4rem;
  line-height: 1.4rem;
}

.dots-withdraw__input {
  width: 100%;
  background: var(--deposit-withdraw-background-secondary);
  border-radius: 20px;
  border: 1px solid var(--deposit-withdraw-border-color);
  height: 4.4rem;
  font-size: 1.6rem;
  min-width: 20rem;
}

.dots-withdraw__label {
  margin: 0 0 1.2rem;
  font-size: 1.4rem;
  line-height: 1.4rem;
  width: 25rem;
  justify-content: flex-start;
  color: var(--deposit-withdraw-font-primary);
  font-weight: 300;
}

.dots-withdraw__input-box {
  display: block;
  width: 100%;
  height: 4.4rem;
}

.dots-withdraw--request__btn {
  margin: auto;
}

.react-tel-input .form-control.dots-withdraw__phone-input {
  width: 100%;
  height: 4.4rem;
  font-size: 1.6rem;
  border-radius: 20px;
  background: var(--deposit-withdraw-background-secondary);
  border: 1px solid var(--deposit-withdraw-border-color);
}

.dots-withdraw__phone-input-container:hover .dots-withdraw__phone-input,
.dots-withdraw__phone-input-container:hover
  .dots-withdraw__phone-input-dropdown {
  background: var(--input__bg-color--hover);
  border: 1px solid var(--input__border-color--hover) !important;
}

.react-tel-input .form-control.dots-withdraw__phone-input:focus {
  background: var(--input__bg-color--focus);
  border: 1px solid var(--input__border-color--focus);
}

.react-tel-input .flag-dropdown.dots-withdraw__phone-input-dropdown {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border: 1px solid var(--deposit-withdraw-border-color);
  background: var(--deposit-withdraw-background-secondary);
}

.react-tel-input
  .flag-dropdown.dots-withdraw__phone-input-dropdown
  .selected-flag,
.react-tel-input
  .flag-dropdown.open.dots-withdraw__phone-input-dropdown
  .selected-flag,
.react-tel-input .flag-dropdown.open.dots-withdraw__phone-input-dropdown {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.dots-withdraw__phone-input-container {
  margin-bottom: 2rem;
}

.dots-withdraw__loader-wrapper {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dots-withdraw__loader__spinner-container {
  margin: 0;
}

.dots-withdraw__brands-wrapper {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.dots-withdraw__brand {
  width: 32px;
  height: 32px;
}

.dots-withdraw__brand--visa {
  width: 64px;
}

.dots-withdraw__brand--bank-transfer {
  width: 70px;
  height: 53px;
  margin-top: 10px;
}

@media (max-width: 576px) {
  .dots-withdraw__frame {
    max-height: 500px;
    width: 100%;
  }
}
