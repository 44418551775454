.modal-header__container {
  height: 4rem;
  display: flex;
  background-color: var(--buy-sell-modal__button-border-color);
  align-items: center;
  padding: 0 1.5rem;
  color: var(--white1);
  gap: 1.5rem;
}

.modal-header__container--buy {
  background-color: var(--buy-sell-modal__button-border-color--buy);
}

.modal-header__back-button {
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 1.4rem;
}

.modal-header__back-button--with-outline {
  outline: 0.1rem solid transparent;
  transition: outline var(--transition__time) ease;
}

.modal-header__back-button--with-outline:focus {
  outline-color: white;
}

.modal-header__back-button--with-outline:hover {
  outline-color: white;
}

.modal-header__back-button-icon {
  width: 0.93rem;
  height: 0.93rem;
  filter: invert(100%);
}

.modal-header__title {
  font-size: 1.4rem;
  font-weight: 500;
  flex: 1 1;
}

.modal-header__finish-icon--buy .icon-modal-failure__symbol,
.modal-header__finish-icon--buy .icon-modal-success__symbol {
  fill: var(--buy-sell-modal__button-border-color--buy);
  stroke: var(--buy-sell-modal__button-border-color--buy);
}
