.deposit-form__title,
.cryptowallet-withdraw__title {
  color: var(--text-primary);
  padding-bottom: 2rem;
}

.deposit-form__body {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
}

.cryptowallet-withdraw__address-container {
  display: flex;
  padding: 2rem;
  background: var(--primary__bg-color);
  justify-content: space-between;
}

.cryptowallet-withdraw__qrcode {
  margin-right: 2rem;
}

.deposit-form__select-wrapper,
.deposit-form__input-wrapper {
  padding-bottom: 2.6rem;
}

.deposit-form__select,
.deposit-form__input {
  height: 4.4rem;
  font-size: 1.6rem;
  border-radius: 0.3rem;
  min-width: 20rem;
  background: var(--secondary__bg-color);
  border: 1px solid var(--border__color);
}

.inline__info {
  width: 100%;
  text-align: right;
}

.deposit-form__close__btn {
  margin-top: 1rem;
}

.deposit-form__form-body {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.deposit-form__select-wrapper,
.deposit-form__input-wrapper {
  margin: 1rem;
  text-align: left;
}

.deposit-form__select-label,
.deposit-form__label {
  margin: 0 0 1.2rem;
  font-size: 1.4rem;
}

.deposit-form__input-box {
  display: block;
  width: 100%;
}

.deposit-form__success {
  display: flex;
  height: 10rem;
  align-items: center;
  justify-content: center;
}

.deposit-form__error {
  padding: 2rem;
}

.deposit-form__btn {
  width: calc(100% - 2rem);
  margin: 1rem;
  height: 5rem;
  border-radius: 0.3rem;
  font-size: 1.4rem;
}
.deposit-form__template-body,
.deposit-form__template-footer {
  text-align: center;
  margin-top: 2rem;
  padding-top: 2rem;
  transition: none;
  border-top: 1px solid var(--border__color-secondary);
}

.deposit-form__spinner-container {
  margin-top: 2rem;
  padding-top: 2rem;
  transition: none;
  border-top: 1px solid var(--border__color-secondary);
  position: initial;
  margin-left: 0;
}

.deposit-form__spinner-circle {
  margin: 0;
}

.deposit-form__label-in-input {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  width: 5rem;
  font-size: 1.4rem;
  margin-left: 0.2rem;
}

.deposit-form__with-label {
  padding-left: 6rem;
}

.deposit-form__bank-info-label {
  padding: 0 1rem 1.5rem;
  color: var(--font__color-secondary);
  font-size: 1.4rem;
}

.deposit-form__bank-info {
  border: 1px solid var(--border__color);
  padding: 1.5rem 2.3rem;
  margin: 1rem 1rem 4rem;
}

.deposit-form__lwt-container {
  justify-content: unset;
}

.deposit-form__lwt-container--template-info {
  justify-content: space-between;
}

.deposit-form__lwt-label {
  min-width: 15rem;
}

.deposit-form__spinner-container {
  margin-top: 2rem;
  padding-top: 2rem;
  transition: none;
  border-top: 1px solid var(--border__color);
  position: initial;
  margin-left: 0;
}

.deposit-form__spinner-circle {
  margin: 0;
}

.deposit-form__frame {
  padding: 1rem 2.2rem 0 2.2rem;
  display: flex;
  flex-direction: column;
}

.deposit-form__fields {
  height: 100%;
}

/* MEDIA QUERIES */

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .sidepane__main-form {
    width: calc(100% - 12rem);
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .sidepane__main-form {
    width: unset;
  }
}

.deposit-form__paypal {
  display: flex;
  margin-bottom: 30px;
  font-size: 1.4rem;
  height: fit-content;
  min-height: 3.6rem;
  flex: 1 1;
  line-height: 3.6rem;
  text-align: center;
  cursor: pointer;
  color: var(--deposit-withdraw-font-primary);
  border: 1px solid var(--purple8);
  border-radius: 20px;
  margin: 0.4rem;
  padding-left: 5px;
  padding-right: 5px;
  outline: none;
  justify-content: center;
  align-items: center;
}

.deposit-form__paypal--selected,
.deposit-form__paypal:hover {
  background: var(--purple8);
  color: var(--white1);
}

.deposit-form__source {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 5px;
  box-sizing: border-box;
  flex-wrap: wrap;
  gap: 10px;
}

.deposit-form__source-item {
  font-size: 1.4rem;
  height: fit-content;
  min-height: 3.6rem;
  flex: 1 1;
  line-height: 3.6rem;
  text-align: center;
  cursor: pointer;
  color: var(--deposit-withdraw-font-primary);
  border: 1px solid var(--purple8);
  border-radius: 20px;
  margin: 0.4rem;
  padding-left: 5px;
  padding-right: 5px;
  outline: none;
  max-width: 210px;
  min-width: 130px;
}

.deposit-form__source-item--selected,
.deposit-form__source-item:hover {
  background: var(--purple8);
  color: var(--white1);
}

.deposit-form__source-item-with-border {
  flex: 1 1;
}

.deposit-form__send-to {
  width: 100%;
  margin-bottom: 40px;
}

.deposit-form__send-to-friend {
  font-size: 1.4rem;
  padding: 3rem 3.7rem 3rem 3.7rem;
}

@media (max-width: 576px) {
  .modal-with-currency__content {
    width: calc(100% - 40px);
  }
  .deposit-form__send-to {
    width: calc(100% - 40px);
    margin-left: 0;
    position: fixed;
    top: 225px;
    z-index: 10;
    background-color: var(--deposit-withdraw-background-primary);
  }

  .deposit-form__source {
    width: 100%;
  }

  .deposit-form__source-item,
  .deposit-form__paypal {
    height: 26px;
  }
}
