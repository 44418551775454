.market-product-card__card-wrapper {
  width: 172.8px;
}

.market-product-card__card {
  padding: 17px 9.6px;
  border-radius: 0px 0px 12.8px 12.8px;
  position: relative;
  z-index: 1;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.market-product-card__placeholder {
  position: absolute;
  bottom: -6px;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: 12.8px;
  overflow: hidden;
}

.market-product-card__placeholder-image {
  border-top-left-radius: 12.8px;
  border-top-right-radius: 12.8px;
  width: 100%;
  height: 100%;
  padding-bottom: 8px;
  transform: scaleY(0);
  transform-origin: bottom center;
  transition: transform 0.5s;
}

.market-product-card__card:hover .market-product-card__placeholder-image {
  transform: scaleY(1);
}

.market-product-card__card:hover .market-product-card__card-image {
  transform: translatex(24px);
  transition: 0.5s ease-in-out;
}

.market-product-card__card:hover .market-product-card__card-title {
  color: var(--white1);
}

.market-product-card__card:hover .market-product-card__highlight-line {
  background: var(--white1);
}

.market-product-card__card:hover .market-product-card__card-price {
  color: var(--white1);
}

.market-product-card__card:hover .market-product-card__card-price-change {
  color: var(--white1);
}

.market-product-card__card-price-change {
  font-size: 12px;
  line-height: 16.8px;
  color: var(--market-product-card-color);
  padding-left: 6.4px;
  display: flex;
  margin-bottom: 10px;
}

.market-product-card__warning-wrapper {
  align-self: center;
}

.market-product-card__warning-wrapper--hidden {
  visibility: hidden;
}

.market-product-card__card:hover .market-product-card__arrowUp .arrow-up-icon {
  fill: var(--white1);
}

.market-product-card__card:hover
  .market-product-card__arrowDown
  .arrow-up-icon {
  fill: var(--white1);
}

.market-product-card__arrowUp .arrow-up-icon,
.market-product-card__arrowDown .arrow-up-icon {
  fill: var(--market-product-card-color);
}

.market-product-card__arrowUp,
.market-product-card__arrowDown {
  fill: var(--market-product-card-color);
  height: 11.2px;
  width: 11.2px;
  margin-right: 3.2px;
  padding-top: 1px;
}

.market-product-card__arrowDown {
  transform: rotate(180deg);
  padding-bottom: 15.2px;
  padding-top: 0px;
}

.market-product-card__card-title {
  color: var(--market-product-card-color);
  font-size: 14.4px;
  line-height: 20px;
  font-weight: 600;
  position: relative;
  padding-left: 6.4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.market-product-card__highlight-line {
  width: 44.4px;
  height: 2px;
  background: var(--market-product-card-color);
  opacity: 0.4;
  margin-left: 6.4px;
  bottom: -3.2px;
}

.market-product-card__card-price {
  color: var(--market-product-card-color);
  font-weight: bold;
  font-size: 30.4px;
  font-weight: 600;
  display: flex;
  align-items: flex-end;
  margin-top: 3.2px;
  padding-left: 6.4px;
}

.market-product-card__card-price--decimal {
  color: var(--market-product-card-color);
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 2.4px;
  padding-left: 0px;
}

.market-product-card__card-price--currency {
  color: var(--market-product-card-color);
  font-size: 12px;
  margin-bottom: 2.4px;
  font-weight: normal;
  padding-left: 6.4px;
}

.market-product-card__card-image {
  width: 153.6px;
  height: 217.6px;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.5s ease-in-out;
  margin-top: 11px;
  box-shadow: -6.4px 6.4px 12px #00000033;
}

.market-product-card__buttons {
  display: flex;
  justify-content: center;
  margin-top: 12.8px;
  padding-bottom: 9.6px;
  gap: 20px;
}

.market-product-card__vote-button__voted,
.market-product-card__vote-button__incoming {
  margin: auto;
  margin-top: 10px;
}

@media (max-width: 576px) {
  .market-product-card__card-wrapper {
    width: auto;
    align-items: center;
    max-width: 236.8px;
  }
  .market-product-card__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: none !important;
    padding-bottom: 0px;
    width: 100%;
  }
  .market-product-card__placeholder {
    transform: scale(1);
    width: 217.6px;
    left: auto;
    right: auto;
  }
  .market-product-card__placeholder-image {
    transform: scaleY(1);
  }
  .market-product-card__card-image {
    width: 236.8px;
    height: 336px;
    margin-top: 11px;
  }
  .market-product-card__card-title {
    padding-left: 10px;
    padding-right: 10px;
    align-self: flex-start;
    color: var(--white1);
    width: 100%;
  }
  .market-product-card__highlight-line {
    margin-left: 10px;
    background: var(--white1);
  }
  .market-product-card__card-price {
    padding-left: 10px;
    align-self: flex-start;
    line-height: 42.4px;
    color: var(--white1);
  }
  .market-product-card__card-price--decimal {
    padding-left: 0;
    align-self: flex-end;
    line-height: 26.4px;
    color: var(--white1);
  }
  .market-product-card__card-price--currency {
    padding-left: 0px;
    align-self: flex-end;
    line-height: 26.4px;
    color: var(--white1);
  }
  .market-product-card__card-price-change {
    padding-left: 10px;
    align-self: flex-start;
    color: var(--white1);
    margin-bottom: 10px;
  }
  .market-product-card__buttons {
    margin-top: 20px;
    padding-bottom: 0px;
    flex-direction: row;
  }
  .market-product-card__arrowUp .arrow-up-icon,
  .market-product-card__arrowDown .arrow-up-icon {
    fill: var(--white1);
  }
  .market-product-card__highlight-line {
    align-self: flex-start;
  }

  .market-product-card__warning-wrapper {
    padding-left: 10px;
    align-self: flex-start;
  }
  
  .market-product-card__vote-button__voted,
  .market-product-card__vote-button__incoming {
    margin-top: 20px;
  }
}
