.price-warning-tag {
  display: inline-block;
  background-color: var(--green4);
  color: var(--white1);
  padding: 2px 12px;
  /* always half the smallest side */
  border-radius: 9999px;
  text-transform: uppercase;
  font-size: 8px;
  line-height: 12px;
  font-weight: 500;
  text-align: center;
}
