.order-history-table__table {
  width: 100%;
}

.order-history-table__column {
  flex: 1 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border: 1px solid var(--order-history-header-border);
  background: var(--order-history-table-column-background);
  color: var(--order-history-table-column-color);
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
}

.order-history-table__column:first-child {
  border-left: none;
}

.order-history-table__column:last-child {
  border-right: none;
}

.order-history-table__row {
  height: 2.8rem;
  border-bottom: none;
}

.order-history-table__row .flex-table__row {
  line-height: auto;
}

.order-history-table__th {
  padding: 0 0.5rem;
  height: 2.5rem;
  text-align: center;
  font-size: 0.9rem;
  color: var(--font__color-secondary);
  border-bottom: 1px solid var(--table__row-separator-color);
}

.order-history-table__td {
  padding: 0 0.4rem;
  text-align: center;
}

.order-history-table__td--size,
.order-history-table__td--price {
  text-align: right;
  padding: 0 1rem;
  width: 8rem;
}

.order-history-table__td--exchange-colors__buy {
  color: var(--exchange-colors__buy);
}

.order-history-table__td--exchange-colors__sell {
  color: var(--exchange-colors__sell);
}

.order-history-table__td--action {
  width: 8rem;
  padding: 0 1rem;
  text-align: center;
}

.order-history-table__buy {
  color: var(--exchange-colors__buy);
}

.order-history-table__sell {
  color: var(--exchange-colors__sell);
}

.order-history-table__size,
.order-history-table__price {
  color: var(--text-primary);
  overflow: hidden;
  text-overflow: ellipsis;
}

.order-history-table__body--multipage {
  overflow: hidden;
}

.order-history-table__body--single-page {
  height: 100%;
  overflow: auto;
}

.order-history-table__body {
  -webkit-overflow-scrolling: touch;
  background: var(--order-history-table-background);
}

.order-history-table__table-header:not(.flex-table__header--background) {
  height: var(--component__sub-header-height);
  margin-top: calc(var(--component__sub-header-height) * -1);
}

.order-history-table__header-column {
  border-left: 1px solid var(--order-history-header-border);
  border-right: 1px solid var(--order-history-header-border);
  border-bottom: 1px solid var(--order-history-header-border);
  height: var(--component__sub-header-height);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--order-history-table-column-header-background);
}

.order-history-table__header-column:first-of-type {
  border-left: 0;
}
.flex-table__header-cell.order-history-table__fixed {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
}

.order-history-table-pagination__content {
  height: calc(100% - 40px) !important;
}

.order-history-table-pagination__page {
  border: none !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--pagination-page-number-color);
}

.order-history-table-pagination__text {
  font-weight: 300;
  font-size: 12px;
  line-height: 12px;
  color: var(--pagination-next-prev-color);
}

.order-history-table-pagination__text--disabled:hover,
.order-history-table-pagination__text--disabled {
  opacity: 0.5;
  color: var(--pagination-next-prev-color);
}

.order-history-table-pagination__arrow svg:not(:root) {
  width: 7px;
  height: 6px;
  margin-bottom: 1px;
}

/* BROWSER QUERIES */

/* IE10+ specific styles */
_:-ms-fullscreen,
:root .order-history-table__fixed {
  position: relative;
  text-align: center;
}

@media only screen and (max-width: 1208px) {
  .order-history-table-pagination__content {
    height: calc(100% - 42px) !important;
  }
}

@media (max-width: 576px) {
  .flex-table__header-cell.order-history-table__fixed,
  .order-history-table__column {
    font-size: 8px;
    line-height: 12px;
  }
}
