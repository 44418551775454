input:focus ~ .ap-signup-input__placeholder-in-input,
input:not(:focus):valid ~ .ap-signup-input__placeholder-in-input,
input:-webkit-autofill ~ .ap-signup-input__placeholder-in-input {
  top: 0px;
  left: 7.2px;
  font-size: 12.8px;
  height: 18.4px;
  padding: 0 4px;
  margin-top: -8px;
}

.ap-signup-input__placeholder-in-input {
  position: absolute;
  pointer-events: none;
  left: 19.2px;
  top: 10px;
  transition: 0.2s ease all;
  height: 19.2px;
  background-color: #fafcff;
  letter-spacing: 0px;
  color: rgba(25, 27, 32, 0.5);
  font-size: 16px;
  line-height: 19.2px;
}

.ap-signup-input__error {
  color: var(--red2);
  margin-left: 20px;
  margin-top: 5px;
  max-width: 250px;
}
