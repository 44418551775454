.login-page__container {
  margin: auto;
  width: 576px;
  min-height: 420.8px;
  padding: 36.8px 0 32px 0;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 6.4px 19.2px #00000066;
  border-radius: 0px 28.8px 28.8px 28.8px;
  background: #fafcff;
}

@media (max-width: 576px) {
  .login-page__container {
    width: auto;
    min-height: auto;
    border-radius: 20px;
    padding: 28px 0px 30px 0px;
    margin: 0 33px;
  }
}
