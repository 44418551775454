.affiliate__tag {
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
}

.affiliate__container-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.affiliate__no-tag {
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
}

.affiliate__tag-container {
  display: flex;
  flex-direction: column;
  gap: 9px;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
}

.affiliate__tag-input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 999px;
  padding: 7.5px 16px;
  background: var(--settings__affiliate-input-bg-color);
  border: 1px solid var(--settings__affiliate-border-color);
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  transition: none;
}

.affiliate__tag-input-container[disabled] {
  color: var(--settings__affiliate-input-color--disabled);
}

.affiliate__tag-input-container:focus-within {
  border-color: transparent;
  background: linear-gradient(var(--settings__affiliate-input-bg-color), var(--settings__affiliate-input-bg-color)) padding-box,
    linear-gradient(131.67deg, var(--blue2), var(--purple1)) border-box;
}

.affiliate__tag-input-container>:not(.affiliate__tag-input) {
  flex-shrink: 0;
}

.affiliate__tag-input {
  flex-grow: 1;
  width: 0;
  min-width: 40px;
  text-overflow: ellipsis;
  background: transparent !important;
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  padding: 0;
}

.affiliate__btn-icon-wrapper,
.affiliate__icon,
.affiliate__icon svg:not(:root) {
  width: 16px;
  height: 16px;
}

.affiliate__icon--edit {
  color: var(--font__color-primary);
}

.affiliate__icon .square {
  fill: transparent;
  stroke: currentColor;
}

.affiliate__btn-container {
  align-self: stretch;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-end;
}

.affiliate__btn-container--no-tag {
  justify-content: flex-start;
}

.affiliate__btn {
  min-width: 140px;
  padding: 7px 23.5px;
  border-radius: 999px;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 3px solid;
  text-transform: capitalize;
  background: var(--buttons-buy-background);
  border-color: var(--buttons-buy-hover-background);
  color: var(--font__color-primary);
  transition: none;
}

.affiliate__btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.affiliate__btn--copy {
  border-color: transparent;
  background: linear-gradient(var(--buttons-buy-background), var(--buttons-buy-background)) padding-box,
    linear-gradient(131.67deg, var(--blue2), var(--purple1)) border-box;
}

.affiliate__btn--copy:hover,
.affiliate__btn--copy:focus {
  background: linear-gradient(131.67deg, var(--blue2), var(--purple1)) border-box;
  color: var(--buttons-buy-hover-text-color);
}

.affiliate__btn--save {
  border-color: var(--buttons-sell-hover-background);
}

.affiliate__btn--save:hover:enabled,
.affiliate__btn--save:focus:enabled {
  background: var(--buttons-sell-hover-background);
  color: var(--buttons-buy-hover-text-color);
}

.affiliate__btn--cancel {
  background: var(--buy-sell-modal-additional-button-background-color);
  border: var(--buy-sell-modal-additional-button-border);
  color: var(--buy-sell-modal-additional-button-color);
}

.affiliate__btn--cancel:hover,
.affiliate__btn--cancel:focus {
  background: var(--buy-sell-modal-additional-button-border-color);
  color: var(--buy-sell-modal-additional-button-background-color);
}

.affiliate__btn--create,
.affiliate__btn--create:hover,
.affiliate__btn--create:focus {
  border-color: transparent;
  background: linear-gradient(131.67deg, var(--blue2), var(--purple1)) border-box;
  color: var(--buttons-buy-hover-text-color);
}

/* MEDIA QUERIES */

@media (max-width: 576px) {
  .affiliate__btn-container {
    justify-content: center !important;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .affiliate__tag-input-container {
    padding: 4px 10px;
    font-size: 10px;
  }

  .affiliate-modal__input {
    min-width: 100%;
  }

  .affiliate-modal__footer {
    margin: 0;
  }

  .affiliate-modal__btn {
    width: 100%;
    margin: 2.5rem 0 0;
  }
}
