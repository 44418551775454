.vote-buttons {
}

.vote-buttons__voted,
.vote-buttons__incoming {
  width: 180px;
  max-width: 100%;
  height: 40px;
  background: var(--vote-button-background-voted);
  color: var(--vote-button-font-voted);
  border-radius: 20px;
  font-size: 14px;
  font-weight: 600;
}

.vote-buttons__incoming {
  background: var(--vote-button-background);
  color: var(--vote-button-font);
}

.vote-buttons__on-vote-buttons {
  display: flex;
  gap: 40px;
}

.vote-buttons__vote-yes,
.vote-buttons__vote-no {
  width: 240px;
  height: 40px;
  border-radius: 20px;
  background: var(--vote-button-background);
  color: var(--vote-button-font);
  font-weight: 600;
}

.vote-buttons__vote-yes {
  border: double 3px transparent;
  background-image: linear-gradient(
      var(--vote-button-background),
      var(--vote-button-background)
    ),
    linear-gradient(90deg, #7c5ced 0%, #53d660 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

.vote-buttons__vote-yes:hover {
  color: var(--white1);
  background-image: linear-gradient(90deg, #7c5ced, #53d660),
    linear-gradient(90deg, #7c5ced 0%, #53d660 100%);
}

.vote-buttons__vote-no {
  border: double 3px transparent;
  background-image: linear-gradient(
      var(--vote-button-background),
      var(--vote-button-background)
    ),
    linear-gradient(90deg, #e02323 0%, #825af6 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

.vote-buttons__vote-no:hover {
  color: var(--white1);
  background-image: linear-gradient(90deg, #e02323, #825af6),
    linear-gradient(90deg, #e02323 0%, #825af6 100%);
}

.vote-buttons__incoming {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid var(--vote-button-font);
}

.vote-buttons__clocks-icon {
  display: flex;
  width: 20px;
  margin-right: 20px;
  fill: var(--vote-button-font);
}

@media (max-width: 576px) {
  .vote-buttons__on-vote-buttons {
    gap: 20px;
  }

  .vote-buttons__vote-yes,
  .vote-buttons__vote-no {
    width: 220px;
    height: 40px;
    border-radius: 20px;
    background: var(--vote-button-background);
    color: var(--vote-button-font);
    font-weight: 600;
  }
}
