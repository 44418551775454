.order-history__tab {
  height: 100%;
  background: var(--primary__bg-color);
  border: 1px solid var(--order-history-header-border);
  border-bottom-left-radius: 20px;
}

.order-history__tab-content .flex-table__wide-row-container::-webkit-scrollbar {
  height: 0.5rem;
}

.order-history__menu {
  overflow-x: auto;
  max-width: var(--balances-order-history-tab-menu__max-width);
}

.order-history__menu-item {
  border: 1px solid var(--order-history-header-border) !important;
  background: var(--order-history-header-background) !important;
  color: var(--order-history-header-color) !important;
  opacity: 1 !important;
}

.order-history-table-pagination__container {
  border-top: 1px solid var(--order-history-header-border) !important;
  border-bottom-left-radius: 20px;
  background-color: var(--order-history-pagination-background);
}

.order-history__tab-content {
  border: 1px solid var(--order-history-header-border) !important;
  height: calc(100% - 41px) !important;
}

.order-history__menu::-webkit-scrollbar-corner {
  background: transparent;
}

.order-history__menu-item--active {
  background: var(--order-history-header-active-background) !important;
  color: var(--order-history-header-active-color) !important;
}

.order-history__menu-item--active:before {
  border-bottom: none !important;
}

.order-history__tab--full-height {
  flex: 1;
  min-height: auto;
  max-height: unset;
  height: auto;
  border-top: 1px solid var(--table__header-separator-color);
}

.order-history__table-td--tooltip {
  text-align: left;
}

.order-history__menu-item {
  padding: 0 1rem;
}

.order-history__menu-item--full-height {
  position: relative;
  border-right: 0;
  letter-spacing: 0.04rem;
  max-width: 15rem;
  padding: 0 1rem;
}

.order-history__menu-item--full-height::after {
  content: '';
  width: 1px;
  height: 112%;
  position: absolute;
  top: -2px;
  right: 0;
  bottom: 0;
  border-right: 1px solid var(--table__row-separator-color);
}

.order-history__header-with-filter {
  display: flex;
  height: 5.2rem;
  align-items: center;
  justify-content: space-between;
  background: var(--tab__bg-color);
}

.order-history__header-with-filter-title {
  padding-left: 2.3rem;
  font-size: 1.4rem;
  letter-spacing: 0.4px;
  color: var(--font__color-primary);
}

.order-history__header-with-filter-datapicker {
  display: flex;
  padding-right: 1.2rem;
}

.order-history__from__dpk-wrapper,
.order-history__to__dpk-wrapper {
  display: flex;
  align-items: center;
}

.order-history__to__dpk-wrapper {
  margin-left: 1.1rem;
}

.order-history__from__dpk-label,
.order-history__to__dpk-label {
  margin: 0;
  padding-right: 1.1rem;
}

.order-history__from__dpk-input,
.order-history__to__dpk-input {
  width: 12.8rem;
}

.order-history__clear__btn {
  margin-left: 2rem;
}

.order-history-table__image--cancel,
.order-history-table__image--cancel svg {
  width: 1.4rem;
  height: 1.4rem;
}

@media only screen and (max-width: 1208px) {
  .order-history__tab-content {
    border: none !important;
    height: calc(100% - 39px) !important;
  }

  .order-history__tab {
    border: 1px solid var(--order-history-mobile-header-border) !important;
    height: 270px !important;
    min-height: 270px !important;
    border-bottom-right-radius: 20px;
  }

  .order-history-table-pagination__container {
    margin-bottom: 2px;
  }

  .order-history-table-pagination__pages {
    display: block !important;
  }

  .order-history-table-pagination__container {
    justify-content: center !important;
  }
}

@media only screen and (max-width: 750px) {
  .order-history__tab-content {
    height: calc(100% - 41px) !important;
  }
}
