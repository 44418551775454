.trade-report-page {
  width: 100%;
  position: relative;
  z-index: 0;
  background: var(--settings__body-bg-color);
  max-width: var(--settings__body-max-width);
  height: var(--settings__body-height);
  margin: var(--settings__body-margin);
  padding: var(--settings__body-padding);
}

.trade-report-page__activity-report {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  position: relative;
  justify-content: space-between;
  flex-wrap: wrap;
  background: var(--settings__body-header-bg-color);
  margin: var(--settings__body-header-margin);
}

.trade-report-page__activity-report-header {
  padding-right: 1rem;
  padding-bottom: 1rem;
  max-width: calc(var(--retail-setting__body-max-width) / 2);
  min-width: var(--retail-settings__retail-text-min-width);
  flex: 1;
}

.trade-report-page__activity-report-header:before {
  display: var(--settings__accent-bar-display);
  content: '';
  background-image: var(--accent-bar__gradient);
  min-width: 100vw;
  height: calc( 100% + (var(--settings__body-header-margin) * 2) + (var(--settings__body-padding) * 4) );
  position: absolute;
  z-index: -1;
  top: calc(((var(--settings__body-padding) * 2) + 2px) * -1);
  bottom: 0;
  left: calc( ( var(--side-menu__item-width) + var(--settings__body-header-margin) + var(--settings__body-padding) + 2px ) * -1 );
  right: 0;
}


.trade-report-page__report-header {
  border-top-left-radius: var(--th-settings__trade-reports-border-radius);
  border-top-right-radius: var(--th-settings__trade-reports-border-radius);
} 

.schedule-report-list__container .flex-table__wide-row-container {
  border-bottom-left-radius: var(--th-settings__trade-reports-border-radius);
  border-bottom-right-radius: var(--th-settings__trade-reports-border-radius);
}

.trade-report-page__singal-report {
  border-radius: var(--th-settings__trade-reports-border-radius);
}

.trade-report-page__singal-report .flex-table__wide-row-container {
  border-bottom-left-radius: var(--th-settings__trade-reports-border-radius);
  border-bottom-right-radius: var(--th-settings__trade-reports-border-radius);
}

.trade-report-page__activity-report-header-title{
  height: auto;
  margin: 0;
  font-size: var(--settings__body-header-title-font-size);
  color: var(--settings__body-header-title-font-color);
  letter-spacing: var(--settings__body-header-title-letter-spacing);
  padding-bottom: var(--settings__body-header-title-padding-bottom)
}

.trade-report-page__activity-report-header-text {
  font-size: var(--settings__body-header-sub-title-font-size);
  color: var(--settings__body-header-sub-title-font-color);
  letter-spacing: var(--settings__body-header-sub-title-letter-spacing);
  line-height: var(--settings__body-header-sub-title-line-height);
  padding-bottom: 0;
}

.trade-report-page__activity-report-buttons {
  display: flex;
  flex-direction: column;
  max-width: 30rem;
  width: 30rem;
}

.trade-report-page__report-header {
  display: flex;
  align-items: center;
  color: var(--settings__card-header-title-font-color);
  background: var(--settings__card-header-bg-color);
  height: var(--settings__body-header-with-border-height);
  padding: var(--settings__card-header-padding);
  justify-content: space-between;
  border-bottom: var(--settings__card-header-accent-border);
  font-size: var(--settings__body-header-title-font-size);
  letter-spacing: var(--settings__card-header-title-letter-spacing);
  line-height: 3.6rem;
}

.trade-report-page__cyclical-report {
  margin-bottom: 2rem;
}

.report-form__datapicker-container .ap-datepicker__input-wrapper{
  display: block
}

.report-form__datapicker-container .report-form__input,
.report-form__datapicker-container .report-form__dpk-input {
  width: 19rem;
}