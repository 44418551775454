.login-form__form {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 21.6px;
}
.login-form__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-form__logo-container {
  width: 76.8px;
  height: 70.4px;
}

.login-form__logo {
  width: 76.8px;
  height: 70.4px;
}

.login-form__title-wrapper {
  display: flex;
}

.login-form__quid-logo {
  width: 130px;
  height: 145px;
  background-image: url(../../../images/onboarding-logo.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: -55px;
  margin-left: -95px;
  margin-right: 30px;
}

.login-form__title {
  font-size: 28px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.login-form__subtitle {
  background: -webkit-linear-gradient(
    131.67deg,
    var(--blue2) 0%,
    var(--purple1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.login-form__problem-login-in {
  width: 100%;
  text-align: center;
  line-height: 18.4px;
  text-decoration: underline;
}

.login-form__problem-login-in:hover,
.login-form__sign-up:hover {
  color: #373946;
}

.login-form__new-one {
  width: 100%;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 20px;
}

.login-form__sign-up {
  width: 100%;
  text-align: center;
  text-decoration: underline;
  font-size: 12px;
}
.login-form__input-wrapper {
  padding-bottom: 11.2px;
}

.login-form__input {
  background-color: #fafcff;
  color: #191b20;
  border: 1px solid var(--purple1);
  border-radius: 20px;
  height: 40px;
  width: 400px;
  font-size: 18px;
  line-height: 40px;
  padding-left: 19px;
}

.login-form__validation-error,
.login-form__input:hover .login-form__validation-error,
.login-form__input:focus .login-form__validation-error {
  border-color: var(--danger__color) !important;
}

.login-form__input:hover,
.login-form__input:focus {
  border: 1px solid var(--purple1);
  background: transparent;
}

.login-form__input::placeholder {
  font-weight: 600;
  color: var(--grey13);
}

.login-form__placeholder-in-input {
  font-weight: 600;
  color: var(--grey13);
}

.login-form__input:-webkit-autofill,
.login-form__input:-webkit-autofill:hover,
.login-form__input:-webkit-autofill:focus,
.login-form__input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 24px white inset !important;
}

.login-form__input:-webkit-autofill::first-line {
  font-size: 16px;
  line-height: 22.4px;
  font-family: 'Poppins';
}

.login-form__input-box {
  flex-direction: column;
}

.login-form__btn {
  width: 182px;
  height: 40px;
  border-radius: 20px;
  background: var(--purple8);
  line-height: 40px;
  margin-top: 18px;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 14px;
}

@media (max-width: 576px) {
  .login-form__input {
    width: 240px;
  }
  .login-form__quid-logo {
    width: 88px;
    height: 100px;
    margin: 0;
    margin-top: -49px;
    margin-left: -28px;
    margin-right: 18px;
  }
  .login-form__title {
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 36px;
    text-transform: uppercase;
  }
  .login-form__subtitle {
    font-size: 14px;
    line-height: 21px;
  }
  .login-form__input {
    height: 36px;
  }
  .login-form__input-wrapper:nth-child(2) {
    padding-bottom: 0;
  }
  .login-form__btn {
    margin-top: 40px;
    width: 119px;
  }
  .login-form__new-one {
    margin-top: 60px;
  }
  .login-form__problem-login-in {
    font-size: 12px;
    font-weight: 400;
  }
  .login-form__new-liquid {
    font-size: 14px;
  }
  .login-form__sign-up {
    font-weight: 400;
  }
}

@media (max-width: 400px) {
  .login-form__quid-logo {
    width: 70px;
    height: 80px;
    margin-top: -25px;
    margin-left: -10px;
    margin-right: 5px;
  }
}
