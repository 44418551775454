.forgot-password-form {
  padding-bottom: 2.24rem;
}

.forgot-password-form__form {
  margin: 0;
  padding: 0;
}

.forgot-password-form__container {
  border-radius: 0.8rem;
  width: 33.28rem;
}

.forgot-password-form__header {
  height: 5.44rem;
  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;
  border-bottom: 2px solid var(--standalone-form__header-border-color);
}

.forgot-password-form__header-text {
  font-size: 1.6rem;
}

.forgot-password-form__information-text {
  width: 100%;
  text-align: center;
  margin-top: 2.4rem;
  line-height: 2.24rem;
}

.forgot-password-form__label {
  font-size: 1.44rem;
  letter-spacing: -0.5px;
}

.forgot-password-form__form {
  margin: 0;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.forgot-password-form__input-wrapper {
  padding-bottom: 1.12rem;
  margin: 0;
}

.forgot-password-form__input {
  background-color: #fafcff;
  color: #191b20;
  border: 2px solid #825af6;
  border-radius: 0px 2.88rem 2.88rem 2.88rem;
  height: 4.48rem;
  font-size: 1.6rem;
  line-height: 2.24rem;
  padding-left: 1.92rem;
}

.forgot-password-form__input:-webkit-autofill,
.forgot-password-form__input:-webkit-autofill:hover,
.forgot-password-form__input:-webkit-autofill:focus,
.forgot-password-form__input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 2.4rem white inset !important;
}

.forgot-password-form__input:-webkit-autofill::first-line {
  font-size: 1.6rem;
  line-height: 2.24rem;
  font-family: 'Poppins';
}

.forgot-password-form__input:hover,
.forgot-password-form__input:focus {
  background-color: #fafcff;
  border: 2px solid #825af6;
}

.forgot-password-form__input-box {
  flex-direction: column;
  width: 33.2rem;
}
.forgot-password-form__separator {
  margin: -2.24rem 2.4rem;
}

.forgot-password-form__btn {
  width: 15.2rem;
  height: 3.84rem;
  border-radius: 0px 2.24rem 2.24rem 2.24rem;
  background: transparent linear-gradient(180deg, #079be1 0%, #a03eea 100%) 0%
    0% no-repeat padding-box;
  text-transform: uppercase;
  line-height: 2.24rem;
  box-shadow: 0px 0.56rem 2.4rem #00000012;
  margin-top: 0.8rem;
}

.forgot-password-form__btn:hover,
.forgot-password-form__btn:focus {
  background: linear-gradient(
    rgba(133, 79, 232, 255) 50%,
    rgba(141, 74, 233, 255) 100%
  );
}

.forgot-password-form__btn:disabled,
.forgot-password-form__btn:disabled:hover {
  background: transparent linear-gradient(180deg, #079be1 0%, #a03eea 100%) 0%
    0% no-repeat padding-box;
}

.forgot-password-form__btn:disabled .forgot-password-form__signup,
.forgot-password-form__btn:disabled:hover .forgot-password-form__signup {
  opacity: 0.5;
}

.forgot-password-form__footer {
  margin: 0;
  padding: 0 2.24rem;
}

.forgot-password-form__checked {
  background-color: #825af6;
  top: 0.72rem;
}

.forgot-password-form__radio-section {
  margin-top: 2.4rem;
}

.ap-radio input[type='radio']:checked ~ label > .forgot-password-form__check {
  background-color: #825af6;
  border: 3px solid #825af6;
}

.ap-radio input[type='radio']:checked ~ .forgot-password-form__label {
  color: #373946;
}

.forgot-password-form__check:hover {
  background-color: #825af6 !important;
}

.forgot-password-form__check {
  border: 2px solid #825af6 !important;
  background-color: transparent !important;
  top: 0.72rem;
}

.ap-radio__label.forgot-password-form__label {
  color: var(--font__color-secondary);
  padding: 0;
  margin: 0;
  padding-left: 2.4rem;
}

.forgot-password-form__link {
  font-size: 0.96rem;
}

/* MEDIA QUERIES */

/* Extra Small Devices, Phones */
@media only screen and (max-width: 576px) {
  .forgot-password-form__container {
    width: auto;
  }

  .forgot-password-form__input {
    width: 24rem;
  }
}
