.modal-with-currency {
  display: flex;
  background: var(--deposit-withdraw-background-primary);
}

.modal-with-currency__modal {
  background: var(--deposit-withdraw-background-primary);
}

.modal-with-currency__modal__content {
  background: var(--deposit-withdraw-background-primary);
  padding-top: 15px;
  padding-bottom: 20px;
  overflow: auto;
}

.modal-with-currency__content {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  margin-top: 15px;
}
.modal-with-currency__modal-header {
  display: flex;
  align-items: center;
  background-color: var(--purple8);
  justify-content: space-between;
  height: 80px;
  min-height: 80px;
  padding-left: 20px;
  padding-right: 23px;
}

.modal-with-currency__modal-header-title {
  margin-left: 15px;
  font-size: 24px;
  color: var(--white1);
  font-weight: 600;
}

.modal-with-currency__modal-header-left {
  display: flex;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 20px;
  padding-left: 14px;
  padding-right: 20px;
  align-items: center;
  height: 51px;
  min-width: 247px;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
}

.modal-with-currency__currency-select-wrapper {
  width: 260px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: visible;
  cursor: pointer;
  border: 1px solid var(--purple8);
  border-radius: 20px;
  padding: 10px 26px 10px 26px;
}

.modal-with-currency__product-wrapper {
  display: flex;
  align-items: center;
}

.modal-with-currency__currency-title {
  color: var(--white1);
}

.modal-with-currency__curency-icon {
  margin-right: 10px;
  height: 28px;
  width: 28px;
}
.modal-with-currency__side {
  padding-left: 20px;
  width: 34.3rem;
  border-left: 1px solid var(--deposit-withdraw-border-color);
}

.modal-with-currency__currency-selector {
  overflow: visible;
}

.modal-with-currency__select {
  display: flex;
  align-items: center;
  padding-left: 0;
  color: var(--deposit-withdraw-font-primary);
  font-size: 14px;
  width: 100%;
  cursor: pointer;
  position: relative;
  overflow: visible;
  font-weight: 600;
}

.modal-with-currency__select-icon {
  display: flex;
  align-items: center;
  fill: var(--purple8);
  width: 12px;
  transform: rotate(0.5turn);
  cursor: pointer;
}

.modal-with-currency__select-icon svg:not(:root) {
  width: 12px;
}

.modal-with-currency__select-content {
  position: absolute;
  left: -66px;
  top: 35px;
  height: 200px;
  width: 260px;
  z-index: 10;
  overflow: hidden;
  background: #32245e;
  border-radius: 20px;
  padding-right: 5px;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.modal-with-currency__currency-types {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding: 5px;
  border: 1px solid var(--deposit-withdraw-border-color);
  box-sizing: border-box;
  border-radius: 0 20px 20px 20px;
  margin-bottom: 40px;
}

.modal-with-currency__currency-tab {
  font-size: 1.4rem;
  height: fit-content;
  min-height: 3.6rem;
  flex: 1 1;
  line-height: 3.6rem;
  text-align: center;
  cursor: pointer;
  color: var(--deposit-withdraw-font-primary);
  border-radius: 0 20px 20px 20px;
  margin: 0.4rem;
  padding-left: 5px;
  padding-right: 5px;
  outline: none;
}

.modal-with-currency__currency-tab--selected,
.modal-with-currency__currency-tab:hover {
  background: var(--pink1);
  color: var(--black2);
  border-radius: 0 20px 20px 20px;
}

.modal-with-currency__select-content-wrapper {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  align-items: flex-start;
  height: 100%;
}

.modal-with-currency__select-content-wrapper::-webkit-scrollbar {
  width: 5px;
}

.modal-with-currency__select-content-wrapper::-webkit-scrollbar-track {
  background: #32245e;
}

.modal-with-currency__select-content-wrapper::-webkit-scrollbar-thumb {
  background: white;
}

.modal-with-currency__select-option.modal-with-currency__select-option--active {
  color: var(--white1);
}

.modal-with-currency__select-option {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  border-bottom: 1px solid var(--white1);
  padding-bottom: 13px;
  color: var(--grey11);
  width: calc(100% - 10px);
}

.modal-with-currency__select-option:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.modal-with-currency__select-option:hover {
  color: var(--white1);
}

.modal-with-currency__modal__close-icon {
  display: none;
}

.modal-with-currency__header-close {
  height: 18px;
  width: 18px;
  cursor: pointer;
  color: var(--white1); 
}

.modal-with-currency__side .sidepane-details__information {
  margin-top: 10px;
  padding: 0;
}
.modal-with-currency__side .sidepane-details__header {
  border-top: unset;
  padding-top: unset;
  padding-right: unset;
}
.modal-with-currency__side
  .sidepane-details__body
  > .sidepane-details__lwt-container {
  padding-right: unset;
}

@media (max-width: 767px) {
  .modal-with-currency {
    flex-direction: column;
    align-items: center;
  }
  .modal-with-currency__content {
    margin-right: 0px;
    margin-bottom: 40px;
  }
  .modal-with-currency__side {
    width: 100%;
    border-left: none;
  }
  .modal-with-currency__side .sidepane-details__information {
    padding-left: 0px;
  }
  .modal-with-currency__side .sidepane-details__header {
    padding-left: 0px;
  }
  .modal-with-currency__side .sidepane-details__body {
    padding-left: 0px;
    width: initial;
  }
  .modal-with-currency__side .sidepane-details__divider {
    margin-left: 0px;
  }

  .modal-with-currency__currency-types {
    margin-left: 0;
  }
}

@media (max-width: 576px) {
  .modal-with-currency__modal__content {
    padding-left: 0;
    padding-right: 0;
    overflow-x: hidden;
  }

  .modal-with-currency {
    position: relative;
    flex-direction: column-reverse;
  }

  .modal-with-currency__fixed-header {
    position: fixed;
    background: var(--deposit-withdraw-background-primary);
    top: calc(10% + 80px);
    left: 0;
    height: 155px;
    width: calc(100% - 20px);
    z-index: 9;
    margin-right: 20px;
  }

  .modal-with-currency__fixed-header--short {
    height: 100px;
  }

  .modal-with-currency__content {
    margin: auto;
  }

  .modal-with-currency__side {
    width: calc(100% - 40px);
    margin-top: 70px;
    padding: 0 20px 0 20px;
  }

  .modal-with-currency__side--bigMargin {
    margin-top: 120px;
  }

  .modal-with-currency__currency-types {
    position: fixed;
    width: calc(100% - 40px);
    top: 170px;
    height: 44px;
    z-index: 10;
  }

  .sidepane-details__body {
    margin-right: 10px;
  }

  .modal-with-currency__modal__content {
    margin-right: 10px;
  }

  .modal-with-currency__modal__content::-webkit-scrollbar {
    width: 10px;
    background: transparent;
  }

  .modal-with-currency__modal__content::-webkit-scrollbar-track {
    background: rgba(228, 229, 233, 0.5);
    margin-bottom: 80px;
    margin-top: 120px;
    border-radius: 5px;
  }

  .modal-with-currency__modal__content::-webkit-scrollbar-thumb {
    background-color: var(--pink6);
    border-radius: 5px;
    background-clip: padding-box;
    border: 1px solid var(--grey12);
  }
}
