.update-terms-of-use-modal {
  margin: 0 24px;
}

.update-terms-of-use-modal__content {
  min-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--terms-of-use-modal-background);
  padding: 40px;
  overflow: hidden;
}

.update-terms-of-use-modal__container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.update-terms-of-use-modal__header {
  height: 69px;
  background: linear-gradient(131.67deg, #3f8cff 0%, #825af6 100%);
  display: flex;
  align-items: center;
  padding-left: 24px;
  gap: 20px;
  width: 100%;
}

.update-terms-of-use-modal__header-text {
  font-weight: 600;
  font-size: 19px;
  line-height: 150%;
  color: var(--white1);
}

.update-terms-of-use-modal__quid-img {
  width: 127px;
  height: 140px;
  align-self: center;
  filter: drop-shadow(0px 5px 10px rgba(63, 43, 133, 0.4));
}

.update-terms-of-use-modal__text {
  font-weight: 600;
  font-size: 19px;
  line-height: 150%;
  color: var(--terms-of-use-modal-text);
  max-width: 480px;
}

.update-terms-of-use-modal__terms {
  color: var(--terms-of-use-modal-terms-text);
  text-decoration: underline;
  cursor: pointer;
}
.update-terms-of-use-modal__buttons {
  display: flex;
  gap: 40px;
  width: 100%;
  justify-content: center;
}

.update-terms-of-use-modal__accept {
  width: 160px;
  height: 44px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid var(--purple8);
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: var(--terms-of-use-modal-text);
  cursor: pointer;
}

.update-terms-of-use-modal__accept:hover {
  background: var(--purple8);
  color: var(--white1);
}

.update-terms-of-use-modal__decline {
  width: 160px;
  height: 44px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid var(--red2);
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: var(--terms-of-use-modal-text);
  cursor: pointer;
}

.update-terms-of-use-modal__decline:hover {
  background: var(--red2);
  color: var(--white1);
}

.update-terms-of-use-modal__check-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(--terms-of-use-modal-text);
}

@media (max-width: 800px) {
  .update-terms-of-use-modal__content {
    min-width: auto;
    padding: 40px 30px;
  }

  .update-terms-of-use-modal__buttons {
    gap: 20px;
  }

  .update-terms-of-use-modal__decline,
  .update-terms-of-use-modal__accept {
    width: 130px;
  }

  .update-terms-of-use-modal__text {
    font-size: 16px;
    text-align: center;
  }

  .update-terms-of-use-modal__check-text {
    font-size: 14px;
  }

  .update-terms-of-use-modal__header {
    height: 44px;
    padding-left: 15px;
  }

  .update-terms-of-use-modal__header-text {
    font-size: 16px;
  }
}
