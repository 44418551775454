.base-lt__layout-template {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: var(--secondary__bg-color);
  border-radius: 1rem;
}

.base-lt__header {
  flex-basis: var(--settings__body-header-with-border-height);
  line-height: var(--settings__body-header-with-border-height);
  width: 100%;
  font-size: var(--settings__body-header-title-font-size);
  color: var(--settings__body-header-title-font-color);
  padding-left: var(--settings__body-header-title-padding-left);
  border-bottom: 2px solid var(--border__color);
}

.base-lt__body {
  flex-grow: 1;
  width: 100%;
  overflow: auto;
}
