.description {
  padding: 20px;
}

.description-quote {
  text-align: center;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  font-weight: 600;
  font-style: italic;
}

.description-link {
  color: #825af6;
  overflow-wrap: break-word;
}

.link-wrapper {
  font-style: italic;
}

.paragraph-wrapper,
.link-wrapper,
.description-quote {
  font-size: 14px;
  line-height: 21px;
  margin-top: 16px;
  text-align: justify;
  color: var(--asset-details-text-color);
  white-space: pre-wrap;
}

.description-title {
  font-size: 23px;
  line-height: 150%;
  font-weight: 600;
  color: var(--asset-details-text-color);
}

.paragraph-wrapper p {
  word-break: break-all;
}

@media (min-width: 1024px) {
  .paragraph-wrapper,
  .link-wrapper,
  .description-quote {
    font-size: 14px;
    line-height: 21px;
  }

  .description-title {
    font-size: 23px;
    line-height: 150%;
  }
}
