.buy-out-result-form {
  max-width: 545px;
  padding-left: 27px;
  padding-top: 19px;
  padding-right: 27px;
  padding-bottom: 34px;
  display: flex;
  flex-direction: column;
  background-color: var(--buy-out-result-form-background);
  border-radius: 0px 20px 20px 20px;
}

.buy-out-result-form__content {
  padding: 0;
  overflow: auto;
}

.buy-out-result-form__header {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: var(--buy-out-result-form-text-color);
}

.buy-out-result-form__icon-wrapper {
  margin-right: 20px;
}

.buy-out-result-form__icon {
  height: 20px;
  width: 20px;
}

.buy-out-result-form__description {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--buy-out-result-form-text-color);
  margin-top: 20px;
}

.buy-out-result-form__buttons {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  width: 100%;
}

.buy-out-result-form__try-again {
  height: 40px;
  width: 140px;
  border-radius: 20px;
  position: relative;
  box-sizing: border-box;
  background: linear-gradient(
        0deg,
        var(--buy-out-submit-background) 0%,
        var(--buy-out-submit-background) 100%
      )
      padding-box,
    linear-gradient(131.67deg, var(--red2) 0%, var(--purple1) 100%) border-box;
  background-clip: content-box, border-box;
  padding: 3px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  color: var(--font__color-primary);
}

.buy-out-result-form__submit {
  height: 40px;
  width: 140px;
  border-radius: 20px;
  position: relative;
  box-sizing: border-box;
  background: linear-gradient(
        0deg,
        var(--buy-out-submit-background) 0%,
        var(--buy-out-submit-background) 100%
      )
      padding-box,
    linear-gradient(131.67deg, var(--blue2) 0%, var(--purple1) 100%) border-box;
  background-clip: content-box, border-box;
  padding: 3px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  color: var(--font__color-primary);
}
