.ap-modal__wrapper {
  position: absolute;
}

.ap-modal {
  position: relative;
  overflow: hidden;
  background: var(--modal__bg-color);
  box-shadow: var(--component__box-shadow);
  display: flex;
  flex-direction: column;
  border-radius: 0 20px 20px 20px;
  max-height: 80%;
}

.ap-modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--modal__overlay-color);
  z-index: var(--z-index__modal);
}

.ap-modal__close {
  display: none;
}

.ap-modal__close-icon {
  padding: 0.75rem;
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  border-radius: 0 0 4px 4px;
  color: var(--font__color-secondary);
}

.ap-modal__close-icon:hover {
  opacity: 0.8;
}

.ap-modal__btn {
  padding: 0 5rem;
}

.ap-modal__header {
  display: flex;
  align-items: center;
  padding: 2.5rem;
  font-size: 1.4rem;
  color: var(--modal__header-color);
  background: var(--modal__header-bg-color);
}

.ap-modal__content {
  padding: 2.5rem;
  overflow: scroll;
}

.ap-modal__footer {
  padding: 2.5rem 0;
  margin-left: 2.5rem;
  border-top: 1px solid var(--border__color);
  display: flex;
  justify-content: space-between;
}
