.offering {
  --panel-gap: 20px;
  max-width: none;
  margin: auto;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  gap: var(--panel-gap);
}

.offering__single-col-layout {
  display: flex;
  flex-direction: column;
  gap: var(--panel-gap);
}

.offering__double-col-layout {
  display: flex;
  justify-content: center;
  gap: var(--panel-gap);
}

.offering__gallery-wrapper {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: var(--panel-gap);
}

.offering__description-wrapper {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: var(--panel-gap);
}

.offering__top-10-owners-block {
  padding: 20px;
}

.offering__top-10-owners-header {
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.offering__top-10-owners-header-text {
  font-weight: 600;
  font-size: 23px;
  line-height: 150%;
  z-index: 2;
  color: var(--asset-details-text-color);
}

.offering__top-owners-table-placeholder {
  margin-top: 16px;
}

.offering__top-10-owners-header-img {
  position: absolute;
  width: 100%;
  object-fit: cover;
  left: 0;
  right: 0;
}

.offering__top-owners-table-row,
.offering__top-owners-table-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 600;
  font-size: 14px;
  color: var(--asset-details-text-color);
}

.offering__top-owners-table-row {
  height: 45px;
  align-items: center;
}

.offering__top-owners-table-header {
  margin-bottom: 20px;
}

.offering__top-owners-table {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  padding-top: 0;
}

.offering__top-owners-table-row-value {
  display: flex;
  flex-direction: row;
  font-weight: 400;
  gap: 5px;
  color: var(--asset-details-text-color);
}

.offering__top-owners-table-row-product {
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: var(--asset-details-text-color);
}

.offering__top-owners-spinner__spinner-container {
  position: relative;
}

.offering__top-owners-table-row-left-part {
  display: flex;
  gap: 10px;
}

.offering__top-owners-table-row-number {
  width: 20px;
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: var(--asset-details-text-color);
  align-items: center;
}
.offering__top-owners-table-row-name {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: var(--asset-details-text-color);
}

.offering__top-owners-table-row-amount {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: var(--asset-details-text-color);
}

.offering__top-owners-tier-badge {
  width: 25px;
  height: 25px;
}

@media (min-width: 1024px) {
  .offering {
    --panel-gap: 38px;
  }
}

@media (max-width: 1024px) {
  .offering__co-own-wrapper {
    left: calc(50% - 222.5px);
    right: auto;
    top: 20px;
  }
}

@media (max-width: 576px) {
  .offering__co-own-wrapper {
    left: calc(50% - 157.5px);
  }
}
