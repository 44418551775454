.affiliate-modal {
  width: 800px;
  border-radius: 0 20px 20px 20px;
  max-height: unset;
  margin: auto;
  background: var(--affiliate-modal-bg-color);
}

.affiliate-modal__overlay {
  justify-content: unset;
  align-items: unset;
  padding: 19.2px;
  overflow-y: auto;
}

.affiliate-modal__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 18px;
  padding-right: 60px;
  gap: 20px;
  background: linear-gradient(90deg, var(--purple8), var(--green3));
  font-weight: 600;
  font-size: 19px;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: var(--white1);
}

.affiliate-modal__header-icon,
.affiliate-modal__header-icon svg:not(:root) {
  width: 24px;
  height: 24px;
}

.affiliate-modal__close-icon {
  color: var(--white1);
  line-height: 0;
  top: 18px;
  right: 18px;
}

.affiliate-modal__close-icon *:not(:root) {
  width: 12px;
  height: 12px;
}

.affiliate-modal__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  padding: 40px;
  gap: 40px;
}

.affiliate-modal__content>* {
  width: 100%;
  max-width: 524px;
}

.affiliate-modal__title {
  font-weight: 600;
  font-size: 23px;
  line-height: 150%;
  text-align: center;
}

.affiliate-modal__body {
  display: flex;
  flex-direction: column;
  padding: 30px 40px 20px;
  border-radius: 20px;
  background: var(--affiliate-modal-body-bg-color);
}

.affiliate-modal__section-header {
  font-weight: 600;
  font-size: 19px;
  line-height: 150%;
  letter-spacing: -0.02em;
}

.affiliate-modal__section-header--number {
  margin-bottom: 20px;
}

.affiliate-modal__section-header--form {
  margin-top: 36px;
  margin-bottom: 26px;
}

.affiliate-modal__settings-btn {
  width: auto;
  min-width: 140px;
  padding: 7px 23.5px;
  border-radius: 999px;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  background: var(--buy-sell-modal-additional-button-background-color);
  border: var(--buy-sell-modal-additional-button-border);
  color: var(--buy-sell-modal-additional-button-color);
  transition: none;
}

.affiliate-modal__settings-btn:hover,
.affiliate-modal__settings-btn:focus {
  background: var(--buy-sell-modal-additional-button-border-color);
  color: var(--buy-sell-modal-additional-button-background-color);
}

@media (max-width: 576px) {

  .affiliate-modal__title {
    display: none;
  }

  .affiliate-modal__body {
    padding: 30px 20px 20px;
  }
}

@media (max-width: 800px) {
  .affiliate-modal__close-icon {
    top: 16px;
    right: 20px;
  }

  .affiliate-modal__close-icon *:not(:root) {
    width: 20px;
    height: 20px;
  }
}
