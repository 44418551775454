.instrument-info-slider {
  position: relative;
  padding: 20px;
  background: var(--instrument-row__background-color);
  min-height: var(--instrument-selector__trigger-height);
}

.instrument-info-slider--with-arrows {
  padding: 20px 55px;
}

.instrument-info-slider__list {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow-x: scroll;
  gap: 10px;
  /* hide scrollbar but allow scrolling */
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
}

.instrument-info-slider__list::-webkit-scrollbar {
  /* for Chrome, Safari, and Opera */
  display: none;
}

.instrument-info-slider__arrow {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

.instrument-info-slider__arrow>span,
.instrument-info-slider__arrow svg:not(:root) {
  width: 7.5px;
  height: 12.5px;
}

.instrument-info-slider__arrow--prev {
  left: 20px;
}

.instrument-info-slider__arrow--next {
  right: 20px;
}

.instrument-info-slider__arrow>button {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--instruments-info-arrow-background-color);
  border: 1px solid var(--instruments-info-arrow-border-color);
  border-width: 1px;
  border-radius: 999px;
  line-height: 0;
  transition: none;
}

.instrument-info-slider__arrow--prev>button {
  padding-right: 1px;
}

.instrument-info-slider__arrow--next>button {
  padding-left: 1px;
}

.instrument-info-slider__arrow .slider-arrow-path {
  fill: var(--instruments-info-arrow-color);
}

.instrument-info-slider__arrow>button:hover:enabled {
  background: var(--instruments-info-arrow-border-color);
}

.instrument-info-slider__arrow>button:hover:enabled .slider-arrow-path {
  fill: var(--instruments-info-arrow-background-color);
}

.instrument-info-slider__arrow>button:disabled {
  opacity: 0.3;
  cursor: default;
}

.instrument-info-item {
  flex-basis: 120px;
  flex-shrink: 0;
  display: flex;
  padding: 11.5px 16.5px;
  flex-flow: column;
  align-items: center;
  border: 1px solid var(--font__color-primary);
  border-radius: 0 20px 20px 20px;
}

.instrument-info-item__value {
  font-size: 1.6rem;
  font-weight: 600;
  white-space: nowrap;
  color: var(--component__color-primary);
}

.instrument-info-item__title {
  font-size: 0.8rem;
  white-space: nowrap;
  color: var(--component__color-primary);
}

.instrument-info-item__value--24h-positive {
  color: var(--exchange-colors__buy);
}

.instrument-info-item__value--24h-negative {
  color: var(--exchange-colors__sell);
}

.instrument-info-item__value--bid {
  color: var(--exchange-colors__buy);
}

.instrument-info-item__value--ask {
  color: var(--exchange-colors__sell);
}

@media (max-width: 576px) {
  .instrument-info-item {
    background-color: var(--instruments-info-arrow-background-color);
  }
}
