.currencies-table {
  margin-top: 20px;
  color: var(--wallets-section-font-primary);
}

.currencies-table__wrapper .table__wide-row-container {
  height: auto;
}

.currencies-table__layout__menu-item {
  flex: none !important;
}

.currencies-table__layout__menu {
  width: max-content;
  gap: 16px;
  background: transparent !important;
  border: none !important;
  margin-left: 12px;
}

.currencies-table__layout__menu-item {
  border: none !important;
  padding-bottom: 8px;
}

.currencies-table__body {
  background: transparent !important;
}

.currencies-table__table-header {
  background: none !important;
  border-radius: 0px !important;
  width: 100% !important;
  height: auto;
  margin-bottom: 17px;
}

.currencies-table__row {
  border: none !important;
  background: transparent !important;
  width: 100% !important;
  padding: 0;
}

.currencies-table__row:hover {
  background: transparent !important;
}

.currencies-table__column {
  width: calc(100% / var(--col-count));
}

.currencies-table__column:first-child {
  justify-content: flex-start !important;
  max-width: calc(100% / var(--col-count));
}

.currencies-table__columnItem {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  line-height: 1.4;
  color: var(--wallets-section-font-primary);
}

.currencies-table__columnItem--fullWidth {
  width: 100%;
  justify-content: center;
}

.currencies-table__columnItem>div {
  font-size: inherit;
}

.currencies-table__currencyColor {
  --size: 12px;
  width: var(--size);
  height: var(--size);
  min-width: var(--size);
  min-height: var(--size);
  border-radius: 50%;
}

.currencies-table__header-column {
  width: calc(100% / var(--col-count));
}

.currencies-table__header-column:first-child {
  justify-content: flex-start !important;
}

.currencies-table__columnHeader {
  font-size: 14px;
  line-height: 1.4;
  color: var(--blue7);
  text-transform: uppercase;
}

.currencies-table__body .table__row-container {
  overflow-y: auto;
  max-height: 143px;
  gap: 17px;
}

.currencies-table__body .table__row-container::-webkit-scrollbar {
  width: 5px;
}

.currencies-table__columnItem {
  text-align: left;
}

@media (max-width: 1580px) {
  .currencies-table__body .table__row-container {
    max-height: 103px;
  }
}

@media (max-width: 576px) {
  .currencies-table__table-header {
    height: auto !important;
  }

  .currencies-table__body .table__row-container {
    max-height: 82px;
  }

  .currencies-table__row {
    height: auto !important;
  }
  .currencies-table__columnHeader {
    font-size: 11.2px;
    text-align: right;
  }
  .currencies-table__columnItem-text {
    font-size: 11.2px;
  }
  .currencies-table__columnItem {
    gap: 4.8px;
    font-size: 11.2px;
  }
}

@media (max-width: 400px) {
  .currencies-table__columnHeader,
  .currencies-table__columnItem-text,
  .currencies-table__columnItem {
    font-size: 9.6px;
  }
  
}
