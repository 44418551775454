.buttons__button {
  border-radius: 0px 20px 20px 20px;
  height: 40px;
  width: 140px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: none;
}

.buttons__button--buy {
  background: var(--buttons-buy-background);
  color: var(--buttons-buy-text-color);
  border: 3px solid var(--buttons-buy-hover-background);
}

.buttons__button--buy:hover {
  background: var(--buttons-buy-hover-background);
  color: var(--buttons-buy-hover-text-color);
  border: none;
}

.buttons__button--sell {
  background: var(--buttons-sell-background);
  color: var(--buttons-sell-text-color);
  border: 3px solid var(--buttons-sell-hover-background);
}

.buttons__button--sell:hover {
  background: var(--buttons-sell-hover-background);
  color: var(--buttons-sell-hover-text-color);
  border: none;
}

.buttons__button--view-details {
  background: var(--buttons-view-details-background);
  color: var(--buttons-view-details-text-color);
  border-radius: 20px;
  border: 3px solid var(--buttons-view-details-hover-background);
}

.buttons__button--view-details:hover {
  background: var(--buttons-view-details-hover-background);
  color: var(--buttons-view-details-hover-text-color);
  border: none;
}

.buttons__button--add-to-favorite {
  width: max-content;
  border-radius: 22.5px;
  border: 3px solid transparent;
  max-height: 40px;
}

.buttons__button--add-to-favorite:hover {
  background: var(--buttons-add-to-favorite-background-color);
  border: 3px solid var(--buttons-add-to-favorite-border-color);
  width: 140px;
}

.buttons__button--add-to-favorite-always-hover {
  background: var(--buttons-add-to-favorite-background-color);
  border: 3px solid var(--buttons-add-to-favorite-border-color);
  width: 140px;
  padding: 0 5px;
}

.buttons__button--add-to-favorite-active {
  width: 140px;
  background: var(--pink2);
  border: 3px solid var(--pink2);
}

.buttons__button--add-to-favorite-active:hover {
  width: 140px;
  background: var(--pink2);
  border: 3px solid var(--pink2);
}

.buttons__add-to-watchlist-text {
  display: none;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  margin-left: 8px;
  color: var(--buttons-add-to-favorite-hover-text-color);
}

.buttons__button--add-to-favorite:hover .buttons__add-to-watchlist-text,
.buttons__button--add-to-favorite-active .buttons__add-to-watchlist-text,
.buttons__button--add-to-favorite-always-hover .buttons__add-to-watchlist-text {
  display: inline-block;
}

.buttons__button--add-to-favorite-active .buttons__add-to-watchlist-text {
  color: var(--buttons-add-to-favorite-active-text-color);
}

.buttons__favorite-icon {
  width: 26px;
  height: 20px;
}

.buttons__favorite-icon svg:not(:root) {
  width: 26px;
  height: 20px;
}

.buttons__button--add-to-favorite .favorite-icon-line {
  fill: var(--buttons-add-to-favorite-icon-color);
}

.buttons__button--add-to-favorite:hover .favorite-icon-line,
.buttons__button--add-to-favorite-always-hover .favorite-icon-line {
  fill: var(--buttons-add-to-favorite-hover-icon-color);
}

.buttons__button--add-to-favorite-active:hover .favorite-icon-line {
  fill: var(--buttons-add-to-favorite-active-icon-color);
}

.buttons__button--add-to-favorite-active .favorite-icon-line {
  fill: var(--buttons-add-to-favorite-active-icon-color);
}

.buttons__button--share {
  width: unset;
  min-width: 140px;
  background: var(--buttons-sell-background);
  color: var(--buttons-sell-text-color);
  border: 3px solid var(--buttons-sell-hover-background);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  gap: 12px;
  padding-left: 8px;
  padding-right: 20px;
}

.buttons__button--share:hover {
  background: var(--buttons-sell-hover-background);
  color: var(--buttons-sell-hover-text-color);
  border: none;
}

.buttons__share-icon {
  width: 20.54px;
  height: 20.54px;
}

.buttons__share-icon svg:not(:root) {
  width: 20.54px;
  height: 20.54px;
}

.buttons__button--share:hover > .buttons__share-icon .share-icon-circle {
  fill: var(--buttons-sell-hover-text-color);
}

.buttons__button--share:hover > .buttons__share-icon .share-icon-line {
  stroke: var(--buttons-sell-hover-text-color);
}

.buttons__button--go-pro {
  width: auto;
  padding-left: 34px;
  padding-right: 34px;
  font-size: 16px;
  line-height: 1.5;
  background: var(--buttons-go-pro-background);
  color: var(--buttons-go-pro-text-color);
  border: 3px solid var(--buttons-go-pro-border);
  border-radius: 20px 5px;
}

.buttons__button--go-pro:hover {
  background: var(--buttons-go-pro-hover-background);
  color: var(--buttons-go-pro-hover-text-color);
  border-color: var(--buttons-go-pro-hover-border);
}

.buttons__button--view-watch-list {
  background: var(--buttons-view-watch-list-background);
  color: var(--buttons-view-watch-list-text-color);
  border: 3px solid var(--buttons-view-watch-list-border-color);
  width: 200px;
  border-radius: 20px;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 24px;
}

.buttons__button--view-watch-list:hover {
  border: 3px solid var(--buttons-view-watch-list-hover-border-color);
  color: var(--buttons-view-watch-list-hover-text-color);
  background: var(--buttons-view-watch-list-hover-background);
}

.buttons__button--remove-from-watch-list {
  background: var(--buttons-remove-from-watch-list-background);
  color: var(--buttons-remove-from-watch-list-text-color);
  border: 3px solid var(--buttons-remove-from-watch-list-border-color);
  width: 180px;
  border-radius: 20px;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 16px;
}

.buttons__button--remove-from-watch-list:focus,
.buttons__button--remove-from-watch-list:hover {
  border: 3px solid var(--buttons-remove-from-watch-list-hover-border-color);
  color: var(--buttons-remove-from-watch-list-hover-text-color);
  background: var(--buttons-remove-from-watch-list-hover-background);
}

.buttons__button--buy-now {
  width: 280px;
  display: inline-flex;
  justify-content: center;
  color: var(--white1) !important;
  padding: 8px;
  border: 1px solid var(--white1);
  border-radius: 999px;
  background-color: var(--grey2);
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  text-transform: uppercase;
  transition: none;
}

.buttons__button--buy-now:hover {
  background: linear-gradient(131.67deg, var(--blue2) 0%, var(--purple1) 100%);
  box-shadow: 0px 5px 30px #e4ddfa66;
}

@media (max-width: 576px) {
  .buttons__button--buy {
    background: var(--blue6);
    color: var(--white1);
  }

  .buttons__button--sell {
    background: var(--purple8);
    color: var(--white1);
  }

  .buttons__button--view-details {
    background: var(--pink2);
    color: var(--white1);
  }

  .buttons__button--add-to-favorite {
    background: var(--buttons-add-to-favorite-background-color);
    border: 3px solid var(--buttons-add-to-favorite-border-color);
    width: 140px;
  }

  .buttons__button--add-to-favorite-active {
    width: 140px;
    background: var(--pink2);
    border: 3px solid var(--pink2);
  }

  .buttons__button--add-to-favorite .buttons__add-to-watchlist-text {
    display: inline-block;
  }

  .buttons__button--add-to-favorite-active:hover .favorite-icon-line {
    fill: var(--blue6);
  }

  .buttons__button--view-watch-list {
    border: 3px solid var(--buttons-view-watch-list-hover-border-color);
    color: var(--buttons-view-watch-list-hover-text-color);
    background: var(--buttons-view-watch-list-hover-background);
  }

  .buttons__button--remove-from-watch-list {
    border: 3px solid var(--buttons-remove-from-watch-list-hover-border-color);
    color: var(--buttons-remove-from-watch-list-hover-text-color);
    background: var(--buttons-remove-from-watch-list-hover-background);
  }
}

/* buy-now is used on the public asset page, which changes to mobile on 1024px */
@media (max-width: 1024px) {
  .buttons__button--buy-now {
    background: linear-gradient(
      131.67deg,
      var(--blue2) 0%,
      var(--purple1) 100%
    );
    box-shadow: 0px 5px 30px #e4ddfa66;
  }
}

/* buy-now is used on the public asset page, which changes to mobile on 1024px */
@media (max-width: 1024px) {
  .buttons__button--buy-now {
    background: linear-gradient(
      131.67deg,
      var(--blue2) 0%,
      var(--purple1) 100%
    );
    box-shadow: 0px 5px 30px #e4ddfa66;
  }
}
