.search-card-input__search-wrapper {
  position: relative;
}

.search-card-input__search-input {
  height: 38px;
  width: 240px;
  padding-left: 60px;
  padding-right: 17px;
  border: 0.25px solid var(--instrument-selector-search-input-border-color);
  border-radius: 0px 20px 20px 20px;
  font-size: 14px;
  line-height: 21px;
  color: var(--instrument-selector-search-input-color);
  background-color: var(--instrument-selector-search-input-background);
}

.search-card-input__search-input::placeholder {
  color: var(--instrument-selector-search-input-color);
}

.search-card-input__search-icon-container {
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--blue6);
  border-radius: 0px 56px 56px 56px;
  position: absolute;
  top: -4px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.search-card-input__search-icon {
  height: 22.5px;
  width: 22.5px;
}

@media (max-width: 1200px) {
  .search-card-input__search-wrapper {
    margin-top: 20px;
  }
}

@media (max-width: 568px) {
  .search-card-input__search-input {
    height: 21px;
    width: 166px;
    padding-left: 36px;
    font-weight: 400;
    font-size: 9px;
    line-height: 14px;
  }
  .search-card-input__search-icon-container {
    height: 25px;
    width: 25px;
    top: -2px;
  }
  .search-card-input__search-icon {
    height: 12.5px;
    width: 12.5px;
  }
}
