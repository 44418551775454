.wallets-summary-header-buttons__container {
  display: flex;
  gap: 20px;
}

.wallets-summary-header-buttons__container > div:hover {
  cursor: pointer;
}

.wallets-summary-header-buttons__deposit {
  height: 38.4px;
  padding: 0px 20px;
  background-color: var(--purple1);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 0px 22.4px 22.4px 22.4px;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 22.4px;
  color: var(--white1);
  font-weight: 600;
  transition: none;
}

.wallets-summary-header-buttons__deposit:disabled {
  filter: grayscale(1);
}

.wallets-summary-header-buttons__deposit:not(:disabled):hover {
  background: var(--pink1);
}

.wallets-summary-header-buttons__withdraw {
  height: 38.4px;
  padding: 0px 20px;
  background: var(--buttons-buy-background);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 22.4px 22.4px 22.4px;
  text-transform: uppercase;
  color: var(--wallets-section-font-primary);
  font-size: 16px;
  line-height: 22.4px;
  border: 3px solid var(--wallets-section-font-secondary);
  font-weight: 600;
  transition: none;
}

.wallets-summary-header-buttons__withdraw:hover {
  background-color: var(--wallets-section-font-secondary);
  color: var(--white1);
}

.wallets-summary-overview__amount {
  display: flex;
  align-items: center;
  font-size: 28.8px;
  line-height: 40.8px;
  color: var(--wallets-section-font-primary);
  font-weight: 600;
}

.wallets-summary-overview__title {
  font-size: 16px;
  color: var(--wallets-section-font-secondary);
  line-height: 22.4px;
}

.wallets-summary-overview__currency {
  font-size: 19.2px;
  color: var(--wallets-section-font-primary);
  line-height: 25.6px;
  font-weight: 600;
  padding-right: 1.6px;
}

.wallets-summary-overview__row {
  margin-bottom: 34.4px;
  text-align: center;
}

@media (max-width: 1650px) {
  .wallets-summary-header-buttons__deposit {
    width: 100%;
  }
  .wallets-summary-header-buttons__withdraw {
    width: 100%;
  }
}

@media (max-width: 1580px) {
  .wallets-summary-header-buttons__container {
    display: flex;
  }
}

@media (max-width: 576px) {
  .wallets-summary-header-buttons__withdraw {
    height: 32px;
  }
  .wallets-summary-header-buttons__deposit {
    height: 32px;
  }
  .wallets-summary-header-buttons__container {
    justify-content: space-between;
    flex-wrap: wrap;
    display: flex;
  }
  .wallets-summary-overview__amount {
    font-size: 19.2px;
    line-height: 25.6px;
    justify-content: center;
  }
  .wallets-summary-overview__currency {
    font-size: 12.8px;
    line-height: 19.2px;
    align-self: flex-end;
  }
}
