.buy-out {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.buy-out-title {
  font-size: 23px;
  line-height: 150%;
  font-weight: 600;
  margin-bottom: 16px;
}

.buy-out-description {
  font-size: 14px;
  line-height: 21px;
}

.buy-out-submit-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
}

.buy-out-submit {
  height: 40px;
  width: 140px;
  border-radius: 20px;
  position: relative;
  box-sizing: border-box;
  background: linear-gradient(
        var(--buy-out-submit-background),
        var(--buy-out-submit-background)
      )
      0 0 / 100% 100% no-repeat,
    linear-gradient(var(--blue2) 0%, var(--purple1) 100%);
  background-clip: content-box, border-box;
  padding: 3px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--buy-out-form-input-label-color);
}

.buy-out-submit:disabled {
  filter: grayscale(1);
}

.buy-out-submit:not(:disabled):hover {
  background: linear-gradient(var(--blue2) 0%, var(--purple1) 100%);
  color: var(--white1);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media (min-width: 1024px) {
  .buy-out-title {
    font-size: 23px;
    line-height: 150%;
  }

  .buy-out-description {
    font-size: 14px;
    line-height: 21px;
  }
}
