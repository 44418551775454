:root {
  --retail-dashboard__card-header-title-font-size: 14.4px !important;
}

.dashboard-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 32px;
  background: var(--liquid-background-primary);
}

.dashboard-page__notification {
  padding: 0 32px;
}

.dashboard-page__content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.dashboard-page__market-place {
  margin-top: 32px;
  width: 100%;
}

.dashboard-page__section {
  height: calc(50% - 11.5px);
}

.dashboard-page__my-assets-section {
  height: calc(50% - 11.5px);
  flex-grow: 1;
  border-radius: 20px;
  overflow: hidden;
}

.dashboard-page__left-part {
  width: 60%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 24px;
}

.dashboard-page__right-part {
  width: 40%;
  margin-left: 24px;
  min-height: 1000px;
}

.dashboard-page__footer {
  width: 100%;
  max-width: 1700px;
  padding: 0 4px;
}

@media (max-width: 1280px) {
  .dashboard-page,
  .dashboard-page__notification {
    padding: 0 19.2px;
  }
  .dashboard-page__content {
    flex-direction: column;
    justify-content: flex-start;
  }
  .dashboard-page__left-part {
    width: 100%;
    justify-content: flex-start;
  }
  .dashboard-page__right-part {
    width: 100%;
    margin-left: 0px;
    margin-top: 19.2px;
    min-height: unset;
  }

  .dashboard-page__market-place {
    padding: 0px 0px;
    margin-top: 19.2px;
  }
  .dashboard-page__section {
    height: auto;
    min-height: 400px;
  }

  .dashboard-page__my-assets-section {
    height: 480px;
  }
}

@media (max-width: 576px) {
  .dashboard-page,
  .dashboard-page__notification {
    padding: 0 19.2px;
  }
  .dashboard-page__content {
    flex-direction: column;
    justify-content: flex-start;
  }
  .dashboard-page__left-part {
    width: 100%;
    justify-content: flex-start;
  }
  .dashboard-page__right-part {
    width: 100%;
    margin-left: 0px;
    margin-top: 19.2px;
    min-height: unset;
  }

  .dashboard-page__market-place {
    padding: 0px 0px;
    margin-top: 19.2px;
  }

  .dashboard-page__footer {
    padding: 0 4px;
  }

  .dashboard-page__section {
    height: max-content;
    min-height: 350px;
  }

  .dashboard-page__my-assets-section {
    height: max-content;
  }
}
