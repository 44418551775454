.section-with-filter-search {
}

.section-with-filter-search__title-container {
  padding-left: 32px;
}

.section-with-filter-search__title {
  color: var(--offerings-title);
  padding-top: 20px;
}
