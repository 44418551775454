.public-co-own .public-asset-details-header {
  background-color: var(--header-footer-color);
  position: sticky;
  top: 0px;
  padding: 20px;
  z-index: 5;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.public-co-own .public-asset-details-header__logo {
  height: 68px;
}

.public-co-own {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  --header-footer-color: rgba(25, 27, 33, 0.83);
  flex-grow: 1;
}

.public-co-own .co-own-page {
  align-self: center;
}

@media (min-width: 1024px) {
  .public-asset-details-header {
    margin-bottom: 43px;
  }
}
