.section-preset-sort {
}

.section-preset-sort__title {
  color: var(--offerings-title);
}

.section-preset-sort__sortWrapper {
  color: var(--black2);
  display: flex;
  justify-content: space-between;
  margin: 25.6px 25.6px 0 0;
}

@media (max-width: 800px) {
  .section-preset-sort__sortWrapper {
    margin: 19.2px 19.2px 0px 0px;
  }
}
