.panel {
  box-sizing: border-box;
}

.panel-full,
.panel-half {
  width: 100%;
  break-inside: avoid-column;
}

.panel-inner {
  box-sizing: border-box;
  border-radius: 20px 20px 20px 20px;
  background: var(--panel-bg-color);
  overflow: hidden;
}

@media (min-width: 1024px) {
  .panel {
    order: 0;
  }
}
