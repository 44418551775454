.global-notification {
  display: flex;
  min-height: 30px;
  height: auto;
  background: var(--white1);
  width: 100%;
  margin-bottom: 29px;
  border-radius: 15px;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: rgba(25, 27, 32, 0.75);
  padding-left: 152px;
  padding-right: 10px;
  position: relative;
  margin-top: 3px;
  justify-content: space-between;
}

.global-notification__notification-wrapper {
  position: absolute;
  left: 0;
  top: -3px;
  height: 36px;
  background: radial-gradient(50% 50% at 50% 50%, #886bee 0%, #5289f7 100%);
  border-radius: 0px 10px 10px 10px;
  width: 138px;
  z-index: 2;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white1);
}

.global-notification__icon {
  height: 28.5px;
  width: 28.5px;
  margin-right: 4.5px;
}

.global-notification__close {
  width: 12px;
  height: 12px;
}

.global-notification__close-wrapper {
  cursor: pointer;
  display: flex;
}