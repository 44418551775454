.gallery-wrapper {
  padding: 20px;
}

.gallery-title {
  font-size: 23px;
  line-height: 150%;
  font-weight: 600;
  margin-bottom: 16px;
}

.gallery-images {
  display: grid;
  grid-template-columns: 30fr 18fr;
  grid-gap: 8px;
}

.gallery-image-container:nth-child(3n-2) {
  grid-row-start: 1;
  grid-row-end: 3;
}

.gallery-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: contain;
}

.gallery-image:hover,
.gallery-image:focus {
  cursor: pointer;
}

.lightbox-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10;
}

.lightbox-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
}

.lightbox {
  position: relative;
  display: flex;
  width: 90%;
  height: 100%;
  margin: auto;
}

.lightbox-arrow {
  display: none;
  align-items: center;
  height: 100%;
  opacity: 0.5;
  cursor: pointer;
}
.lightbox-arrow:hover {
  opacity: 1;
}

.lightbox-image {
  max-width: 100%;
  max-height: 100%;
  margin-right: auto;
  margin-left: auto;
  object-fit: contain;
}

@media (min-width: 1024px) {
  .gallery-title {
    font-size: 23px;
    line-height: 150%;
  }

  .gallery-images {
    grid-gap: 16px;
  }

  .lightbox {
    width: 80%;
  }

  .lightbox-arrow {
    display: flex;
  }
}
