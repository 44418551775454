.signup-form__form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signup-form__header-container {
  display: flex;
  width: 100%;
}

.signup-form__logo-container {
  width: 7.68rem;
  height: 7.04rem;
}

.signup-form__logo {
  width: 130px;
  height: 145px;
  margin-top: -55px;
  margin-left: -35px;
  margin-right: 30px;
}

.signup-form__title-container {
  margin-left: 45px;
}

.signup-form__title {
  font-size: 28px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.signup-form__sub-title {
  background: -webkit-linear-gradient(
    131.67deg,
    var(--blue2) 0%,
    var(--purple1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.signup-form__done-title {
  text-align: center;
  font-size: 2rem;
  line-height: 2.16rem;
  color: #373946;
  font-weight: 600;
  margin-top: 1.44rem;
}

.signup-form__done-sub-title {
  text-align: center;
  font-size: 2rem;
  line-height: 2.16rem;
  color: #373946;
  font-weight: 600;
  margin-top: 0.24rem;
}

.signup-form__form {
  margin: 0;
}

.signup-form__form .spinner__container--inline {
  margin-top: 40px;
  margin-bottom: 13.4px;
}

.signup-form__affiliate-container {
  width: 400px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
  padding: 10px 20px;
  margin-top: 40px;
  margin-bottom: 13.4px;
  background: linear-gradient(90.57deg, var(--purple8) 0%, var(--green3) 100%);
  border-radius: 10px;
  font-size: 19px;
  line-height: 1.5;
  font-weight: 600;
}

.signup-form__affiliate-container--expired {
  background: linear-gradient(90.57deg, var(--red2) 0%, var(--purple8) 100%);
}

.signup-form__affiliate-icon {
  margin-top: 2px;
}

.signup-form__affiliate-info--expired {
  font-size: 18px;
}

.signup-form__container {
  width: 100%;
  padding-top: 0;
  border-right: none;
  border-bottom: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 2.16rem;
}

.signup-form__container:last-child {
  border-right: none;
}

.signup-form__footer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.signup-form__wrapper {
  display: flex;
  justify-content: space-between;
  min-height: 18.56rem;
}

.signup-form__checkboxes-wrapper {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 400px;
  gap: 20px;
}

.signup-form__checkbox-row {
  display: flex;
}

.signup-form__checkbox-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  padding-left: 24px;
  color: var(--grey2);
}

.signup-form__checkbox-text-link {
  color: #7c5ced;
  font-weight: 600;
  display: inline;
  font-size: 14px;
  line-height: 21px;
}

.signup-form__checkbox {
  width: 20px;
  height: 20px;
}

.signup-form__text {
  line-height: 1.6rem;
}

.signup-form__sign-in {
  margin-top: 20px;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: var(--black1);
}

.signup-form__sign-in-link {
  margin-top: 8px;
  text-decoration: underline;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--purple8);
}

.signup-form__sign-in-link:hover {
  color: black;
}

.signup-form__btn {
  width: 182px;
  height: 40px;
  border-radius: 20px;
  background: var(--purple8);
  margin-top: 40px;
  margin-bottom: 0;
}

.signup-form__btn:hover,
.signup-form__btn:focus {
  background: linear-gradient(
    rgba(133, 79, 232, 255) 50%,
    rgba(141, 74, 233, 255) 100%
  );
}

.signup-form__btn:disabled .signup-form__signup,
.signup-form__btn:disabled:hover .signup-form__signup {
  opacity: 0.5;
}

.signup-form__input-wrapper,
.signup-form__valid-field__input-wrapper,
.signup-form__invalid-field__input-wrapper {
  height: auto;
  display: flex;
  padding-bottom: 25px;
  margin: 0;
}

.signup-form__valid-field__input-wrapper:nth-child(3),
.signup-form__invalid-field__input-wrapper:nth-child(3) {
  margin-bottom: 50px;
}

.signup-form__input-box,
.signup-form__valid-field__input-box,
.signup-form__invalid-field__input-box {
  flex-direction: column;
  position: relative;
  border: 1px solid var(--purple1);
  border-radius: 20px;
  height: 42px;
}

.signup-form__valid-field__input-box {
  border: double 1px transparent;
  border-radius: 20px;
  background-image: linear-gradient(white, white),
    linear-gradient(90.57deg, #7c5ced 0%, #53d660 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

.signup-form__invalid-field__input-box {
  border: 1px solid var(--red2);
  border-radius: 20px;
}

.ap-signup-input__placeholder-in-input {
  font-weight: 600;
}

.signup-form__input,
.signup-form__valid-field__input,
.signup-form__invalid-field__input {
  background-color: #fafcff;
  color: #191b20;
  height: 39px;
  width: 400px;
  font-size: 18px;
  line-height: 40px;
  padding-left: 19px;
  padding-right: 38px;
  border-radius: 20px;
  border: 1px solid transparent;
  padding-top: 7px;
}

.signup-form__valid-field__placeholder-in-input {
  color: var(--green3);
}

.signup-form__invalid-field__placeholder-in-input {
  color: var(--red2);
}

.signup-form__invalid-field__input:-webkit-autofill,
.signup-form__invalid-field__input:-webkit-autofill:hover,
.signup-form__invalid-field__input:-webkit-autofill:focus,
.signup-form__invalid-field__input:-webkit-autofill:active,
.signup-form__valid-field__input:-webkit-autofill,
.signup-form__valid-field__input:-webkit-autofill:hover,
.signup-form__valid-field__input:-webkit-autofill:focus,
.signup-form__valid-field__input:-webkit-autofill:active,
.signup-form__input:-webkit-autofill,
.signup-form__input:-webkit-autofill:hover,
.signup-form__input:-webkit-autofill:focus,
.signup-form__input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.signup-form__input:-webkit-autofill::first-line {
  font-size: 1.6rem;
  line-height: 2.24rem;
  font-family: 'Poppins';
}

.signup-form__valid-icon-container {
  position: absolute;
  right: 15px;
  top: 7px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: linear-gradient(90.57deg, #7c5ced 0%, #53d660 100%);
}

.signup-form__valid-icon-container--password {
  position: unset;
}

.signup-form__label,
.signup-form__valid-field__label,
.signup-form__invalid-field__input-box {
  font-size: 1.12rem;
  letter-spacing: -0.5px;
}

.signup-form__password-tip {
  width: 100%;
  color: var(--grey2);
  font-weight: 400;
  margin-left: 20px;
  margin-top: 5px;
}

.signup-form__password-icons {
  position: absolute;
  top: 0;
  right: 10px;
  height: 39px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.signup-form__password-icon {
  margin-right: 10px;
  cursor: pointer;
}

.signup-form__invalid-field__info {
  padding-bottom: 20px;
}

.signup-form__password-rule-error {
  color: var(--red2);
}

.signup-form__input:focus ~ .signup-form__info,
.signup-form__valid-field__input:focus ~ .signup-form__valid-field__info,
.signup-form__invalid-field__input:focus ~ .signup-form__invalid-field__info {
  display: unset;
}

.signup-form__input:focus ~ .signup-form__error,
.signup-form__valid-field__input:focus ~ .signup-form__valid-field__error,
.signup-form__invalid-field__input:focus ~ .signup-form__invalid-field__error {
  display: none;
}

.signup-form__links {
  padding: 0 2.24rem;
  margin: 0;
}

.signup-form__link {
  line-height: 1.6rem;
  text-decoration: underline;
}

.signup-form__link:hover {
  color: black;
}

.signup-form__info {
  margin-left: 15px;
  padding-top: 0.4rem;
  color: var(--grey2);
  font-weight: 400;
}

.signup-form__error {
  height: 0px;
  margin-bottom: 0px;
  position: relative;
  padding-top: 3px;
  max-width: 33.2rem;
}

.signup-form__resend-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signup-form__resend-info {
  margin-top: 2.8rem;
  margin-bottom: 1.2rem;
}

.signup-form__done {
  margin-top: 5.6rem;
  text-align: center;
}

@media (max-width: 820px) {
}

/* MEDIA QUERIES */

/* Extra Small Devices, Phones */
@media (max-width: 576px) {
  .signup-form__affiliate-container {
    width: 300px;
    padding: 10px;
    gap: 10px;
    font-size: 16px;
  }
  .signup-form__affiliate-info--expired {
    font-size: inherit;
  }
  .signup-form__affiliate-icon,
  .signup-form__affiliate-icon svg:not(:root) {
    width: 16px;
    height: 16px;
  }
  .signup-form__checkboxes-wrapper {
    width: 300px;
  }
  .signup-form__title-container {
    margin-left: 0;
  }
  .signup-form__form-container {
    width: auto;
  }
  .signup-form__input,
  .signup-form__valid-field__input,
  .signup-form__invalid-field__input {
    width: 24rem;
  }
  .signup-form__error,
  .signup-form__valid-field__error,
  .signup-form__invalid-field__error {
    max-width: 24rem;
  }
  .signup-form__logo {
    display: none;
  }
  .signup-form__password-rule-error,
  .signup-form__password-rule {
    width: 16rem;
  }
}
