.vote-progress__title {
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: var(--voteTextColor);
}

.vote-progress__progress-wrapper {
  margin-top: 10px;
  padding-top: 14.5px;
  padding-bottom: 14.5px;
  padding-left: 17px;
  padding-right: 17px;
  display: flex;
  flex-direction: column;
  gap: 18.5px;
  background-color: rgba(25, 27, 32, 0.4);
  border-radius: 20px;
}

.vote-progress__progress {
  display: flex;
  gap: 20px;
  align-items: center;
}

.vote-progress__progress-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: var(--white1);
  width: 25px;
  text-align: center;
}

.vote-progress__progress-value {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: var(--white1);
}

.vote-progress__progress-line-wrapper {
  width: 400px;
  height: 20px;
  display: flex;
  gap: 20px;
}

.vote-progress__progress-line {
  height: 100%;
  border-radius: 20px;
  border: 1px solid #ffffff;
  box-shadow: inset 0px 5px 4px rgba(255, 255, 255, 0.25);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.vote-progress__progress-line--positive {
  background: linear-gradient(90.57deg, #7c5ced 0%, #53d660 100%);
}

.vote-progress__progress-line--negative {
  background: linear-gradient(90.57deg, #e02323 0%, #825af6 100%);
}
