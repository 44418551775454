.transaction-history__wrapper {
  height: calc(100% - 63px);
  margin-top: 25.6px;
  padding: 0px 25.6px;
  padding-bottom: 25.6px;
}

.transaction-history__body {
  background: transparent !important;
}

.transaction-history__spinner-container {
  position: initial;
}
.transaction-history__spinner-circle {
  width: 3.5rem;
  height: 3.5rem;
}

.transaction-history__table-header {
  background: none !important;
  border-radius: 0px !important;
  width: 100% !important;
  margin-bottom: 19.2px;
  height: 20px;
  padding: 0 20px !important;
}

.transaction-history__wrapper-with-reminder .transaction-history__tab-content {
  max-height: 820px;
}

.transaction-history__tab-content {
  max-height: 696px;
  overflow: auto;
}

.transaction-history__tab-content .table__wide-row-container {
  height: auto;
}

.transaction-history__row {
  min-height: 65px;
  border: none !important;
  width: 100% !important;
  padding: 10px 20px !important;
}

.transaction-history__row--highlighted {
  background: var(--purple8);
  border-radius: 0px 20px 20px 20px;
}

.transaction-history__column {
  width: 25%;
}

.transaction-history__column:last-child {
  justify-content: flex-end;
}

.transaction-history__column:first-child {
  justify-content: flex-start !important;
}

.transaction-history__columnItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  text-align: start;
  line-height: 22.4px;
  color: var(--wallets-section-font-primary);
}

.transaction-history__dateColumnWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.transaction-history__columnItem-small-mark {
  font-size: 12px;
}

.transaction-history__columnItem--fullWidth {
  width: 100%;
}

.transaction-history__currencyColor {
  width: 12.8px;
  height: 12.8px;
  border-radius: 6.4px;
}

.transaction-history__header-column {
  width: 25%;
}

.transaction-history__header-column:first-child {
  justify-content: flex-start !important;
}

.transaction-history__columnHeader {
  line-height: 22.4px;
  color: var(--blue7);
  text-transform: uppercase;
}

.transaction-history__body .table__row-container {
  overflow-y: auto;
}

.transaction-history__menu {
  margin-bottom: 24px;
  background: transparent;
  gap: 48px;
  border: none;
}

.transaction-history__payment-history-active-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.transaction-history__payment-history-row {
  width: calc(100% - 20px);
  display: flex;
  padding: 0 10px 0 10px;
  margin: 10px;
  flex-direction: row;
  justify-content: space-between;
}

.transaction-history__skip-payment-button {
  margin: 10px 0 0 10px;
  width: 170px;
  height: 28px;
  background: linear-gradient(131.67deg, #3f8cff 0%, #825af6 100%);
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
}

.transaction-history__payment-history-row--active {
  height: 62px;
  margin: 0 10px;
  background: #6249bd;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 0px 20px 20px 20px;
  align-items: center;
  color: var(--white1);
}
.transaction-history__payment-history-column {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  width: 25%;
}
.transaction-history__payment-history-column {
  min-width: 56px;
}

.transaction-history__payment-history-column--date {
  display: flex;
  flex-direction: column;
}

.transaction-history__payment-history-column-date {
  font-weight: 400;
  font-size: 16px;
  text-align: center;
}

.transaction-history__payment-history-column--status {
  padding-right: 5px;
  min-width: 100px;
  justify-content: flex-end;
}

.transaction-history__payment-history-column--amount {
  justify-content: center;
}

@media (max-width: 1000px) {
  .transaction-history__payment-history-column--status {
    padding-right: 10px;
  }
}

@media (max-width: 576px) {
  .transaction-history__menu {
    gap: 5px;
    justify-content: space-between;
    margin-bottom: 27.2px;
    border-bottom: 1.6px solid var(--black4);
  }

  .transaction-history__menu-item {
    font-size: 12.8px;
    line-height: 18.4px;
    height: 28.8px;
    padding: 0 5px;
  }
  .transaction-history__wrapper {
    margin-top: 28.8px;
    padding: 0px 19.2px;
    padding-bottom: 19.2px;
  }
  .transaction-history__wrapper {
    margin-top: 22.4px;
  }
  .transaction-history__table-header {
    padding: 0px;
    margin-bottom: 6.4px !important;
    height: auto !important;
    padding: 0 10px !important;
  }
  .transaction-history__row {
    padding: 5px 10px !important;
    min-height: 0;
    height: auto !important;
  }
  .transaction-history__row--highlighted {
    border-radius: 0px 10px 10px 10px;
  }
  .transaction-history__columnHeader {
    font-size: 11.2px;
    line-height: 19.2px;
  }
  .transaction-history__columnItem-text {
    font-size: 11.2px;
    line-height: 16px;
  }
  .transaction-history__header-column {
    white-space: normal !important;
  }
  .transaction-history__column {
    white-space: normal !important;
  }
  .transaction-history__payment-history-column-date,
  .transaction-history__columnItem {
    font-size: 9.6px;
    line-height: 16px;
  }
  .transaction-history__row {
    padding-bottom: 9.6px !important;
  }

  .transaction-history__payment-history-column {
    font-size: 9.6px;
  }

  .transaction-history__payment-history-column--status {
    min-width: unset;
  }

  .transaction-history__skip-payment-button {
    margin-left: 0px;
  }

  .transaction-history__payment-history-column--date,
  .transaction-history__payment-history-row--active {
    margin: 0;
  }

  .transaction-history__payment-history-row {
    width: 100%;
  }
}
