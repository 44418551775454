.public-profile-card {
  padding-top: 28px;
  display: flex;
  flex-direction: row;
}

.public-profile-card__info {
  margin-left: 3rem;
}

.public-profile-card__info-text {
  font-size: 16px;
  color: var(--settings-font-primary);
  margin-bottom: 24px;
}
