.instrument-positions-summary {
  height: 23.5rem;
}

.instrument-positions-summary__hr {
  margin: 0;
}

.instrument-positions-summary__row {
  display: flex;
  padding: 1rem 1.6rem;
  text-align: right;
  align-items: center;
  color: var(--font__color-primary);
  border-bottom: 1px solid var(--border__color);
}

.instrument-positions-summary__row:first-of-type {
  padding: 0 1.8rem;
  height: var(--component__sub-header-height);
  justify-content: space-between;
}

.instrument-positions-summary__row:first-of-type > * {
  font-size: var(--component__sub-header-font-size);
  color: var(--font__color-secondary);
}

.instrument-positions-summary__row:first-of-type
  .instrument-positions-summary__product-symbol:nth-of-type(2) {
  text-align: right;
}

.instrument-positions-summary__row-label {
  flex: 1;
  text-align: left;
  color: var(--font__color-secondary);
}

.instrument-positions-summary__product-available,
.instrument-positions-summary__product-symbol {
  flex: 2;
  color: var(--font__color-primary);
}

.instrument-positions-summary__product-hold {
  flex: 2;
  color: var(--font__color-primary);
}

.instrument-positions-summary__product-pending-deposits {
  flex: 2;
  color: var(--font__color-primary);
}

.instrument-positions-summary__product-total-balance {
  flex: 2;
  color: var(--font__color-primary);
}

.trade-component {
  background: var(--component__bg-color);
  height: calc(100% - var(--component__header-height));
}

.trade-component__header {
  height: var(--component__header-height);
  line-height: var(--component__header-height);
  background: var(--component__header-bg-color);
  padding: 0 1.75rem;
  border-bottom: 1px solid var(--component__header-separator-color);
}

.trade-component__section {
  padding-top: 1.75rem;
  margin-top: 1.75rem;
  border-top: 1px solid var(--border__color);
}

.trading-layout__tab-content .trade-component {
  height: 100%;
}

@media (max-height: 856px) and (max-width: 1280px) {
  .instrument-positions-summary {
    height: inherit;
    border-top: none;
  }
}

@media (max-height: 1080px) {
  .instrument-positions-summary {
    height: inherit;
    border-top: none;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .instrument-positions-summary {
    height: 100%;
  }
}

