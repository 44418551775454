.nav-menu-horizontal__container {
  opacity: 1;
  z-index: 1;
  display: flex;
  padding: 0 40px 10px;
  /* background: var(--nav-header__bg-color); */
}

.nav-menu-horizontal__list {
  padding: 0;
  margin: 0;
  overflow: auto;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 80px;
  height: 86px;
  padding: 0 32px;
  background: var(--settings-nav-menu-background) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 0px 32px 32px 32px;
  opacity: 1;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.nav-menu-horizontal__list::-webkit-scrollbar {
  height: var(--scrollbar__size);
}

.nav-menu-horizontal__list li {
  display: flex;
  height: 68px;
}

.nav-menu-horizontal__setting-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 1;
  padding-top: 4px;
  border-bottom: 4px solid #32245e;
  color: var(--settings-nav-menu-item);
  padding: 0 15px;
}

.nav-menu-horizontal__setting-item > div {
  text-align: center;
  line-height: 25px;
  height: 25px;
  white-space: nowrap;
}

.nav-menu-horizontal__item-icon {
  width: 20px;
  height: 20px;
}

.nav-menu-horizontal__setting-item--hoverable:hover {
  color: var(--settings-nav-menu-item-selected);
}

.nav-menu-horizontal__setting-item--selected:hover {
  opacity: 0.8;
}

.nav-menu-horizontal__setting-item-icon,
.nav-menu-horizontal__setting-item-icon > svg {
  height: 25px;
  width: 25px;
}

.nav-menu-horizontal__setting-item--selected {
  border-bottom: 4px solid #7c5ced;
  transition: 0s;
  color: var(--settings-nav-menu-item-selected) !important;
}

@media (max-width: 1200px) {
  .nav-menu-horizontal__list {
    gap: 60px;
  }
}

@media (max-width: 1000px) {
  .nav-menu-horizontal__list {
    gap: 20px;
  }
}

@media (max-width: 576px) {
  .nav-menu-horizontal__container {
    padding: 0 24px 10px;
  }

  .nav-menu-horizontal__list::-webkit-scrollbar {
    display: none;
  }

  .nav-menu-horizontal__setting-item > div {
    line-height: 21px;
    height: 21px;
  }
  .nav-menu-horizontal__setting-item {
    padding: 0 5px;
  }
}

@media (max-width: 457px) {
  .nav-menu-horizontal__list {
    gap: 10px;
  }

  .nav-menu-horizontal__setting-item > div {
    white-space: normal;
    line-height: 1;
    margin-top: 5px;
  }
}

@media (max-width: 305px) {
  .nav-menu-horizontal__list {
    padding: 0 16px;
    border-radius: 0 16px 16px 16px;
  }
}
