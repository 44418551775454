.stub-page-mobile__scroll-container {
  padding: 33px 28px;
  align-items: center;
  justify-content: space-between;
}

.stub-page-mobile__wordmark {
  width: 100%;
  max-width: 270px;
  margin-top: 33px;
  margin-bottom: 18px;
}

.stub-page-mobile__quid {
  max-width: 198px;
  width: 100%;
  margin-bottom: 20px;
}

.stub-page-mobile__title {
  font-weight: 700;
  font-size: 64px;
  line-height: 1.5;
  letter-spacing: 0.05em;
  text-align: center;
  margin-bottom: 10px;
}

.stub-page-mobile__subtitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 20px;
}

.stub-page-mobile__text-content {
  max-width: 254px;
  text-align: center;
  margin-bottom: 50px;
}

.stub-page-mobile__back-button {
  margin-bottom: 39px;
}

.stub-page-mobile__links-container {
  display: flex;
  flex-flow: row wrap;
  column-gap: 20px;
  row-gap: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  margin-bottom: 20px;
}

.stub-page-mobile__copyright {
  text-align: center;
}
