.amount-number-input__amountInputWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: 300;
  font-size: 12px;
  margin: auto;
  margin-top: 20px;
  color: var(--deposit-withdraw-font-primary);
  width: fit-content;
}

.amount-number-input__input-area {
  display: flex;
  width: 213px;
  height: 65px;
  border: 1px solid var(--deposit-withdraw-font-primary);
  background: var(--deposit-withdraw-background-secondary);
  border-radius: 0 20px 20px 20px;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.amount-number-input__amount-input {
  display: flex;
  width: 100px;
  font-size: 24px;
  color: var(--deposit-withdraw-font-primary);
  border-radius: 5px;
  text-align: center;
  background: transparent;
}

.amount-number-input__amount-button {
  height: 100%;

  display: flex;
  align-items: center;
}

.amount-number-input__amount-button--disabled {
  cursor: not-allowed;
}

.amount-number-input__amount-button--disabled .amount-number-input__icon {
  stroke: var(--deposit-withdraw-font-secondary);
}

.amount-number-input__icon {
  display: flex;
  align-items: center;
  width: 25px;
  stroke: var(--deposit-withdraw-font-primary);
}
