.pending-order__loading-wrapper {
  text-align: center;
  padding: 5rem;
}

.pending-order__spinner-container {
  position: static;
  display: inline-flex;
}

.pending-order__spinner-circle {
  border-color: var(--purple4);
  border-left-color: var(--purple1);
  font-size: var(--buy-sell-modal__font-size);
  line-height: var(--buy-sell-modal__line-height);
}

.pending-order__spinner-container .spinner__text {
  font-size: var(--buy-sell-modal__font-size);
}
