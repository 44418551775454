.buy-sell-modal {
  min-width: 53rem;
  border-radius: 0 2rem 2rem 2rem;
  max-height: unset;
  margin: auto;
}

.buy-sell-modal__overlay {
  justify-content: unset;
  align-items: unset;
  padding: 1.92rem;
  overflow-y: auto;
}

.buy-sell-modal__content {
  background: var(--buy-sell-modal-background);
  overflow: hidden;
  padding: 0;
}

.buy-sell-modal__confirm-container {
  padding: 0 5rem;
  display: flex;
  flex-direction: column;
}

.buy-sell-modal__non-input-wrapper {
  width: 53rem;
  display: flex;
  flex-direction: column;
}

.buy-sell-modal__confirm-details {
  display: flex;
  flex-direction: column;
  padding: 1.6rem 3rem 3rem;
  border-radius: 2rem;
  background-color: var(--buy-sell-modal-confirm-details-background-color);
}

.buy-sell-modal__image-fields-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  gap: 5.1rem;
  margin-top: 3.1rem;
  margin-left: 2.6rem;
  margin-right: 0.5rem;
}

.buy-sell-modal__card-image {
  width: 6.6rem;
  box-shadow: -0.64rem 0.64rem 1.2rem #00000033;
  border-radius: 0.5rem;
}

.buy-sell-modal__card-title {
  font-size: 2.4rem;
  line-height: 3.6rem;
  padding-bottom: 0.9rem;
  border-bottom: 0.05rem solid var(--grey7);
}

.buy-sell-modal__fields-container {
  flex: 1 1;
  font-size: var(--buy-sell-modal__font-size);
  line-height: var(--buy-sell-modal__line-height);
  font-weight: normal;
}

.buy-sell-modal__row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  font-size: var(--buy-sell-modal__font-size);
  line-height: var(--buy-sell-modal__line-height);
}

.buy-sell-modal__row--highlighted {
  background: linear-gradient(135deg, var(--purple1) 0%, var(--pink1) 100%);
  border-radius: 1rem;
  font-weight: 500;
  color: var(--white1);
  padding: 0.5rem 1rem;
  margin: 0.5rem -1rem 0;
}

.buy-sell-modal__fee {
  text-decoration: underline;
}

.buy-sell-modal__field-value {
  font-weight: 500;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: end;
}

.buy-sell-modal__footer {
  border: initial;
  gap: 4rem;
  background: var(--buy-sell-modal-background);
  padding: 4rem 2rem;
  justify-content: center;
  margin: 0;
}

.buy-sell-modal__btn {
  min-width: var(--buy-sell-modal__button-min-width);
  min-height: var(--buy-sell-modal__button-min-height);
  background: var(--buy-sell-modal-button-background-color);
  border: var(--buy-sell-modal__button-border);
  border-radius: var(--buy-sell-modal__button-border-radius);
  color: var(--buy-sell-modal-button-color);
  padding: var(--buy-sell-modal__button-padding);
  font-size: 1.4rem;
  font-weight: 600;
  box-shadow: none;
  line-height: 1;
  display: block;
  flex-direction: unset;
  transition: none;
}

.buy-sell-modal__btn--buy {
  color: var(--place-order-button-color--buy);
  border-color: var(--buy-sell-modal__button-border-color--buy);
}

/* use disabled attribute instead of disabled state to make it work with html 
elements that does not have disabled state */
.buy-sell-modal__btn[disabled] {
  opacity: 0.6;
  cursor: not-allowed;
}

.buy-sell-modal__btn:not([disabled]).buy-sell-modal__btn:hover,
.buy-sell-modal__btn:not([disabled]).buy-sell-modal__btn:focus {
  background: var(--buy-sell-modal__button-border-color);
  color: var(--white1);
}

.buy-sell-modal__btn:not([disabled]).buy-sell-modal__btn--buy:hover,
.buy-sell-modal__btn:not([disabled]).buy-sell-modal__btn--buy:focus {
  background: var(--buy-sell-modal__button-border-color--buy);
  color: var(--white1);
}

.buy-sell-modal__additional-button__btn {
  min-width: var(--buy-sell-modal__button-min-width);
  min-height: var(--buy-sell-modal__button-min-height);
  background: var(--buy-sell-modal-additional-button-background-color);
  border: var(--buy-sell-modal-additional-button-border);
  border-radius: 2rem;
  color: var(--buy-sell-modal-additional-button-color);
  padding: var(--buy-sell-modal__button-padding);
  font-size: 1.4rem;
  font-weight: 600;
  box-shadow: none;
  line-height: 1;
  display: block;
  flex-direction: unset;
  transition: none;
}

.buy-sell-modal__additional-button__btn:hover,
.buy-sell-modal__additional-button__btn:focus {
  background: var(--buy-sell-modal-additional-button-border-color);
  color: var(--buy-sell-modal-additional-button-background-color);
}

.buy-sell-modal__confirm-title {
  font-size: 1.4rem;
  line-height: 2.1rem;
  margin: 2.7rem 0;
  color: var(--buy-sell-modal-confirm-title-color);
}

.buy-sell-modal__highlighted {
  color: var(--pink1);
}

.buy-sell-modal__below-market-confirm-details {
  display: flex;
  flex-direction: column;
  padding: 3rem 3rem;
  border-radius: 2rem;
  background-color: var(--buy-sell-modal-confirm-details-background-color);
}

@media (max-width: 576px) {
  .buy-sell-modal {
    min-width: 0;
    width: 100%;
  }

  .buy-sell-modal__confirm-container {
    padding: 0 4.3rem;
  }

  .buy-sell-modal__non-input-wrapper {
    width: 100%;
  }

  .buy-sell-modal__image-fields-container {
    margin: 1rem 0rem 0rem 0rem;
    flex-direction: column;
    align-items: stretch;
    gap: 1rem;
  }

  .buy-sell-modal__card-title {
    text-align: center;
  }

  .buy-sell-modal__card-image {
    width: 6.9rem;
    align-self: center;
  }

  .buy-sell-modal__confirm-title {
    margin: 2.7rem 3.2rem;
  }

  .buy-sell-modal__confirm-details {
    padding: 2rem;
  }

  .buy-sell-modal__footer {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
}
