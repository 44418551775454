.slider-arrows__next-arrow-bg {
  position: absolute;
  height: 100%;
  width: 100%;
}

.slider-arrows__prev-arrow-bg {
  position: absolute;
  height: 100%;
  width: 100%;
  transform: rotate(180deg);
}

@media (max-width: 1100px) {
  .slider-arrows__prev-arrow-bg,
  .slider-arrows__next-arrow-bg {
    display: none;
  }
}