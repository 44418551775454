.assets-filter {
  display: flex;
  flex-flow: row wrap;
  column-gap: 30px;
  row-gap: 5px;
  align-items: center;
}

.assets-filter__filter-row {
  display: flex;
  gap: 40px;
  align-items: center;
  flex: 1 1;
  flex-basis: auto;
}

.assets-filter__filter-title {
  color: var(--instrument-selector-filter-label-color);
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

.assets-filter__filter-option {
  transition: 0.1s all ease;
  cursor: pointer;
  height: 36px;
  color: var(--instrument-selector-tab-color);
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  align-content: flex-start;
  position: relative;
  padding: 0px 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.assets-filter__filter-option--active,
.assets-filter__filter-option:hover {
  color: var(--instrument-selector-active-tab-color);
}

.assets-filter__filter-option:before,
.assets-filter__filter-option:hover:before {
  content: '';
  position: absolute;
  width: 100%;
  bottom: 0;
  border-bottom: 5px solid #32245e;
  border-radius: 20px;
}

.assets-filter__filter-option--active:before,
.assets-filter__filter-option--active:hover:before {
  border-bottom: 5px solid var(--purple8);
}

.assets-filter__top-level-wrapper {
  display: flex;
  flex-flow: row wrap;
  gap: 20px;
  align-items: center;
}

.assets-filter__top-level-wrapper .assets-filter__filter-row {
  justify-content: center;
}

.assets-filter__selector-wrapper {
  display: flex;
  flex: 1 0;
  flex-basis: auto;
  justify-content: flex-end;
}

@media (max-width: 800px) {
  .assets-filter__filter-row {
    gap: 12px;
  }
}

@media (max-width: 576px) {
  .assets-filter__filter-option {
    font-size: 12.8px;
    line-height: 18.4px;
    padding: 0 5px 5px;
  }
}

@media (max-width: 480px) {
  .assets-filter__filter-option {
    font-size: 11px;
    line-height: 1.5;
  }
}

@media (max-width: 400px) {
  .select-asset-modal__pagination-wrapper {
    height: calc(100% - 231px);
  }
}

@media (max-width: 380px) {
  .assets-filter__filter-row {
    gap: 8px;
  }
}
