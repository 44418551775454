.payments {
  display: flex;
  flex-direction: column;
}

.payments__header {
  width: 100%;
  height: 110px;
  margin-top: 20px;
  background: var(--settings-widget-background);
  padding: 20px 0px;
  border-radius: 0px 20px 20px 20px;
}

.payments__header-title {
  margin-left: 20px;
  font-weight: 600;
  font-size: 23px;
  line-height: 150%;
}

.payments__header-description {
  margin: 5px 0 0 20px;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
}

.payments__body {
  margin-top: 20px;
  background: var(--payments-body-background);
  color: var(--payments-font-color);
}

.payments__payments {
  width: 100%;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  font-weight: 600;
  font-size: 19px;
  line-height: 150%;
  letter-spacing: -0.02em;
}

.payments__cards-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 43px;
  border-bottom: 1px solid #4a378e;
}

.payments__card {
  width: 100%;
  height: 63px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  border: 1px solid var(--purple8);
  border-radius: 10px;
  padding: 10px;
}

.payments__card-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
}

.payments__card-right {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.payments__recurring-banner {
  margin-right: 25px;
  background: linear-gradient(90.57deg, #7c5ced 0%, #53d660 100%);
  padding: 5px 10px;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: var(--white1);
}

.payments__card-icon {
  width: 60px;
  height: 40px;
  margin-right: 20px;
}

.payments__delete-icon {
  stroke: var(--payments-font-color);
  cursor: pointer;
}

.payments__add-funds-button {
  align-self: end;
  width: 160px;
  height: 40px;
  background: linear-gradient(131.67deg, #3f8cff 0%, #825af6 100%);
  border-radius: 22.5px;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.04em;
  color: #ffffff;
  text-transform: uppercase;
}

.payments__add-funds-button:disabled {
  filter: grayscale(1);
}

.payments__cancel-payment-container {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  align-items: flex-start;
  font-weight: 600;
  font-size: 19px;
  line-height: 150%;
}

.payments__cancel-payment-button {
  display: flex;
  margin-top: 20px;
  height: 44px;
  padding: 10px 30px;
  border: 3px solid var(--red2);
  border-radius: 20px;
  background: transparent;
  font-weight: 600;
  font-size: 16px;
  color: var(--payments-font-color);
  align-items: center;
}

.payments__cancel-payment-button:hover {
  background: var(--red2);
}

.payments__payment-history {
  margin-top: 80px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}

.payments__payment-history-table {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  background: var(--payments-history-background);
  border-radius: 20px;
  padding-bottom: 15px;
}

.payments__table-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 41px;
  background: var(--paymnets-history-header-background);
  border-radius: 20px 20px 0px 0px;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
}

.payments__header-column {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  text-transform: uppercase;
  color: #5289f7;
}

.payments__payment-history-active-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.payments__payment-history-row {
  width: calc(100% - 20px);
  display: flex;
  padding: 0 10px 0 10px;
  margin: 10px;
  flex-direction: row;
  justify-content: space-between;
}

.payments__skip-payment-button {
  margin: 10px 0 0 10px;
  width: 170px;
  height: 28px;
  background: linear-gradient(131.67deg, #3f8cff 0%, #825af6 100%);
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
}

.payments__payment-history-row--active {
  height: 62px;
  margin: 0 10px;
  background: #6249bd;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 0px 20px 20px 20px;
  align-items: center;
  color: var(--white1);
}
.payments__payment-history-column {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 400;
  font-size: 14px;

  text-align: center;
}

.payments__header-column,
.payments__payment-history-column {
  min-width: 56px;
}

.payments__payment-history-column--date {
  max-width: 72px;
}

.payments__payment-history-column--status {
  min-width: 100px;
  justify-content: flex-end;
}

@media (max-width: 576px) {
  .payments__header {
    height: auto;
  }
}
