.order-entry-form__form {
  all: revert;
  color: var(--order-entry-color);
}

.order-entry-form__rt-wrapper.order-entry-form__rt-wrapper {
  border-radius: 0 12px 12px 12px;
  overflow: hidden;
  padding: 4px;
  background: var(--order-entry-order-type-background);
  gap: 5px;
}

.order-entry-form__rt-wrapper--additive.order-entry-form__rt-wrapper--additive {
  border: 0.5px solid var(--order-entry-order-type-border-additive);
}

.order-entry-form__rt-wrapper--subtractive.order-entry-form__rt-wrapper--subtractive {
  border: 0.5px solid var(--order-entry-order-type-border-subtractive);
}

.order-entry-form__rt-label--checked.btn.btn.btn.btn.btn.btn {
  color: var(--white1);
}

.order-entry-form__rt-wrapper .btn.btn.btn.btn.btn {
  border-radius: 0 12px 12px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  line-height: 13px;
  font-weight: 600;
  padding: 3.5px;
}

.order-entry-form .ap-segmented-button__label--checked-additive,
.order-entry-form .ap-segmented-button__label--checked-additive:hover {
  background: var(--blue2);
}

.order-entry-form .ap-segmented-button__label--checked-subtractive,
.order-entry-form .ap-segmented-button__label--checked-subtractive:hover {
  background: var(--purple8);
}

.order-entry-form__balance {
  margin: 20px 10px;
}

.order-entry-form__balance-key {
  font-size: 10px;
  line-height: 1.5;
}

.order-entry-form__balance-value {
  display: flex;
  align-items: center;
  margin-top: 5px;
  font-size: 10px;
  line-height: 1.5;
  font-weight: 600;
  text-align: center;
}

.order-entry-form__balance-value-number {
  flex: 1 1;
  flex-basis: auto;
  font-size: 12px;
  text-align: right;
}

.order-entry-form__balance-value-symbol {
  width: 18%;
  display: flex;
  justify-content: flex-end;
}

.order-entry-form__picker-label {
  font-size: 10px;
  line-height: 15px;
  margin: 23px 10px 10px 5px;
}

.order-entry-form .quantity-picker {
  margin: 5px 10px 20px 10px;
}

.order-entry-form .quantity-picker__input-container--expanded {
  border-radius: 10px;
}

.order-entry-form .quantity-picker__show-input {
  padding-left: 10px;
  padding-right: 10px;
}

.order-entry-form .quantity-picker__input-wrapper {
  padding-left: 10px;
  padding-right: 17px;
}

.order-entry-form__field__input-wrapper {
  margin-left: 10px;
  margin-right: 10px;
}

.order-entry-form__field__input-wrapper--stop {
  margin-bottom: 20px;
}

.order-entry-form__field__input-wrapper--quantity {
  margin-top: 25px;
}

.order-entry-form__field__input-wrapper--total {
  margin-bottom: 0px;
}

.order-entry-form__field__label {
  display: block;
  font-size: 10px;
  line-height: 15px;
  color: var(--order-entry-color);
  margin-bottom: 5px;
}

.order-entry-form__field__input-box {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.order-entry-form__field__input {
  flex: 1 1;
  flex-basis: auto;
  height: unset;
  display: inline-block;
  border-radius: 999px;
  color: var(--order-entry-color);
  padding: 3.5px 10px;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 600;
  text-align: right;
  border: none !important;
  transition: none;
}

.order-entry-form__field__input,
.order-entry-form__field__input:hover,
.order-entry-form__field__input:focus {
  background: var(--order-entry-input-background-color);
}

.order-entry-form__field__input:disabled {
  background: var(--order-entry-input-background-color--disabled);
}

.order-entry-form__field__input-wrapper--market .ap-input__input {
  padding: 5px 10px;
  font-size: 10px;
  font-weight: 400;
}

.order-entry-form__field__input-wrapper--market .ap-input__input::placeholder {
  color: var(--order-entry-color);
}

/* remove if we need to display errors */
.order-entry-form__field__error {
  display: none;
}

.order-entry-form__field__right-label {
  width: 18%;
  height: unset;
  padding: 0;
  font-size: 10px;
  line-height: 23px;
  font-weight: 600;
  letter-spacing: 0px;
  color: var(--order-entry-color);
  border: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.order-entry-form .percentage-slider {
  margin: 35px 17.5px 40px;
}

.order-entry-form__form .percentage-slider {
  --track-color: var(--buy-sell-modal__button-border-color--buy);
  --track-inactive-color: var(--quantity-picker-track-inactive-color--buy);
  --thumb-color: var(--buy-sell-modal__button-border-color--buy);
  --tooltip-color: var(--quantity-picker-tooltip-color--buy);
  --mark-color: var(--buy-sell-modal-background);
}

.order-entry-form__form--subtractive .percentage-slider {
  --track-color: var(--buy-sell-modal__button-border-color);
  --track-inactive-color: var(--quantity-picker-track-inactive-color);
  --thumb-color: var(--buy-sell-modal__button-border-color);
  --tooltip-color: var(--quantity-picker-tooltip-color);
}

/* delete these two rules below to make progress visible */
.order-entry-form .percentage-slider__slider-track {
  background-color: transparent;
}

.order-entry-form .percentage-slider__slider-dot-active {
  background-color: var(--mark-color);
}

.order-entry-form__fee-container {
  display: flex;
  align-items: center;
  font-size: 10px;
  line-height: 17px;
  margin: 5px 10px 0px;
  padding: 5px 0px;
}

.order-entry-form__fee-sign {
  flex: 1 1;
  flex-basis: auto;
  font-size: 12px;
  text-align: right;
  margin: 0px 10px;
}

.order-entry-form__fee-value {
  font-size: 12px;
}

.order-entry-form__fee-symbol {
  width: 18%;
  font-weight: 600;
  text-align: center;
  display: flex;
  justify-content: flex-end;
}

.order-entry-form__tip {
  margin: 25px 10px 0px;
  padding: 10px;
  font-size: 10px;
  line-height: 1.5;
  color: var(--white1);
  border-radius: 20px;
}

.order-entry-form__form .order-entry-form__tip {
  background-color: var(--blue2);
}

.order-entry-form__form--subtractive .order-entry-form__tip {
  background-color: var(--purple8);
}

.order-entry-form__tip-title {
  font-size: 12px;
  font-weight: 600;
}

.order-entry-form__submit-container {
  display: flex;
  justify-content: center;
  margin: 40px 20px 0px 20px;
}

.order-entry-form__submit__btn {
  width: 200px;
  padding: 0px 20px;
  min-height: 40px;
  max-width: 200px;
  align-self: center;
  text-transform: uppercase;
  text-align: center;
  border-radius: 0 20px 20px 20px;
  border: 3px solid transparent !important;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  transition: none;
}

.order-entry-form__submit__btn:hover {
  color: var(--order-entry-submit-color-hover);
}

.order-entry-form__submit__btn--additive {
  color: var(--order-entry-submit-color-additive);
}

.order-entry-form__submit__btn--additive,
.order-entry-form__submit__btn--additive:focus,
.order-entry-form__submit__btn--additive:active {
  background: var(--order-entry-submit-background-additive);
}

.order-entry-form__submit__btn--additive:hover {
  background: var(--order-entry-submit-background-additive-hover);
}

.order-entry-form__submit__btn--subtractive {
  color: var(--order-entry-submit-color-subtractive);
}

.order-entry-form__submit__btn--subtractive,
.order-entry-form__submit__btn--subtractive:focus,
.order-entry-form__submit__btn--subtractive:active {
  background: var(--order-entry-submit-background-subtractive);
}

.order-entry-form__submit__btn--subtractive:hover {
  background: var(--order-entry-submit-background-subtractive-hover);
}

.order-entry-form-modal__overlay {
  justify-content: unset;
  align-items: unset;
  padding: 24px;
  overflow-y: auto;
}

.order-entry-form-modal {
  width: 295px;
  border-radius: 0px 20px 20px 20px;
  max-height: unset;
  margin: auto;
}

.order-entry-form-modal--additive {
  background: var(--order-entry-background-additive);
}

.order-entry-form-modal--subtractive {
  background: var(--order-entry-background-subtractive);
}

.order-entry-form-modal__header {
  height: 38px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  color: var(--white1);
  padding: 0px 12px 0px 20px;
  text-transform: uppercase;
}

.order-entry-form-modal--additive .order-entry-form-modal__header {
  background: var(--blue6);
}

.order-entry-form-modal--subtractive .order-entry-form-modal__header {
  background: var(--purple8);
}

.order-entry-form-modal__close-icon {
  width: 14px;
  height: 14px;
}

.order-entry-form-modal__content {
  padding: 13px 10px 30px 10px;
  overflow: hidden;
}
