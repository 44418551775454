.breadcrumbs {
  display: flex;
  margin-left: 72px;
  margin-bottom: 18px;
}

.breadcrumbs__arrow-right {
  padding: 0px 5px;
}

.breadcrumbs__link {
  cursor: pointer;
  color: var(--bread-crumbs-color);
}

.breadcrumbs__content {
  background: transparent;
  flex-grow: 1;
  position: relative;
}

@media (max-width: 576px) {
  .breadcrumbs {
    margin-left: 48px;
  }
  .breadcrumbs__link {
    font-size: 12px;
  }
}
