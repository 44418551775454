.ap_typography__color--inherit {
  color: 'inherit';
}

.ap_typography__color--black1 {
  color: var(--black1);
}

.ap_typography__color--black2 {
  color: var(--black2);
}

.ap_typography__color--black3 {
  color: var(--black3);
}

.ap_typography__color--black4 {
  color: var(--black4);
}

.ap_typography__color--black5 {
  color: var(--black5);
}

.ap_typography__color--black6 {
  color: var(--black6);
}

.ap_typography__color--black7 {
  color: var(--black7);
}

.ap_typography__color--black8 {
  color: var(--black8);
}

.ap_typography__color--black12 {
  color: var(--black12);
}

.ap_typography__color--white1 {
  color: var(--white1);
}

.ap_typography__color--white2 {
  color: var(--white2);
}

.ap_typography__color--white3 {
  color: var(--white3);
}

.ap_typography__color--white4 {
  color: var(--white4);
}

.ap_typography__color--blue1 {
  color: var(--blue1);
}

.ap_typography__color--blue2 {
  color: var(--blue2);
}

.ap_typography__color--grey1 {
  color: var(--grey1);
}

.ap_typography__color--grey2 {
  color: var(--grey2);
}

.ap_typography__color--grey3 {
  color: var(--grey3);
}

.ap_typography__color--grey4 {
  color: var(--grey4);
}

.ap_typography__color--grey5 {
  color: var(--grey5);
}

.ap_typography__color--pink1 {
  color: var(--pink1);
}

.ap_typography__color--purple1 {
  color: var(--purple1);
}

.ap_typography__color--purple2 {
  color: var(--purple2);
}

.ap_typography__color--purple3 {
  color: var(--purple3);
}

.ap_typography__color--purple4 {
  color: var(--purple4);
}

.ap_typography__color--purple8 {
  color: var(--purple8);
}

.ap_typography__color--green1 {
  color: var(--green1);
}

.ap_typography__color--green2 {
  color: var(--green2);
}

.ap_typography__color--red1 {
  color: var(--red1);
}

.ap_typography__color--brown1 {
  color: var(--brown1);
}

.ap_typography__fontSize--small1 {
  font-size: 8px;
}

.ap_typography__fontSize--small2 {
  font-size: 9.6px;
}

.ap_typography__fontSize--small3 {
  font-size: 11.2px;
}

.ap_typography__fontSize--caption1 {
  font-size: 12px;
}

.ap_typography__fontSize--caption2 {
  font-size: 12.8px;
}

.ap_typography__fontSize--subbody {
  font-size: 14.4px;
}

.ap_typography__fontSize--body {
  font-size: 16px;
}

.ap_typography__fontSize--subtitle {
  font-size: 19.2px;
}

.ap_typography__fontSize--title {
  font-size: 22.4px;
}

.ap_typography__fontSize--big {
  font-size: 25.6px;
}

.ap_typography__fontSize--headline {
  font-size: 28.8px;
}

.ap_typography__fontSize--subheader {
  font-size: 30.4px;
}

.ap_typography__fontSize--header {
  font-size: 32px;
}

.ap_typography__fontSize--jumbo {
  font-size: 38.4px;
}

.ap_typography__fontSize--mega {
  font-size: 60.8px;
}

.ap_typography__opacity--normal {
  opacity: 1;
}

.ap_typography__opacity--light1 {
  opacity: 0.8;
}

.ap_typography__opacity--light2 {
  opacity: 0.6;
}

.ap_typography__opacity--light3 {
  opacity: 0.4;
}

.ap_typography__weight--weight300 {
  font-weight: 300;
}
.ap_typography__weight--weight500 {
  font-weight: 500;
}

.ap_typography__weight--weight600 {
  font-weight: 600;
}

.ap_typography__weight--weight700 {
  font-weight: 700;
}

.ap_typography__weight--normal {
  font-weight: normal;
}

.ap_typography__weight--bold {
  font-weight: bold;
}

.ap_typography__underline {
  text-decoration: underline;
}

.ap_typography__noWrap {
  white-space: nowrap;
}

.ap_typography__notSelectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
