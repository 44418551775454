.public-asset-details-header {
  background-color: var(--header-footer-color);
  position: sticky;
  top: 0px;
  padding: 20px;
  z-index: 5;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.public-asset-details-header__logo {
  height: 68px;
}

.public-asset-details {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  --header-footer-color: rgba(25, 27, 33, 0.83);
  flex-grow: 1;
}

.public-asset-details__spinner-container {
  flex-grow: 1;
}

.public-asset-details__asset-details-wrapper {
  display: flex;
  flex-direction: column;
  gap: 38px;
  max-width: 1520px;
  padding: 0 14px 14px;
  margin-bottom: 8px;
  margin: 0 auto;
}

.public-asset-details .market-place-asset-header {
  margin: 0;
}

.public-asset-details__button-container {
  position: sticky;
  z-index: 5;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  padding: 20px;
  background-color: var(--header-footer-color);
}

.public-asset-details>.footer {
  background-color: var(--header-footer-color);
  margin: 0;
  padding: 20px;
}

.public-asset-details>.footer * {
  color: var(--white1);
}

.public-asset-details>.footer svg {
  fill: var(--white1);
}

@media (min-width: 1024px) {
  .public-asset-details-header {
    margin-bottom: 43px;
  }

  .public-asset-details__asset-details-wrapper {
    padding: 0 55px;
  }

  .public-asset-details>.footer {
    width: 100%;
    max-width: 1520px;
    background-color: unset;
    padding: 62px 55px 46px;
    margin: 0 auto;
  }

  .public-asset-details>.footer * {
    color: unset;
  }
}
