.onboarding-overlay__wrapper {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(19, 21, 35, 0.8);
  backdrop-filter: blur(5px);
  z-index: 1000000;
}

.onboarding-overlay__content-wrapper {
  min-height: 245px;
  width: 550px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  background-color: var(--onboarding-container-background);
  padding-top: 12px;
  padding-right: 17px;
  padding-bottom: 20px;
  padding-left: 17px;
  position: sticky;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.onboarding-overlay__content {
  display: flex;
  flex-direction: column;
}

.onboarding-overlay__logo {
  position: absolute;
  left: 0;
  top: -35px;
  width: 180px;
  max-width: 180px;
}

.onboarding-overlay__skip {
  align-self: flex-end;
  width: 120px;
  height: 30px;
  cursor: pointer;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--lightgrey1);
  border-radius: 20px;
}

.onboarding-overlay__skip:hover {
  color: var(--white1);
  background-color: var(--purple8);
}

.onboarding-overlay__text-wrapper {
  padding-left: 191px;
  padding-top: 18px;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: var(--onboarding-text-color);
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.onboarding-overlay__button {
  align-self: center;
  width: 160px;
  height: 40px;
  background-color: var(--purple8);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white1);
  cursor: pointer;
}
