.payments-modal__content {
  padding: 0;
  overflow-y: scroll;
  overflow-x: auto;
  width: 800px;
  border-radius: 0px 20px 20px 20px;
  background: var(--payments-modals-background);
}

.payments-modal__delete-card-header,
.payments-modal__skip-payment-header {
  height: 65px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(90.57deg, var(--red2) 0%, #825af6 100%);
  padding: 0 25px;
  color: var(--white1);
}
.payments-modal__skip-payment-header {
  background: linear-gradient(131.67deg, #3f8cff 0%, #825af6 100%);
}

.payments-modal__header-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  font-size: 19px;
  line-height: 150%;
  letter-spacing: -0.02em;
}

.payments-modal__trash-icon,
.payments-modal__pause-icon {
  stroke: var(--white1);
  fill: transparent;
  margin-right: 25px;
}

.payments-modal__close-icon {
  width: 15px;
  cursor: pointer;
}

.payments-modal__body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.payments-modal__title {
  margin-top: 40px;
  font-weight: 600;
  font-size: 19px;
  line-height: 150%;
}

.payments-modal__card-info,
.payments-modal__payment-info {
  width: 390px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 20px 40px;
  background: var(--payments-modals-info-background);
}

.payments-modal__card-info-field {
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  margin-bottom: 10px;
}

.payments-modal__card-info-value {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  margin-bottom: 20px;
}

.payments-modal__buttons {
  margin: 40px 0 20px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 40px;
}

.payments-modal__cancel-button,
.payments-modal__confirm-delete,
.payments-modal__confirm-skip {
  width: 190px;
  height: 41px;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 20px;
  color: var(--payments-font-color);
}

.payments-modal__cancel-button {
  border: 3px solid var(--payments-font-color);
}

.payments-modal__cancel-button:hover {
  background: var(--payments-font-color);
  color: var(--payments-modals-info-background);
}

.payments-modal__confirm-delete {
  border: 3px solid var(--red2);
}
.payments-modal__confirm-delete:hover {
  background: var(--red2);
}

.payments-modal__confirm-skip {
  border: 3px solid var(--purple8);
}

.payments-modal__confirm-skip:hover {
  background: var(--purple8);
}

.payments-modal__spinner__spinner-container {
  position: relative;
}

@media (max-width: 830px) {
  .payments-modal__delete-card-header {
    width: 100%;
  }
  .payments-modal__content {
    width: 100%;
  }
  .payments-modal__body {
    padding: 0 40px;
  }

  .payments-modal__title {
    text-align: center;
  }
}

@media (max-width: 430px) {
  .payments-modal__card-info {
    width: 300px;
  }

  .payments-modal__cancel-button,
  .payments-modal__confirm-delete,
  .payments-modal__confirm-skip {
    width: 140px;
  }

  .payments-modal__buttons {
    gap: 20px;
  }
}
