.select__wrapper {
  position: relative;
  min-width: 121px;
  height: 26px;
}

.select__arrow {
  width: 12px;
  height: 8px;
  position: absolute;
  display: flex;
  right: 9.5px;
  top: 9.5px;
  pointer-events: none;
}

.select__arrow svg:not(:root) {
  fill: var(--select-arrow-color);
}

.select__arrow--active svg:not(:root) {
  fill: var(--select-active-arrow-color);
  transform: rotate(180deg);
}

.select__select {
  border: 1px solid var(--select-border-color);
  border-radius: 20px;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  display: block;
  width: 100%;
  height: 100%;
  padding-left: 12px;
  padding-right: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--select-background-color);
  color: var(--select-text-color);
  cursor: pointer;
}

.select__select--active {
  background-color: var(--select-active-background-color);
  color: var(--select-active-text-color);
}

.select__select::-ms-expand {
  display: none;
}

.select__list-wrapper {
  position: absolute;
  z-index: 100;
  top: 31px;
  width: 121px;
  max-height: 152px;
  background: var(--black15);
  border-radius: 10px;
  padding-top: 8px;
  padding-left: 10px;
  padding-right: 3px;
  padding-bottom: 5px;
  overflow: hidden;
}

.select__list-inner-wrapper {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding-right: 5px;
  height: 100%;
  max-height: 152px;
}

.select__list-inner-wrapper::-webkit-scrollbar {
  width: 2px;
  border-radius: 5px;
}

.select__list-inner-wrapper::-webkit-scrollbar-track {
  background: var(--black15);
}

.select__list-inner-wrapper::-webkit-scrollbar-thumb {
  background: var(--white1);
  border-radius: 5px;
}

.select__list-item {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: var(--grey11);
  cursor: pointer;
}

.select__list-item:hover,
.select__list-item--active {
  color: var(--white1);
}

.select__list-item-divider {
  height: 1px;
  background: var(--grey11);
  width: 100%;
  margin: 5px 0;
}

.select__list-item-divider--active {
  background: var(--white1);
}

@media (max-width: 800px) {
}
