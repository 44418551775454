

.simple-order-message__container {
  font-size: 1.4rem;
  line-height: 2.1rem;
  margin-top: 2rem;
  padding: 0 5rem;
  color: var(--buy-sell-modal-confirm-title-color);
  text-align: center;
}

.simple-order-message__image {
  height: 10.3rem;
  display: block;
  margin: auto;
  margin-bottom: 2rem;
}