.onboarding__wrapper {
  background: var(--onboarding-container-background);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  min-height: 245px;
  max-width: 550px;
  padding-top: 12px;
  padding-right: 17px;
  padding-bottom: 20px;
  padding-left: 17px;
}

.onboarding__arrow {
  position: absolute;
}

.onboarding__arrow--hide {
  display: none;
}

.onboarding__arrow--left-top {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0px 73px 34px 0;
  border-color: transparent var(--onboarding-container-background) transparent
    transparent;
  left: -73px;
  top: 17px;
}

.onboarding__arrow--top-right {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0px 73px 34px;
  border-color: transparent transparent var(--onboarding-container-background)
    transparent;
  right: 23px;
  top: -73px;
}

.onboarding__arrow--bottom-right {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 73px 0px 0 34px;
  border-color: var(--onboarding-container-background) transparent transparent
    transparent;
  right: 23px;
  bottom: -73px;
}

.onboarding__arrow--right-top {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0px 0 34px 73px;
  border-color: transparent transparent transparent
    var(--onboarding-container-background);
  right: -73px;
  top: 23px;
}

.onboarding__content {
  display: flex;
  flex-direction: column;
}

.onboarding__logo {
  position: absolute;
  left: 0;
  top: -35px;
  width: 180px;
}

.onboarding__skip {
  align-self: flex-end;
  width: 120px;
  height: 30px;
  cursor: pointer;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--lightgrey1);
  border-radius: 20px;
}

.onboarding__skip:hover {
  color: var(--white1);
  background-color: var(--purple8);
}

.onboarding__text-wrapper {
  padding-left: 191px;
  padding-top: 18px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: var(--onboarding-text-color);
  white-space: pre-wrap;
}

.onboarding__prev-button {
  width: 160px;
  height: 40px;
  border: 1px solid var(--onboarding-text-color);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--onboarding-text-color);
  cursor: pointer;
}

.onboarding__next-button {
  width: 160px;
  height: 40px;
  background-color: var(--purple8);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white1);
  cursor: pointer;
}

.onboarding__buttons-wrapper {
  display: flex;
  gap: 40px;
  align-self: center;
  margin-top: 40px;
}
