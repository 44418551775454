.card-offering-block__container {
  border-radius: 0px 25.6px 25.6px 25.6px;
  position: relative;
  overflow: hidden;
  min-height: 500px;
  color: var(--offering-title-color);
}
.card-offering-block__mobile-preview-image-wrapper {
  position: absolute;
  height: 200px;
  width: 200px;
  right: 13px;
  top: 86px;
}

.card-offering-block__additional-price {
  position: absolute;
  left: -13px;
  top: 50px;
  color: var(--red1);
  width: 165px;
  font-size: 11px;
  text-align: center;
}

.card-offering-block__mobile-preview-image {
  height: 200px;
  width: 200px;
}

.card-offering-block__container::before {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  content: '';
  opacity: 1;
}
.card-offering-block__sold-out-banner {
  position: absolute;
  right: 0;
  pointer-events: none;
  height: 325px;
}
.card-offering-block__content-wrapper {
  display: flex;
  padding: 29px 40px;
  position: relative;
}
.card-offering-block__content {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 60%;
}
.card-offering-block__rarity-block {
  display: flex;
  margin-top: 10px;
  gap: 10px;
}
.card-offering-block__info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
  height: 64px;
  justify-content: center;
  flex: 1;
  border: 1px solid var(--offering-title-color);
  border-radius: 0px 20px 20px 20px;
  opacity: 1;
}

.card-offering-block__population-info {
  position: absolute;
  width: 15px;
  height: 15px;
  right: -28px;
  top: 0;
}

.card-offering-block__total-size {
  background-color: linear-gradient(131.67deg, #3f8cff 0%, #825af6 100%);
  margin-top: 20px;
  border-radius: 0px 20px 20px 20px;
  opacity: 1;
  padding: 0 16px;
  height: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--offering-title-color);
}
.card-offering-block__footer-buttons {
  margin-top: 32px;
  display: flex;
  align-items: center;
  gap: 40px;
}

.card-offering-block__title {
  line-height: 36px;
  font-size: 24px;
  color: var(--currentOfferingTextColor);
  font-weight: 600;
}
.card-offering-block__description {
  margin-top: 20px;
  line-height: 18px;
  font-size: 12px;
  color: var(--currentOfferingTextColor);
  font-weight: 500;
}
.card-offering-block__grab-token {
  margin-top: 10px;
  line-height: 27px;
  font-size: 18px;
  font-weight: 500;
  color: var(--productTextColor);
}
.card-offering-block__total-size-title {
  line-height: 15px;
  font-size: 10px;
  color: var(--currentOfferingTextColor);
  font-weight: 500;
}
.card-offering-block__total-size-amount {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: var(--currentOfferingTextColor);
  margin-top: 10px;
}
.card-offering-block__block-title {
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  color: var(--currentOfferingTextColor);
  position: relative;
}
.card-offering-block__block-description {
  color: var(--currentOfferingTextColor);
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

.card-offering-block__add-to-favorite-button {
  margin-top: 53px;
}

.card-offering-block__availability-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.card-offering-block__mobile-content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.card-offering-block__buy-sell-wrapper {
  display: flex;
  gap: 40px;
  position: relative;
  overflow: visible;
  text-align: center;
}

@media (max-width: 900px) {
  .card-offering-block__content {
    max-width: 100%;
  }
}

@media (max-width: 576px) {
  .card-offering-block__container {
    min-height: max-content;
  }
  .card-offering-block__content-wrapper {
    padding: 20px;
  }
  .card-offering-block__content--second-block {
    display: none;
  }
  .card-offering-block__text-block {
    padding-right: 60%;
  }
  .card-offering-block__title {
    line-height: 21px;
    font-size: 14px;
  }
  .card-offering-block__description {
    line-height: 15px;
    font-size: 10px;
    width: 200px;
  }
  .card-offering-block__grab-token {
    line-height: 21px;
    font-size: 14px;
  }
  .card-offering-block__total-size {
    margin-top: 10px;
    padding: 0px 20px;
    flex-direction: row;
    width: 200px;
    height: 40px;
    justify-content: space-between;
    align-items: center;
  }
  .card-offering-block__total-size-amount {
    margin-top: 0;
  }
  .card-offering-block__rarity-block {
    margin-top: 9.6px;
  }
  .card-offering-block__info {
    padding: 0px 20px;
    flex-direction: row;
    width: 200px;
    height: 40px;
    min-height: 40px;
    justify-content: space-between;
    align-items: center;
  }
  .card-offering-block__footer-buttons {
    margin-top: 40px;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
  }
  .card-offering-block__sold-out-banner {
    height: 200px;
  }
  .card-offering-block__add-to-favorite-button {
    margin-top: 0;
  }
  .card-offering-block__additional-price {
    top: 44px;
    font-size: 10px;
    left: 0;
  }
}
