.reminder__wrapper {
  height: 108.8px;
  width: 100%;
  border-radius: 0px 25.6px 25.6px 25.6px;
  display: flex;
  justify-content: center;
  position: relative;
  background: #d23b80;
  overflow: hidden;
}

.reminder__back-img {
  position: absolute;
  z-index: 1;
  mix-blend-mode: soft-light;
  width: 244.8px;
  height: 263.2px;
  top: 0;
  left: 0;
}

.reminder__content-wrapper {
  margin-top: 16.8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 45.6px;
  text-align: center;
  z-index: 2;
}

.reminder__content-wrapper-with-small-padding {
  padding: 0 17.6px;
}

.reminder__close-wrapper {
  width: 101.6px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
  gap: 6px;
  border-radius: 0px 25.6px;
  background: rgba(228, 239, 244, 0.41);
  cursor: pointer;
}

.reminder__close-icon {
  width: 10px;
  height: 10px;
}

.reminder__close-icon svg:not(:root) {
  width: 10px;
  height: 10px;
}

.reminder__close-wrapper:hover {
  background: rgba(255, 152, 229, 0.41);
}

@media (max-width: 1465px) {
  .reminder__wrapper-with-small-padding {
    height: 123.2px;
  }
}

@media (max-width: 850px) {
  .reminder__wrapper {
    height: 123.2px;
  }
}

@media (max-width: 600px) {
  .reminder__content-wrapper {
    padding: 0 17.6px;
    align-items: flex-start;
    text-align: left;
  }

  .reminder__back-img {
    height: 135.2px;
    width: 148px;
  }
}
