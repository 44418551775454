.verification-remind-modal {
  max-width: 450px;
}

.verification-remind-modal__header {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 25px;
  height: 70px;
  background: var(--purple6);
  color: var(--white1);
  font-size: 20px;
  font-weight: 600;
}

.verification-remind-modal__image {
  height: 50px;
  width: 80px;
  background-image: url(./Squid.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: -10px;
  z-index: 5;
}

.verification-remind-modal__close-icon-container {
  position: absolute;
  display: none;
  right: 0;
  top: 0;
  background: var(--purple1);
  height: 28px;
  width: 80px;
  color: var(--white1);
  border-radius: 0 32px 0 32px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.verification-remind-modal__icon {
  display: flex;
  align-items: center;
  width: 8px;
  height: 8px;
  margin-left: 5px;
}

.verification-remind-modal__close-icon {
  display: none;
}

.verification-remind-modal__content {
  background: var(--liquid-background-secondary);
}

.verification-remind-modal__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.verification-remind-modal__text {
  margin-top: 10px;
  color: var(--liquid-primary-font-color);
}

.verification-remind-modal__button {
  background: var(--blue2);
  color: var(--white1);
  height: 26px;
  width: 180px;
  border-radius: 0 32px 32px 32px;
  margin-top: 60px;
  margin-bottom: 20px;
}

@media (max-width: 576px) {
  .verification-remind-modal {
    margin: 20px;
  }
}
