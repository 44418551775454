.stub-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: var(--stub-page-background);
  position: relative;
  overflow-y: auto;
}

.stub-page__scroll-container {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}

.stub-page__wordmark {
  filter: var(--stub-page-wordmark-filter)
}

.stub-page__title {
  width: 100%;
  display: inline-block;
  background: linear-gradient(131.67deg, var(--blue2) 0%, var(--purple1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-text-stroke: 1px var(--stub-page-text-color);
  text-transform: uppercase;
}

.stub-page__title>div>div {
  font-size: inherit;
}

.stub-page__subtitle {
  text-transform: uppercase;
  color: var(--stub-page-text-color);
}

.stub-page__text-content,
.stub-page__copyright {
  font-size: 16px;
  line-height: 24px;
  color: var(--stub-page-text-color);
}

.stub-page__copyright {
  font-size: 16px;
  line-height: 24px;
  color: var(--stub-page-links-text-color);
}

.stub-page__links-container>a {
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: var(--stub-page-links-text-color);
}

.stub-page__back-button {
  padding: 6.5px 20px;
  text-transform: uppercase;
  border-radius: 0 20px 20px 20px;
  border: 3px solid transparent;
  background: linear-gradient(0deg,
      var(--stub-page-button-bg-color) 0%,
      var(--stub-page-button-bg-color) 100%) padding-box,
    linear-gradient(131.67deg, var(--blue2) 0%, var(--purple1) 100%) border-box;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  transition: none;
  color: var(--stub-page-text-color);
}

.stub-page__back-button:hover,
.stub-page__back-button--additive:focus {
  background: linear-gradient(131.67deg, var(--blue2) 0%, var(--purple1) 100%) border-box;
  color: var(--white1)
}

.stub-page__divider {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
}

.stub-page__divider svg:not(:root) {
  width: 100%;
}
