.community-page {
  width: 100%;
  padding-left: 55px;
  padding-right: 55px;
  display: flex;
  flex-direction: column;
  color: var(--community-page-font);
  position: relative;
  align-items: center;
}

.community-page__header {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 40px;
  padding-right: 25px;
  background: var(--community-page-background-secondary);
  border-radius: 20px;
  font-weight: 700;
  font-size: 24px;
}

.community-page__discord-link-container {
  width: 220px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: var(--community-page-discord-link-bg);
  border: 3px solid var(--purple8);
  box-sizing: border-box;
  border-radius: 20px;
  cursor: pointer;
  color: var(--community-page-font);
}

.community-page__discord-icon {
  width: 22px;
  height: 17px;
  fill: var(--purple8);
}

.community-page__discord-link-container:hover {
  background: var(--purple8);
  color: var(--white1);
}

.community-page__discord-link-container:hover .community-page__discord-icon {
  fill: var(--white1);
}

.community-page__bg-container {
  position: absolute;
  display: flex;
  height: 675px;
  width: calc(100% - 110px);
  max-width: 1130px;
  top: 100px;
  z-index: 10;
  flex-direction: column;
  border-radius: 20px;
  background: url(./images/grandmasters_bg.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.community-page__breaking-line-container {
  max-width: 1130px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
  border-radius: 20px;
  background: #6b6e79;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.community-page__breaking-line-header {
  height: 100%;
  width: 230px;
  min-width: 230px;
  background: linear-gradient(131.67deg, #3f8cff 0%, #825af6 100%);
  border-radius: 20px;
  text-align: center;
  line-height: 40px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  z-index: 5;
  color: var(--white1);
}

.community-page__breaking-line {
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  overflow: visible;
}

.community-page__breaking-line-item {
  display: flex;
  flex: row;
  height: 100%;
  margin-right: 40px;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  color: var(--white1);
}

.community-page__top3-container {
  width: 100%;
  max-height: 610px;
  display: flex;
  margin-top: 80px;
  border-radius: 0px 20px 20px 20px;
  word-wrap: normal;
}

.community-page__faded-container {
  margin: auto;
  height: 570px;
  width: calc(100% - 44px);
  background: rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.community-page__title {
  margin: auto;
  margin-top: 20px;
}

.community-page__grandmasters {
  display: flex;
  flex-direction: row;
  margin: auto;
  gap: 80px;
  margin-bottom: 20px;
}

.community-page__grandmaster {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.community-page__user-info-container {
  display: flex;
  flex-direction: column;
  width: 220px;
  height: 224px;
  margin-top: 20px;
  padding: 20px;
  background: linear-gradient(
    131.67deg,
    rgba(63, 140, 255, 0.75) 0%,
    rgba(130, 90, 246, 0.75) 100%
  );
  border-radius: 20px;
}

.community-page__user-info-title {
  width: 100px;
  height: 18px;
  line-height: 18px;
  border-radius: 20px;
  background: var(--blue10);
  color: var(--purple8);
  text-align: center;
  font-weight: 600;
  font-size: 12px;
}

.community-page__user-info-username {
  margin-top: 20px;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  color: var(--grey12);
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.community-page__user-info-joined {
  margin-top: 20px;
  font-weight: 400;
  font-size: 10px;
  color: var(--white1);
  line-height: 18px;
}

.community-page__user-info-XP {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  margin-top: 5px;
  line-height: 21px;
  color: var(--white1);
}

.community-page__XP-span {
  font-weight: 400;
  font-size: 12px;
  margin-left: 10px;
}

.community-page__user-info-details {
  width: 100px;
  height: 25px;
  border: 1px solid var(--white1);
  border-radius: 20px;
  color: var(--white1);
  margin-top: 25px;
  font-weight: 600;
  font-size: 10px;
  text-align: center;
  line-height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.community-page__user-info-details:hover {
  background-color: var(--grey2);
}

.community-page__xp-tops {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1130px;
  margin-top: 700px;
  gap: 20px;
}

.community-page__top-100-container-monthly,
.community-page__top-100-container-alltime {
  display: flex;
  position: relative;
  flex-direction: column;
  width: calc(50% - 10px);
  height: 90px;
}

.community-page__top-100-container-monthly--close,
.community-page__top-100-container-alltime--close {
  height: 1600px;
}

.community-page__top-100-container-monthly--open,
.community-page__top-100-container-alltime--open {
  height: 7200px;
}

.community-page__top-100-container-monthly--mobile,
.community-page__top-100-container-alltime--mobile {
  height: 100px;
}

.community-page__top-bg-image {
  position: absolute;
  width: 100%;
  height: 100%;
}

.community-page__top-100-header {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 0px 20px 20px 20px;
  padding-left: 30px;
  padding-right: 10px;
  background: transparent;
}

.community-page__top-100-header-left {
  display: flex;
  flex-direction: row;
  font-weight: 600;
  font-size: 20px;
  align-items: center;
  z-index: 5;
}

.community-page__monthly-badge,
.community-page__all-time-badge {
  margin-right: 25px;
  padding: 5px;
  background: linear-gradient(131.67deg, #3f8cff 0%, #825af6 100%);
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: var(--white1);
}

.community-page__all-time-badge {
  background: linear-gradient(135deg, #825af6 0%, #ff98e5 100%);
}

.community-page__view-all-button-monthly,
.community-page__view-all-button-all-time {
  width: 140px;
  height: 40px;
  border: 3px solid var(--blue6);
  box-sizing: border-box;
  border-radius: 20px;
  background: var(--community-page-discord-link-bg);
  color: var(--community-page-font);
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  z-index: 5;
  min-height: 40px;
}

.community-page__view-all-button-monthly--desktop,
.community-page__view-all-button-all-time--desktop {
  margin-top: 30px;
}

.community-page__view-all-button-monthly--mobile,
.community-page__view-all-button-all-time--mobile {
  height: 30px;
  min-height: 30px;
}

.community-page__view-all-button-all-time {
  border: 3px solid var(--pink2);
}

.community-page__monthly-top-content,
.community-page__all-time-top-content {
  width: calc(100% - 40px);
  height: calc(100% - 110px);
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-top: 0;
  margin: auto;
  margin-top: 5px;
  z-index: 5;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  align-items: center;
}

.community-page__all-time-top-content::-webkit-scrollbar,
.community-page__monthly-top-content::-webkit-scrollbar {
  width: 10px;
  background: transparent;
}

.community-page__all-time-top-content::-webkit-scrollbar-track,
.community-page__monthly-top-content::-webkit-scrollbar-track {
  border-radius: 5px;
  background: var(--community-page-scroll-track);
  margin-bottom: 4px;
}

.community-page__all-time-top-content::-webkit-scrollbar-thumb,
.community-page__monthly-top-content::-webkit-scrollbar-thumb {
  background: var(--community-page-scroll-color);
  border-radius: 5px;
  background-clip: padding-box;
  border: 1px solid var(--community-page-scroll-color);
}

.community-page__top-item,
.community-page__all-time-top-item {
  display: flex;
  position: relative;
  width: 100%;
  min-height: 60px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.community-page__top-item-bg-image {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.community-page__top-item-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
  max-width: 70%;
  overflow: hidden;
}

.community-page__top-position {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  margin-right: 10px;
  padding: 5px 23px;
  background: var(--white1);
  border: 3px solid var(--grey2);
  box-sizing: border-box;
  border-radius: 0px 20px 20px 20px;
  color: var(--grey2);
}

.community-page__top-item-tier-badge,
.community-page__top-item-tier-badge svg:not(:root) {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.community-page__top-item-username {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--grey2);
}

.community-page__top-item-xp {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  white-space: nowrap;
  color: var(--grey2);
  margin-right: 25px;
}

@media (max-width: 1250px) {
  .community-page__grandmasters {
    gap: 50px;
  }
}

@media (max-width: 1200px) {
  .community-page__user-info-container {
    width: 180px;
  }

  .community-page__top-position {
    padding: 5px 15px;
  }

  .community-page__top-item-tier-badge {
    margin-right: 5px;
  }

  .community-page__top-item-username {
    font-size: 14px;
  }
}

@media (max-width: 1150px) {
  .community-page__top-100-header {
    padding-left: 15px;
  }

  .community-page__all-time-tier-badge,
  .community-page__monthly-tier-badge {
    margin-right: 10px;
  }

  .community-page__view-all-button-all-time,
  .community-page__view-all-button-monthly {
    width: 120px;
  }

  .community-page__top-item-left {
    margin-left: 10px;
  }
  .community-page__top-item-xp {
    margin-right: 10px;
  }
  .community-page__top-position {
    margin-right: 5px;
  }
}

@media (max-width: 1050px) {
  .community-page {
    padding-left: 25px;
    padding-right: 25px;
  }

  .community-page__bg-container {
    width: calc(100% - 50px);
  }

  .community-page__xp-tops {
    flex-direction: column;
  }

  .community-page__top-100-container-alltime {
    width: 100%;
  }

  .community-page__top-100-container-monthly {
    width: 100%;
  }

  .community-page__top-position {
    padding: 5px 23px;
  }

  .community-page__top-item-tier-badge {
    margin-right: 10px;
  }

  .community-page__top-item-username {
    font-size: 16px;
  }

  .community-page__top-item-left {
    margin-left: 20px;
  }
  .community-page__top-item-xp {
    margin-right: 25px;
  }
  .community-page__top-position {
    margin-right: 10px;
  }

  .community-page__all-time-badge {
    margin-left: 10px;
  }

  .community-page__monthly-badge {
    margin-left: 10px;
  }
}

@media (max-width: 1000px) {
  .community-page__grandmasters {
    gap: 30px;
  }

  .community-page__top-100-header-left {
    font-size: 16px;
  }
}

@media (max-width: 950px) {
  .community-page__grandmasters {
    gap: 15px;
  }

  .community-page__user-info-container {
    width: 160px;
  }

  .community-page__user-info-username {
    font-size: 12px;
  }

  .community-page__top-100-header-left {
    font-size: 12px;
  }

  .community-page__view-all-button-all-time,
  .community-page__view-all-button-monthly {
    width: 100px;
  }
}

@media (max-width: 860px) {
  .community-page__user-info-container {
    width: 150px;
  }

  .community-page__top3-container {
    height: 550px;
  }

  .community-page__monthly-tier-badge,
  .community-page__all-time-tier-badge {
    font-size: 10px;
  }

  .community-page__view-all-button-all-time,
  .community-page__view-all-button-monthly {
    font-size: 12px;
    height: 30px;
    width: 90px;
  }
}

.community-page__your-grandmasters {
  width: 530px;
}

@media (max-width: 820px) {
  .community-page {
    padding-left: 15px;
    padding-right: 15px;
  }

  .community-page__your-grandmasters {
    width: 450px;
  }

  .community-page__breaking-line-header {
    font-size: 10px;
    width: 160px;
    min-width: 160px;
  }
}

@media (max-width: 700px) {
  .community-page__faded-container {
    width: calc(100% - 22px);
    height: calc(100% - 22px);
  }

  .community-page__user-info-details {
    margin: auto;
  }
}

@media (max-width: 660px) {
  .community-page__user-info-container {
    width: 135px;
  }

  .community-page__your-grandmasters {
    width: 400px;
  }
}

@media (max-width: 615px) {
  .community-page__grandmasters {
    gap: 10px;
  }

  .community-page__user-info-container {
    padding-left: 10px;
    padding-right: 10px;
    width: 120px;
  }
}

@media (max-width: 576px) {
  .community-page {
    width: 100%;
  }

  .community-page__header {
    height: 100px;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }

  .community-page__discord-link-container {
    background: var(--purple8);
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: var(--white1);
  }

  .community-page__discord-icon {
    fill: var(--white1);
  }

  .community-page__bg-container {
    width: calc(100% - 28px);
    margin-top: 20px;
    background: none;
  }

  .community-page__top3-container {
    height: 650px;
    background: url(./images/grandmasters_bg_mobile.png);
    background-size: cover;
    background-repeat: no-repeat;
  }

  .community-page__title {
    display: flex;
  }

  .community-page__your-grandmasters {
    width: calc(100% - 32px);
    margin: auto;
  }

  .community-page__slider-container {
    margin-bottom: 50px;
  }

  .community-page__slider-container .slick-next {
    right: 8px;
    top: 405px;
    fill: var(--white1);
  }

  .community-page__slider-container .slick-prev {
    left: 8px;
    top: 400px;
    fill: var(--white1);
    transform: rotateY(180deg);
  }

  .community-page__slider-container .slick-dots {
    margin-bottom: 0px;
  }

  .community-page__grandmaster-animation {
    margin: auto;
  }

  .community-page__user-info-container {
    margin: auto;
    margin-top: 20px;
    margin-bottom: 0;
    width: 250px;
    padding: 20px;
    align-items: center;
  }

  .community-page__user-info-username {
    font-size: 20px;
  }

  .community-page__user-info-details {
    margin-top: 20px;
    background-color: var(--grey2);
  }

  .community-page__user-info-joined {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }

  .community-page__user-info-XP {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }

  .community-page__xp-tops {
    flex-direction: column;
  }

  .community-page__top-100-container-alltime,
  .community-page__top-100-container-monthly {
    width: 100%;
    background-size: contain;
  }

  .community-page__monthly-top-content,
  .community-page__all-time-top-content {
    width: 100%;
  }

  .community-page__all-time-top-content {
    margin-left: 0;
  }

  .community-page__all-time-badge {
    margin-right: 20px;
  }

  .community-page__top-100-header-left {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  .community-page__view-all-button-all-time {
    width: 120px;
    background: var(--pink2);
    color: var(--white1);
  }

  .community-page__view-all-button-monthly {
    width: 120px;
    background: var(--blue6);
    color: var(--white1);
  }
}
