.switch__switch {
  width: 58px;
  height: 32px;
  border-radius: 16px;
  background: var(--grey7) 0% 0% no-repeat padding-box;
  padding: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  user-select: none;
}

.switch__switch--on {
  background: var(--blue2) 0% 0% no-repeat padding-box;
}

.switch__toggle {
  background: #e4eff4 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000080;
  border-radius: 16px;
  width: 26px;
  height: 26px;
  left: 0;
  position: relative;
  transition: transform 0.2s ease-in-out;
  cursor: inherit;
}

.switch__toggle--on {
  transform: translateX(100%);
}

.switch__text {
  cursor: inherit;
  margin-left: 1rem;
}

.switch__text--on {
  margin: 0;
}
