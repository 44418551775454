.my-collectibles-header {
  width: calc(100% - 64px);
  height: 96px;
  display: flex;
  margin: 0px 32px 10px 32px;
  padding: 0 35px 0 32px;
  align-items: center;
  justify-content: space-between;
  background: var(--my-collectibles-section-header-background);
  box-shadow: 0px 3px 6px var(--black4);
  border-radius: 0px 32px 32px 32px;
  font-weight: 600;
  font-size: 22.4px;
}

.my-collectibles-header__button {
  width: 220px;
  height: 28px;
  color: var(--white1);
  background: var(--blue2);
  font-weight: 600;
  font-size: 16px;
  border-radius: 0px 32px 32px 32px;
  text-transform: uppercase;
  letter-spacing: 0px;
}

@media (max-width: 800px) {
  .my-collectibles-header {
    font-weight: 600;
    font-size: 16px;
  }

  .my-collectibles-header__button {
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 660px) {
  .my-collectibles-header__button {
    font-size: 12px;
  }
}

@media (max-width: 576px) {
  .my-collectibles-header {
    height: 64px;
    width: auto;
    border-radius: 0px 25.6px 25.6px 25.6px;
    margin: 0 19.2px 6.4px;
    padding: 0px 19.2px;
    font-size: 19.2px;
  }

  .my-collectibles-header__button {
    font-size: 12px;
  }
}
