.deposit-form {
  padding: 0 0 20px 0;
}

.deposit-form__amount {
  width: 420px;
  margin: 20px 0 0 30px;
}

.deposit-form__amountInputWrapper {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: fit-content;
  margin: 0 auto 20px auto;
}

.deposit-form__amount-input {
  display: block;
  margin: 20px 0;
  width: 100px;
  font-size: 24px;
  color: var(--deposit-withdraw-font-primary);
  border-radius: 5px;
  text-align: center;
}

.deposit-form__feeWarning {
  margin-bottom: 3px;
  color: var(--deposit-withdraw-font-primary);
  font-size: 12px;
  font-weight: 300;
  width: 430px;
  margin-top: 10px;
}

.deposit-form__amountButtons {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}

.deposit-form__amountButton {
  border-radius: 5px;
  background: white;
  padding: 5px;
  font-size: 20px;
  cursor: pointer;
}

.deposit-form__amount-button {
  width: 180px;
  height: 40px;
  margin: auto;
  margin-top: 37px;
  background: var(--purple8);
  color: var(--white1);
  border: 1px solid var(--white1);
  border-radius: 20px;
  padding: 12px 16px;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
}

.deposit-form__amount-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.deposit-form__spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%;
  position: relative;
}

.deposit-form__instructions {
  width: 80%;
  margin: 0 auto;
  padding: 8px;
  background-color: white;
  border-radius: 10px;
}

.deposit-form__inputWrapper {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.deposit-form__inputWrapper label {
  width: 30%;
  color: var(--deposit-withdraw-font-primary);
  font-size: 14px;
  font-weight: 300;
}

.deposit-form__eTransferInput {
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
  padding: 4px;
  padding-left: 15px;
  background: transparent;
  border: 1px solid var(--deposit-withdraw-font-primary);
  border-radius: 20px;
  color: var(--deposit-withdraw-font-primary);
  background: var(--deposit-withdraw-background-secondary);
}

.deposit-form__eTransferInput:disabled {
  background: var(--black7);
  opacity: 0.7;
}

.deposit-form__noAccess {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 350px;
  padding: 10px;
}

/* Premierpay receipt stylings */

.pp_message {
  padding: 4px;
  width: auto !important;
  max-width: 400px;
  text-align: center;
  color: #777;
}

.pp_header {
  font-size: 1.7em;
  font-weight: 300;
  letter-spacing: 0.3em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  display: block;
}

.pp_message_text {
  font-size: 0.83em;
  font-weight: bold;
  margin-block-start: 1.67em;
  margin-block-end: 1.67em;
}

.pp_parameters {
  padding-top: 20px;
  padding-bottom: 10px;
  background-color: rgb(247, 247, 247);
  color: #777;
  text-align: center;
  margin-top: -10px;
}

.pp_parameter {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.3em;
  text-align: center;
  display: block;
  color: #222;
  margin-bottom: 10px;
}

@media (max-width: 576px) {
  .deposit-form__amount {
    width: auto;
    margin: 0;
  }
}
