.user-tier-xp__wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}

.user-tier-xp__title-wrapper {
  display: flex;
  margin-top: 6px;
  align-items: baseline;
}

.user-tier-xp__level-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: var(--white1);
}

.user-tier-xp__tier-name {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: var(--grey12);
  padding-left: 15px;
}

.user-tier-xp__divider {
  height: 1px;
  width: 100%;
  background-color: var(--grey12);
  margin-top: 8px;
}
.user-tier-xp__progress-wrapper {
  margin-top: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
}

.user-tier-xp__progress-level-wrapper {
  height: 42.2px;
  width: 42.2px;
  border-radius: 50%;
  border-top-right-radius: 0;
  background-color: var(--levelColor);
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-tier-xp__progress-line-wrapper {
  width: 214.48px;
  height: 19.69px;
  border-radius: 14px;
  border: 1px solid var(--white1);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.user-tier-xp__progress-level {
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  color: var(--blue2);
  padding-top: 5px;
}

.user-tier-xp__progress-level-number {
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: var(--blue2);
}

.user-tier-xp__progress-value {
  position: absolute;
  top: 25px;
  font-size: 10px;
  line-height: 15px;
  color: var(--white1);
}

.user-tier-xp__pending-progress-value {
  color: var(--grey14);
}

#tier-progress {
  width: 200.65px;
  height: 7.03px;
  border: 1px solid var(--white1);
  border-radius: 20px;
}

#tier-progress[value]::-webkit-progress-bar {
  border-radius: 20px;
  background: transparent;
}

#tier-progress[value]::-webkit-progress-value {
  background: linear-gradient(
    90deg,
    var(--currentLevelColor) 0%,
    var(--nextLevelColor) 100%
  );
  border-radius: 20px;
}

@media (max-width: 800px) {
  .user-tier-xp__progress-wrapper {
    margin-top: 0;
    padding: 0 5px;
  }
  .user-tier-xp__progress-level-wrapper {
    width: 28.39px;
    height: 28.36px;
  }
  .user-tier-xp__progress-level {
    font-size: 10px;
    font-weight: 600;
  }
  .user-tier-xp__progress-level-number {
    font-size: 18px;
    font-weight: 600;
  }
  .user-tier-xp__progress-line-wrapper {
    width: 144.3px;
    height: 13.23px;
    margin: 0 9.46px;
  }
  .user-tier-xp__progress-value {
    font-size: 9px;
    font-weight: 400;
    top: 17px;
  }

  #tier-progress {
    width: 135px;
    height: 4.73px;
  }
}
