.market-place-asset-details-page {
  width: 100%;
  padding: 0 40px;
  margin-top: 40px;
}
.market-place-asset-details-page__card {
  padding: 56px;
  border-radius: 0px 32px 32px 32px;
}
.market-place-asset-details-page__description {
  line-height: 36px;
  opacity: 0.88;
}
.market-place-asset-details-page__card-content {
  padding-right: 60%;
}
.market-place-asset-details-page__short-info-block {
  display: flex;
  flex-direction: column;
  margin-top: 106px;
  gap: 24px;
}
.market-place-asset-details-page__info-row {
  display: flex;
  gap: 24px;
}
.market-place-asset-details-page__info-bubble {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  flex: 1;
  box-shadow: 0px 2px 16px #00000014;
  border-radius: 0px 32px 32px 32px;
}
.market-place-asset-details-page__info-bubble--bordered {
  border: 2px solid var(--white1);
}

.market-place-asset-details-page__footer {
  padding: 0 5px;
}

.market-place-asset-details-page__spinner-container {
  flex-grow: 1;
}

@media (max-width: 576px) {
  .market-place-asset-details-page {
    padding: 0 24px;
    margin-top: 20px;
  }
  .market-place-asset-details-page__card {
    padding: 24px;
  }
  .market-place-asset-details-page__description {
    padding-right: 50%;
  }
  .market-place-asset-details-page__card-content {
    padding-right: unset;
  }
  .market-place-asset-details-page__info-bubble {
    padding: 10px 0;
  }
  .market-place-asset-details-page__description {
    line-height: 18px;
  }
  .market-place-asset-details-page__short-info-block {
    margin-top: 71px;
    gap: 12px;
  }
  .market-place-asset-details-page__info-row {
    gap: 28px;
  }
}
