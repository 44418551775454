.deposit-withdraw-status-modal {
  max-width: 1045px;
  border-radius: 0 20px 20px 20px;
}

.deposit-withdraw-status-modal__content {
  padding: 0 !important;
  overflow: auto;
}

.deposit-withdraw-status-modal__close-icon {
  display: none;
}

.deposit-withdraw-status-modal__close-button {
  color: var(--white1);
  cursor: pointer;
}

.deposit-withdraw-status-modal__header {
  display: flex;
  gap: 13px;
  padding: 22.5px 41px;
  background: var(--purple8);
  align-items: center;
}

.deposit-withdraw-status-modal__success-check {
  width: 20px;
  height: 20px;
  stroke: var(--white1);
  fill: transparent;
  flex-shrink: 0;
}

.deposit-withdraw-status-modal__success-check svg:not(:root) {
  width: 20px;
  height: 20px;
}

.deposit-withdraw-status-modal__header-title {
  font-size: 23px;
  line-height: 1.5;
  flex-grow: 1;
}

.deposit-withdraw-status-modal__body {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 50px;
  padding: 60px 125px;
}

.deposit-withdraw-status-modal__body-text {
  display: flex;
  flex-direction: column;
}

.deposit-withdraw-status-modal__body-upper-text {
  margin-bottom: 40px;
  color: var(--deposit-withdraw-status-modal-color);
  font-size: 20px;
  line-height: 1.5;
  text-align: center;
  font-weight: 600;
}

.deposit-withdraw-status-modal__body-bottom-text {
  color: var(--deposit-withdraw-status-modal-bottom-text-color);
  font-size: 18px;
  line-height: 1.5;
  text-align: center;
  font-weight: 400;
}

.deposit-withdraw-status-modal__button {
  width: 182px;
  height: 40px;
  text-align: center;
  background: var(--deposit-withdraw-status-modal-background);
  border: 1px solid var(--deposit-withdraw-status-modal-background-border);
  color: var(--deposit-withdraw-status-modal-color);
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
}

.deposit-withdraw-status-modal__button:hover {
  background: var(--deposit-withdraw-status-modal-color);
  color: var(--deposit-withdraw-status-modal-background);
}

.deposit-withdraw-status-modal__buttons {
  display: flex;
  gap: 80px;
  margin: 0 auto;
}

.deposit-withdraw-status-modal__recurring-info-container {
  width: 540px;
  margin: auto;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 20px 40px;
  background: var(--deposit-withdraw-status-modal-recurring-info-bg);
}

.deposit-withdraw-status-modal__recurring-info-field {
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  margin-bottom: 10px;
}

.deposit-withdraw-status-modal__recurring-info-value {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  margin-bottom: 20px;
}

@media (max-width: 800px) {
  .deposit-withdraw-status-modal__recurring-info-container {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .deposit-withdraw-status-modal {
    max-width: 420px;
  }

  .deposit-withdraw-status-modal__close-button {
    display: none;
  }

  .deposit-withdraw-status-modal__body-upper-text {
    font-size: 18px;
    margin-bottom: 30px;
  }

  .deposit-withdraw-status-modal__body-bottom-text {
    font-size: 14px;
  }

  .deposit-withdraw-status-modal__body {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 40px;
    padding: 40px 20px;
  }

  .deposit-withdraw-status-modal__buttons {
    flex-direction: column;
    gap: 20px;
  }
}
