.select-asset-from-watch-list-modal {
  display: flex;
  flex-direction: column;
  width: 800px;
  height: 1000px;
  background: var(--select-asset-modal-background-color);
  border-radius: 0px 20px 20px 20px;
  max-height: 90%;
}

.select-asset-from-watch-list-modal__header-wrapper {
  background: linear-gradient(131.67deg, #3f8cff 0%, #825af6 100%);
  border-radius: 0px 20px 0px 0px;
  height: 60px;
  min-height: 60px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--white1);
  padding-left: 25px;
}

.select-asset-from-watch-list-modal__empty-assets {
  display: flex;
  width: 100%;
  justify-content: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin-top: 130px;
  color: var(--select-asset-modal-no-assets-color);
}

.select-asset-from-watch-list-modal__header-icon {
  height: 15px;
  width: 19px;
  margin-right: 10px;
}

.select-asset-from-watch-list-modal__header-back-wrapper {
  margin-right: 40px;
  display: flex;
  cursor: pointer;
}

.select-asset-from-watch-list-modal__header-back-icon {
  height: 24.5px;
  width: 13.7px;
}

.select-asset-from-watch-list-modal__close-icon {
  color: var(--white1);
  top: 17px;
  right: 12.5px;
}

.select-asset-from-watch-list-modal__content {
  overflow: auto;
  padding-top: 42px;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  height: 100%;
}

.select-asset-from-watch-list-modal__actions-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 42px;
  padding-right: 27px;
}

.select-asset-from-watch-list-modal__actions-wrapper
  .search-card-input__search-wrapper {
  margin-top: 0;
}

.select-asset-from-watch-list-modal__filter-wrapper {
  margin: 40px 50px 0px;
}

.select-asset-from-watch-list-modal__cards-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.select-asset-from-watch-list-modal__pagination-wrapper {
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 258px);
}

.select-asset-from-watch-list-modal__pagination-wrapper
  .select-asset-from-watch-list-modal-pagination__container {
  height: 65px;
  background: var(--black13);
}

.select-asset-from-watch-list-modal-pagination__content {
  height: calc(100% - 60px);
  overflow-y: auto;
  padding: 0 30px;
  margin-right: 30px;
  padding-bottom: 20px;
  margin-top: 20px;
}

.select-asset-from-watch-list-modal-pagination__content::-webkit-scrollbar {
  width: 18px;
  background: transparent;
}

.select-asset-from-watch-list-modal-pagination__content::-webkit-scrollbar-track {
  border-radius: 20px;
  background: var(--select-asset-modal-scroll-track-bg);
  margin-bottom: 4px;
}

.select-asset-from-watch-list-modal-pagination__content::-webkit-scrollbar-thumb {
  background: var(--select-asset-modal-scroll-thumb-bg);
  width: 10px;
  border-radius: 20px;
  background-clip: padding-box;
  border: 4px solid var(--select-asset-modal-scroll-track-bg);
}

.select-asset-from-watch-list-modal-pagination__container
  .select-asset-from-watch-list-modal-pagination__pages {
  height: 100%;
  margin-top: 10px;
}

.select-asset-from-watch-list-modal-pagination__pages
  .select-asset-from-watch-list-modal-pagination__page {
  color: var(--white1);
  border: none;
}

.select-asset-from-watch-list-modal-pagination__pages
  .select-asset-from-watch-list-modal-pagination__page:first-child,
.select-asset-from-watch-list-modal-pagination__pages
  .select-asset-from-watch-list-modal-pagination__page:last-child {
  border: none;
  border-right: none;
  border-left: none;
  border-radius: 0;
}

.select-asset-from-watch-list-modal-pagination__pages
  .select-asset-from-watch-list-modal-pagination__page--active {
  background: transparent;
  font-size: 12px;
  font-weight: 600;
  border: none;
}

.select-asset-from-watch-list-modal-pagination__text {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: var(--white1);
}

.select-asset-from-watch-list-modal-pagination__text--prev:first-child,
.select-asset-from-watch-list-modal-pagination__text--next:last-child {
  display: none;
}

.select-asset-from-watch-list-modal-pagination__next
  .select-asset-from-watch-list-modal-pagination__text--next {
  flex-direction: column-reverse;
  color: var(--white1);
}

.select-asset-from-watch-list-modal-pagination__previous
  .select-asset-from-watch-list-modal-pagination__text--prev {
  color: var(--white1);
}

.select-asset-from-watch-list-modal__loader-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 114px);
}

@media (max-width: 800px) {
  .select-asset-from-watch-list-modal {
    width: auto;
    height: 865px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .select-asset-from-watch-list-modal-pagination__content {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 30px;
  }
  .select-asset-from-watch-list-modal__filter-wrapper {
    margin: 18px 20px 0px;
  }
  .select-asset-from-watch-list-modal__content {
    padding-top: 15px;
  }
  .select-asset-from-watch-list-modal__actions-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }

  .select-asset-from-watch-list-modal__pagination-wrapper {
    height: calc(100% - 208px);
  }
}
