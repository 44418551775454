.hero-banner {
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  border-radius: 0 20px 20px 20px;
  overflow: hidden;
}

.hero {
  position: relative;
  flex: none;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 40px;
  z-index: 2;
  padding: 20px 10px;
  width: 100%;
}

.hero-banner__asset-main-data {
  display: flex;
  flex-direction: column-reverse;
  gap: 40px;
}

.hero-banner__asset-main-data-text {
  display: flex;
  flex-direction: row;
}

.hero-banner__asset-main-data .co-own-button {
  flex-shrink: 0;
  align-self: center;
}

.hero-banner__inner-co-own-wrapper {
  display: inline-block;
  flex-shrink: 0;
  justify-self: center;
}

.hero-banner__asset-number {
  color: var(--purple7);
  font-size: 24px;
  line-height: 1.5;
  font-weight: 600;
}

.hero-banner__divider {
  width: 5px;
  height: 30px;
  border-radius: 5px;
  background-color: var(--pink2);
  margin: 3px 20px 0;
  flex-shrink: 0;
}

.hero-banner__asset-name {
  color: var(--white1);
  font-size: 24px;
  line-height: 1.5;
  font-weight: 600;
}

.hero-desktop-video-container {
  display: none;
  flex: none;
  position: relative;
  z-index: 1;
  height: auto;
  width: 100%;
}

.hero-video-container {
  display: flex;
  justify-content: center;
  position: relative;
}

.hero-img {
  width: 100%;
  max-height: 430px;
  border-radius: 0 20px 20px 20px;
  min-width: 0;
  object-fit: contain;
}

.hero-desktop-video {
  width: 100%;
  height: 100%;
  border-radius: 0 20px 20px 20px;
  object-fit: cover;
  object-position: 75%;
}

.hero-mobile-video {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

.hero-numbers {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;
}

.hero-number-category {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid var(--white5);
  border-radius: 0px 20px 20px 20px;
  padding: 12px 0;
}

.hero-number-category-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.hero-number-category-title {
  font-size: 16px;
  line-height: 24px;
  color: var(--white1);
  text-align: center;
}

.hero-number-category-value {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  color: var(--white1);
}

.hero-number__spinner-container {
  --spinner__color-tertiary: transparent;
}

.hero-number-category.odd-total:first-child {
  grid-column-start: 1;
  grid-column-end: 3;
}

.hero-description-text {
  font-size: 16px;
  line-height: 24px;
  color: var(--white1);
}

.hero-image-wrapper {
  height: 200px;
  width: 150px;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.loader {
  font-size: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  animation: load8 1.1s infinite linear;
}

.hero-video__spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  width: auto;
  --spinner__color-primary: var(--white1);
  --spinner__color-tertiary: transparent;
}

.hero-video__spinner-circle,
.hero-video__spinner-circle:after {
  width: 100px;
  height: 100px;
  border-width: 11px;
}

@keyframes load8 {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@media (min-width: 1024px) {
  .hero {
    grid-template-areas:
      'title title'
      'description video'
      '. video'
      'numbers video';
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content auto 80px auto;
    column-gap: 80px;
    row-gap: 0;
    padding: 30px 40px 50px;
  }

  .hero--with-video {
    /* legacy workaround that we got from the iframe times. makes the info overlap
    the desktop video in a row-oriented flexbox. why not use absolute positioning?
    as I understand it, it's used to make sure that the parent will be as high as 
    the largest content (be it video or info) */
    margin-left: -100%;
    /* on desktop, when we have to display a video, it makes the spinner centered 
    correctly for MOST of the cards. a notable exception is the pokemon box */
    --workaround-offset: 70px;
  }

  .hero-banner__asset-main-data {
    grid-area: title;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 40px;
    gap: 20px;
  }

  .hero-banner__asset-main-data .co-own-button {
    align-self: initial;
    margin-top: -14px;
    margin-right: -24px;
  }

  .hero-banner__inner-co-own-wrapper {
    grid-row-start: 2;
    grid-column: 1 / span 2;
    margin-top: -14px;
    margin-right: -24px;
    justify-self: flex-end;
  }

  .hero--with-video .hero-video__spinner-container {
    left: calc(50% - calc(var(--workaround-offset) / 2));
  }

  .hero-description {
    grid-area: description;
    padding: 0;
  }

  .hero-video-container {
    grid-area: video;
    /* make it overlap the title */
    grid-row-start: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .hero-desktop-video-container {
    display: block;
  }

  .hero-mobile-video {
    display: none;
    padding-right: 64px;
    padding-left: 0;
  }

  .hero-numbers {
    grid-area: numbers;
    padding: 0;
  }

  .hero-number-category.odd-total:first-child .hero-number-category-value {
    font-size: 28px;
  }

  .hero-number-category-title {
    font-size: 20px;
    line-height: 28px;
  }

  .hero-number-category-value {
    font-size: 24px;
    line-height: 32px;
  }

  .hero-description-text {
    font-size: 20px;
    line-height: 28px;
  }
  .hero-img-margin {
    margin-top: 60px;
  }
}
