.upcoming-offerings {
  margin-top: 32px;
  background: var(--upcoming-offerings-background);
  border-radius: 0px 25.6px 25.6px 25.6px;
  color: var(--offerings-title);
}

.upcoming-offerings__noProductsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.upcoming-offerings__content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 32px;
  padding: 24px 32px;
}

.upcoming-offerings__no-products {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--grey13);
  position: relative;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  padding: 0 40px;
  text-align: center;
  margin-top: 50px;
  min-height: 560px;
}

.upcoming-offerings__no-products-button {
  border-radius: 20px;
  height: 40px;
  width: 200px;
  border: 3px solid var(--blue6);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: var(--market-place-no-products-color);
  margin-top: 58px;
  background-color: transparent;
  z-index: 2;
  cursor: pointer;
  transition: none;
}

.upcoming-offerings__no-products-button:hover {
  background-color: var(--blue6);
  color: var(--white1);
}

.upcoming-offerings__no-products-logo {
  position: absolute;
  height: 420px;
  width: 400px;
}

@media (max-width: 576px) {
  .upcoming-offerings__content {
    gap: 42.4px;
    padding: 19.2px;
  }
  .upcoming-offerings__no-products-button {
    background-color: var(--blue6);
    color: var(--white1);
  }
  .upcoming-offerings__no-products-logo {
    height: 315px;
    width: 300px;
  }
}
