.receive {
  padding: 3.7rem 3rem;
  font-size: 1.4rem;
  width: 450px;
}

.receive__source {
  display: flex;
  justify-content: space-between;
  align-content: center;
  border: 1px solid var(--component__border-color);
  box-sizing: border-box;
  margin-bottom: 4.3rem;
  border-radius: 4px;
  background-color: var(--secondary__bg-color);
}

.receive__source-item {
  font-size: 1.4rem;
  height: 3.6rem;
  line-height: 3.6rem;
  flex: 1 1;
  text-align: center;
  cursor: pointer;
  background: var(--component__bg-color);
  color: var(--component__color-secondary);
  border-radius: 2px;
  margin: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.receive__source-item-with-border {
  flex: 1 1;
  border-right: 1px solid var(--border__color);
}

.receive__source-item--active,
.receive__source-item:hover {
  background: var(--purple1);
  color: var(--white1);
}

/* MEDIA QUERIES */

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .receive__source-item {
    height: auto;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .receive {
    padding: 3.5rem 0;
    width: 100%;
  }

  .receive__source {
    margin: 0 2.4rem 4.3rem;
  }
}
