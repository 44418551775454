.co-own-button {
  width: 445px;
  height: 60px;
  border: 3px solid var(--blue6);
  box-shadow: 0px 5px 30px rgba(228, 221, 250, 0.4);
  border-radius: 20px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  z-index: 2;
}

.co-own-button__background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.2;
  object-fit: cover;
  width: 445px;
  height: 60px;
}

.co-own-button__avatar-with-text {
  display: flex;
  align-items: center;
  gap: 20px;
}

.co-own-button__avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  z-index: 2;
}

.co-own-button__text {
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: var(--white1);
  position: relative;
  z-index: 2;
}

.co-own-button__arrow {
  position: relative;
  z-index: 2;
  width: 24px;
  height: 24px;
  display: flex;
}

@media (max-width: 576px) {
  .co-own-button {
    width: 315px;
  }

  .co-own-button__text {
    font-size: 12px;
  }
}
