.wire-transfer {
  margin: 40px 30px 0 20px;
  color: var(--wire-transfer-text-color);
}

.wire-transfer__info-wrapper {
  margin-bottom: 35px;
}

.wire-transfer__bank-information {
  display: flex;
  gap: 40px;
  justify-content: space-between;
  margin-bottom: 40px;
}

.wire-transfer__bank-info {
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  width: 190px;
}

.wire-transfer__bank-info:last-child {
  width: 220px;
}

.wire-transfer__bank-info-header {
  font-weight: 600;
  font-size: inherit;
}

.wire-transfer__bank-info-content,
.wire-transfer__bank-info-content > div {
  font-size: inherit;
}

.wire-transfer__fee-info {
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  width: 100%;
}

.wire-transfer__deposit-ticket-wrapper {
  position: relative;
}

.wire-transfer__deposit-inputs {
  margin-bottom: 65px;
}

.wire-transfer__inputWrapper {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.wire-transfer__inputWrapper label {
  width: 30%;
  color: var(--deposit-withdraw-font-primary);
  font-size: 14px;
  font-weight: 300;
}

.wire-transfer__deposit-input {
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
  padding: 4px;
  padding-left: 15px;
  background: transparent;
  border: 1px solid var(--deposit-withdraw-font-primary);
  border-radius: 20px;
  color: var(--deposit-withdraw-font-primary);
  background: var(--deposit-withdraw-background-secondary);
}

.wire-transfer__inputWrapper:last-child .wire-transfer__deposit-input {
  margin-bottom: 0;
}

.wire-transfer__deposit-input:disabled {
  background: var(--wire-transfer-disabled-input-background);
}

.wire-transfer__submit-deposit-button {
  width: 180px;
  height: 40px;
  font-size: 14px;
  background: var(--purple8);
  border: 1px solid var(--white1);
  box-sizing: border-box;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  cursor: pointer;
  color: var(--white1);
}

.wire-transfer__submit-deposit-button-disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

@media (max-width: 576px) {
  .wire-transfer__bank-information {
    flex-direction: column;
    gap: 20px;
  }

  .wire-transfer {
    margin-left: 20px;
  }
}
