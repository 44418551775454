.buy-out-confirmation-form {
  max-width: 545px;
  padding-left: 27px;
  padding-top: 19px;
  padding-right: 27px;
  padding-bottom: 34px;
  display: flex;
  flex-direction: column;
  background-color: var(--buy-out-result-form-background);
  border-radius: 0px 20px 20px 20px;
}

.buy-out-confirmation-form__content {
  padding: 0;
  overflow: auto;
}

.buy-out-confirmation-form__loading-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-color: var(--modal__overlay-color);
}

.buy-out-confirmation-form__loading-overlay > .spinner__container {
  width: 30px;
  height: 30px;
  background-color: var(--buy-out-form-wrapper-background);
  border-radius: 9999px;
}

.buy-out-confirmation-form__header {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: var(--buy-out-result-form-text-color);
}

.buy-out-confirmation-form__description {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--buy-out-result-form-text-color);
  margin-top: 20px;
}

.buy-out-confirmation-form__buttons {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-top: 30px;
  width: 100%;
}

.buy-out-confirmation-form__go-back {
  border: 3px solid var(--buy-out-go-back-border-color);
  height: 40px;
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: var(--buy-out-go-back-color);
  background: var(--buy-out-go-back-background);
}

.buy-out-confirmation-form__submit {
  height: 40px;
  width: 140px;
  border-radius: 20px;
  position: relative;
  box-sizing: border-box;
  background: linear-gradient(
        0deg,
        var(--buy-out-submit-background) 0%,
        var(--buy-out-submit-background) 100%
      )
      padding-box,
    linear-gradient(131.67deg, var(--blue2) 0%, var(--purple1) 100%) border-box;
  background-clip: content-box, border-box;
  padding: 3px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  color: var(--font__color-primary);
}
