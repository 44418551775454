.verify-email-page__container {
  margin: auto;
  width: 57.6rem;
  min-height: 17.48rem;
  padding: 3.68rem 3.68rem 3.2rem 3.68rem;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0.64rem 1.92rem #00000066;
  border-radius: 0px 2.88rem 2.88rem 2.88rem;
  background: #fafcff;
  align-items: center;
}

.verify-email-page__body {
  margin: 3.6rem 0px;
}

.verify-email-page__body-text {
  text-align: center;
}

.verify-email-page__spinner__spinner-container .spinner__text {
  color: #373946;
  font-size: 1.36rem;
  text-align: center;
  font-weight: 600;
}

.verify-email-page__link {
  min-width: 15.2rem;
  height: 3.84rem;
  border-radius: 0px 2.24rem 2.24rem 2.24rem;
  background: transparent linear-gradient(180deg, #079be1 0%, #a03eea 100%) 0%
    0% no-repeat padding-box;
  line-height: 2.24rem;
  box-shadow: 0px 0.56rem 2.4rem #00000012;
  margin-top: 0.8rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
}

.verify-email-page__link:hover,
.verify-email-page__link:focus {
  background: linear-gradient(
    rgba(133, 79, 232, 255) 50%,
    rgba(141, 74, 233, 255) 100%
  );
}

.verify-email-page__error-btn {
  background: #ff0000;
  min-width: 24rem;
  height: 3.84rem;
  border-radius: 0px 2.24rem 2.24rem 2.24rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.8rem;
}

.verify-email-page__error-btn:hover {
  background: linear-gradient(
    rgba(133, 79, 232, 255) 50%,
    rgba(141, 74, 233, 255) 100%
  );
}

@media (max-width: 576px) {
  .verify-email-page__container {
    width: 100%;
    min-height: auto;
    border-radius: 0px;
    padding: 2.08rem 2.08rem 1.6rem 2.08rem;
  }
}
