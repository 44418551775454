.pro-exchange-layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--pro-exchange-backgrougd);
  padding: 0 40px;
}

.pro-exchange-layout__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.pro-exchange-layout__footer {
  padding: 0 5px 0 5px;
}

.pro-exchange-layout__title {
  margin: 46px 0 5px 0;
  display: flex;
  align-items: center;
}

.pro-exchange-layout__arrow-icon {
  color: var(--black1);
  margin: 0 10px 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pro-exchange-layout .ap-tab__menu-item {
  height: 40px;
  background: var(--pro-exchange-chart-tab-background);
  color: var(--pro-exchange-chart-tab-color);
  opacity: 0.5;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}

.pro-exchange-layout .ap-tab__menu-item:hover {
  color: var(--pro-exchange-chart-tab-active-color);
  opacity: 1;
}

.pro-exchange-layout .ap-tab__menu-item--active,
.ap-tab__menu-item--active:hover {
  background: var(--pro-exchange-chart-tab-active-background);
  color: var(--pro-exchange-chart-tab-active-color);
  opacity: 1;
}

.pro-exchange-layout .ap-tab__menu-item--active:before,
.pro-exchange-layout .ap-tab__menu-item--active:hover:before,
.pro-exchange-layout .ap-tab__menu-item:hover:before {
  border-bottom: 2px solid var(--pink2);
}

.trading-layout__container {
  border-radius: 0 20px 20px 20px;
  background-color: var(--trading-layout-background);
  border: 1px solid var(--trading-layout-border);
  overflow: hidden;
  border: none;
}

.order-entry__buy-sell-radio-tab-wrapper {
  padding: 0;
}

.ap-segmented-button,
.ap-segmented-button__label {
  border-radius: 32px;
  color: var(--order-entry-labels);
}

.order-entry__group-label,
.order-entry__select-label,
.order-entry__label {
  color: var(--order-entry-labels);
}

.order-entry__select {
  border-radius: 12px;
  border: 1px solid var(--order-entry-select-border-color);
  background: var(--order-entry-select-background);
}

.order-entry__lwt-label {
  font-size: 12px;
  color: var(--order-entry-labels);
}

.order-entry__lwt-text {
  font-size: 12px;
  font-weight: 600;
  color: var(--order-entry-labels);
}

.order-entry__btn {
  font: normal normal 600 16px/25px Poppins;
  color: var(--white1);
  border-radius: 0px 28px 28px 28px;
  background: var(--purple1);
  text-transform: uppercase;
}

.order-entry__with-label,
.order-entry__label-in-input {
  border-radius: 12px;
  border: 1px solid var(--grey6);
}

.order-entry__label-in-input {
  background: var(--order-entry-label-in-input-background);
  color: var(--order-entry-label-in-input-color);
  width: 36px;
}

.order-entry__buy-sell-wrapper .btn-group {
  height: 40px;
}

.order-entry__buy-sell-wrapper .ap-radio-tab__label,
.trading-layout__open-modal {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  text-transform: uppercase;
  background: var(--buy-sell-tab-background);
  color: var(--font__color-primary);
  border: 0.5px solid var(--font__color-primary);
  transition: none;
}

.order-entry__buy-sell-wrapper .ap-radio-tab__label--additive,
.trading-layout__open-modal--additive {
  border-right: none;
}

.order-entry__buy-sell-wrapper .ap-radio-tab__label--checked-additive,
.order-entry__buy-sell-wrapper .ap-radio-tab__label--additive:hover,
.trading-layout__open-modal--additive {
  background: var(--blue2);
  color: var(--white1);
}

.order-entry__buy-sell-wrapper .ap-radio-tab__label--checked-subtractive,
.order-entry__buy-sell-wrapper .ap-radio-tab__label--subtractive:hover,
.trading-layout__open-modal--subtractive {
  background: var(--purple8);
  color: var(--white1);
}

.lightweight-interval-button {
  color: var(--lightweight-interval-button-color);
}

.lightweight-interval-button.active {
  color: var(--lightweight-interval-button-active-color);
}

.bulk-cancel-buttons__btn {
  border: 0.5px solid var(--bulk-cancel-buttons-border) !important;
  border-radius: 10px;
  background-color: var(--bulk-cancel-buttons) !important;
  color: var(--bulk-cancel-buttons-color) !important;
}

.bulk-cancel-buttons__image {
  color: var(--bulk-cancel-buttons);
  background-color: var(--bulk-cancel-buttons-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
}

.instrument-table__title {
  font-weight: 600;
}

.flex-table__title {
  font-weight: 600;
  height: 40px;
}

.orderbook__image {
  color: var(--bulk-cancel-buttons-color);
  display: flex;
  align-items: center;
}

.instrument-table__row {
  border-right: 0;
  border-left: 1px solid var(--instrument-table__row-separator-color);
}

.trading-layout__container {
  display: flex;
  flex-direction: column;
}

.trading-layout__chart-header {
  height: var(--component__header-height);
  line-height: var(--component__header-height);
  background: var(--component__header-bg-color);
  padding: 0 1.75rem;
  border-bottom: 1px solid var(--component__header-separator-color);
}

.trading-layout__chart-container {
  grid-area: chart;
}

.trading-layout__tabs-container--orderbook-container {
  grid-area: orderbook;
}

.trading-layout__activity-container {
  grid-area: activity;
  border-bottom-left-radius: 20px;
  overflow: auto;
}

.trading-layout__balance-container {
  margin: 40px 20px 0px;
}

.trading-layout__balance-container .trade-component {
  background: transparent;
  margin-top: 10px;
}

.trading-layout__show-balance {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  background: var(--quantity-picker-input-background-color);
  padding: 0px 15px;
  height: 25px;
  font-size: 10px;
  border: 1px solid var(--font__color-primary);
  color: var(--font__color-primary);
}

.trading-layout__show-balance .arrow-path {
  fill: var(--font__color-primary);
}

.order-entry__container {
  grid-area: orderentry;
  margin-left: 20px;
  border-bottom-right-radius: 20px;
}

.order-entry__container--additive {
  background: var(--order-entry-background-additive);
}

.order-entry__container--subtractive {
  background: var(--order-entry-background-subtractive);
}

.trading-layout__info-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--trading-layout__info-bar-bg-color);
  height: var(--trading-layout__info-bar-height);
}

.trading-layout__body {
  border: none;

  display: grid;
  grid-template-columns: 50% 25% 25%;
  grid-template-rows: auto 27rem;
  grid-template-areas:
    'chart orderbook orderentry'
    'activity activity orderentry';
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}

.order-entry__container > .order-entry-form {
  padding: 22px 10px 20px 10px;
}

.trading-layout__buy-sell-buttons-container {
  height: 40px;
  display: flex;
}

.trading-layout__buy-sell-buttons-container > * {
  flex-grow: 1;
}

.pro-exchange-layout__breadcrumbs {
  margin-left: 40px;
}

.primary-market-blocker {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 0px 0px 32px 32px;
  z-index: 1000;
  display: flex;
  justify-content: center;
  padding-top: 97px;
}

.primary-market-blocker__container {
  width: 549px;
  height: 205px;
  border-radius: 0px 20px 0px 0px;
  background: var(--primary-market-blocker-container-background);
  border-radius: 20px;
}

.primary-market-blocker__header {
  height: 40px;
  width: 100%;
  background: var(--purple8);
  color: var(--white1);
  display: flex;
  align-items: center;
  padding-left: 23px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border-radius: 0 20px 0 0;
}

.primary-market-blocker__header span {
  padding-left: 4px;
}

.primary-market-blocker__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding-top: 24px;
}

.primary-market-blocker__content-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--primary-market-blocker-text-color);
}

.primary-market-blocker__content-link {
  display: flex;
  align-items: center;
  text-align: center;
}

.primary-market-blocker__link {
  width: 220px;
  height: 40px;
  background: var(--primary-market-blocker-link-background-color);
  border: 1px solid var(--primary-market-blocker-text-color);
  box-sizing: border-box;
  border-radius: 20px;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: var(--primary-market-blocker-text-color);
  padding-top: 11px;
}

.primary-market-blocker__link:hover {
  color: var(--primary-market-blocker-text-color) !important;
}

@media only screen and (max-width: 1208px) {
  .trading-layout__body {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    padding: 0;
  }
}

@media only screen and (max-width: 1208px) {
  .trading-layout__container {
    border-radius: 20px;
    background: transparent;
  }

  .trading-layout__tab {
    border: none !important
  }
}

@media only screen and (min-width: 1521px) {
  .order-history-table__body {
    min-width: unset;
  }
}

.trading-layout__tabs-container {
  border-left: var(--trading-layout__border);
}

.trading-layout__chart-tabs-container {
  height: 100%;
}

@media only screen and (max-width: 1208px) {
  .trading-layout__chart-tabs-container {
    height: calc(100% - 270px);
    height: fit-content;
  }

  .order-entry__container {
    border-left: none;
  }
  .trading-layout__body {
    gap: 20px;
  }
}

.trading-layout__tab,
.trading-layout__tab-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.trading-layout__tab {
  border: 1px solid var(--pro-exchange-chart-container-border-color);
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 1208px) {
  .trading-layout__menu {
    min-height: 3.6rem;
  }
}

@media only screen and (max-width: 468px) {
  .trading-layout__row {
    flex-direction: column;
  }
}

@media only screen and (max-width: 969px) {
  .instrument-selector-popover__popover {
    border-right: none;
  }

  .instrument-table__row {
    border-left: none;
  }

  .trading-layout__container {
    margin-bottom: 0px;
  }
}

@media (max-width: 576px) {
  .pro-exchange-layout {
    padding: 0 14px;
  }

  .primary-market-blocker__container {
    width: 340px;
    height: 215px;
  }

  .primary-market-blocker__header {
    padding-left: 19px;
  }

  .primary-market-blocker__content-text {
    width: 283px;
  }
}
