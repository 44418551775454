.share-profile-modal__overlay {
  justify-content: unset;
  align-items: unset;
  padding: 19.2px;
  overflow-y: auto;
}

.share-profile-modal {
  background: var(--buy-sell-modal-background);
  --preview-height: 257px;
  width: 380px;
  margin: auto;
  max-height: unset;
}

.share-profile-modal__content {
  overflow: hidden;
  padding: 26px 26px 0 26px;
}

/* default modal close icon, we don't need it because we use a custom component */
.share-profile-modal__close-icon {
  display: none;
}

.share-profile-modal__title {
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  background: var(--purple7);
  color: var(--white1);
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}

.share-profile-modal__subtitle {
  text-align: center;
  font-size: 14px;
  margin-bottom: 40px;
}

.share-profile-modal__preview-container {
  --scale-factor: 0.315;
  width: 100%;
  height: var(--preview-height);
  overflow: hidden;
  border-radius: 20px;
  margin-bottom: 13px;
  position: relative;
}

.share-profile-modal__preview-container>.public-portfolio {
  transform: scale(var(--scale-factor));
  transform-origin: 0 0;
  width: calc(100% / var(--scale-factor));
  height: calc(var(--preview-height) / var(--scale-factor));
  pointer-events: none;
  overflow: hidden;
}

.share-profile-modal__preview-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  pointer-events: none;
  transition: var(--transition__settings), opacity var(--transition__time) ease;
}

.share-profile-modal__preview-container:hover>.share-profile-modal__preview-overlay {
  opacity: 1;
  pointer-events: all;
}

.share-profile-modal__url-container {
  height: 40px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  padding-left: 23px;
  padding-right: 3px;
  border-radius: 20px;
  background-color: var(--share-profile-modal-url-background);
  overflow: hidden;
}

.share-profile-modal__url-field {
  font-weight: 500;
  overflow: hidden;
  /* workaround to make Textfit work in flexbox */
  width: calc(100% - 48px);
}

.share-profile-modal__url-field>div>div {
  font-size: inherit;
}

.share-profile-modal__copy-button {
  flex-shrink: 0;
}

.share-profile-modal__copy-icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.share-profile-modal__copy-icon svg>rect {
  fill: var(--share-profile-modal-copy-icon-fill-color);
  stroke: var(--share-profile-modal-copy-icon-stroke-color);
}

.share-profile-modal__copy-status {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--purple7);
  border-radius: 20px;
  color: var(--white1);
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 500ms ease;
}

.share-profile-modal__copy-status--success {
  opacity: 1;
  pointer-events: all;
}

.share-profile-modal__btn {
  min-width: 180px;
  min-height: 40px;
  border: 3px solid;
  border-radius: 20px;
  padding: 0 22px;
  font-size: 14px;
  font-weight: 600;
  box-shadow: none;
  line-height: 1;
  display: block;
  flex-direction: unset;
  text-transform: capitalize;
  transition: none;
  background: var(--buttons-buy-background);
  color: var(--buttons-buy-text-color);
  border-color: var(--buttons-buy-hover-background);
}

a.share-profile-modal__btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.share-profile-modal__btn:hover,
.share-profile-modal__btn:focus {
  background: var(--buttons-buy-hover-background);
  color: var(--buttons-buy-hover-text-color);
}

.share-profile-modal__btn--open {
  background: var(--buttons-sell-background);
  color: var(--buttons-sell-text-color);
  border-color: var(--buttons-sell-hover-background);
}

.share-profile-modal__btn--open:hover,
.share-profile-modal__btn--open:focus {
  background: var(--buttons-sell-hover-background);
  color: var(--buttons-sell-hover-text-color);
}

.share-profile-modal__footer {
  border: initial;
  gap: 40px;
  padding: 40px 20px;
  justify-content: center;
  margin: 0;
}

@media (max-width: 348px) {
  .share-profile-modal {
    --preview-height: 192px;
  }
}
