.instrument-card {
  height: 100%;
  width: 100%;
  border-radius: 20px;
  display: flex;
  z-index: 2;
  cursor: pointer;
}

.instrument-card__border-wrapper {
  height: 200px;
  width: 100%;
  border-radius: 20px;
  padding: 5px;
  cursor: pointer;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
  position: relative;
}

.instrument-card__instrument-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 157px;
  min-width: 157px;
  margin-left: 18px;
}

.instrument-card__instrument-image {
  height: 90%;
  max-width: 157px;
  width: auto;
}

.instrument-card__content {
  margin-left: 48px;
  display: flex;
  flex-direction: column;
  padding-top: 27px;
  padding-bottom: 15px;
  justify-content: space-between;
  padding-right: 23px;
}

.instrument-card__title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--white1);
  padding-right: 32px;
}

.instrument-card__number {
  padding-right: 15px;
}

.instrument-card__rows {
  display: flex;
  gap: 8px;
}

.instrument-card__row {
  height: 59px;
  width: 105px;
  border: 0.5px solid var(--white1);
  border-radius: 0px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.instrument-card__row-name {
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: var(--white1);
}

.instrument-card__row-value {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--white1);
}

.instrument-card__mobile-content {
  display: flex;
  padding-top: 6.5px;
  padding-left: 16px;
  padding-right: 18px;
  padding-bottom: 18px;
  width: 100%;
  overflow: hidden;
}

.instrument-card__mobile-title-wrapper {
  margin-left: 7px;
  margin-bottom: 10px;
  max-width: 150px;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  color: var(--white1);
}

.instrument-card__mobile-left-content {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.instrument-card__mobile-right-content {
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-top: 49.5px;
  height: 100%;
  align-items: center;
}

.instrument-card__mobile-image-wrapper {
  max-width: 108px;
  width: 108px;
  height: 184px;
}

.instrument-card__mobile-image {
  width: 100%;
  height: 100%;
}

.instrument-card__remove-btn {
  width: 21px;
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  top: 22px;
  right: 32px;
  border: 1px solid var(--red2);
  color: var(--white1);
  transition: none;
}

.instrument-card__remove-btn > span,
.instrument-card__remove-btn svg:not(:root) {
  width: 11px;
  height: 11px;
}

.instrument-card__remove-btn:hover {
  background: var(--red2);
}

@media (max-width: 800px) {
  .instrument-card__border-wrapper {
    height: 269px;
  }

  .instrument-card__rows {
    flex-direction: column;
  }

  .instrument-card__row {
    width: 140px;
    height: 40px;
    gap: 5px;
  }

  .instrument-card__row-value {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
  }

  .instrument-card__row-name {
    font-weight: 400;
    font-size: 8px;
    line-height: 8px;
  }

  .instrument-card__number {
    padding-right: 5px;
  }

  .instrument-card__remove-btn {
    top: 14px;
    right: 25px;
    background: var(--red2);
  }
}
