.page-header__container {
  height: 126.4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* to overflow navmenu */
  z-index: 2000;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background: var(--liquid-background-primary);
  padding-bottom: 30.4px;
}

.page-header__container.page-header__container--onboarding {
  position: static;
  padding-bottom: 0;
}

.page-header__left {
  height: 44.8px;
  display: flex;
  align-items: center;
  margin-top: 51.2px;
}

.page-header__wordmark-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  position: relative;
}

.page-header__wordmark {
  z-index: 5;
  width: 366px;
  height: 40px;
  transition: all 0.25s;
}

.page-header__wordmark-tablets {
  display: none;
}

.page-header__wordmark-hidden {
  z-index: 5;
  background-image: url(./images/WORDMARK.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.25s;
  width: 0;
  height: 0;
}

.page-header__wordmark-hidden-light {
  z-index: 5;
  background-image: url(./images/WORDMARK-light.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.25s;
  width: 0;
  height: 0;
}

.page-header__right {
  height: 51.2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 48px;
  margin-right: -20px;
  margin-left: 80px;
}

.page-header__referral-button {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 13px;
  padding: 7px 17px;
  border: 3px solid var(--white1);
  border-radius: 999px;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: var(--white1);
  background: linear-gradient(90deg, var(--purple8), var(--green3));
  box-shadow: var(--nav-bar-referral-btn-shadow);
  margin-right: 30px;
}

.page-header__consign-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 13px;
  width: 210px;
  min-width: 200px;
  height: 44px;
  border-radius: 20px;
  border: solid 3px transparent;
  background-image: linear-gradient(
      var(--header-consign-background) 0%,
      var(--header-consign-background) 100%
    ),
    linear-gradient(90.57deg, #7c5ced 0%, #53d660 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  margin-right: 30px;
  cursor: pointer;
  color: var(--header-consign-text-color);
}

.page-header__consign-icon {
  fill: var(--header-consign-text-color);
}

.page-header__consign-button:hover {
  background-image: linear-gradient(90.57deg, #7c5ced 0%, #53d660 100%),
    linear-gradient(90.57deg, #7c5ced 0%, #53d660 100%);
  color: var(--white1);
}

.page-header__consign-button:hover .page-header__consign-icon {
  fill: var(--white1);
}

.page-header__referral-icon,
.page-header__referral-icon svg:not(:root) {
  width: 24px;
  height: 24px;
}

.page-header-mobile .page-header__referral-button {
  align-self: center;
  margin-bottom: 20px;
}

.page-header__notification-container {
  position: relative;
  display: flex;
  align-items: flex-end;
}

.page-header__notification-image {
  position: relative;
  width: 40px;
  height: 40px;
  background-image: url(./images/Notification.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 40px;
  z-index: 1000;
  cursor: pointer;
}

.page-header__notification-body {
  position: absolute;
  width: 300px;
  top: 34px;
  left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #714cdd;
  border-radius: 0 32px 32px 32px;
  padding: 15px;
  z-index: 10;
}

.page-header__notification-close {
  position: absolute;
  right: 0;
  top: 0;
  background: var(--purple1);
  width: 45px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 32px 0 32px;
  cursor: pointer;
}

.page-header__notification-close-icon {
  width: 8px;
  height: 8px;
}

.page-header__notification-text {
  margin-top: 20px;
  font-size: 14px;
  font-weight: 400;
  color: var(--white1);
}

.page-header__notification-button {
  width: 180px;
  height: 28px;
  background: var(--blue2);
  color: var(--white1);
  font-size: 16px;
  font-weight: 500;
  margin: 20px 0 10px 10px;
  border-radius: 0 32px 32px 32px;
}

.page-header__search-container {
  display: flex;
  position: relative;
  min-width: 80px;
  max-width: 240px;
}

.page-header__search__container {
  height: 38px;
  position: relative;
  border: none;
  padding-left: 0;
  background-color: transparent;
}

.page-header__search__input {
  width: 100%;
  height: 38px;
  color: var(--instrument-selector-search-input-color);
  background-color: var(--instrument-selector-search-input-background);
  padding-left: 60px;
  padding-right: 14.4px;
  border-radius: 0px 19.2px 19.2px 19.2px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  border: 0.25px solid var(--instrument-selector-search-input-border-color);
}

.page-header__search__input::placeholder {
  color: var(--instrument-selector-search-input-color);
}

.page-header__search-icon-container,
.page-header__icon-container {
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--blue6);
  border-radius: 0px 20px 20px 20px;
}

.page-header__search-icon-container {
  position: absolute;
  left: 0;
  top: -3.5px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.page-header__search__list {
  position: relative;
  z-index: 20;
  margin-top: 2px;
  background: var(--white1);
  border-radius: 19.2px;
  box-shadow: 0px 2px 2px var(--black4);
}

.page-header__search__list-label {
  font-size: 11.2px;
}

.page-header__icon-container {
  margin-left: 21.6px;
  cursor: pointer;
}

.page-header__search-icon {
  height: 22.5px;
  width: 22.5px;
}

.page-header__mail-icon {
  width: 14.4px;
}

.page-header-mobile__nav-items {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.page-header-mobile__theme-button {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.page-header-mobile__theme-icon {
  cursor: pointer;
  height: 80px;
  width: 80px;
}

.page-header-mobile__sign-out-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 200px;
  min-height: 50px;
  border-radius: 20px;
  border: 0.25px solid var(--nav-bar-sign-out-background);
  background: transparent;
  color: var(--nav-bar-sign-out-background);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  align-self: center;
  margin-top: 20px;
  margin-bottom: 40px;
}

.page-header-mobile__theme-button .theme-icon-background {
  fill: var(--nav-bar-theme-background);
}

@media (max-width: 1350px) {
  .page-header__wordmark {
    width: 366px;
    background-repeat: no-repeat;
    background-position: center;
  }
}

@media (max-width: 1250px) {
  .page-header__wordmark {
    display: none;
  }

  .page-header__wordmark-tablets {
    display: block;
    height: 98.56px;
    width: 107.52px;
    background-image: url(../../images/site-logo.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
  }
}

@media (max-width: 1015px) {
  .page-header__container {
    height: auto;
  }

  .page-header__search-container {
    width: 200px;
    max-width: 200px;
  }

  .page-header__right {
    margin: 0;
    margin-left: 40px;
    margin-top: 48px;
  }

  .page-header__right > .page-header__referral-button {
  }
}

@media (max-width: 800px) {
  .page-header__right {
    margin: 0;
    margin-right: 19.2px;
    height: auto;
  }
  .page-header__consign-button {
    margin-right: 0;
    align-self: center;
    margin-bottom: 20px;
  }
}

@media (max-width: 680px) {
  .page-header__right {
    width: 100%;
    flex-direction: column-reverse;
    align-items: flex-end;
    margin-right: 19.2px;
    margin-left: 19.2px;
  }

  .page-header__search-container {
    width: 100%;
    max-width: 384px;
  }

  .page-header__search__container {
    margin-top: 16px;
  }

  .page-header__search__input {
    width: 100%;
  }

  .page-header__search-icon-container {
    margin-top: 16px;
  }
}

@media (max-width: 600px) {
  .page-header__right {
    align-items: flex-start;
  }

  .page-header__search-container {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .page-header__notification-body {
    left: -150px;
    border-radius: 32px;
    top: 35px;
    z-index: 20;
  }
}

.page-header-mobile {
  --top-bar-height: 76.8px;
  height: var(--top-bar-height);
}

.page-header-mobile__top-bar {
  width: 100%;
  height: var(--top-bar-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--header-mobile-background);
  box-shadow: 0px 2px 8px var(--black4);
  margin-bottom: 4px;
  position: fixed;
  z-index: 10000;
}

.page-header-mobile__left {
  display: flex;
  align-items: center;
}

.page-header-mobile__nav-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 22px;
  margin-left: 24px;
}

.page-header-mobile__burger-row {
  width: 30px;
  height: 2px;
  background: var(--header-burger-menu);
  border-radius: 5px;
  cursor: pointer;
}

.page-header-mobile__logo-wrapper {
}

.page-header-mobile__logo {
  height: 52px;
  width: 156px;
  display: flex;
}

.page-header-mobile__header-right-part {
  display: flex;
  align-items: center;
  gap: 30px;
}

.page-header-mobile__wordmark-link-wrapper {
  align-self: center;
}

.page-header-mobile__wordmark-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}

.page-header-mobile__wordmark {
  z-index: 5;
  width: 156px;
  height: 22px;
  transition: all 0.25s;
}

.page-header-mobile__right {
  display: flex;
  align-items: center;
}

.page-header-mobile__search-icon-container {
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--blue6);
  border-radius: 0px 16px 16px 16px;
  margin-right: 28px;
}

.page-header-mobile__search-icon {
  height: 12.5px;
  width: 12.42px;
}

.page-header-mobile__username {
  margin-right: 19.2px;
  margin-left: 19.2px;
  cursor: pointer;
  color: var(--liquid-primary-font-color);
}

.page-header-mobile__navbar-root {
  /* for mobile browsers with sliding bottom bar */
  --bottom-safe-margin: -500px;
  position: fixed;
  z-index: 1000;
  /* make it slightly overlap with top bar to avoid a gap */
  top: calc(var(--top-bar-height) - 1px);
  bottom: var(--bottom-safe-margin);
  left: 0;
  right: 0;
}

.page-header-mobile__navbar {
  background: var(--header-mobile-background);
  height: 100%;
  width: 100%;
}

.page-header-mobile__navbar-content {
  height: calc(100% + var(--bottom-safe-margin));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow-y: auto;
}

.page-header-mobile__expanded-top-bar {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(131.67deg, var(--blue2) 0%, var(--purple1) 100%);
  padding: 0 26px;
}

.page-header-mobile__tier-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-header-mobile__tier-img {
  height: 36px;
  width: 36px;
  z-index: 1;
  fill: black;
}

.page-header-mobile__name {
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  color: var(--white1);
}

.page-header-mobile--mobileClose {
  width: 23px;
  height: 23px;
}

.page-header-mobile__tier-exp-wrapper {
}

.page-header-mobile__close-wrapper {
}

.page-header-mobile__close-icon-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 33.6px;
  margin-bottom: 16px;
}

.page-header-mobile__close-icon {
  margin-right: 25.6px;
}

.page-header-mobile__nav-item {
  display: flex;
  width: 300px;
  height: 50px;
  align-items: center;
  margin-bottom: 6.4px;
  border-radius: 0px 25px 25px 25px;
  justify-content: center;
}

/* for cases without the referral button */
.page-header-mobile__nav-item:first-child {
  margin-top: 30px;
}

.page-header-mobile__nav-item--active {
  background: var(--nav-bar-item-active-background);
}

.page-header-mobile__nav-icon {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-header-mobile__nav-item-text {
  margin-left: 12.8px;
  color: var(--liquid-primary-font-color);
  width: 130px;
}

.page-header-mobile__nav-icon--community svg:not(:root) {
  width: 32px;
  height: 32px;
}

.page-header-mobile__nav-icon--vote svg:not(:root) {
  width: 20px;
  height: 20px;
}

.page-header-mobile__degree {
  position: absolute;
  top: -6px;
  left: 0;
  display: flex;
  width: 46px;
  height: 42px;
  background: var(--purple8);
  border-radius: 0 50% 50% 50%;
  padding-left: 3px;
  padding-top: 1px;
  font-size: 5px;
  z-index: 0;
}

.page-header-mobile__degree-ending {
  font-size: 4px;
  margin-right: 2px;
}

@media (max-width: 433px) {
  .page-header__notification-body {
    left: -90px;
  }
}
