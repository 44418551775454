.wallets-page__reminder-wrapper {
  margin-bottom: 20px;
}

.wallets-page__recent-transaction-wrapper {
  width: 100%;
  height: 100%;
  border-radius: 0 20px 20px 20px;
  position: relative;
  background-color: var(--wallets-section-background);
}

.wallets-page__view-all-arrows .view-all-arrow-line {
  stroke: var(--grey2);
}

.wallets-page__section-header {
  border-radius: 0 20px 0px 0px;
}

.wallets-page__section__title-container {
  padding: unset;
  gap: 20px;
  margin-bottom: 20px;
}

.wallets-page__section__title,
.wallets-page__section2__title {
  color: var(--wallets-section-font-primary);
}

.wallets-page__section__title {
  padding: unset;
}

.wallets-page__section__container {
  padding: 20px 24px;
}

.wallets-page__section__container,
.wallets-page__section2__container {
  background-color: var(--wallets-section-background);
}

.wallets-page__view-all {
  border-radius: 0px 25.6px;
  background: var(--wallets-section-view-all-background);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: min-content;
}

.wallets-page__view-all:hover {
  opacity: 1;
  background: var(--wallets-section-view-all-background-hover);
}

.wallets-page__view-all-text {
  letter-spacing: 0px;
  color: var(--wallets-section-view-all-color);
  font-size: 16px;
  line-height: 22.4px;
  padding: 4.8px 17.6px;
}

.wallets-page__view-all:hover .wallets-page__view-all-text {
  color: var(--wallets-section-view-all-color-hover);
}

.wallets-page__container {
  padding: 0 32px;
  flex: 1 0 auto;
}

.wallets-page__footer {
  width: 100%;
  padding: 0 4px;
}

.wallets-page__columns-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.wallets-page__column1 {
  width: 50%;
  flex: 1;
}

.wallets-page__column1--expanded {
  display: none;
}

.wallets-page__column2 {
  width: 50%;
  flex: 1;
  margin-left: 32px;
}

.wallets-page__column2--expanded {
  width: 100%;
  height: max-content;
  margin-top: 0;
  margin-left: 0;
}

.wallets-page__column2--expanded .transaction-history__wrapper {
  height: max-content;
}

.wallets-page__column2--expanded .transaction-history__tab-content {
  max-height: max-content;
}

.wallets-page__overview-with-chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--wallet-summary-chart-backgroud);
  border-radius: 20px;
  gap: 20px;
  padding: 20px;
}

.wallets-page.chartWrapper {
  flex-direction: row;
}

.wallets-page .chart-legend {
  margin-top: 0px;
  margin-left: 70.4px;
  grid-template-rows: repeat(4, 1fr);
  grid-template-columns: auto;
}

.wallets-page__link {
  font-size: 10.4px;
  margin-right: 16px;
}

.wallets-page__available-funds {
  display: flex;
  min-width: 240px;
  min-height: 38px;
  flex-direction: column;
  gap: 10px;
  align-items: center;

  background: var(--wallet-page-available-funds-background);
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 12px;
  font-weight: 600;
}

.wallets-page__available-funds-value {
  margin-left: 20px;
  font-weight: 400;
  font-size: 12px;
}

.wallets-page__available-funds-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 1580px) {
  .wallets-page__container {
    width: 100%;
  }
  .wallets-page__columns-container {
    flex-direction: column;
    justify-content: flex-start;
  }
  .wallets-page__column1 {
    width: 100%;
  }
  .wallets-page__column2 {
    width: 100%;
    margin-left: 0px;
    margin-top: 19.2px;
  }
  .wallets-page__column2--expanded {
    margin-top: 0;
  }
}

@media (max-width: 880px) {
  .wallets-page__container {
    width: 100%;
    padding: 0 19.2px;
  }
  .wallets-page__columns-container {
    flex-direction: column;
    justify-content: flex-start;
  }
  .wallets-page__column1 {
    width: 100%;
  }
  .wallets-page__column2 {
    width: 100%;
    margin-left: 0px;
  }
  .wallets-page__column2--expanded {
    margin-top: 0;
  }
  .wallets-page__footer {
    padding: 0 4px;
  }
  .wallets-page__section__title-container {
    flex-direction: column;
  }
  .wallets-page.chartWrapper {
    flex-direction: column;
  }
  .wallets-page .chart-legend {
    margin-left: 0px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
  }
  .wallets-page .chart-legend-row {
    margin-top: 9.6px;
  }
  .wallets-page__chart .chart-legend-label {
    font-size: 11.2px;
    line-height: 16px;
  }
}
