.App,
#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

html {
  background: var(--secondary__bg-color);
}

#lightweight-chart {
  min-height: 40rem;
}

#support-chat-stub {
  position: fixed;
  height: 460px;
  width: 0;
  bottom: 100px;
  right: 150px;
}

@media only screen and (max-width: 968px) {
  .trading-layout__chart-tabs-container {
    height: fit-content !important;
  }
}
