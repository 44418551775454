.table {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  flex-shrink: 0;
}

.table__title {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  height: 3.6rem;
  line-height: 3.6rem;
  color: white;
  font-size: 1.3rem;
  padding: 0 1.6rem;
  background: rgb(6, 56, 67);
  border-bottom: 1px solid black;
}

.table__column {
  flex: 1;
  letter-spacing: 0.4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  flex-shrink: 0;
  text-overflow: ellipsis;
  text-align: center;
  font-size: 14px;
}

.table__header-cell {
  font-size: 14px;
}

.table__header {
  height: 45px;
  display: flex;
  color: rgb(58, 162, 219);
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 97%;
  border-radius: 10px;
  background: rgb(126, 132, 163);
  margin-bottom: 15px;
  flex-shrink: 0;
}

.table__header--background {
  height: 45px;
  background: rgb(126, 132, 163);
  border-bottom: 1px solid black;
  border-radius: 10px;
}

.table__body {
  width: 100%;
  height: calc(100% - 3.6rem);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.table__row {
  display: flex;
  width: 97%;
  align-items: center;
  padding: 7px 0px;
  border-radius: 7px;
}

.table__row:last-child {
  border-bottom: 1px solid transparent;
}

.table__row:not(.table__row--no-hover):hover {
  background: rgb(126, 132, 163);
}

.table__empty-icon,
.table__column--empty {
  display: flex;
  align-items: center;
  overflow: hidden;
  color: darkcyan;
  padding: 0 1rem;
}

.table__empty-icon {
  margin-right: 0.5rem;
  margin-top: 0.4em;
}

.table__row--empty,
.table__row--empty:hover {
  height: 4.5rem;
  background: transparent;
}

.table__pagination-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.table__wide-row-container {
  height: 100%;
  flex: 1;
}

.table__row-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100% - 60px);
  overflow-y: scroll;
  overflow-x: hidden;
}
