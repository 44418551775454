.instrument-summary__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 80px;
  width: 100%;
  background: var(--instrument-summary-background);
  padding: 10px 30px 10px 20px;
  gap: 20px;
}

.instrument-summary__title-wrapper,
.instrument-summary__buttons-wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}

.instrument-summary__warning-wrapper {
  flex-shrink: 0;
  flex-grow: 1;
  text-align: end;
}

.instrument-summary__number {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: var(--pink2);
}

.instrument-summary__title {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: var(--white1);
}

.instrument-summary__choose-asset {
  height: 40px;
  min-width: 207px;
  font-size: 14px;
  font-weight: 600;
  padding: 8px 20px;
  border-radius: 20px;
  background: var(--buttons-sell-background);
  color: var(--font__color-primary);
  border: 3px solid var(--buttons-sell-hover-background);
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  gap: 10px;
  transition: none;
}

.instrument-summary__choose-asset:hover {
  background: var(--buttons-sell-hover-background);
  color: var(--buttons-sell-hover-text-color);
}

.instrument-summary__choose-asset-icon {
  width: 20px;
  height: 18px;
}

.instrument-summary__choose-asset-icon svg:not(:root) {
  width: 20px;
  height: 18px;
}

.instrument-summary__choose-asset
  > .instrument-summary__choose-asset-icon
  .choose-icon-path {
  fill: var(--font__color-primary);
}

.instrument-summary__choose-asset:hover
  > .instrument-summary__choose-asset-icon
  .choose-icon-path {
  fill: var(--buttons-sell-hover-text-color);
}

.instrument-summary__buttons-wrapper .buttons__button--view-details {
  width: 160px;
}

@media (max-width: 950px) {
  .instrument-summary__wrapper {
    gap: 30px;
    flex-direction: column;
    padding: 20px 20px 10px 20px;
  }

  .instrument-summary__buttons-wrapper {
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }
}

@media (max-width: 576px) {
  .instrument-summary__buttons-wrapper .buttons__button--view-details {
    width: 130px;
  }

  .instrument-summary__choose-asset {
    width: 181px;
    min-width: 181px;
  }
}
