.quantity-picker,
.quantity-picker .percentage-slider {
  --track-color: var(--buy-sell-modal__button-border-color);
  --track-inactive-color: var(--quantity-picker-track-inactive-color);
  --thumb-color: var(--buy-sell-modal__button-border-color);
  --mark-color: var(--buy-sell-modal-background);
  --tooltip-color: var(--quantity-picker-tooltip-color);
}

.quantity-picker--buy,
.quantity-picker--buy .percentage-slider {
  --track-color: var(--buy-sell-modal__button-border-color--buy);
  --track-inactive-color: var(--quantity-picker-track-inactive-color--buy);
  --thumb-color: var(--buy-sell-modal__button-border-color--buy);
  --tooltip-color: var(--quantity-picker-tooltip-color--buy);
}

.quantity-picker__picker-container {
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  align-items: flex-end;
  margin-bottom: 2rem;
}

.quantity-picker__picker-container .percentage-slider {
  flex-grow: 1;
}

.quantity-picker__currency-value {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  text-align: right;
}

.quantity-picker__currency-value-symbol {
  font-size: 0.8rem;
  line-height: 1.2rem;
  font-weight: 600;
  color: var(--lightgrey1);
}

.quantity-picker__input-container {
  background-color: var(--thumb-color);
  border-radius: 2rem;
  display: inline-block;
}

.quantity-picker__input-container--expanded {
  display: block;
}

.quantity-picker__show-input {
  padding: 0.5rem 2.7rem;
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--white1)
}

.quantity-picker__input-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  padding: 0 1.05rem 0.5rem 2.15rem;
}

.quantity-picker__input {
  flex-grow: 1;
  padding: 0.4rem 1rem;
  border: 1px solid var(--font__color-primary);
  border-radius: 2rem;
  background: var(--quantity-picker-input-background-color);
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--font__color-primary);
}

.quantity-picker__input-symbol {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: var(--white1);
}

/* delete these two rules below to make progress visible */
.quantity-picker .percentage-slider__slider-track {
  background-color: transparent;
}

.quantity-picker .percentage-slider__slider-dot-active {
  background-color: var(--mark-color);
}
