.dashboard-wallet-summary__wrapper {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  position: relative;
  background: var(--wallet-summary-background);
}

.dashboard-wallet-summary__chart-with-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 24px;
}

.dashboard-wallet-summary__reminder-wrapper {
  margin-bottom: 16px;
  width: 100%;
}

.dashboard-wallet-summary__add-funds-button {
  width: 160px;
  height: 40px;
  border-radius: 22.5px;
  /* original figma gradient uses 131.67deg, but it's mirrored by 'transform' */
  background: var(--wallet-summary-add-funds-background);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white1);
  cursor: pointer;
  transition: none;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.5;
}

.dashboard-wallet-summary__add-funds-button:disabled{
  filter: grayscale(1);
}

.dashboard-wallet-summary__add-funds-button:not(:disabled):hover {
  background: var(--wallet-summary-add-funds-hover-background);
  border: var(--wallet-summary-add-funds-hover-border);
}

.dashboard-wallet-summary__chart-wrapper {
  background: var(--wallet-summary-chart-backgroud);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
  gap: 20px;
}

.dashboard-wallet-summary__chart {
  min-height: 375px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dashboard-wallet-summary__available-balance {
  color: var(--wallet-summary-chart-title);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0px;
  font-weight: medium;
}

.dashboard-wallet-summary__available-balance-value {
  color: var(--wallet-summary-available-balance);
  font-size: 28.8px;
  line-height: 32px;
  font-weight: 600;
  margin-top: 6.4px;
  display: flex;
}

.dashboard-wallet-summary__available-balance-currency {
  color: var(--wallet-summary-available-balance);
  font-size: 19.2px;
  font-weight: 600;
}

.dashboard-wallet-summary__view-detail {
  border-radius: 0px 25.6px;
  background: var(--wallet-summary-view-details-background);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: 4.8px 16px;
  height: min-content;
}

.dashboard-wallet-summary__view-detail:hover {
  opacity: 1;
  background: var(--wallet-summary-view-details-background-hover);
}

.dashboard-wallet-summary__view-detail-text {
  letter-spacing: 0px;
  color: var(--wallet-summary-view-details-color);
  font-size: 16px;
  line-height: 22.4px;
}

.dashboard-wallet-summary__view-detail:hover
  .dashboard-wallet-summary__view-detail-text {
  color: var(--white1);
}

.dashboard-wallet-summary__available-funds {
  display: flex;
  min-width: 240px;
  max-width: 350px;
  height: 38px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background: var(--wallet-page-available-funds-background);
  border-radius: 20px;
  padding: 10px 20px;
  font-weight: 600;
}

.dashboard-wallet-summary__available-funds-value {
  font-weight: 400;
  padding-left: 5px;
}

.dashboard-wallet-summary__available-funds > div {
  font-size: inherit;
}

@media (max-width: 576px) {
  .dashboard-wallet-summary__available-funds {
    max-width: 280px;
  }
  .dashboard-wallet-summary__add-funds-button:hover {
    border: none;
    background: linear-gradient(228.33deg, var(--blue2), var(--purple1));
  }
  .dashboard-wallet-summary__view-detail {
    background: var(--white2);
    box-shadow: 0 3.2px 4.8px 0 var(--black9);
    padding: 8px 16px;
  }
  .dashboard-wallet-summary__view-detail-text {
    font-size: 11.2px;
    line-height: 16px;
  }
  .dashboard-wallet-summary__chart {
    min-height: 0px;
  }
  .dashboard-wallet-summary__available-balance {
    font-size: 16px;
    line-height: 22.4px;
  }
  .dashboard-wallet-summary__available-balance-value {
    font-size: 19.2px;
    line-height: 25.6px;
    align-items: flex-end;
    margin-top: 0px;
  }
  .dashboard-wallet-summary__available-balance-currency {
    font-size: 12.8px;
    line-height: 19.2px;
  }
  .dashboard-wallet-summary__chart .chart-legend {
    margin: 0px;
    width: 100%;
    margin-top: 8px;
  }
  .dashboard-wallet-summary__chart .chart-legend-label {
    font-size: 11.2px;
    line-height: 16px;
  }
}
