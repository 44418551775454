.section-header {
  display: flex;
  min-height: 40px;
  height: 40px;
  justify-content: space-between;
  background: var(--color);
  border-radius: 20px 20px 0px 0px;
  align-items: center;
  padding-left: 13px;
}

.section-header__title-with-icon-wrapper {
  display: flex;
  align-items: center;
}

.section-header__title-icon {
  display: flex;
  width: 16px;
  fill: var(--white1);
}

.section-header__title {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: var(--color);
  padding-left: 20px;
}

.section-header__view-all-wrapper {
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color);
  box-shadow: -5px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px 20px 0px 20px;
  height: 100%;
  cursor: pointer;
  z-index: 1000;
}

.section-header__view-all {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: var(--color);
  text-transform: uppercase;
  display: flex;
}

.section-header__view-all-arrows {
  padding-left: 5px;
  display: flex;
}

.section-header__view-all-arrows .ap-icon--viewAllArrowRight {
  display: flex;
  align-items: center;
}
