.percentage-slider {
  --track-color: var(--purple8);
  --track-inactive-color: var(--pink6);
  --thumb-color: var(--purple8);
  --mark-color: var(--white1);
  --tooltip-color: var(--purple10);
  margin: 0 7.5px;
}

.percentage-slider--always-with-tooltip {
  margin: 0 24px;
}

.percentage-slider__handle-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

.percentage-slider__tooltip {
  display: inline-block;
  position: absolute;
  right: auto;
  bottom: 10px;
  transform: translateX(-50%);
  min-width: 48px;
  padding: 5px 7px;
  height: 25px;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  color: var(--white1);
  background-color: var(--tooltip-color);
  border-radius: 0 5px 5px 5px;
  opacity: 0;
}

.percentage-slider__slider-handle:active+.percentage-slider__tooltip,
.percentage-slider__slider-handle:hover+.percentage-slider__tooltip,
.percentage-slider__tooltip--always-visible {
  opacity: 1;
}

.percentage-slider__slider {
  position: relative;
  height: 15px;
  padding: 5px 0;
  width: 100%;
  border-radius: 999px;
  touch-action: none;
}

.percentage-slider__slider-rail {
  position: absolute;
  width: 100%;
  background-color: var(--track-inactive-color);
  height: 5px;
  border-radius: 999px;
}

.percentage-slider__slider-track {
  position: absolute;
  left: 0;
  height: 5px;
  border-radius: 999px;
  background-color: var(--track-color);
}

.percentage-slider__slider-handle {
  position: absolute;
  width: 15px;
  height: 15px;
  cursor: grab;
  border-radius: 50%;
  background-color: var(--thumb-color);
  touch-action: pan-x;
}

.percentage-slider__slider-handle:focus {
  outline: none;
}

.percentage-slider__slider-handle-click-focused:focus {
  box-shadow: unset;
}

.percentage-slider__slider-handle:active {
  box-shadow: 0 0 5px var(--thumb-color);
  cursor: grabbing;
}

.percentage-slider__slider-step {
  position: absolute;
  width: 100%;
  height: 5px;
  background: transparent;
}

.percentage-slider__slider-dot {
  position: absolute;
  bottom: -2.5px;
  margin-left: -5px;
  width: 10px;
  height: 10px;
  border: 1px solid var(--thumb-color);
  background-color: var(--mark-color);
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
}

.percentage-slider__slider-dot-active {
  background-color: var(--thumb-color);
}

.percentage-slider__slider-disabled .percentage-slider__slider-handle,
.percentage-slider__slider-disabled .percentage-slider__slider-mark-text,
.percentage-slider__slider-disabled .percentage-slider__slider-dot {
  cursor: not-allowed;
}
