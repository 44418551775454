.affiliate-program-page {
  width: 100%;
  position: relative;
  z-index: 0;
  background: var(--settings__body-bg-color);
  margin: var(--settings__body-margin);
  padding: var(--settings__body-padding);
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 10px;
  flex-grow: 1;
}

.affiliate-program-page__main-header {
  background: var(--settings__affiliate-header-bg-color);
  border-radius: 0px 20px 20px 20px;
  padding: 20px;
}

.affiliate-program-page__header {
  font-size: 23px;
  font-weight: 600;
  line-height: 1.5;
  margin: 0;
}

.affiliate-program-page__description {
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
}

.affiliate-program-page__body-wrapper {
  background: var(--settings__affiliate-content-bg-color);
  border-radius: 0px 20px 20px 20px;
  padding: 30px 20px;
}

.affiliate-program-page__body {
  max-width: 1100px;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: space-between;
}

.affiliate-program-page__container {
  flex-grow: 1;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 26px;
}

.affiliate-program-page__container-header--title {
  font-weight: 600;
  font-size: 19px;
  line-height: 150%;
  letter-spacing: -0.02em;
  margin: 0;
}

.affiliate-program-page__info-title {
  font-weight: 600;
  font-size: 19px;
  line-height: 150%;
  letter-spacing: -0.02em;
  margin-top: 90px;
}

.affiliate-program-page__info-content {
  font-size: 20px;
  line-height: 150%;
  margin-top: 30px;
  text-align: justify;
}

.affiliate-program-page__info-content a {
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  text-decoration: underline;
  color: var(--settings__affiliate-support-link-color);
}

.affiliate-program-page__info-content a:hover {
  color: var(--pink1);
}

/* MEDIA QUERIES */

@media (max-width: 800px) {
  .affiliate-program-page__info-title {
    font-size: 14px;
    margin-top: 40px;
  }

  .affiliate-program-page__info-content {
    font-size: 12px;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .affiliate-program-page {
    height: unset;
    flex: 1;
  }
}
