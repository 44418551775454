.co-own-page {
  width: 100%;
  padding-left: 55px;
  padding-right: 55px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 1350px;
  margin-bottom: 50px;
}

.co-own-page__main-block-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-radius: 20px;
  background-color: var(--co-own-background);
  gap: 40px;
}

.co-own-page__main-block-left-part {
  display: flex;
  flex-direction: column;
  background-color: var(--co-own-left-part-background);
  border-radius: 20px;
  padding: 20px;
  width: 341px;
}

.co-own-page__main-block-avatar {
  height: 200px;
  width: 200px;
  align-self: center;
  border-radius: 50%;
  margin-bottom: 40px;
  margin-top: 20px;
}

.co-own-page__main-block-left-part-text-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 35px;
  align-items: center;
  gap: 8px;
}

.co-own-page__main-block-left-part-text-title {
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--co-own-text-background);
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: var(--co-own-text-color);
}

.co-own-page__main-block-left-part-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: var(--co-own-sub-text-color);
}

.co-own-page__main-block-left-part-video {
  width: 246.28px;
  height: 189.44px;
}

.co-own-page__main-block-right-part {
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  overflow: hidden;
}

.co-own-page__main-block-right-part-name {
  font-weight: 600;
  font-size: 33px;
  line-height: 150%;
  color: var(--co-own-text-color);
}

.co-own-page__main-block-right-part-subname {
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: var(--co-own-sub-text-color);
  margin-top: 10px;
}

.co-own-page__main-block-right-part-description {
  margin-top: 40px;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(--co-own-text-color);
}

.co-own-page__main-block-right-part-collectibles-title {
  font-weight: 600;
  font-size: 19px;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: var(--co-own-text-color);
  margin-top: 20px;
}

.co-own-page__main-block-right-part-collectibles {
  display: flex;
  gap: 6px;
  margin-top: 18px;
  padding: 12.2px 24px;
  overflow: auto;
}

.co-own-page__description-block-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px;
  border-radius: 20px;
  background-color: var(--co-own-background);
}

.co-own-page__description-block-title {
  font-weight: 600;
  font-size: 33px;
  line-height: 150%;
  color: var(--co-own-text-color);
  margin-bottom: 10px;
}

.co-own-page__description-block-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(--co-own-text-color);
  margin-top: 30px;
}

.co-own-page__main-block-right-part-collectibles::-webkit-scrollbar {
  height: 6px;
}

.co-own-page .slick-slider {
  width: 100%;
}

.co-own-page .slick-dots {
  bottom: -28px;
}

.co-own-page .slick-dots li button:before {
  font-size: 6px;
  color: var(--pink6);
  opacity: 1;
}

.co-own-page .slick-dots li.slick-active button:before {
  color: var(--purple8);
  opacity: 1;
}

.co-own-page__video-wrapper {
  cursor: pointer;
  border-radius: 18.9444px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  margin-bottom: 8.42px;
  margin-top: 13.42px;
  border-radius: 18.9444px;
  width: 246.28px;
  height: 189.44px;
  align-self: center;
}

@media (max-width: 800px) {
  .co-own-page {
    padding-left: 18px;
    padding-right: 18px;
  }

  .co-own-page .buy-sell-product-card__card-wrapper {
    margin: 0 auto;
  }

  .co-own-page__main-block-wrapper {
    flex-direction: column;
    justify-content: flex-start;
    padding: 0;
    background: transparent;
    gap: 20px;
  }

  .co-own-page__main-block-left-part {
    width: 100%;
  }

  .co-own-page__main-block-right-part-name {
    text-align: center;
  }

  .co-own-page__main-block-right-part-subname {
    text-align: center;
    margin-top: 4px;
    margin-bottom: 40px;
  }

  .co-own-page__main-block-left-part-text-title {
    font-size: 14px;
  }

  .co-own-page__main-block-left-part-text {
    font-size: 12px;
  }

  .co-own-page__main-block-right-part-description {
    margin-top: 25px;
    font-size: 14px;
    line-height: 150%;
  }

  .co-own-page__video-wrapper {
    margin-top: 88.42px;
    margin-bottom: 68.42px;
  }

  .co-own-page__main-block-collectibles {
    background-color: var(--co-own-background);
    border-radius: 20px;
    padding-bottom: 38px;
  }

  .co-own-page__main-block-right-part-collectibles-title {
    padding-top: 0;
    padding-left: 20px;
  }

  .co-own-page__main-block-right-part-collectibles {
    padding: 0 12px;
    overflow: visible;
  }

  .co-own-page__description-block-wrapper {
    padding: 30px;
  }

  .co-own-page__description-block-text {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
  }

  .co-own-page__description-block-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 10px;
  }

  .co-own-page__description-block-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    margin-top: 20px;
  }
}
