.wire-transfer-result-modal {
  width: 1045px;
  height: 394px;
  border-radius: 20px 20px 20px 0;
  display: flex;
  flex-direction: column;
}

.wire-transfer-result-modal__header-wrapper {
  height: 80px;
  background: var(--purple8);
  border-radius: 20px 0 0 0;
}

.wire-transfer-result-modal__header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 13px;
  width: 198px;
  margin-left: 41px;
  margin-top: 26px;
}

.wire-transfer-result-modal__header {
  color: var(--white1);
  font-weight: 600;
  font-size: 14px;
}

.wire-transfer-result-modal__content {
  background: var(--wire-transfer-result-modal-body-background);
}

.wire-transfer-result-modal__body {
  flex-grow: 1;
  padding: 40px 0 0 0;
  max-width: 810px;
  margin: 0 auto 79px auto;
  color: var(--wire-transfer-result-modal-body-color);
  font-size: 18px;
}

.wire-transfer-result-modal__close-button {
  width: 180px;
  height: 40px;
  background: var(--wire-transfer-result-modal-close-button-background);
  border: 1px solid var(--wire-transfer-result-modal-close-button-color);
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 700;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  cursor: pointer;
}

.wire-transfer-result-modal__close-icon {
  display: none;
}
