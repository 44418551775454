.reset-password-page__container {
  margin: auto;
  width: 57.6rem;
  min-height: 32.84rem;
  padding: 3.68rem 3.68rem 3.2rem 3.68rem;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0.64rem 1.92rem #00000066;
  border-radius: 0px 2.88rem 2.88rem 2.88rem;
  background: #fafcff;
  align-items: center;
}

@media (max-width: 576px) {
  .reset-password-page__container {
    width: 100%;
    min-height: auto;
    border-radius: 0px;
    padding: 2.08rem 2.08rem 1.6rem 2.08rem;
  }
}
