.dashboard-market-place__wrapper {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  position: relative;
  background: var(--dashboard-market-place-background);
  padding-bottom: 30.4px;
}

.dashboard-market-place__header-buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 40px;
  margin-bottom: 10px;
}

.dashboard-market-place__tab {
  height: calc(100% - 55px) !important;
}

.dashboard-market-place__wrapper .section__title-container {
  display: block;
  padding: 0;
}

.dashboard-market-place__wrapper .section__title {
  padding: 0;
  line-height: unset;
}

.dashboard-market-place__filter-wrapper {
  margin: 20px 40px 0;
}

.dashboard-market-place__cards {
  padding: 0px 36px;
  margin: 39px 0 10px 30px;
}

.dashboard-market-place__cards--no-assets {
  height: 350px;
}

.dashboard-market-place__no-assets {
  color: var(--available-collectibles-no-products-color);
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  padding: 0 40px;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-market-place__cards .slick-slider {
  padding: 0px 48px;
}
.dashboard-market-place__cards .slick-disabled {
  opacity: 0.5;
}

.dashboard-market-place__cards .slick-prev {
  transform: rotateY(180deg);
  z-index: 20;
  fill: var(--pink1);
  margin-top: -10px;
}

.dashboard-market-place__cards .slick-next {
  fill: var(--pink1);
  right: 10px;
}

.slick-arrow.slick-next::before,
.slick-arrow.slick-prev::before {
  content: '';
}

.slick-arrow.slick-prev .ap-icon--sliderArrow,
.slick-arrow.slick-next .ap-icon--sliderArrow,
.slick-arrow.slick-prev .ap-icon--sliderArrow svg:not(:root),
.slick-arrow.slick-next .ap-icon--sliderArrow svg:not(:root) {
  height: 27.2px;
  width: 24px;
}

.slick-arrow.slick-prev .ap-icon--sliderArrow {
  transform: rotate(180deg);
}

.slick-arrow.slick-prev .ap-icon--sliderArrow:hover .sliderArrow-d,
.slick-arrow.slick-next .ap-icon--sliderArrow:hover .sliderArrow-d {
  fill: var(--purple1);
}

.slick-arrow.slick-prev .ap-icon--sliderArrow:hover .sliderArrow-c,
.slick-arrow.slick-next .ap-icon--sliderArrow:hover .sliderArrow-c {
  fill: var(--white1);
  opacity: 1;
}

.slick-prev.slick-disabled .ap-icon--sliderArrow .sliderArrow-c,
.slick-prev.slick-disabled .ap-icon--sliderArrow .sliderArrow-e,
.slick-next.slick-disabled .ap-icon--sliderArrow .sliderArrow-c,
.slick-next.slick-disabled .ap-icon--sliderArrow .sliderArrow-e {
  opacity: 0.25;
}

.slick-prev.slick-disabled .ap-icon--sliderArrow:hover .sliderArrow-d,
.slick-next.slick-disabled .ap-icon--sliderArrow:hover .sliderArrow-d {
  fill: var(--purple1);
  opacity: 0.25;
}

.slick-prev.slick-disabled .ap-icon--sliderArrow:hover .sliderArrow-c,
.slick-next.slick-disabled .ap-icon--sliderArrow:hover .sliderArrow-c {
  fill: var(--white1);
  opacity: 1;
}

.dashboard-market-place__no-products {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--available-collectibles-no-products-color);
  position: relative;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  padding: 0 40px;
  text-align: center;
  margin-top: 50px;
  min-height: 341px;
}

.dashboard-market-place__no-products-button {
  border-radius: 20px;
  height: 40px;
  width: 200px;
  border: 3px solid var(--purple8);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: var(--purple8);
  margin-top: 58px;
  background-color: var(--available-collectibles-background);
  z-index: 2;
  cursor: pointer;
  transition: none;
}

.dashboard-market-place__no-products-button:hover {
  background-color: var(--purple8);
  color: var(--white1);
}

.dashboard-market-place__no-products-logo {
  position: absolute;
  height: 211px;
  width: 200px;
}

@media (max-width: 1100px) {
  .dashboard-market-place__cards .buy-sell-product-card__card-wrapper {
    margin: auto;
  }

  .dashboard-market-place__cards .slick-next {
    right: 30px;
  }

  .dashboard-market-place__cards .slick-prev {
    left: 35px;
  }
}

@media (max-width: 800px) {
  .dashboard-market-place__no-products {
    font-size: 20px;
    line-height: 30px;
  }
}

@media (max-width: 650px) {
  .dashboard-market-place__cards .slick-next {
    right: 15px;
  }

  .dashboard-market-place__cards .slick-prev {
    left: 20px;
  }
}

@media (max-width: 576px) {
  .dashboard-market-place__header-buttons {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
  }
  .dashboard-market-place__no-products-button {
    background-color: var(--purple8);
    color: var(--white1);
  }
  .dashboard-market-place__cards .slick-prev,
  .dashboard-market-place__cards .slick-next {
    fill: var(--slick-slider-arrow-color);
  }
  .dashboard-market-place__cards .slick-next {
    right: 15px !important;
  }
  .dashboard-market-place__cards .slick-prev {
    left: 15px !important;
  }
  .dashboard-market-place__cards .market-product-card__card-wrapper {
    max-width: 210px;
    display: flex;
    flex-direction: column;
    margin: auto;
  }
  .dashboard-market-place__wrapper {
    padding-bottom: 38.4px;
  }
  .dashboard-market-place__cards {
    padding: 0 0;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .dashboard-market-place__view-all {
    background: var(--white2);
    box-shadow: 0 3.2px 4.8px 0 var(--black9);
    padding: 8px 16px;
  }
  .dashboard-market-place__view-all-text {
    font-size: 11.2px;
    line-height: 16px;
    padding: 0px;
  }
  .tab__menu.dashboard-market-place__menu {
    top: 57.6px;
    gap: 8px;
    display: flex;
    justify-content: space-between;
  }
  .dashboard-market-place__wrapper .assets-filter__filter-option {
    font-size: 10.9px;
    line-height: 18.4px;
    padding-bottom: 7px;
  }
  .dashboard-market-place__cards .slick-slider {
    padding: 0px;
  }
  .dashboard-market-place__cards .slick-dots li,
  .dashboard-market-place__cards .slick-dots li button,
  .dashboard-market-place__cards .slick-dots li button:before {
    width: 20px;
    height: 20px;
    margin: 0 3.2px;
    color: var(--blue6);
  }

  .dashboard-market-place__cards .slick-dots li button:before {
    font-size: 10px;
  }
  .dashboard-market-place__cards .slick-disabled {
    opacity: 0.5;
  }

  .dashboard-market-place__cards .slick-initialized .slick-slide {
    width: 300px;
  }

  .dashboard-market-place__cards .slick-dots {
    bottom: -35px;
  }

  .dashboard-market-place__market-card__card-image {
    width: 183px;
    height: 260px;
    margin-top: 15px;
  }

  .dashboard-market-place__market-card__placeholder {
    display: unset;
    transform: none;
    transition: none;
    bottom: unset;
    width: 210px;
  }

  .dashboard-market-place__market-card__placeholder-image {
    transform: none;
    transition: none;
    width: 210px;
    height: 380px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .dashboard-market-place__market-card__card-title,
  .dashboard-market-place__market-card__highlight-line,
  .dashboard-market-place__market-card__card-price,
  .dashboard-market-place__market-card__card-price-change {
    padding-left: 0;
    margin-left: 0;
  }

  .dashboard-market-place__market-card__card-title {
    margin-top: 10px;
  }

  .dashboard-market-place__market-card__buttons {
    position: absolute;
    bottom: 0px;
  }

  .dashboard-market-place__market-card__card-wrapper {
    height: 500px;
  }

  .dashboard-market-place__cards .slick-prev {
    left: 50px;
  }
}

@media (max-width: 380px) {
  .dashboard-market-place__market-card__buttons {
    width: 240px;
  }
}
