.info-button {
  align-self: flex-start;
  position: relative;
}

.info-button:hover .info-button__icon-text-wrapper {
  display: flex;
}

.info-button__icon-text-wrapper {
  z-index: 1;
  position: absolute;
  width: 174px;
  border-radius: 20px;
  padding-left: 39px;
  padding-right: 18px;
  background: var(--purple11);
  left: -13px;
  font-weight: 400;
  font-size: 8px;
  line-height: 12px;
  display: none;
  padding-top: 10px;
  padding-bottom: 8px;
  cursor: pointer;
  color: var(--grey2);
  top: -18px;
  text-align: left;
}

.info-button__icon {
  height: 15px;
  width: 15px;
  z-index: 2;
  cursor: pointer;
  align-self: center;
  position: relative;
}

.info-button:hover .info-button__icon .icon-border {
  stroke: var(--grey2);
}

.info-button:hover .info-button__icon .icon-symbol,
.info-button:hover .info-button__icon .icon-dot {
  fill: var(--grey2);
}

.info-button__icon .icon-border {
  stroke: var(--icon-color);
}
.info-button__icon .icon-symbol,
.info-button__icon .icon-dot {
  fill: var(--icon-color);
}
