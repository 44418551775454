.footer {
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  padding: 32px;
  margin: 0 25.6px;
}

.footer__left-row {
  display: flex;
  align-items: center;
  position: relative;
  flex: 1/3;
}

.footer__left-row-stub {
  width: 530px;
  height: 100%;
  position: absolute;
  z-index: 1;
  pointer-events: none;
}

.footer__social-icons {
  display: flex;
  flex: 1/3;
  gap: 30px;
  flex-wrap: wrap;
}

.footer__social-icon {
  cursor: pointer;
  color: var(--footer-social-link-color);
  fill: var(--footer-social-link-color);
}

.footer__right-row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: var(--footer-font-color);
  font-size: 16px;
  line-height: 22.4px;
  flex: 1/3;
}

.footer__left-row-item {
  margin-right: 25.6px;
  cursor: pointer;
  color: var(--footer-font-color);
  font-size: 16px;
  line-height: 22.4px;
}

.footer__left-row-item:hover {
  color: var(--footer-hover-font-color);
}

.footer__text {
  z-index: 1;
}

@media (max-width: 1280px) {
  .footer__left-row-stub {
    width: 450px;
  }
}

@media (max-width: 800px) {
  .footer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 19.2px;
    margin: 0 9.6px;
    padding-top: 45px;
  }
  .footer__social-icons {
    margin-bottom: 40px;
  }
  .footer__left-row-stub {
    width: auto;
  }
  .footer__text {
    display: flex;
  }
  .footer__left-row,
  .footer__right-row {
    width: 100%;
  }
  .footer__left-row-item {
    font-size: 9.6px;
    line-height: 13.6px;
    margin-right: 0px;
  }
  .footer__left-row {
    justify-content: space-between;
  }
  .footer__right-row {
    justify-content: center;
    font-size: 8px;
    line-height: 12px;
    opacity: 0.8;
    margin-top: 20px;
  }
}
