.spotlight-decorated {
  position: relative;
  z-index: 1000001;
}

/* TODO: remove :first-child? */
.spotlight-decorated > *:first-child {
  box-shadow: var(--spotlight_shadow_color) 0 0 15px;
}

.spotlight-overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  backdrop-filter: blur(7.5px);
  z-index: 1000000;
}
