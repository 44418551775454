.vote-countdown-modal,
.live-vote-modal,
.confirm-vote-modal {
  overflow: hidden;
  max-width: 1000px;
  min-height: 290px;
  width: calc(100% - 250px);
  background: var(--vote-modal-background);
  border-radius: 20px;
}

.vote-countdown-modal__content,
.live-vote-modal__content,
.confirm-vote-modal__content {
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  padding: 25px 45px 45px 45px;
}

.vote-countdown-modal__body,
.live-vote-modal__body,
.confirm-vote-modal__body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.vote-countdown-modal__title,
.live-vote-modal__title,
.confirm-vote-modal__title {
  font-weight: 600;
  font-size: 24px;
  color: var(--vote-modal-font-primary);
  margin: 10px 0 10px 10px;
}

.vote-countdown-modal__description,
.live-vote-modal__description,
.live-vote-modal__description-2,
.confirm-vote-modal__description {
  max-width: 870px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--vote-modal-font-primary);
  margin-left: 10px;
  opacity: 0.89;
}

.confirm-vote-modal__description,
.live-vote-modal__description-2 {
  margin-top: 25px;
}

.live-vote-modal__warning {
  color: var(--red3);
}

.vote-countdown-modal__back-button,
.live-vote-modal__back-button {
  width: 180px;
  height: 40px;
  margin: auto;
  margin-top: 50px;
  color: var(--vote-modal-font-primary);
  border: 3px solid var(--vote-modal-font-primary);
  border-radius: 20px;
  font-weight: 600;
  font-size: 14px;
}

.vote-countdown-modal__back-button:hover,
.live-vote-modal__back-button:hover {
  color: var(--vote-modal-background);
  background: var(--vote-modal-font-primary);
}

.confirm-vote-modal__checkbox-container {
  display: flex;
  margin: auto;
  margin-top: 120px;
  font-weight: 400;
  font-size: 16px;
  color: var(--vote-modal-font-primary);
}

.confirm-vote-modal__checkbox label {
  margin-right: 20px;
  background: transparent;
  border: 1px solid var(--vote-modal-font-primary);
  height: 120px;
  width: 120px;
}

.confirm-vote-modal__checkbox #tick_mark {
  width: 80px;
  height: 80px;
  top: -3px;
}

.confirm-vote-modal__checkbox #tick_mark::before {
  width: 10px;
  height: 40px;
}

.confirm-vote-modal__buttons {
  display: flex;
  margin: auto;
  margin-top: 50px;
  max-width: 480px;
  gap: 80px;
}

.confirm-vote-modal__next-button,
.confirm-vote-modal__cancel-button {
  width: 200px;
  height: 40px;
  text-align: center;
  color: var(--vote-modal-font-primary);
  font-size: 14px;
  font-weight: 600;
  border-radius: 20px;
  background: var(--vote-modal-background);
  border: 3px solid var(--vote-modal-font-primary);
}

.confirm-vote-modal__next-button {
  border: double 3px transparent;
  background-image: linear-gradient(
      var(--vote-modal-background),
      var(--vote-modal-background)
    ),
    linear-gradient(90deg, #7c5ced 0%, #53d660 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

.confirm-vote-modal__next-button:disabled {
  opacity: 0.5;
}

.confirm-vote-modal__next-button:hover {
  color: var(--white1);
  background-image: linear-gradient(90deg, #7c5ced, #53d660),
    linear-gradient(90deg, #7c5ced 0%, #53d660 100%);
}

.confirm-vote-modal__next-button--no {
  background-image: linear-gradient(
      var(--vote-modal-background),
      var(--vote-modal-background)
    ),
    linear-gradient(90deg, #e02323 0%, #825af6 100%);
}

.confirm-vote-modal__next-button--no:hover {
  color: var(--white1);
  background-image: linear-gradient(90deg, #e02323, #825af6),
    linear-gradient(90deg, #e02323 0%, #825af6 100%);
}

.confirm-vote-modal__cancel-button:hover {
  background: var(--vote-modal-font-primary);
  color: var(--vote-modal-background);
}

@media (max-width: 970px) {
  .confirm-vote-modal {
    width: calc(100% - 100px);
  }

  .confirm-vote-modal__checkbox label {
    margin-right: 0;
  }

  .confirm-vote-modal__checkbox {
    margin-left: 40px;
  }

  .confirm-vote-modal__buttons {
    gap: 40px;
  }
}

@media (max-width: 800px) {
  .vote-countdown-modal,
  .live-vote-modal,
  .confirm-vote-modal {
    width: calc(100% - 100px);
  }
}

@media (max-width: 670px) {
  .confirm-vote-modal__cancel-button,
  .confirm-vote-modal__next-button {
    width: 180px;
  }
}

@media (max-width: 576px) {
  .vote-countdown-modal,
  .live-vote-modal,
  .confirm-vote-modal {
    width: calc(100% - 30px);
    margin-top: 20px;
  }
  .vote-countdown-modal__back-button,
  .live-vote-modal__back-button {
    background: var(--vote-modal-font-primary);
    color: var(--vote-modal-background);
  }

  .live-vote-modal__content,
  .confirm-vote-modal__content {
    overflow-y: scroll;
  }

  .confirm-vote-modal__checkbox-container {
    margin-top: 30px;
  }

  .confirm-vote-modal__checkbox label {
    margin-right: 0;
  }

  .confirm-vote-modal__checkbox {
    margin-left: 30px;
  }

  .vote-countdown-modal__description,
  .live-vote-modal__description,
  .live-vote-modal__description-2,
  .confirm-vote-modal__description {
    margin-left: 0px;
  }

  .confirm-vote-modal__buttons {
    flex-direction: column;
    gap: 20px;
  }
}
