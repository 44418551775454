.slider3D__wrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 auto;
  overflow: hidden;
  padding: 0 30px 70px 30px;
  max-width: 100%;
  height: 100%;
}

.slider3D__cards {
  perspective: 170px;
  transform-style: preserve-3d;
  transition: all 1s ease;
  height: 100%;
  padding-left: 23px;
}

.slider3D__card {
  position: absolute;
  transition: all 1s ease;
  top: 6%;
  height: 100%;
  cursor: pointer;
}

.slider3D__arrow {
  width: 30px;
  height: 34px;
  cursor: pointer;
  color: var(--my-collectibles-slider-arrow);
  display: flex;
  align-items: center;
  fill: var(--slick-slider-arrow-color);
}

.slider3D__arrow--left {
  transform: rotate(180deg);
}

.slider3D__arrowWrapper {
  width: 53px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 12;
}

.slider3D__arrowWrapper--right {
  right: 0;
  padding-left: 23px;
}

.slider3D__arrowWrapper--left {
  left: 0;
  padding-left: 10px;
}

.slider3D__navigation-icon:hover .a {
  fill: var(--purple1);
}

.slider3D__navigation-icon:hover .c {
  fill: var(--white1);
}

.slider3D__image {
  height: 100%;
  outline: 1px solid transparent;
}

.slider3D__imageShadow {
  width: 100%;
  height: 22px;
  position: relative;
  top: 10px;
  background: var(--black3);
  border-radius: 50%;
  filter: blur(10px);
  transition: opacity 1s cubic-bezier(0.12, 0, 0.39, 0);
}

.slider3D__imageShadowGone {
  opacity: 0;
}

.slider3D__imageWhiteout {
  position: absolute;
  width: 100%;
  height: 73%;
  bottom: 67px;
  transition: all 1s cubic-bezier(0.12, 0, 0.39, 0);
  display: none;
}

@media (max-width: 1600px) {
  .slider3D__arrowWrapper--right {
    padding-left: 0;
  }
  .slider3D__arrowWrapper--left {
    padding-left: 10px;
  }
}

@media (max-width: 810px) {
  .slider3D__arrowWrapper--right {
    padding-left: 10px;
  }
}

@media (max-width: 685px) {
  .slider3D__arrowWrapper--right {
    padding-left: 20px;
  }
  .dashboard-my-assets__wrapper {
    padding-left: 20px;
  }
}

@media (max-width: 655px) {
  .slider3D__arrowWrapper--right {
    padding-left: 30px;
  }
}

@media (max-width: 578px) {
  .slider3D__imageWhiteout {
    height: 60%;
  }

  .slider3D__cards {
    perspective: 250px;
  }
}
