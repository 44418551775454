.settings-page__container {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: var(--settings-background);
}
.settings-page__screens-content__content {
  margin: 0 40px;
  display: flex;
  flex-direction: column;
}

.settings-page__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

.settings-page__content .user-contacts-page,
.settings-page__content .loyalty-token-page,
.settings-page__content .affiliate-program-page,
.settings-page__content .api-keys-page,
.settings-page__content .trade-report-page,
.settings-page__content .kyc-settings {
  padding: unset;
  background: var(--settings-background);
}

.settings-page__content
  .user-contacts-page
  .user-contacts-page__block-with-text,
.settings-page__content .api-keys-page .api-keys-page__description-container,
.settings-page__content .trade-report-page .trade-report-page__activity-report,
.settings-page__content .kyc-settings .kyc-settings__header {
  margin-left: unset;
  margin-right: unset;
}

.settings-page__footer {
  flex-shrink: 0;
}

@media only screen and (max-width: 576px) {
  .settings-page__content {
    flex-direction: column;
    flex: 1 0 auto;
  }
  .settings-page__screens-content__content {
    margin: 0 24px;
  }
}
