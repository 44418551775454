.withdraw-form__title,
.cryptowallet-withdraw__title {
  color: var(--text-primary);
  padding-bottom: 2rem;
}

.withdraw-form__title-container {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--deposit-withdraw-font-primary);
  border-radius: 0 20px 20px 20px;
  margin-bottom: 40px;
  padding: 7px 25px 7px 25px;
  gap: 20px;
}

.withdraw-form__title {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  color: var(--black1);
  padding: 0;
  border-radius: 0 20px 20px 20px;
  cursor: pointer;
}

.withdraw-form__title--selected {
  background: var(--pink5);
}

.withdraw-form__body {
  display: flex;
  flex-direction: column;
}

.cryptowallet-withdraw__address-container {
  display: flex;
  padding: 2rem;
  background: var(--primary__bg-color);
  justify-content: space-between;
  /* font-size: 0.8rem; */
}

.cryptowallet-withdraw__qrcode {
  margin-right: 2rem;
}

.withdraw-form__input-wrapper,
.withdraw-form__select-wrapper {
  padding-bottom: 2.6rem;
}

.withdraw-form__input-wrapper:last-child {
  padding-bottom: 0;
}

.inline__info {
  width: 100%;
  text-align: right;
}

.withdraw-form__close__btn {
  margin-top: 1rem;
}

.withdraw-form__form-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 1rem;
  min-height: 30rem;
  height: 100%;
}

.withdraw-form__select-wrapper,
.withdraw-form__input-wrapper {
  margin: 1rem;
  text-align: left;
}

.withdraw-form__select-label,
.withdraw-form__label {
  margin: 0 0 1.2rem;
  font-size: 1.4rem;
}

.withdraw-form__select,
.withdraw-form__input {
  height: 4.4rem;
  font-size: 1.6rem;
  border-radius: 0.3rem;
  min-width: 20rem;
  background: var(--secondary__bg-color);
  border: 1px solid var(--border__color);
}

.withdraw-form__input-box {
  display: block;
  width: 100%;
}

.withdraw-form__input {
  height: 4.4rem;
  font-size: 1.6rem;
  border-radius: 0.3rem;
  min-width: 19.6rem;
  background: var(--secondary__bg-color);
  border: 1px solid var(--border__color);
}

.withdraw-form__error {
  padding: 0 3.2rem 1.5rem;
}

.withdraw-form__btn {
  width: calc(100% - 2rem);
  margin: 1rem;
  height: 5rem;
  border-radius: 0.3rem;
  font-size: 1.4rem;
}

.withdraw-form__template-body,
.withdraw-form__template-footer {
  text-align: center;
  margin-top: 2rem;
  padding-top: 2rem;
  transition: none;
  border-top: 1px solid var(--border__color-secondary);
}

.withdraw-form__spinner-container {
  margin-top: 2rem;
  padding-top: 2rem;
  transition: none;
  border-top: 1px solid var(--border__color-secondary);
  position: initial;
  margin-left: 0;
}

.withdraw-form__spinner-circle {
  margin: 0;
}

.withdraw-form__frame {
  padding: 2.2rem;
  display: flex;
  flex-direction: column;
  height: 68vh;
}
.withdraw-form__frame-container {
  overflow-y: auto;
}

.withdraw-form__section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.withdraw-form__label-in-input {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  width: 5rem;
  font-size: 1.4rem;
  margin-left: 0.2rem;
  margin-top: 0.1rem;
}

.withdraw-form__with-label {
  padding-left: 6rem;
}

.withdraw-form__info-items {
  margin: 1rem 1rem 2rem;
}

.withdraw-form__lwt-container {
  display: flex;
  flex-direction: row;
  min-height: 3rem;
  border-bottom: 1px solid var(--component__border-color);
  align-items: center;
  font-size: 1.2rem;
}

.withdraw-form__lwt-label {
  margin: 0;
}

.withdraw-form__input-wrapper--amount {
  width: 45%;
}

.withdraw-form__footer {
  padding: 0 2.2rem;
  border-top: 1px solid var(--border__color);
  display: flex;
  align-items: center;
  min-height: 10.6rem;
  height: 10.6rem;
}

.withdraw-form__source {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  border: 1px solid var(--border__color);
  box-sizing: border-box;
  border-radius: 4px;
  background-color: var(--secondary__bg-color);
}

.withdraw-form__source-item {
  font-size: 1.4rem;
  height: unset;
  min-height: 3.6rem;
  flex: 1 1;
  line-height: 3.6rem;
  text-align: center;
  cursor: pointer;
  background: var(--secondary__bg-color);
  color: var(--font__color-secondary);
  border-radius: 2px;
  margin: 0.4rem;
}

.withdraw-form__source-item-with-border {
  flex: 1 1;
  border-right: 1px solid var(--border__color);
}

.withdraw-form__source-item--selected,
.withdraw-form__source-item:hover {
  background: var(--purple1);
  color: var(--white1);
}

.withdraw-form__send-to {
  padding: 3.7rem 3rem;
  padding-bottom: 0px;
}

.withdraw-form__send-to-friend {
  font-size: 1.4rem;
  padding: 3rem 3.7rem 3rem 3.7rem;
}

.withdraw-form__send-to-friend form.send-form {
  padding: 0rem !important;
}

.withdraw-form__send-to-friend .send-form__send-to {
  display: none;
}

.withdraw-form__send-to-friend .send-form__info-items {
  display: none;
}

.withdraw-form__frame-container {
  margin-bottom: 2rem;
}

@media (max-width: 576px) {
  .withdraw-form__title-container {
    position: fixed;
    top: 185px;
    z-index: 10;
    left: 18px;
    width: calc(100% - 36px);
  }
}
