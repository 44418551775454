.page-header-nav__svg-curves {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  fill: var(--liquid-background-primary);
}
.page-header-nav__navigationContainer {
  position: fixed;
  display: flex;
  flex-direction: column;
  height: 100%;
  z-index: 16;
}
.page-header-nav__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: var(--liquid-background-secondary);
  overflow-x: hidden;
  position: relative;
  margin-bottom: 4px;
  border-bottom-right-radius: 19.2px;
}
.page-header-nav__container::-webkit-scrollbar {
  display: none;
}
.page-header-nav__navButtons {
  display: flex;
  flex-direction: column;
  margin: 48.8px 0 11.2px 0px;
  padding-left: 16px;
}
.page-header-nav__logoWrapper {
  margin-top: 27.4px;
  padding-bottom: 0px;
  padding-left: 0px;
  background: var(--liquid-background-primary);
  height: 121px;
  width: 107.52px;
}
.page-header-nav__logo {
}
.page-header-nav__toggleButtonWrapper {
  display: flex;
  justify-content: flex-end;
}
.page-header-nav__selectedPart {
  display: flex;
  background: var(--liquid-background-primary);
  border-top-left-radius: 25.6px;
  border-bottom-left-radius: 25.6px;
  transition: box-shadow, width;
}
.page-header-nav__beforeSelectedPart {
  display: flex;
  background: var(--liquid-background-secondary);
  box-shadow: 25.6px 0px 0 0 var(--liquid-background-primary);
  border-bottom-right-radius: 25.6px;
  transition: box-shadow, width;
}
.page-header-nav__afterSelectedPart {
  display: flex;
  background: var(--liquid-background-secondary);
  box-shadow: 25.6px -0px 0 0 var(--liquid-background-primary);
  border-top-right-radius: 25.6px;
  transition: box-shadow, width;
}

.page-header-nav__container--closed {
  background: var(--liquid-background-secondary);
  width: max-content;
}

.page-header-nav__onboarding-wrapper {
  display: flex;
  align-items: center;
  padding: 9.6px 16px;
  border-radius: 25.6px;
  width: 100%;
  min-height: 58px;
}

.page-header-nav__theme-button {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.page-header-nav__theme-icon {
  cursor: pointer;
}

.page-header-nav__item {
  display: flex;
  align-items: center;
  border: solid 3.2px transparent;
  border-radius: 25.6px;
  color: var(--nav-item__color);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  white-space: pre;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: var(--z-index__navigation);
  flex-grow: 1;
  overflow: hidden;
  transition: width;
}
.page-header-nav__menuToggle {
  height: 32px;
  width: 32px;
  display: flex;
  align-self: flex-end;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  white-space: pre;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: var(--z-index__navigation);
  overflow: 'hidden';
  border-radius: 0px 56px 56px 56px;
  border: solid 1px transparent;
  margin-right: 12.8px;
  margin-bottom: 6.4px;
  fill: var(--expand-navbar-icon);
}
.page-header-nav__item--hoverable:hover {
  color: var(--nav-item__color);
  background: var(--liquid-background-primary) 0% 0% no-repeat padding-box;
}
.page-header-nav__collapsedMargin {
  margin-right: 22.4px;
}
.page-header-nav__menuToggle--hoverable:hover {
  border-color: var(--nav-icon-hover-border);
}

.page-header-nav__item--selected,
.page-header-nav__item--selected:active,
.page-header-nav__item--selected:hover {
  background: var(--liquid-background-secondary) 0% 0% no-repeat padding-box;
  border-color: var(--liquid-background-primary);
  position: relative;
}

.page-header-nav__label {
  color: var(--nav-bar-item-color);
  padding-left: 12.8px;
  letter-spacing: 0px;
  transition: width 0.5s;
}

.page-header-nav__icon.ap-icon {
  display: flex;
  width: 32px;
  justify-content: center;
}

.page-header-nav__icon--exchange {
  margin-top: 2.4px;
}

.page-header-nav__menu-toggle {
  cursor: pointer;
  border-right: 1px solid var(--tab__separator-color);
  width: var(--nav-header__height);
  min-height: var(--nav-header__height);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--nav-item__color);
  z-index: 1;
  display: none;
}

.page-header-nav__item--hoverable:not(.page-header-nav__item--selected):hover
  .page-header-nav__icon {
  transition-duration: 0.5s;
  transition-property: transform;
}
.page-header-nav__item--hoverable:not(.page-header-nav__item--selected):hover
  .page-header-nav__icon {
  transform: rotate(1turn);
}
.page-header-nav__menu-toggle:hover,
.page-header-nav__menu-toggle:focus {
  color: var(--nav-item__color--action);
}

.page-header-nav__icon--menu-toggle {
  margin-right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
  width: 80%;
}

.page-header-nav__icon--menu-toggle:before {
  content: '';
  border-left: 3.2px solid transparent;
  border-right: 3.2px solid transparent;
  border-top: 3.2px solid;
  display: inline-block;
  margin-top: 0rem;
}

.page-header-nav__icon--menu-toggle svg {
  display: none;
}

.page-header-nav__icon--settings svg:not(:root) {
  width: 40px;
  height: 40px;
}

.page-header-nav__sign-out-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 120px;
  height: 40px;
  min-height: 40px;
  box-shadow: 0px 5px 10px var(--nav-bar-sign-out-shadow-color);
  border-radius: 20px;
  border: 0.25px solid var(--nav-bar-sign-out-background);
  background: transparent;
  color: var(--nav-bar-sign-out-background);
  font-size: 12px;
  margin-bottom: 24px;
  align-self: center;
}

.page-header-nav__icon--community svg:not(:root)  {
  width: 32px;
  height: 32px;
}

.page-header-nav__icon--vote svg:not(:root)  {
  width: 20px;
  height: 20px;
}

/* BROWSER QUERIES */

/* IE11+ specific styles */
_:-ms-fullscreen,
:root .page-header-nav__icon--menu-toggle:before {
  margin-top: var(--nav-header__height);
}

/* MEDIA QUERIES */

/* Medium Devices, Tablets */
@media only screen and (max-width: 1200px) {
  .page-header-nav__item {
    border-right: 0px solid transparent;
  }

  .page-header-nav__item:focus {
    border-right: solid 0px transparent;
  }
}

@media (max-width: 800px) {
  .page-header-nav__menuToggle {
    display: none;
  }
  .page-header-nav__navButtons {
    margin-top: 64px;
  }

  .page-header-nav__toggleButtonWrapper {
    height: 23.2px;
  }
}

/* Small Devices, Phones */
@media only screen and (max-width: 720px) {
  .page-header-nav__container {
    order: 1;
    flex-direction: column;
    z-index: var(--z-index__navigation);
    /* height: var(--nav-header__height); */
    flex-grow: unset;
    justify-content: unset;
  }

  .page-header-nav__item:hover:not(.page-header-nav__item--selected) {
    background: #e4eff4 0% 0% no-repeat padding-box;
  }

  .page-header-nav__menu-toggle {
    position: absolute;
    right: 0;
    width: 100%;
    display: flex;
  }

  .page-header-nav__icon--menu-toggle {
    position: absolute;
    right: 16px;
    width: 0;
    height: 0;
  }

  .page-header-nav__icon.ap-icon {
    height: auto;
  }

  /* BROWSER QUERIES */

  /* IE11+ specific styles */
  _:-ms-fullscreen,
  :root .page-header-nav__icon--menu-toggle {
    right: 20px;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 645px) {
  .page-header-nav__menu-toggle {
    position: relative;
    width: var(--nav-header__height);
    justify-content: center;
  }

  .page-header-nav__icon--menu-toggle {
    right: unset;
  }

  .page-header-nav__icon--menu-toggle svg {
    margin-top: 4.8px;
    display: block;
  }

  .page-header-nav__icon--menu-toggle:before {
    display: none;
  }
}
