.sidepane-details {
}

.sidepane-details__extra-header {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: var(--deposit-withdraw-crypto-instructions-color);
  margin-top: 30px;
}

.sidepane-details__header {
  padding: 3rem 2.5rem 2rem;
  font-weight: 600;
  font-size: 16px;
  padding: 0;
  margin-top: 60px;
}

.sidepane-details__header--top {
  border-top: 4px solid var(--border__color);
}

.sidepane-details__body {
  padding-left: 0;
  margin-top: 16px;
  margin-bottom: 20px;
}

.sidepane-details__details-row:first-child {
  border-top: 1px solid var(--border__color);
  border-bottom: 1px solid var(--border__color);
}

.sidepane-details__lwt-container {
  display: flex;
  min-height: 3.4rem;
  justify-content: center;
  border-bottom: 1px solid var(--deposit-withdraw-border-color);
  border-top: none;
  flex-direction: column;
  padding-right: 2.5rem;
  height: auto;
  gap: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.sidepane-details__lwt-label {
  font-size: 12px;
  font-weight: 300;
  color: var(--deposit-withdraw-font-secondary);
  line-height: 1.5;
  letter-spacing: -0.4px;
  margin: 0;
}

.sidepane-details__lwt-text {
  font-size: 1.3rem;
  font-weight: 500;
  color: var(--deposit-withdraw-font-secondary);
  line-height: 1.5;
  letter-spacing: -0.4px;
  padding-left: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidepane-details__information {
  padding: 2rem 2.5rem;
}

.sidepane-details__information-text {
  padding-bottom: 2rem;
  color: var(--deposit-withdraw-font-primary);
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
}

.sidepane-details__divider {
  display: none;
}

.sidepane-details__information--withdraw {
  padding-top: 2.8rem;
}

.sidepane-details__information-text--deposit:first-child {
  padding-bottom: 2.5rem;
}

.sidepane-details__information--top {
  margin-top: -31rem;
}

.sidepane-details__information-text--top {
  padding-top: 1rem;
  padding-bottom: 1.7rem;
}

.sidepane-details__information-header {
  font-weight: normal;
  font-size: 16px;
  padding-bottom: 1.35rem;
  border-bottom: 1px solid var(--border__color);
  margin-right: -2.5rem;
}

/* MEDIA QUERIES */

@media only screen and (max-width: 576px) {
  .sidepane-details__information-header {
    border: none;
  }
}
/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .sidepane-details__lwt-container {
    flex-direction: column;
    align-items: start;
    height: auto;
    padding: 2.5rem;
    padding-left: 0;
  }

  .sidepane-details__lwt-text {
    padding-left: 0;
    font-size: 1.6rem;
  }
}
