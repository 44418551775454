.search-cards-modal {
  position: absolute;
  top: 200px;
  width: 100%;
  max-width: 320px;
  background: var(--white1);
  border-radius: 0 12px 12px 12px;
  overflow: visible;
}

.search-cards-modal__header,
.search-cards-modal__close-icon {
  display: none;
}

.search-cards-modal__content {
  position: relative;
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: visible;
}

.search-cards-modal__body {
  width: 100%;
}

.search-cards-modal__input {
  font-size: 14px;
}

.search-cards-modal__button {
  position: absolute;
  z-index: 1000;
  top: 185px;
  width: 112px;
  height: 40px;
  border-radius: 0 28px 28px 28px;
  background: transparent linear-gradient(180deg, #079be1 0%, #ab44f9 100%) 0%
    0% no-repeat padding-box;
}
.search-cards-modal__search__container {
  background: var(--white1);
  height: 40px;
  padding-left: 0;
  border-radius: 0px 12px 12px 12px;
}

.search-cards-modal__search__list {
  position: relative;
  z-index: 20;
  margin-top: 2px;
  background: var(--white1);
  border-radius: 12px;
  box-shadow: 0px 2px 2px var(--black4);
}

.search-cards-modal__search__list-label {
  font-size: 14px;
}

.search-cards-modal__search__input {
  width: 100%;
  height: 100%;
  padding-left: 10px;
  background: var(--white1);
  color: var(--grey1);
  border-radius: 0px 12px 12px 12px;
  font: normal normal normal 18px/28px Poppins;
}

.search-cards-modal__search__input::placeholder {
  color: var(--grey1);
}
