.successful-order__container {
  padding: 0 5rem;
  display: flex;
  flex-direction: column;
}

.successful-order__title {
  font-size: 1.4rem;
  line-height: 2.1rem;
  margin-top: 2.7rem;
  margin-bottom: 0.7rem;
  color: var(--buy-sell-modal-confirm-title-color);
  text-align: center;
}

.successful-order__xp-container {
  display: inline-flex;
  flex-direction: row;
  align-items: flex-end;
  margin: auto;
}

.successful-order__xp-image {
  height: 9.966rem;
  position: relative;
  padding-right: 1.765rem;
}

.successful-order__xp-text-container {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 1.2rem 2.6rem 1rem 5rem;
  background-color: var(--buy-sell-modal-xp-text-background);
  border-radius: 2rem;
  gap: 2rem;
  border: 0.1rem solid var(--purple1);
  margin-left: -6.87rem;
  margin-bottom: 1rem;
}

.successful-order__xp-label {
  font-size: 1rem;
  font-weight: 600;
}

.successful-order__xp-value {
  color: var(--buy-sell-modal-xp-number-color);
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-weight: 600;
}

@media (max-width: 645px) {
  .successful-order__xp-text-container {
    gap: 1rem;
  }
  
  .successful-order__container {
    padding: 0 2rem;
  }
}
