.problem-logging-in-page__container {
  margin: auto;
  width: 57.6rem;
  min-height: 38.28rem;
  padding: 3.68rem 3.68rem 3.2rem 3.68rem;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0.64rem 1.92rem #00000066;
  border-radius: 0px 2.88rem 2.88rem 2.88rem;
  background: #fafcff;
  align-items: center;
}

.problem-logging-in-page__logo-container {
  width: 7.68rem;
  height: 7.04rem;
}

.problem-logging-in-page__logo {
  width: 7.68rem;
  height: 7.04rem;
}

.problem-logging-in-page__header-text {
  text-align: center;
  line-height: 3.12rem;
  margin-top: 1.92rem;
}

.problem-logging-in-page__footer {
  margin: 0;
  padding: 0 2.24rem;
}

.problem-logging-in-page__link {
  font-size: 0.96rem;
}

.problem-logging-in-page__login {
  width: 100%;
  text-align: center;
  text-decoration: underline;
  margin-top: 0.8rem;
}

.problem-logging-in-page__login:hover {
  color: #373946;
}

/* MEDIA QUERIES */

/* Extra Small Devices, Phones */
@media only screen and (max-width: 576px) {
  .problem-logging-in-page__container {
    width: 100%;
    min-height: auto;
    border-radius: 0px;
    padding: 2.08rem 2.08rem 1.6rem 2.08rem;
  }
}
