.settings-page__content .kyc-settings .kyc-settings__header div {
  color: var(--settings-font-primary);
}
.settings-page__content
  .kyc-settings--star
  .kyc-level--selected
  .custom-icon--star {
  display: none;
}
.settings-page__content .kyc-settings .kyc-level .kyc-level__footer--verified {
  color: var(--purple1);
}
.settings-page__content .kyc-settings .kyc-level {
  border: 1px solid var(--grey6);
  border-radius: 12px;
}
.settings-page__content .kyc-settings .kyc-level--selected {
  border-width: 2px;
}
.settings-page__content .kyc-settings .kyc-level .kyc-level__header-title,
.settings-page__content .kyc-settings .kyc-level .kyc-level__body {
  opacity: 0.7;
}
.settings-page__content .kyc-settings .kyc-level .kyc-level__header-title {
  font-weight: 600;
}
.settings-page__content
  .kyc-settings
  .kyc-level--selected
  .kyc-level__header-title,
.settings-page__content .kyc-settings .kyc-level--selected .kyc-level__body {
  opacity: 1;
}
.settings-page__content .kyc-settings .kyc-level .kyc-level__body {
  border-width: 1px 0;
  border-color: var(--grey6);
}
.settings-page__content
  .kyc-settings
  .kyc-level
  .kyc-level__body
  .kyc-level__sub-title {
  font-weight: bold;
}
.settings-page__content .kyc-settings .kyc-level .kyc-level__header-title {
  background: unset;
}
.settings-page__content
  .kyc-settings
  .kyc-level
  .kyc-level__footer--can-increase
  .kyc-level__change__btn--general {
  background-color: var(--blue2);
  color: var(--white1);
  border: unset;
}
