.liquid-pagination {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.liquid-pagination__container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  min-height: 40px;
  line-height: 40px;
  padding: 0 10px;
  border: 1px solid var(--pagination-border-color);
  background-color: var(--pagination-background-color);
}

.liquid-pagination__text {
  color: var(--pagination-next-prev-color);
  user-select: none;
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 8px;
  line-height: 12px;
}

.liquid-pagination__text--disabled {
  cursor: not-allowed;
  user-select: none;
}

.liquid-pagination__text:hover {
  color: var(--pagination-next-prev-color);
}

.liquid-pagination__text--disabled:hover,
.liquid-pagination__text--disabled {
  opacity: 0.5;
}

.liquid-pagination__page {
  color: var(--pagination-page-number-color);
  padding: 6px 7.5px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.liquid-pagination__page:hover {
  color: var(--component__color-primary);
}

.liquid-pagination__page--active,
.liquid-pagination__page--active:hover {
  color: var(--pagination-page-number-color);
}

.liquid-pagination__pages {
  line-height: 1rem;
  user-select: none;
  margin: 0 22.5px;
}

.liquid-pagination__text:last-child {
  border-left: none;
}

.liquid-pagination__arrow {
  margin: 0 10px;
}

.liquid-pagination__text:first-child {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.liquid-pagination__text:last-child {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.liquid-pagination__previous,
.liquid-pagination__next {
  display: flex;
  align-items: center;
}

.ap-icon--caretNext,
.ap-icon--caretPrevious {
  height: 12px;
}

.order-history-table-pagination__container {
  min-height: unset;
  border-bottom-left-radius: 16px !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
}

@media only screen and (max-width: 1208px) {
  .order-history-table-pagination__container {
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
  }
}
