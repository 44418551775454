.user-settings-page {
  background: var(--settings__body-bg-color);
  margin: var(--settings__body-margin);
  padding: var(--settings__body-padding);
  height: var(--settings__body-height);
}

.user-settings-page__container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 32px;
}

.user-settings-page__row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 32px;
  width: var(--settings__profile-and-security-row-width);
  flex: 1 0 auto;
}

.theme-confirm__lwt-container {
  min-height: 2.6rem;
  height: auto;
  padding: 5px 0;
}

.user-settings-page__empty-item {
  flex: 1;
  margin: 16px;
  padding: 25.6px 25.6px 0;
}

.profile-and-security-layout__layout-template {
  width: 408px;
  height: 344px;
  border-radius: 8px;
  margin-bottom: 16px;
}

.profile-and-security-layout__layout-template--sm {
  height: 216px;
}

.profile-and-security-widget__body
  .settings-enable-two-factor-auth__2fa-container {
  padding-top: 22.4px;
}

.profile-and-security-widget__body .reset-password-form,
.profile-and-security-widget__body--sm .reset-password-form {
  padding: 14.4px 0;
}

.profile-and-security-widget__layout-template {
  border-radius: var(--settings__card-border-radius-size);
  padding: 25.6px;
  background: var(--settings-widget-background) 0% 0% no-repeat padding-box;
  /* margin: 20px; */
  flex: 1;
}

.profile-and-security-widget__layout-template
  .retail-profile-form
  .retail-profile-form__container
  .user-profile-form-static__row:last-child
  > div {
  padding-bottom: 0;
}

.profile-and-security-widget__header {
  display: inline-flex;
  flex-basis: auto;
  padding-bottom: 19.2px;
  justify-content: space-between;
  border-bottom: 1px solid rgba(112, 112, 112, 0.25);
  background: var(--settings-widget-background) 0% 0% no-repeat padding-box;
  padding-left: 0;
}

.profile-and-security-widget__header > .profile-and-security-widget__header {
  border-bottom: none;
  padding: 0;
}

.profile-and-security-widget__header-text {
  /* var(--settings__card-header-bg-color); */
  font-size: 19.2px;
  line-height: 25.6px;
  font-weight: 600;
  color: var(--settings-font-primary);
  letter-spacing: 0;
}
.profile-and-security-widget__body div[class$='-static__row'],
.profile-and-security-widget__body
  div[class^='theme-settings']
  div[class$='__row'] {
  border-bottom: none;
}
.profile-and-security-widget__body .region-settings {
  height: auto;
}

.profile-and-security-widget__body div[class$='-static__row']:first-of-type,
.profile-and-security-widget__body
  div[class^='theme-settings']
  div[class$='__row'],
.profile-and-security-widget__body .region-settings__row,
.profile-and-security-widget__body
  .reset-password-form
  .reset-password-form__text-info,
.profile-and-security-widget__body--sm
  .reset-password-form
  .reset-password-form__text-info {
  border-bottom: none;
  border-bottom: 1px solid rgba(112, 112, 112, 0.25);
  font-size: 12.8px;
  line-height: 19.2px;
  letter-spacing: 0px;
  color: var(--settings-font-primary);
}

.profile-and-security-widget__body
  div[class$='__button-container']
  button[class$='__btn--general'] {
  height: unset;
  margin: 0;
  background: var(--blue2) 0% 0% no-repeat padding-box;
  border-radius: 0px 22.4px 22.4px 22.4px;
  opacity: 1;
}
.profile-and-security-widget__body div[class$='__button-container'] {
  margin-top: 20px;
}
.profile-and-security-widget__body div[class$='__button-container'] button {
  padding: 4.8px 17.6px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--white1);
}

.profile-and-security-widget__body
  div[class$='__lwt-container']
  label[class$='__lwt-label'] {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0px;
  color: var(--settings-font-primary);
  opacity: 1;
}
.profile-and-security-widget__body div[class$='__lwt-container'] {
  padding: 22.4px 0;
}
.profile-and-security-widget__body
  div[class$='__lwt-container']
  span[class$='__lwt-text'] {
  text-align: left;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  letter-spacing: 0px;
  color: var(--settings-font-primary);
  opacity: 1;
}

.profile-and-security-widget__icon {
  margin-right: 8px;
  cursor: pointer;
  height: 11.2px;
}

.user-settings-page__page-header {
  background: var(--settings__body-header-bg-color);
  position: relative;
}

.user-settings-page__title {
  line-height: 32px;
  letter-spacing: 0px;
  opacity: 1;
  color: var(--settings-font-primary);
}
.user-settings-page__sub-title {
  line-height: 24px;
  color: var(--settings-font-primary);
  opacity: 1;
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 576px) {
  .user-settings-page__page-header {
    margin: unset;
  }
  .profile-and-security-widget__layout-template {
    flex: unset;
    padding: 19.2px;
  }
  .user-settings-page__row {
    gap: 19.2px;
  }

  .user-settings-page__container {
    gap: 19.2px;
  }
  .profile-and-security-widget__header {
    padding-bottom: 12.8px;
  }

  .profile-and-security-widget__body
    .settings-enable-two-factor-auth__2fa-container {
    padding-top: 12.8px;
  }
  .profile-and-security-widget__body div[class$='__lwt-container'] {
    padding: 12.8px 0;
  }
  .profile-and-security-widget__header-text {
    font-size: 16px;
    line-height: 24px;
  }
  .profile-and-security-widget__body
    div[class$='__lwt-container']
    label[class$='__lwt-label'] {
    font-size: 12.8px;
  }
  .profile-and-security-widget__body
    div[class$='__lwt-container']
    span[class$='__lwt-text'] {
    font-size: 12.8px;
  }
  .profile-and-security-widget__body div[class$='__button-container'] {
    margin-top: 12.8px;
  }

  .profile-and-security-widget__body div[class$='-static__row']:first-of-type,
  .profile-and-security-widget__body
    div[class^='theme-settings']
    div[class$='__row'],
  .profile-and-security-widget__body .region-settings__row,
  .profile-and-security-widget__body
    .reset-password-form
    .reset-password-form__text-info,
  .profile-and-security-widget__body--sm
    .reset-password-form
    .reset-password-form__text-info {
    line-height: 24px;
  }
}
