.notification-center {
  position: relative;
  margin-right: 30px;
}

.notification-center__icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  background: linear-gradient(135deg, var(--purple1) 0%, var(--pink1) 100%);
  cursor: pointer;
  position: relative;
}

.notification-center__unseen-icon {
  display: flex;
  position: absolute;
  width: 12px;
  height: 12px;
  top: 0;
  right: 0;
}

.notification-center__unseen-icon svg:not(:root) {
  width: 12px;
  height: 12px;
}

.notification-center__icon,
.notification-center__icon svg:not(:root) {
  width: 20px;
  height: 25px;
}

.notification-center__no-notifications-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 26px;
  margin-bottom: 26px;
}

.notification-center__no-notifications-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: var(--notification-center-no-notifications-title);
}

.notification-center__no-notifications-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: var(--notification-center-no-notifications-font);
  padding-top: 10px;
}

.notification-center__notification-list-wrapper {
  width: 300px;
  border-radius: 10px;
  position: absolute;
  background: var(--white1);
  top: 55px;
}

.notification-center__notification-list-header {
  display: flex;
  align-items: center;
  padding-left: 20px;
  background: linear-gradient(135deg, var(--purple1) 0%, var(--pink1) 100%);
  height: 40px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: var(--white1);
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}

.notification-center__notification-list-icon {
  margin-right: 10px;
}

.notification-center__notification-list-icon,
.notification-center__notification-list-icon svg:not(:root) {
  width: 13px;
  height: 15px;
}

.notification-center__notification-list-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  background: var(--notification-center-list-background);
  border-radius: 0px 0px 10px 10px;
}

.notification-center__notification-row {
  width: 100%;
  height: 60px;
  border-radius: 10px;
  border: 0.5px solid var(--notification-center-row-border-color);
  background: var(--notification-center-row-background);
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.notification-center__notification-row-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: var(--notification-center-row-text-color);
  display: flex;
  gap: 10px;
}

.notification-center__notification-row-date {
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: var(--notification-center-row-date-color);
  text-align: right;
}

.notification-center__notification-list-view-all {
  height: 38px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: var(--notification-center-row-view-all-text-color);
  border: 1px solid var(--notification-center-row-view-all-border-color);
  background: var(--notification-center-row-view-all-background);
  border-radius: 10px;
  cursor: pointer;
}

.notification-center__notification-list-view-all:hover {
  border: 1px solid var(--notification-center-row-view-all-hover-border-color);
  background: var(--notification-center-row-view-all-hover-background);
  color: var(--notification-center-row-view-all-text-hover-color);
}

.notification-center__notification-count {
  width: 22px;
  height: 22px;
  margin-left: 8px;
  background: var(--purple8);
  border-radius: 10px;
  color: var(--white1);
  line-height: 22px;
  text-align: center;
}

.notification-center__notification-type {
  width: 16px;
  height: 16px;
  color: var(--notification-center-row-text-color);
}

.notification-center__notification-row--global {
  background: var(--notification-center-global-background);
}

.notification-center__notification-row--global .global-line {
  stroke: var(--notification-center-row-text-color);
}

.notification-center__notification-row--specific-user {
  background: var(--notification-center-specific-user-background);
}

.notification-center__notification-row--specific-user .specific-user-line {
  stroke: var(--notification-center-row-text-color);
}

.notification-center__notification-row--reminder {
  background: var(--notification-center-reminder-background);
}

.notification-center__notification-row--reminder .reminder-line {
  stroke: var(--notification-center-row-text-color);
}

.notification-center__notification-row--reward {
  background: var(--notification-center-reward-background);
}

.notification-center__notification-row--reward .reward-line {
  stroke: var(--notification-center-row-text-color);
}

@media (max-width: 800px) {
  .notification-center {
    margin-right: 0;
  }
  .notification-center__icon-wrapper {
    width: 25px;
    height: 25px;
  }
  .notification-center__icon,
  .notification-center__icon svg:not(:root) {
    height: 13.89px;
    width: 11.11px;
  }
  .notification-center__notification-list-wrapper {
    left: -235px;
  }

  .notification-center__unseen-icon {
    width: 7px;
    height: 7px;
    top: 0;
    right: 0;
  }

  .notification-center__unseen-icon svg:not(:root) {
    width: 7px;
    height: 7px;
  }
}

@media (max-width: 360px) {
  .notification-center__notification-list-wrapper {
    left: -195px;
  }
}
