#_checkbox {
  display: none;
}

.custom-checkbox__checkbox {
  display: none;
}

.custom-checkbox {
  display: flex;
  cursor: pointer;
}

.custom-checkbox__children {
  margin-left: 25px;
}
.custom-checkbox__input {
  transform: scale(0.25);
}
.custom-checkbox label {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  width: 60px;
  height: 60px;
  margin: 0 auto;
  background-color: var(--white1);
  transform: translateY(-50%);
  border: 5px solid var(--purple1);
  border-radius: 3px;
  cursor: pointer;
  transition: 0.2s ease all;
  overflow: hidden;
  z-index: 1;
}

.custom-checkbox label:hover {
  border: 8px solid var(--purple1);
}

.custom-checkbox label:active {
  transform: translateY(-50%) scale(0.9);
}

#tick_mark {
  position: absolute;
  top: -1px;
  right: 0;
  left: 0;
  width: 35px;
  height: 35px;
  margin: 0 auto;
  margin-left: 6px;
  transform: rotateZ(-45deg);
}

#tick_mark:before,
#tick_mark:after {
  content: '';
  position: absolute;
  background-color: #fff;
  border-radius: 2px;
  opacity: 0;
  transition: 0.2s ease transform, 0.2s ease opacity;
}

#tick_mark:before {
  left: 0;
  bottom: 0;
  width: 10px;
  height: 25px;
  transform: translateY(-68px);
}

#tick_mark:after {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 10px;
  transform: translateX(78px);
}

#_checkbox:checked + label {
  background-color: var(--purple1);
}

#_checkbox:checked + label:before {
  width: 0;
  height: 0;
}

#_checkbox:checked + label #tick_mark:before,
#_checkbox:checked + label #tick_mark:after {
  transform: translate(0);
  opacity: 1;
}

.custom-checkbox__checkbox:checked + label {
  background-color: var(--purple1);
}

.custom-checkbox__checkbox:checked + label:before {
  width: 0;
  height: 0;
}

.custom-checkbox__checkbox:checked + label #tick_mark:before,
.custom-checkbox__checkbox:checked + label #tick_mark:after {
  transform: translate(0);
  opacity: 1;
}
