.card-offerings {
  position: relative;
  border-radius: 20px;
  height: 100%;
  overflow: hidden;
}

.card-offerings__offering-wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.card-offerings__sold-out-banner {
  position: absolute;
  right: 0;
  z-index: 100;
  pointer-events: none;
  height: 300px;
}

.card-offerings__noAssets {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--black2);
  background: var(--white2);
  height: calc(100% - 40px);
  min-height: 311.2px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.card-offerings__no-offerings-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #4a378e;
  height: calc(100% - 40px);
  min-height: 311.2px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  position: relative;
  overflow: hidden;
}

.card-offerings__no-offerings-background {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
}

.card-offerings__no-offerings-text {
  z-index: 3;
  max-width: 600px;
}

.card-offerings__backgroundWrapper--image {
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.card-offerings__backgroundImageFaded {
  opacity: 0.1;
}

.card-offerings__backgroundWrapper {
  height: 100%;
  overflow: hidden;
  width: 80%;
  position: absolute;
  top: 0;
}

.card-offerings__cardCoverWrapper {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}

.card-offerings__cardCover {
  height: 85%;
  top: 48px;
  position: absolute;
  right: 24px;
}

.card-offerings__cardImage {
  height: 100%;
  position: absolute;
  right: 0;
  opacity: var(--opacity);
}

.card-offerings__content {
  height: 100%;
  position: relative;
  z-index: 5;
  padding: 20px 15px 30px 40px;
  padding-bottom: 0px;
  color: var(--white1);
  display: flex;
  flex-direction: column;
}

.card-offerings__productName {
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
  margin-bottom: 10px;
  width: 65%;
}

@media screen and (min-width: 576px) {
  /* without disabling autoprefixer -webkit-box-orient will get removed
  box-orient itself is required to make line-clamp work */
  .card-offerings__productName {
    /* autoprefixer: off */
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.card-offerings__productCollection {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  opacity: 0.89;
}

.card-offerings__rowContainer {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  margin-top: 40px;
  max-width: 45%;
}

.card-offerings__numberInfo {
  width: max-content;
  min-width: 140.5px;
  box-shadow: 0px 2px 12.8px var(--black5);
  border: 1px solid var(--currentOfferingTextColor);
  border-radius: 0px 20px 20px 20px;
  padding: 0 10px;
  background: transparent;
  text-align: center;
  height: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card-offerings__populationInfo-icon {
  top: 0px;
  align-self: flex-start;
  position: absolute;
  right: -25px;
  width: 15px;
  height: 15px;
}

.card-offerings__numberInfo--bigger {
  padding: 19.2px 12.8px;
}

.card-offerings__numberTitle {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  position: relative;
  color: var(--currentOfferingTextColor);
}

.card-offerings__numberText {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0px;
  color: var(--currentOfferingTextColor);
  text-transform: uppercase;
  margin-top: 10px;
}

.card-offerings__productDescription {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0px;
  color: var(--white1);
  max-width: 30%;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 32px;
  line-height: 16px;
  opacity: 0.88;
}

.card-offerings__button {
  width: 108px;
  height: 48px;
  text-align: center;
  font: normal normal 600 12px/18px Poppins;
  letter-spacing: 0px;
  position: relative;
  padding: 15.6px 9.6px;
  background: var(--blue2) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3.2px 6.4px var(--black4);
  border-radius: 0px 22.8px 22.8px 22.8px;
  color: var(--white1);
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.1s ease-in-out;
  margin-bottom: 16px;
}

.card-offerings__button:hover {
  transform: translateY(-3.2px);
  box-shadow: 0px 9.6px 12.8px var(--black7);
}

.card-offerings__button:active {
  background: var(--grey1) 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3.2px 6.4px var(--black6), 0px 3.2px 6.4px var(--black4);
}

.card-offerings__footerWrapper {
  display: grid;
  align-items: center;
  margin-top: 20px;
  gap: 20px 40px;
  width: 320px;
}

.card-offerings__view-details-button__button {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
}

.card-offerings__buy-button__button {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
}

.card-offerings__offerings-section .slick-slider {
  height: calc(100% - 40px);
}

.card-offerings__offerings-section .slick-list,
.card-offerings__offerings-section .slick-track,
.card-offerings__offerings-section .slick-slide>div,
.card-offerings__offering-slide {
  height: 100%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.card-offerings__offerings-section .slick-dots {
  bottom: 5px;
  pointer-events: none;
}

.card-offerings__offerings-section .slick-dots li {
  pointer-events: initial;
}

.card-offerings__offerings-section .slick-dots li button:before {
  font-size: 5px;
  color: var(--pink2);
  opacity: 0.5;
}

.card-offerings__offerings-section .slick-dots li.slick-active button:before {
  color: var(--pink2);
  opacity: 1;
}

.card-offerings__offerings-section .slick-prev {
  left: 15px;
  z-index: 10;
}

.card-offerings__offerings-section .slick-next {
  right: 15px;
  z-index: 10;
}

@media (max-width: 1280px) {
  .card-offerings__content {
    padding: 15px 15px 20px 40px;
    display: block;
  }

  .card-offerings__cardCover {
    position: absolute;
    top: 40px;
    width: 100vw;
    overflow: hidden;
    right: 16px;
  }

  .card-offerings__cardImage {
    position: absolute;
    top: 2.4px;
  }
}

@media (max-width: 576px) {
  .card-offerings__no-offerings-wrapper {
    min-height: 385px;
  }
  .card-offerings__noAssets {
    height: 343px;
  }

  .card-offerings__offerings-section .slick-list,
  .card-offerings__offerings-section .slick-track,
  .card-offerings__offerings-section .slick-slide>div,
  .card-offerings__offering-slide {
    height: auto;
  }

  .card-offerings__offerings-section .slick-slider {
    height: auto;
  }

  .card-offerings__offerings-section .slick-dots {
    bottom: 0;
  }

  .card-offerings__sold-out-banner {
    height: 250px;
  }

  .card-offerings__content {
    padding: 20px;
    display: flex;
  }

  .card-offerings__name-collection {
    margin-bottom: 9.6px;
  }

  .card-offerings__productName {
    font-size: 24px;
    line-height: 36px;
    font-weight: 600;
    margin-bottom: 1px;
  }

  .card-offerings__productCollection {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    opacity: 0.89;
  }

  .card-offerings__rowContainer {
    flex-direction: column;
    gap: 10px;
    max-width: auto;
    justify-content: flex-start;
    margin-top: 20px;
  }

  .card-offerings__numberInfo {
    width: 200px;
    height: 41px;
    border-radius: 0 20px 20px 20px;
    background-color: var(--purple8);
    padding: 0;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .card-offerings__populationInfo-icon {
    left: 66px;
    right: 0;
  }

  .card-offerings__numberTitle {
    font: normal normal 400 9.6px/16px Poppins;
    display: flex;
    align-items: center;
    max-width: 56px;
    text-align: left;
  }

  .card-offerings__numberText {
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin-top: 0;
  }

  .card-offerings__productDescription {
    width: 200px;
    min-width: 200px;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    max-height: unset;
    line-height: unset;
    margin-bottom: 9.6px;
  }

  .card-offerings__favoriteIconWrapper {
    width: 32px;
    height: 32px;
  }

  .card-offerings__cardCoverWrapper {
    width: 100%;
    right: 0;
  }

  .card-offerings__cardImage {
    height: 212px;
    position: absolute;
    right: 8px !important;
    top: 83.2px;
    max-width: 46.7680608%;
    object-fit: contain;
  }

  .card-offerings__cardCover {
    height: 100%;
    position: absolute;
    top: 0;
    width: 100vw;
    overflow: hidden;
    right: -16px;
  }

  .card-offerings__footerWrapper {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    justify-content: space-between;
    gap: 10px;
  }

  .card-offerings__fav-button__button {
    border: none;
  }

  .card-offerings__view-details-button__button {
    display: none;
  }

  .card-offerings__no-offerings-text {
    max-width: 100%;
  }

  @-moz-document url-prefix() {
    .card-offerings__cardImage {
      right: -59.2px;
    }
  }
}

@media (max-width: 360px) {
  .card-offerings__fav-button__button {
    font-size: 10px;
  }
}
