.confirm-remove-from-watchlist-modal__overlay {
  justify-content: unset;
  align-items: unset;
  padding: 25px;
  overflow-y: auto;

}

.confirm-remove-from-watchlist-modal {
  background: var(--confirm-remove-from-watchlist-modal-bg-color);
  width: 400px;
  border-radius: 20px;
  max-height: unset;
  margin: auto;
}

.confirm-remove-from-watchlist-modal__content {
  overflow: hidden;
  padding: 20px 40px 40px;
}

.confirm-remove-from-watchlist-modal__header {
  padding: 10px 25px;
  background: var(--purple8);
  color: var(--white1);
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
  text-transform: capitalize;
}

.confirm-remove-from-watchlist-modal__text {
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 30px;
}

.confirm-remove-from-watchlist-modal__buttons-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  column-gap: 20px;
  row-gap: 10px;
}

.confirm-remove-from-watchlist-modal__btn {
  flex: 1 0;
  flex-basis: auto;
  max-width: 150px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  color: var(--confirm-remove-from-watchlist-modal-btn-color);
  border: 3px solid var(--purple8);
  border-radius: 999px;
  padding: 6.5px 20px;
  transition: none;
}

.confirm-remove-from-watchlist-modal__btn:hover {
  background: var(--purple8);
  color: var(--white1);
}

.confirm-remove-from-watchlist-modal__btn--cancel {
  border-color: var(--confirm-remove-from-watchlist-modal-cancel-border-color);
}

.confirm-remove-from-watchlist-modal__btn--cancel:hover {
  background: var(--confirm-remove-from-watchlist-modal-cancel-border-color);
  color: var(--confirm-remove-from-watchlist-modal-cancel-color-hover)
}

@media (max-width: 576px) {
  .confirm-remove-from-watchlist-modal__content {
    padding: 20px 20px 40px;
  }
}
