.standalone-layout-custom__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: hidden;
  padding: 125px 0 100px 0;
  background-color: var(--black2);
}

.standalone-layout-custom__bg-image {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  bottom: 0;
  right: 0;
}

.standalone-layout-custom__header {
  height: 100px;
  width: 100%;
  box-shadow: 0px 6.4px 12.8px #00000080;
  background-color: #000f26;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 5;
}

.standalone-layout-custom__go-back-container {
  display: flex;
  margin-left: 70px;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #ffffff;
  white-space: nowrap;
}

.standalone-layout-custom__go-back-icon {
  width: 24px;
  height: 24px;
  margin-right: 15px;
}

.standalone-layout-custom__links-wrapper {
  height: 100%;
  display: flex;
  margin-left: 100px;
  align-items: center;
  justify-content: space-between;
  gap: 80px;
}

.standalone-layout-custom__link {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  cursor: pointer;
  color: var(--white1);
}

.standalone-layout-custom__footer {
  height: 90.4px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #000f26;
  position: fixed;
  bottom: 0;
  z-index: 2;
}

.standalone-layout-custom__footer-icons {
  display: flex;
  gap: 12.8px;
}

.standalone-layout-custom__social-icon {
  width: 28.8px !important;
  height: 28.8px;
  cursor: pointer;
}

.standalone-layout-custom__social-icon svg:not(:root) {
  width: 40px;
  height: 30px;
}

.standalone-layout-custom__social-icon:hover .a {
  fill: #825af6;
}

.standalone-layout-custom__social-icon:hover .b {
  fill: #ffffffe6;
}

.standalone-layout-custom__footer-email {
  margin-right: 100px;
  margin-left: 20px;
  color: rgba(255, 255, 255, 0.75);
  white-space: nowrap;
}

.standalone-layout-custom__login {
  margin-right: 70px;
  width: 120px;
  height: 40px;
  border-radius: 20px;
  background: var(--purple9);
  border: 1px solid var(--purple11);
  color: #ffffff;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.04em;
}

.standalone-layout-custom__login:hover,
.standalone-layout-custom__login:focus {
  background: var(--purple11);
  color: var(--purple9);
}
.standalone-layout-custom__sign-up {
  margin-right: 70px;
  width: 120px;
  height: 40px;
  border-radius: 20px;
  background: linear-gradient(131.67deg, #3f8cff 0%, #825af6 100%);
  border: 1px solid #000f26;
  color: #ffffff;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.04em;
}

.standalone-layout-custom__sign-up:hover,
.standalone-layout-custom__sign-up:focus {
  background: linear-gradient(
    rgba(133, 79, 232, 255) 50%,
    rgba(141, 74, 233, 255) 100%
  );
}

.standalone-layout-custom__hamburger {
  display: none;
}

.standalone-layout-custom__hamburger-menu {
  position: absolute;
  top: 96px;
  left: 0;
  right: 0;
  background-color: #191b20;
  padding-bottom: 12px;
}

.standalone-layout-custom__hamburger-link,
.standalone-layout-custom__hamburger-sign-up {
  margin-left: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.standalone-layout-custom__content {
  padding: 36px 0;
  overflow-y: auto;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.standalone-layout-custom__content::-webkit-scrollbar {
  display: none;
}

.standalone-layout-custom__logan-wrapper {
  width: 896px;
  height: 238px;
  margin: 0 auto;
  margin-top: -36px;
  margin-bottom: 36px;
  border-radius: 20px;
  overflow: hidden;
}

.standalone-layout-custom__logan-img {
  width: 896px;
  height: 238px;
  object-fit: cover;
}

/* MEDIA QUERIES */
@media only screen and (max-width: 1200px) {
  .standalone-layout-custom__links-wrapper {
    gap: 60px;
  }
}

@media only screen and (max-width: 1100px) {
  .standalone-layout-custom__links-wrapper {
    margin-left: 70px;
    gap: 50px;
  }
}

@media only screen and (max-width: 950px) {
  .standalone-layout-custom__link {
    font-size: 14px;
  }

  .standalone-layout-custom__links-wrapper {
    gap: 30px;
  }
  .standalone-layout-custom__footer-email {
    margin-right: 50px;
  }
}

@media only screen and (max-width: 900px) {
  .standalone-layout-custom__logan-wrapper {
    width: 390px;
    max-width: 390px;
    height: 113.63px;
    margin-top: 0;
    margin-bottom: 30px;
  }
  .standalone-layout-custom__logan-img {
    width: 390px;
    max-width: 390px;
    height: 113.63px;
    object-fit: cover;
  }
  .standalone-layout-custom__content.standalone-layout-custom__content--with-banner {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 800px) {
  .standalone-layout-custom__link {
    font-size: 12px;
  }

  .standalone-layout-custom__links-wrapper {
    margin-left: 40px;
    gap: 20px;
  }
}

@media only screen and (max-width: 650px) {
  .standalone-layout-custom__footer-email {
    margin-right: 20px;
  }

  .standalone-layout-custom__links-wrapper {
    margin-left: 20px;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 576px) {
  .standalone-layout-custom__sign-up,
  .standalone-layout-custom__login {
    display: none;
  }
  .standalone-layout-custom__wrapper {
    position: relative;
    padding: 0;
    margin-top: 100px;
    overflow-y: unset;
    justify-content: unset;
    max-height: unset;
    min-height: auto;
    height: auto;
    background-color: var(--black2);
  }

  .standalone-layout-custom__bg-image {
    z-index: 1;
  }

  .standalone-layout-custom__content {
    margin-top: 100px;
    overflow-y: unset;
    padding: 0;
    margin-bottom: 40px;
    z-index: 2;
  }
  .standalone-layout-custom__logo {
    margin: auto;
    position: relative;
    margin-top: 16px;
    margin-bottom: 0;
  }
  .standalone-layout-custom__wordmark-image {
    display: none;
  }

  .standalone-layout-custom__footer {
    position: unset;
    height: auto;
    flex-direction: column;
  }

  .standalone-layout-custom__links-wrapper {
    margin: 10px 0 30px 0;
    flex-direction: column;
  }

  .standalone-layout-custom__link {
    height: 30px;
    line-height: 30px;
  }

  .standalone-layout-custom__footer-email {
    margin: 0 0 15px 0;
  }

  .standalone-layout-custom__hamburger {
    display: flex;
  }

  .standalone-layout-custom__go-back-container {
    margin-left: 25px;
  }

  .standalone-layout-custom__hamburger {
    margin-right: 25px;
  }
}

@media only screen and (max-width: 414px) {
  .standalone-layout-custom__logan-wrapper {
    padding: 0 19px;
    width: 100%;
    max-width: 100%;
  }

  .standalone-layout-custom__logan-img {
    width: 100%;
    object-fit: contain;
  }
}
