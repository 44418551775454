.settings-page__content
  .trade-report-page
  .trade-report-page__activity-report
  .trade-report-page__activity-report-header:before {
  display: none;
}
.settings-page__content .trade-report-page {
  max-width: unset;
}
.settings-page__content
  .trade-report-page
  .trade-report-page__activity-report-buttons {
  width: unset;
  max-width: unset;
}
.settings-page__content
  .trade-report-page
  .trade-report-page__activity-report-buttons
  .activity-reporting-buttons__btn--general {
  width: unset;
  padding: 0 80px;
  background-color: var(--purple1);
  color: var(--white1);
  letter-spacing: 0;
}
.settings-page__content
  .trade-report-page
  .trade-report-page__activity-report
  .trade-report-page__activity-report-header
  > div {
  color: var(--settings-font-primary);
  letter-spacing: 0;
}
.settings-page__content
  .trade-report-page
  .trade-report-page__activity-report
  .trade-report-page__activity-report-header-title,
.settings-page__content .trade-report-page .trade-report-page__report-header {
  font-weight: 600;
}
.settings-page__content
  .trade-report-page
  .trade-report-page__cyclical-report
  .trade-report-page__report-header,
.settings-page__content
  .trade-report-page
  .trade-report-page__singal-report
  .trade-report-page__report-header {
  border-bottom: unset;
  padding-left: unset;
  padding-right: unset;
}
.trade-report-page__report-header {
  padding-left: 10px !important;
}
.settings-page__content
  .trade-report-page
  .trade-report-page__cyclical-report
  .flex-table__header
  .schedule-report-list__header-column--type,
.settings-page__content
  .trade-report-page
  .trade-report-page__singal-report
  .flex-table__header
  .download-report__header-column--type {
  padding-left: unset;
}
.settings-page__content
  .trade-report-page
  .trade-report-page__cyclical-report
  .flex-table__header
  .flex-table__column,
.settings-page__content
  .trade-report-page
  .trade-report-page__singal-report
  .flex-table__header
  .flex-table__column {
  font-weight: 600;
}
.settings-page__content
  .trade-report-page
  .trade-report-page__cyclical-report
  .schedule-report-list__body,
.settings-page__content
  .trade-report-page
  .trade-report-page__cyclical-report
  .schedule-report-list__body
  .flex-table__header--background,
.settings-page__content
  .trade-report-page
  .trade-report-page__singal-report
  .download-report__container
  .download-report__body,
.settings-page__content
  .trade-report-page
  .trade-report-page__singal-report
  .download-report__container
  .download-report__body
  .flex-table__header--background {
  background-color: unset;
}
.settings-page__content
  .trade-report-page
  .trade-report-page__singal-report
  .download-report__container
  .download-report__refresh-reports
  .download-report__refresh-reports__icon--refresh {
  color: var(--purple1);
}
.settings-page__content
  .trade-report-page
  .trade-report-page__singal-report
  .flex-table__body
  .flex-table__row
  .flex-table__column
  .flex-table__empty-icon,
.settings-page__content
  .trade-report-page
  .trade-report-page__cyclical-report
  .flex-table__body
  .flex-table__row
  .flex-table__column
  .flex-table__empty-icon {
  display: none;
}
