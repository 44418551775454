.upcoming-offering-card {
  display: flex;
  flex-direction: column;
  width: calc(50% - 16px);
  background: var(--upcoming-offerings-card-background) 0% 0% no-repeat
    padding-box;
  border-radius: 0 25.6px 25.6px 25.6px;
}

.upcoming-offering-card--sold-out {
  position: relative;
  overflow: hidden;
}

.upcoming-offering-card__timer-container {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px 20px;
  background: linear-gradient(135deg, var(--purple1) 0%, var(--pink1) 100%);
  border-radius: 0 25.6px 0px 0px;
}

.upcoming-offering-card__timer {
  color: var(--white1);
  font-size: 14px;
  line-height: 1.5;
  font-weight: 600;
}

.upcoming-offering-card__time-unit {
  font-size: 12px;
  font-weight: 400;
}

.upcoming-offering-card__info-icon {
  height: 15px;
  width: 15px;
}

.upcoming-offering-card__info-icon svg:not(:root) {
  height: 15px;
  width: 15px;
}

.upcoming-offering-card__info-container .icon-symbol,
.upcoming-offering-card__info-container .icon-dot {
  fill: var(--white1);
}

.upcoming-offering-card__info-container .icon-border {
  stroke: var(--white1);
}

.upcoming-offering-card__info-container:hover .icon-symbol,
.upcoming-offering-card__info-container:hover .icon-dot,
.upcoming-offering-card__info-container:focus-within .icon-symbol,
.upcoming-offering-card__info-container:focus-within .icon-dot {
  fill: var(--grey2);
}

.upcoming-offering-card__info-container:hover .icon-border,
.upcoming-offering-card__info-container:focus-within .icon-border {
  stroke: var(--grey2);
}

.upcoming-offering-card__info-container {
  position: relative;
  cursor: pointer;
}

.upcoming-offering-card__info-text {
  display: none;
  position: absolute;
  z-index: 101;
  top: -10px;
  left: -10px;
  width: 200px;
  gap: 14px;
  background-color: var(--purple11);
  padding: 10px;
  border-radius: 20px;
  color: var(--grey2);
  font-size: 10px;
  line-height: 1.5;
}

.upcoming-offering-card__info-container:hover
  .upcoming-offering-card__info-text,
.upcoming-offering-card__info-container:focus-within
  .upcoming-offering-card__info-text {
  display: flex;
}

.upcoming-offering-card__sold-out-banner {
  position: absolute;
  right: 0;
  z-index: 100;
  pointer-events: none;
  height: 250px;
  top: 2px;
}

.upcoming-offering-card__card-content {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
  overflow: hidden;
}

.upcoming-offering-card__card-content-main {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.upcoming-offering-card__text-content {
  flex-basis: 55%;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.upcoming-offering-card__image-content {
  flex-basis: 45%;
  min-width: 0;
  align-self: center;
  perspective: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upcoming-offering-card__title {
  color: var(--upcoming-offerings-card-title);
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.upcoming-offering-card__rarity-text {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: var(--blue6);
}

.upcoming-offering-card__button--add-to-favorite {
  flex-grow: 1;
}

.upcoming-offering-card__buttons-wrapper {
  display: flex;
  gap: 20px;
}

.upcoming-offering-card__image-shadow {
  width: var(--width);
  height: 12px;
  background-color: var(--black7);
  border-radius: 50%;
  filter: blur(8px);
  margin-top: 0;
  transform: scale(0.9) rotateX(13deg) rotateY(12deg) rotateZ(-3deg);
  transition: all 1s ease;
}

.upcoming-offering-card__card-image {
  width: 100%;
  max-height: 200px;
  transform: scale(0.8) rotateX(13deg) rotateY(12deg) rotateZ(-3deg);
  transition: all 1s ease;
  object-fit: contain;
}

.upcoming-offering-card__image-content:hover
  .upcoming-offering-card__image-shadow {
  transform: scale(0.9) rotateX(13deg) rotateY(-12deg) rotateZ(0deg);
}

.upcoming-offering-card__image-content:hover
  .upcoming-offering-card__card-image {
  transform: scale(0.9) rotateX(13deg) rotateY(-12deg) rotateZ(0deg);
}

@media (max-width: 916px) {
  .upcoming-offering-card {
    width: 100%;
  }
}

@media (max-width: 800px) {
  .upcoming-offering-card__info-text {
    align-items: flex-start;
    left: -10px;
  }
}

@media (max-width: 576px) {
  .upcoming-offering-card__image-content {
    align-self: flex-start;
  }

  .upcoming-offering-card__image-shadow {
    margin-top: 0;
  }

  .upcoming-offering-card__buttons-wrapper {
    justify-content: space-between;
  }
}
