.disable-2fa-modal {
  padding-left: 0.6rem;
}

.disable-2fa-modal__text-item {
  color: var(--font__color-secondary);
  font-size: 1.6rem;
  margin-bottom: 2rem;
  width: 30rem;
}

.disable-2fa-modal__btn {
  width: 10.8rem;
}

.disable-2fa-modal__footer {
  border-top: 1px solid var(--border__color);
  padding-top: 2.6rem;
  padding-bottom: 0.6rem;
  margin-top: 2rem;
}
