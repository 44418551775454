.reset-password__container {
  align-items: center;
}

.reset-password__logo-container {
  width: 7.68rem;
  height: 7.04rem;
}

.reset-password__logo {
  width: 7.68rem;
  height: 7.04rem;
}

.reset-password__title-wrapper {
  margin-top: 1.92rem;
}

.reset-password__title {
  text-align: center;
  line-height: 3.12rem;
}

.reset-password__header {
  height: 5.44rem;
  border-top-left-radius: var(--standalone-form__border-radius);
  border-top-right-radius: var(--standalone-form__border-radius);
  border-bottom: 2px solid var(--standalone-form__header-border-color);
}

.reset-password__separator {
  margin: 0.72rem -2.24rem 2.4rem -2.24rem;
}

.reset-password__label {
  font-size: 1.12rem;
  letter-spacing: -0.5px;
}

.reset-password__form {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2.16rem;
}

.reset-password__input-wrapper {
  padding-bottom: 1.12rem;
  margin: 0;
}

.reset-password__input-box {
  width: 33.2rem;
}

.reset-password__input {
  background-color: #fafcff;
  color: #191b20;
  border: 2px solid #825af6;
  border-radius: 0px 2.88rem 2.88rem 2.88rem;
  height: 4.48rem;
  font-size: 1.6rem;
  line-height: 2.24rem;
  padding-left: 1.92rem;
}

.reset-password__info {
  margin-left: 15px;
  padding-top: 0.5rem;
}

.reset-password__input:-webkit-autofill,
.reset-password__input:-webkit-autofill:hover,
.reset-password__input:-webkit-autofill:focus,
.reset-password__input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 2.4rem white inset !important;
}

.reset-password__input:-webkit-autofill::first-line {
  font-size: 1.6rem;
  line-height: 2.24rem;
  font-family: 'Poppins';
}

.reset-password__input:hover,
.reset-password__input:focus {
  background-color: #fafcff;
  border: 2px solid #825af6;
}

.reset-password__input-box {
  flex-direction: column;
}

.reset-password__btn {
  min-width: 20rem;
  height: 3.84rem;
  border-radius: 0px 2.24rem 2.24rem 2.24rem;
  background: transparent linear-gradient(180deg, #079be1 0%, #a03eea 100%) 0%
    0% no-repeat padding-box;
  text-transform: uppercase;
  line-height: 2.24rem;
  box-shadow: 0px 0.56rem 2.4rem #00000012;
  margin-top: 0.8rem;
}

.reset-password__btn:hover,
.reset-password__btn:focus {
  background: linear-gradient(
    rgba(133, 79, 232, 255) 50%,
    rgba(141, 74, 233, 255) 100%
  );
}

.reset-password__btn:disabled,
.reset-password__btn:disabled:hover {
  background: transparent linear-gradient(180deg, #079be1 0%, #a03eea 100%) 0%
    0% no-repeat padding-box;
}

.reset-password__btn:disabled .reset-password__reset,
.reset-password__btn:disabled:hover .reset-password__reset {
  opacity: 0.5;
}

.reset-password__link {
  min-width: 20rem;
  height: 3.84rem;
  border-radius: 0px 2.24rem 2.24rem 2.24rem;
  background: transparent linear-gradient(180deg, #079be1 0%, #a03eea 100%) 0%
    0% no-repeat padding-box;
  text-transform: uppercase;
  line-height: 2.24rem;
  box-shadow: 0px 0.56rem 2.4rem #00000012;
  margin-top: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem 0px;
}

.reset-password__link:hover,
.reset-password__link:focus {
  background: linear-gradient(
    rgba(133, 79, 232, 255) 50%,
    rgba(141, 74, 233, 255) 100%
  );
}

.reset-password__message {
  margin: 3.6rem 0px;
}

.reset-password__message-text {
  text-align: center;
}

/* MEDIA QUERIES */

/* Extra Small Devices, Phones */
@media (max-width: 576px) {
  .reset-password__input {
    width: 24rem;
  }
}
