.market-place-asset-header {
  right: 0;
  padding: 40px 32px 26px 40px;
  margin: 0 40px;
  z-index: 3;

  background: var(--asset-header-background);
  color: var(--asset-header-font);
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  padding-left: 40px;
  padding-right: 32px;
}

.market-place-asset-header__icon-border {
  stroke: var(--asset-header-font);
}
.market-place-asset-header__icon-symbol,
.market-place-asset-header__icon-dot {
  fill: var(--asset-header-font);
}

.market-place-asset-header__wrapper {
  display: flex;
  flex-direction: column;
  width: auto;
}

.market-place-asset-header__asset-main-data {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.market-place-asset-header__asset-number {
  color: var(--purple7);
  font-size: 24px;
  font-weight: 600;
}

.market-place-asset-header__divider {
  width: 5px;
  height: 30px;
  border-radius: 5px;
  background-color: var(--pink2);
  margin: 0 20px;
  flex-shrink: 0;
}

.market-place-asset-header__asset-name {
  font-size: 24px;
  font-weight: 600;
}

.market-place-asset-header__asset-header-title {
  padding-bottom: 15px;
  padding-right: 25px;
  border-bottom: 2px solid rgba(112, 112, 112, 0.25);
  /* #707070; */
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
}

.market-place-asset-header__asset-header-title-buttons {
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: flex-start;
}

.market-place-asset-header__buttons-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 40px;
}

.market-place-asset-header__share-icon {
  cursor: pointer;
}

.market-place-asset-header__share-icon svg:not(:root) {
  height: 64px;
  width: 64px;
}

.market-place-asset-header__share-icon:hover .top-circle,
.market-place-asset-header__share-icon:hover .bottom-circle {
  fill: var(--pink1);
}

.market-place-asset-header__alert-me {
  box-shadow: 0px 2px 8px #00000029;
  border-radius: 0px 38px 38px 38px;
  width: 178px;
  height: 80px;
  background: var(--blue2);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.market-place-asset-header__asset-info-block {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding-left: 15px;
  gap: 50px;
}

.market-place-asset-header__asset-info-block-left {
  display: flex;
  flex-direction: row;
  padding-left: 15px;
  gap: 40px;
  align-items: center;
}

.market-place-asset-header__info {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.market-place-asset-header__switch {
  min-width: 132px;
  width: 197px;
  max-width: 197px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.market-place-asset-header__info-icon {
  z-index: 2;
  position: absolute;
}

.market-place-asset-header__info-key {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  margin-bottom: 5px;
}

.market-place-asset-header__info-value {
  font-size: 18px;
  line-height: 1.5;
  font-weight: 600;
}

.market-place-asset-header__buysell-block {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.market-place-asset-header .buttons__button--add-to-favorite {
  min-width: 45px;
  min-height: 45px;
}

.market-place-asset-header__hovering-container {
  height: 45px;
  width: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px;
  border-radius: 22.5px;
  transition: var(--transition__settings), padding-right 300ms ease;
}

.market-place-asset-header__hovering-container--favorite {
  cursor: pointer;
}

.market-place-asset-header__hovering-container--favorite:hover {
  padding-left: 26px;
}

.market-place-asset-header__hovering-container--share:hover {
  padding-left: 20px;
}

.market-place-asset-header__hovering-container:hover {
  width: auto;
  background: var(--asset-header-fav-share-background);
  padding-right: 16px;
}

.market-place-asset-header__hovering-container:hover
  > .market-place-asset-header__hovering-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-right: 20px;
}

.market-place-asset-header__hovering-content {
  display: none;
}

.market-place-asset-header__hovering-content--favorite {
  white-space: nowrap;
  font-size: 14px;
}

.market-place-asset-header__favorite-icon {
  height: 36px;
  width: 36px;
}

.market-place-asset-header__favorite-icon svg:not(:root) {
  height: 36px;
  width: 36px;
}

.market-place-asset-header__favorite-icon .b {
  fill: var(--purple8);
}

.market-place-asset-header__favorite-icon:hover .b {
  fill: var(--blue6);
}

.market-place-asset-header__share-icon {
  width: 26px;
  height: 26px;
}

.market-place-asset-header__share-icon svg:not(:root) {
  width: 26px;
  height: 26px;
}

.market-place-asset-header__social-icon {
  width: 22.21px;
  height: 22.21px;
  cursor: pointer;
}

.market-place-asset-header__social-icon svg:not(:root) {
  width: 22.21px;
  height: 22.21px;
}

.market-place-asset-header__social-icon .a {
  fill: #825af6;
}

.market-place-asset-header__social-icon .b {
  fill: #ffffffe6;
}

.market-place-asset-header__hovering-content--expanded {
  display: flex;
  gap: 20px;
}

.market-place-asset-header__top-owners-cross {
  height: 33px;
  width: 33px;
}

.market-place-asset-header__top-owners-cross svg:not(:root) {
  height: 33px;
  width: 33px;
}

.market-place-asset-header__top-owners-cross .cross-b {
  fill: var(--white1);
  stroke: var(--white1);
}

@media (max-width: 1315px) {
  .market-place-asset-header__buttons-block {
    flex-direction: column;
    gap: 20px;
    justify-content: center;
  }
}

@media (max-width: 1110px) {
  .market-place-asset-header__asset-info-block-left {
    flex-wrap: wrap;
    gap: 20px;
  }

  .market-place-asset-header__asset-info-block-left > * {
    flex: 1 0;
    flex-basis: calc(calc(100% - 20px) / 2);
  }

  .market-place-asset-header__asset-header-title-buttons {
    flex-direction: column;
    gap: 0px;
    align-items: flex-end;
  }
}

@media (max-width: 1060px) {
  .market-place-asset-header {
    padding-top: 26px;
  }
  .market-place-asset-header__switch {
    align-self: center;
  }
  .market-place-asset-header__asset-number,
  .market-place-asset-header__asset-name {
    font-size: 16px;
    line-height: 24px;
  }
  .market-place-asset-header__asset-name {
    word-break: break-word;
  }
  .market-place-asset-header__asset-header-title {
    padding-right: 0;
    padding-bottom: 10px;
  }
  .market-place-asset-header__info-value {
    font-size: 14px;
  }
  .market-place-asset-header__asset-info-block-left {
    flex-wrap: nowrap;
    padding: 0;
    gap: 0;
  }
  .market-place-asset-header__asset-info-block-left > * {
    flex-basis: auto;
  }

  .market-place-asset-header__asset-info-block {
    flex-direction: column;
    padding: 0;
    margin-top: 24px;
  }

  .market-place-asset-header__buttons-block {
    flex-direction: row;
    gap: 20px;
  }
}

@media (max-width: 900px) {
  .market-place-asset-header__top-owners-hovered {
    z-index: 10;
    left: 0px;
  }
}

@media (max-width: 700px) {
  .market-place-asset-header {
    padding: 40px 20px 26px 20px;
  }
  .market-place-asset-header__top-owners-hovered {
    left: -63px;
  }

  .market-place-asset-header__top-owners-table {
    width: 300px;
    padding: 13px 10px 15px 10px;
  }
}

@media (max-width: 576px) {
  .market-place-asset-header__asset-header-title {
    flex-direction: column;
    align-items: flex-start;
  }
  .market-place-asset-header__asset-header-title-buttons {
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
  .market-place-asset-header__top-owners-hovered-title {
    padding: 0;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 26px;
    padding-right: 16px;
  }
  .market-place-asset-header__top-owners-container {
    justify-content: flex-start;
  }
  .market-place-asset-header__asset-info-block {
    gap: 20px;
  }
  .market-place-asset-header {
    margin: 0 20px;
    padding-left: 25px;
    padding-top: 10px;
    padding-right: 16px;
    padding-bottom: 28px;
  }

  .market-place-asset-header__info-key {
    font-size: 8px;
  }

  .market-place-asset-header__info-value {
    font-size: 12px;
  }

  .market-place-asset-header__top-owners-hovered {
    left: -25px;
  }
  .market-place-asset-header__hovering-container {
    justify-content: space-evenly;
    background: var(--asset-header-fav-share-background);
    width: auto;
    padding-left: 16px;
    padding-right: 16px;
    height: 40px;
  }

  .market-place-asset-header__hovering-content--expanded {
    margin-right: 20px;
  }
  .market-place-asset-header__buttons-block {
    gap: 20px;
    justify-content: center;
    margin-top: 20px;
    flex-wrap: wrap;
  }
}

@media (max-width: 368px) {
  .market-place-asset-header__asset-main-data {
    flex-wrap: wrap;
  }

  .market-place-asset-header__asset-header-title-buttons {
    flex-direction: column;
    align-items: flex-start;
  }

  .market-place-asset-header__top-owners-table {
    width: 280px;
  }
}
