:root {
  /* PC == palette color */

  /* BASE COLORS */
  --pc__branding: 64, 80, 144;
  --pc__branding-gradient-start: 99, 127, 234;
  --pc__branding-gradient-end: 77, 105, 211;
  --pc__font-primary: 255, 255, 255;
  --pc__font-secondary: 149, 162, 191;
  --liquid-primary-font-color: var(--white1);
  --liquid-secondary-font-color: var(--pink1);
  --asset-details-text-color: var(--white1);
  --panel-bg-color: var(--black8);

  /* BACKGROUND COLORS (order=light-dark)*/
  --pc__background-primary: 57, 65, 84;
  --pc__background-secondary: 40, 46, 61;
  --pc__background-tertiary: 26, 32, 41;
  --pc__background-quaternary: 13, 16, 21;
  --liquid-background-primary: var(--black10);
  --liquid-background-secondary: var(--black8);

  /* ACCENT COLORS */
  --pc__accent-additive: 117, 130, 255;
  --pc__accent-subtractive: 255, 71, 102;
  --pc__accent-warning: 249, 133, 48;
  --pc__accent-success: 79, 203, 133;
  --pc__accent-danger: 255, 71, 71;
  --pc__accent-highlight: 117, 130, 255;

  /* TH = theme  */

  /* GENERAL */
  --th__component-box-shadow-color: 0, 0, 0, 0.21;
  --wordmark-image: url(../../images/WORDMARK-DARK.png);

  /* SETTINGS PAGES*/
  --th-settings__accent-bar-display: none;
  /* SETTINGS -- container */
  --th-settings__body-bg-color: rgb(var(--pc__background-secondary));
  --th-settings__body-max-width: 100%;
  --th-settings__body-height: 100%;
  --th-settings__body-margin: 0;
  --th-settings__body-padding: 0;
  /* SETTINGS -- container - header */
  --th-settings__body-header-bg-color: transparent;
  --th-settings__body-header-margin: 2rem;
  --th-settings__body-header-with-border-height: 7rem;
  /* SETTINGS -- container - header title */
  --th-settings__body-header-title-font-size: 2rem;
  --th-settings__body-header-title-font-color: rgb(var(--pc__font-primary));
  --th-settings__body-header-title-letter-spacing: 0.08rem;
  --th-settings__body-header-title-padding-bottom: 1rem;
  --th-settings__body-header-title-padding-left: 2.8rem;
  --th-settings__body-header-title-padding-right: 1.6rem;
  /* SETTINGS -- container - header sub title*/
  --th-settings__body-header-sub-title-font-size: 1.1rem;
  --th-settings__body-header-sub-title-font-color: rgb(
    var(--pc__font-secondary)
  );
  --th-settings__body-header-sub-title-letter-spacing: 0.05rem;
  --th-settings__body-header-sub-title-line-height: 1.5rem;
  /* SETTINGS -- card */
  --th-settings__card-box-shadow: 0 0.5rem 2rem 0 transparent;
  --th-settings__card-border-radius-size: 0px 32px 32px 32px;
  /* SETTINGS -- card - header*/
  --th-settings__card-header-bg-color: rgb(var(--pc__background-primary));
  --th-settings__card-header-accent-border: 2px solid transparent;
  --th-settings__card-header-padding: 1rem 2rem;
  --th-settings__card-header-title-font-size: 1.2rem;
  --th-settings__card-header-title-font-color: rgb(var(--pc__font-primary));
  --th-settings__card-header-title-letter-spacing: 0.02rem;
  /* SETTINGS PAGES --- SPECIFIC SETTINGS*/
  /* SETTINGS -- profile & security */
  --th-settings__profile-and-security-card-max-width: 50%;
  --th-settings__profile-and-security-card-margin-r: 0;
  --th-settings__profile-and-security-card-margin-b: 0;
  --th-settings__profile-and-security-row-width: 100%;
  --th-settings__profile-and-security-card-border-right: 1px solid
    rgb(var(--pc__background-tertiary));
  --th-settings__profile-and-security-card-border-bottom: 1px solid
    rgb(var(--pc__background-tertiary));
  /* SETTINGS -- kyc */
  --th-settings__kyc-cards-container-margin-l: 2rem;
  --th-settings__kyc-card-border-radius: 0.75rem;
  --th-settings__kyc-card-bg-color: rgb(var(--pc__background-secondary));
  --th-settings__kyc-card-container-border-color: rgba(
    var(--pc__font-secondary),
    0.4
  );
  --th-settings__kyc-card-container-border-color--selected: rgb(
    var(--pc__font-secondary)
  );
  --th-settings__kyc-card-container-border-size: 0.1rem;
  --th-settings__kyc-card-container-border-size--selected: 0.2rem;
  --th-settings__kyc-card-header-bg-color: transparent;
  --th-settings__kyc-card-body-top-border-color: rgba(
    var(--pc__font-secondary),
    0.4
  );
  --th-settings__kyc-card-body-top-border-size: var(
    --th-settings__kyc-card-container-border-size
  );
  --th-settings__kyc-card-body-bottom-border-color: rgba(
    var(--pc__font-secondary),
    0.4
  );
  --th-settings__kyc-card-body-bottom-border-size: var(
    --th-settings__kyc-card-container-border-size
  );
  --th-settings__kyc-card-footer-color--verified: rgb(
    var(--pc__accent-success)
  );
  /* SETTINGS -- kyc - star */
  --th-settings__kyc-card-star-display: none;
  --th-settings__kyc-card-star-bg-color: rgb(var(--pc__font-primary));
  --th-settings__kyc-card-star-fill-color: rgb(var(--pc__accent-additive));
  --th-settings__kyc-card-star-box-shadow: -0.5rem 0.5rem 4rem 0
    rgba(0, 0, 0, 0.5);
  /* SETTINGS -- trade reports*/
  --th-settings__trade-reports-create-new-header-display: none;
  --th-settings__trade-reports-create-new-actions-holder-padding: 0;
  --th-settings__trade-reports-create-new-actions-holder-flex-direction: column;
  --th-settings__trade-reports-create-new-actions-holder-border: none;
  --th-settings__trade-reports-create-new-actions-btn-width: 100%;
  --th-settings__trade-reports-create-new-actions-top-btn-margin-b: 2rem;
  --th-settings__trade-reports-create-new-actions-btn-font-color: rgb(
    var(--pc__font-primary)
  );
  --th-settings__trade-reports-create-new-actions-btn-bg-color: rgba(
    var(--pc__accent-additive),
    0.9
  );
  /* SETTINGS -- API keys*/
  --th-settings__api-keys-create-new-header-display: none;
  --th-settings__api-keys-create-new-actions-holder-margin: 0;
  --th-settings__api-keys-create-new-actions-holder-btn-width: 100%;
  --th-settings__api-keys-create-new-actions-holder-btn-border: none;
  --th-settings__api-keys-create-new-actions-documentation-btn-margin-t: 0;
  --th-settings__api-keys-create-new-actions-documentation-btn-justify-content: left;
  --th-settings__api-keys-create-new-actions-documentation-btn-font-color: rgb(
    var(--pc__accent-additive)
  );
  --th-settings__api-keys-create-new-actions-btn-font-color: rgb(
    var(--pc__font-primary)
  );
  --th-settings__api-keys-create-new-actions-btn-bg-color: rgba(
    var(--pc__accent-additive),
    0.9
  );
  /* SETTINGS -- TrustPay External Credit Card Form */
  --th-settings__trustpay-form-font-color: 0, 0, 0;
  /* SETTINGS -- Affiliate */
  --settings__affiliate-header-bg-color: var(--black13);
  --settings__affiliate-content-bg-color: var(--purple13);
  --settings__affiliate-input-bg-color: var(--black11);
  --settings__affiliate-input-color--disabled: var(--grey14);
  --settings__affiliate-border-color: var(--white1);
  --settings__affiliate-support-link-color: var(--purple10);
  --affiliate-modal-bg-color: var(--black11);
  --affiliate-modal-body-bg-color: var(--black13);
  /* PADDED DECIMAL */
  /* default */
  --padded-decimal__units-color: var(--grey14);
  --padded-decimal__separator-color: var(--grey14);
  --padded-decimal__decimals-color: var(--grey14);
  --padded-decimal__padding-color: var(--grey14);
  /* sell */
  --padded-decimal__units-sell-color: var(--lightgreen1);
  --padded-decimal__separator-sell-color: var(--lightgreen1);
  --padded-decimal__decimals-sell-color: var(--lightgreen1);
  --padded-decimal__padding-sell-color: var(--lightgreen1);
  /* buy */
  --padded-decimal__units-buy-color: var(--red3);
  --padded-decimal__separator-buy-color: var(--red3);
  --padded-decimal__decimals-buy-color: var(--red3);
  --padded-decimal__padding-buy-color: var(--red3);
  /* NAV-BAR */
  --expand-navbar-icon: var(--pink1);
  --nav-icon-hover-border: var(--pink1);
  --nav-bar-item-color: var(--white1);
  --nav-bar-mobile-background: var(--white1);
  --nav-bar-sign-out-background: var(--grey12);
  --nav-bar-sign-out-shadow-color: rgba(255, 255, 255, 0.15);
  --nav-bar-theme-background: var(--black11);
  --nav-bar-item-active-background: var(--grey2);
  --nav-bar-referral-btn-shadow: 0px 5px 30px rgba(228, 221, 250, 0.4);
  /* HEADER */
  --header-burger-menu: var(--white1);
  --header-search-input-color: var(--white1);
  --header-search-input-background: var(--grey2);
  --header-popover-background: var(--grey8);
  --header-popover-color: var(--white1);
  --header-mobile-background: var(--black8);
  --header-consign-background: var(--grey2);
  --header-consign-text-color: var(--white1);
  /* DASHBOARD*/
  --wallet-summary-background: var(--black11);
  --wallet-summary-view-details-background: var(--grey8);
  --wallet-summary-view-details-color: var(--pink1);
  --wallet-summary-view-details-background-hover: var(--black10);
  --wallet-summary-view-details-color-hover: var(--white1);
  --wallet-summary-chart-backgroud: var(--blue5);
  --wallet-summary-chart-title: var(--pink1);
  --wallet-summary-available-balance: var(--white1);
  --wallet-summary-currencies-color: var(--white1);
  --wallet-chart-label-color: var(--white1);
  --wallet-summary-add-funds-background: linear-gradient(
    228.33deg,
    var(--blue2),
    var(--purple1)
  );
  --wallet-summary-add-funds-hover-background: var(--grey2);
  --wallet-summary-add-funds-hover-border: 3px solid var(--purple8);
  --my-collectibles-slider-background: var(--black11);
  --my-collectibles-slider-arrow: var(--white1);
  --my-collectibles-title: var(--white1);
  --my-collectibles-view-all-background: var(--grey8);
  --my-collectibles-view-all-color: var(--pink1);
  --my-collectibles-view-all-background-hover: var(--black10);
  --my-collectibles-view-all-color-hover: var(--white1);
  --my-collectibles-no-assets-background: var(--black11);
  --my-collectibles-no-assets-color: var(--black14);
  --dashboard-market-place-background: var(--black11);
  --dashboard-market-place-title: var(--white1);
  --dashboard-market-place-menu-item: var(--white1);
  --dashboard-market-place-menu-item-active: var(--pink1);
  --dashboard-market-place-go-pro-button-border-radius: 16px 2px;
  --market-product-card-color: var(--white1);
  --product__no-product-color: var(--white1);
  --slick-slider-arrow-color: var(--white1);
  /* FOOTER */
  --footer-font-color: var(--grey9);
  --footer-hover-font-color: var(--link__color--hover);
  --footer-social-link-color: var(--white1);
  /* BREAD-CRUMBS */
  --bread-crumbs-color: var(--grey10);
  /* OFFERINGS PAGE */
  --offering-title-color: var(--white1);
  --offerings-background: var(--black10);
  --upcoming-offerings-background: var(--black13);
  --offerings-title: var(--white1);
  --upcoming-offerings-card-background: var(--black10);
  --upcoming-offerings-card-title: var(--white1);
  /* MARKET PLACE */
  --marketplace-font-color: var(--white1);
  --market-place-background: var(--black10);
  --buy-sell-section-background: var(--black13);
  --buy-sell-section-title-color: var(--white1);
  --buy-sell-section-no-products-color: var(--grey13);
  --market-place-header-background: var(--black13);
  --market-place-header-title-color: var(--white1);
  --market-place-header-go-pro-text-color: var(--white1);
  --market-place-header-go-pro-hover-text-color: var(--white1);
  --market-place-header-go-pro-background: var(--orange1);
  --market-place-header-go-pro-hover-background: transparent;
  --market-place-header-go-pro-border-color: var(--white1);
  --market-place-header-go-pro-hover-border-color: var(--orange1);
  --market-place-no-products-color: var(--white1);
  /* MY COLLECTIBLES */
  --my-collectibless-background: var(--black10);
  --my-collectibles-section-header-background: var(--black8);
  --my-collectibles-section-background: var(--black11);
  --my-watch-list-sectio-noproduct: var(--white1);
  /* SECTION */
  --section-title: var(--white1);
  /* SELECT */
  --select-background-color: var(--grey2);
  --select-active-background-color: var(--purple8);
  --select-border-color: var(--purple8);
  --select-text-color: var(--white1);
  --select-active-text-color: var(--white1);
  --select-arrow-color: var(--white1);
  --select-active-arrow-color: var(--white1);
  --vote-select-item-color: var(--grey12);
  --vote-select-active-item: var(--purple9);
  --vote-select-divider-color: var(--black13);
  /* SETTINGS */
  --settings-background: var(--black10);
  --settings-nav-menu-background: var(--black8);
  --settings-nav-menu-item: var(--white1);
  --settings-nav-menu-item-selected: var(--white1);
  --settings-font-primary: var(--white1);
  --settings-widget-background: var(--black8);
  /* BUY-SELL-MODAL */
  --buy-sell-modal-background: var(--black13);
  --buy-sell-modal-button-background-color: var(--grey2);
  --buy-sell-modal-button-color: var(--white1);
  --buy-sell-modal-additional-button-background-color: var(--grey2);
  --buy-sell-modal-additional-button-border-color: var(--white1);
  --buy-sell-modal-additional-button-border: 0.3rem solid
    var(--buy-sell-modal-additional-button-border-color);
  --buy-sell-modal-additional-button-color: var(--white1);
  --buy-sell-modal-confirm-title-color: var(--font__color-primary);
  --buy-sell-modal-confirm-details-background-color: var(--grey2);
  --buy-sell-modal-card-info-background-color: var(--grey2);
  --buy-sell-modal-xp-number-color: var(--purple10);
  --buy-sell-modal-xp-text-background: var(--grey2);
  --place-order-button-color--buy: var(--white1);
  --quantity-picker-tooltip-color: var(--black14);
  --quantity-picker-tooltip-color--buy: var(--black14);
  --quantity-picker-track-inactive-color: var(--black14);
  --quantity-picker-track-inactive-color--buy: var(--black14);
  --quantity-picker-input-background-color: var(--black14);
  /* SHARE PROFILE MODAL */
  --share-profile-modal-url-background: var(--grey2);
  --share-profile-modal-copy-icon-fill-color: var(--purple8);
  --share-profile-modal-copy-icon-stroke-color: var(--white1);
  /* NOT FOUND */
  --stub-page-background: var(--black10);
  --stub-page-wordmark-filter: invert(100%);
  --stub-page-text-color: var(--white1);
  --stub-page-button-bg-color: var(--grey2);
  --stub-page-links-text-color: rgba(var(--pc__font-primary), 0.75);
  /* PRO-EXCHANGE */
  --pro-exchange-backgrougd: var(--black10);
  --instrument-row__background-color: var(--black13);
  --trading-layout-background: var(--black13);
  --trading-layout-border: var(--black8);
  --exchange-colors__buy: var(--red3);
  --instrument-selector__trigger-border: 4px solid var(--pink1);
  --exchange-colors__sell: var(--lightgreen1);
  --tab__border-color--selected: var(--pink1);
  --lightweight-interval-button-active: var(--pink1);
  --lightweight-interval-button-active-font: var(--black8);
  --lightweight-interval-button-active-color: var(--pink2);
  --lightweight-interval-button: var(--blue8);
  --lightweight-interval-button-color: var(--white1);
  --bulk-cancel-buttons: var(--black8);
  --bulk-cancel-buttons-border: var(--grey6);
  --bulk-cancel-buttons-color: var(--white1);
  --order-entry-labels: var(--white1);
  --link__color: var(--pink1);
  --orderbook__spread-bg-color: var(--black14);
  --bulk-cancel-btn__bg-color: var(--black12);
  --bulk-cancel-buttons: var(--grey2);
  --bulk-cancel-btn__label-color: var(--white1);
  --segmented-button-active: var(--pink1);
  --segmanted-button-checked-color: var(--black1);
  --order-entry-label-in-input-background: var(--black8);
  --order-entry-label-in-input-color: var(--white1);
  --order-entry-select-border-color: var(--grey6);
  --order-entry-select-background: var(--black10);
  --order-entry-bg-mobile: var(--blue3);
  --order-entry-background-additive: var(--grey2);
  --order-entry-background-subtractive: var(--grey2);
  --order-entry-order-type-background: var(--grey2);
  --order-entry-order-type-border-additive: var(--blue8);
  --order-entry-order-type-border-subtractive: var(--grey13);
  --order-entry-submit-color-additive: var(--white1);
  --order-entry-submit-color-subtractive: var(--white1);
  --order-entry-submit-background: var(--grey2);
  --order-entry-input-background-color: var(--black14);
  --order-entry-input-background-color--disabled: var(--black14);
  --instrument-selector-background: var(--grey2);
  --instrument-selector-filter-label-color: var(--white1);
  --instrument-selector-tabs-background: var(--grey2);
  --instrument-selector-tab-color: var(--grey12);
  --instrument-selector-active-tab-color: var(--white1);
  --instrument-selector-search-input-color: var(--white1);
  --instrument-selector-search-input-background: var(--black11);
  --instrument-selector-search-input-border-color: var(--white1);
  --instrument-selector-search-input-icon-shadow-color: rgba(228, 221, 250, 0.4);
  --instrument-selector-card-background: var(--black13);
  --instrument-selector-card-border-color: var(--grey13);
  --instrument-selector-active-card-border-color: var(--white1);
  --instrument-selector-card-name-color: var(--grey12);
  --instrument-summary-background: var(--black13);
  --instrument-summary-title-color: var(--white1);
  --instrument-summary-value-color: var(--white6);
  --instrument-summary-value-border-color: var(--pink4);
  --instruments-info-arrow-color: var(--white1);
  --instruments-info-arrow-background-color: var(--grey2);
  --instruments-info-arrow-border-color: var(--white1);
  --buy-sell-tab-background: var(--grey2);
  --pro-exchange-chart-container-border-color: var(--white1);
  --pro-exchange-chart-tab-background: var(--black14);
  --pro-exchange-chart-tab-color: var(--white1);
  --pro-exchange-chart-tab-active-background: var(--grey13);
  --pro-exchange-chart-tab-active-color: var(--white1);
  --pro-exchange-order-book-title-border-color: var(--black14);
  --pro-exchange-order-book-title-background-color: var(--grey13);
  --pro-exchange-order-book-title-color: var(--white1);
  --pro-exchange-order-book-row-background: var(--grey2);
  --pro-exchange-order-book-header-background: var(--grey2);
  --pro-exchange-chart-background: var(--grey2);
  --pro-exchange-chart-timeline-background: var(--black13);
  --pro-exchange-chart-top-border-color: var(--white1);
  --pro-exchange-active-tab-border-color: var(--pink2);
  /* ORDER HISTORY */
  --order-history-header-border: var(--black12);
  --order-history-mobile-header-border: var(--black12);
  --order-history-header-background: var(--black13);
  --order-history-header-active-background: var(--grey2);
  --order-history-header-color: var(--lightgrey1);
  --order-history-header-active-color: var(--white1);
  --order-history-table-background: var(--black14);
  --order-history-table-column-header-background: var(--black14);
  --order-history-pagination-background: var(--black13);
  --order-history-table-column-background: var(--black14);
  --order-history-table-column-color: var(--white1);
  /* WALLETS */
  --wallets-section-background: var(--black8);
  --wallets-section-font-primary: var(--white1);
  --wallets-section-font-secondary: var(--pink1);
  --wallets-section-view-all-background: var(--grey8);
  --wallets-section-view-all-background-hover: var(--black10);
  --wallets-section-view-all-color: var(--pink1);
  --wallets-section-view-all-color-hover: var(--white1);
  --wallet-page-available-funds-background: var(--black13);
  /* DEPOSIT-WITHDRAW-MODALS */
  --deposit-withdraw-header-background: var(--black10);
  --deposit-withdraw-currency-selector-background: var(--black8);
  --deposit-withdraw-font-primary: var(--white1);
  --deposit-withdraw-font-secondary: var(--white1);
  --deposit-withdraw-border-color: var(--grey11);
  --deposit-withdraw-background-primary: var(--black13);
  --deposit-withdraw-background-secondary: var(--grey2);
  --deposit-input-background: transparent;
  --deposit-withdraw-crypto-instructions-color: #ae9cf3;
  --deposit-withdraw-crypto-text-color: #caccd2;
  --deposit-withdraw-card-border-color: var(--black12);
  --deposit-withdraw-card-border-color--selected: var(--purple8);
  --deposit-withdraw-card-background-color: var(--black14);
  --deposit-withdraw-card-background-color--selected: var(--purple13);
  --deposit-withdraw-card-font-color: var(--grey14);
  --deposit-selected-card-text-color: var(--white1);
  --deposit-recurring-switch-background: var(--purple14);
  --deposit-recurring-switch-toogle: var(--pink6);
  --deposit-recurring-switch-toogle-border: none;
  --deposit-limit-reached-background-color: var(--purple12);
  /* ACH/WIRE TRANSFERS */
  --deposit-ach-button-background: var(--grey2);
  --deposit-ach-button-background-border: var(--white1);
  --deposit-ach-button-color: var(--white1);
  /* NOTIFICATIONS */
  --notification-background: var(--black8);
  --notification-font-color: var(--white1);
  /* ASSET HEADER */
  --asset-header-background: var(--black8);
  --asset-header-font: var(--white1);
  --asset-header-button-background: var(--grey2);
  --asset-header-fav-share-background: var(--grey2);
  /* ONBOARDING */
  --onboarding-container-background: var(--white1);
  --onboarding-text-color: var(--grey2);
  --spotlight_shadow_color: var(--grey11);
  /* KYC statuses */
  --kyc-status-wrapper-background: var(--purple9);
  /* WALLET CHART */
  --wallet-chart-background: linear-gradient(var(--grey2), var(--grey2))
      padding-box,
    linear-gradient(131.67deg, var(--blue2) 0%, var(--purple1) 100%) border-box;
  --wallet-chart-inner-circle-background: linear-gradient(
    131.67deg,
    #3f8cff33 0%,
    #825af633 100%
  );
  --wallet-chart-selected-circle-background: linear-gradient(
    131.67deg,
    #242f4c 0%,
    #2b2a4b 100%
  );
  --wallet-chart-labels-background: var(--grey2);
  --wallet-chart-label-color: var(--white1);
  --wallet-chart-selected-label-background: var(--black14);
  --wallet-chart-slider-arrow-color: var(--white1);
  /* PUBLIC PROFILE */
  --public-profile-text-color: var(--white1);
  /* DEPOSIT WITHDRAW STATUS MODALS */
  --deposit-withdraw-status-modal-background: var(--grey2);
  --deposit-withdraw-status-modal-background-border: var(--white1);
  --deposit-withdraw-status-modal-color: var(--white1);
  --deposit-withdraw-status-modal-recurring-info-bg: var(--purple15);
  --deposit-withdraw-status-modal-bottom-text-color: var(--grey14);
  /* wire transfer for deposit */
  --wire-transfer-text-color: white;
  --wire-transfer-disabled-input-background: var(--black13);
  --wire-transfer-result-modal-body-background: var(--black13);
  --wire-transfer-result-modal-body-color: var(--white1);
  --wire-transfer-result-modal-close-button-background: var(--grey2);
  --wire-transfer-result-modal-close-button-color: var(--white1);
  /* BUTTONS */
  --buttons-buy-background: var(--grey2);
  --buttons-buy-hover-background: var(--blue6);
  --buttons-buy-text-color: var(--white1);
  --buttons-buy-hover-text-color: var(--white1);
  --buttons-sell-background: var(--grey2);
  --buttons-sell-hover-background: var(--purple8);
  --buttons-sell-text-color: var(--white1);
  --buttons-sell-hover-text-color: var(--white1);
  --buttons-view-details-background: var(--grey2);
  --buttons-view-details-hover-background: var(--pink2);
  --buttons-view-details-text-color: var(--white1);
  --buttons-view-details-hover-text-color: var(--white1);
  --buttons-add-to-favorite-background: var(--grey2);
  --buttons-add-to-favorite-hover-background: var(--blue6);
  --buttons-add-to-favorite-text-color: var(--white1);
  --buttons-add-to-favorite-icon-color: var(--white1);
  --buttons-add-to-favorite-hover-icon-color: var(--white1);
  --buttons-add-to-favorite-active-icon-color: var(--blue6);
  --buttons-add-to-favorite-border-color: var(--white1);
  --buttons-add-to-favorite-background-color: var(--grey2);
  --buttons-add-to-favorite-hover-text-color: var(--white1);
  --buttons-add-to-favorite-active-text-color: var(--grey2);
  --buttons-go-pro-background: linear-gradient(
        175.85deg,
        transparent 48%,
        #141f1f19 52%
      )
      border-box,
    linear-gradient(var(--orange1), var(--orange1)) border-box;
  --buttons-go-pro-hover-background: linear-gradient(
        175.85deg,
        transparent 48%,
        #d5d9e11a 52%
      )
      padding-box,
    linear-gradient(var(--grey2), var(--grey2)) padding-box;
  --buttons-go-pro-text-color: var(--white1);
  --buttons-go-pro-hover-text-color: var(--white1);
  --buttons-go-pro-border: transparent;
  --buttons-go-pro-hover-border: var(--orange1);
  --buttons-view-watch-list-background: var(--black13);
  --buttons-view-watch-list-hover-background: var(--pink2);
  --buttons-view-watch-list-text-color: var(--pink2);
  --buttons-view-watch-list-hover-text-color: var(--white1);
  --buttons-view-watch-list-border-color: var(--pink2);
  --buttons-view-watch-list-hover-border-color: var(--white1);
  --buttons-remove-from-watch-list-background: var(--grey2);
  --buttons-remove-from-watch-list-hover-background: var(--red3);
  --buttons-remove-from-watch-list-text-color: var(--white1);
  --buttons-remove-from-watch-list-hover-text-color: var(--white1);
  --buttons-remove-from-watch-list-border-color: var(--red3);
  --buttons-remove-from-watch-list-hover-border-color: var(--red3);
  /* AVAILABILITY PROGRESS BAR */
  --availability-text-color: var(--grey12);
  --availability-progress-border: rgba(228, 229, 233, 0.5);
  /* SWITCH */
  --switch-modal-background: var(--grey2);
  --switch-modal-text-color: var(--white1);
  --switch-info-background: var(--grey2);
  --switch-info-text: var(--white1);
  /* PRIMARY MARKET BLOCKER */
  --primary-market-blocker-text-color: var(--white1);
  --primary-market-blocker-link-background-color: var(--grey2);
  --primary-market-blocker-container-background: var(--black13);
  --primary-market-blocker-modal-background-color: rgba(0, 0, 0, 0.8);
  /* BUY OUT FORM */
  --buy-out-submit-background: var(--grey2);
  --buy-out-go-back-background: var(--grey2);
  --buy-out-go-back-border-color: var(--white1);
  --buy-out-go-back-color: var(--white1);
  --buy-out-form-wrapper-background: var(--black13);
  --buy-out-form-input-label-color: var(--white1);
  --buy-out-form-input-border-color: var(--white1);
  --buy-out-form-input-color: var(--white1);
  --buy-out-form-input-background-color: var(--grey2);
  --buy-out-form-input-disabled-color: var(--grey11);
  --buy-out-result-form-background: var(--black13);
  --buy-out-result-form-text-color: var(--white1);
  /* PAGINATION */
  --pagination-background-color: var(--grey2);
  --pagination-border-color: var(--black12);
  --pagination-page-number-color: var(--white1);
  --pagination-next-prev-color: var(--black12);
  /* SELECT ASSET MODAL */
  --select-asset-modal-background-color: var(--black14);
  --select-asset-modal-no-assets-color: var(--grey13);
  --select-asset-modal-scroll-track-bg: var(--grey2);
  --select-asset-modal-scroll-thumb-bg: var(--black12);
  /* CONFIRM REMOVE FROM WATCHLIST MODAL */
  --confirm-remove-from-watchlist-modal-bg-color: var(--grey2);
  --confirm-remove-from-watchlist-modal-btn-color: var(--white1);
  --confirm-remove-from-watchlist-modal-cancel-border-color: var(--white1);
  --confirm-remove-from-watchlist-modal-cancel-color-hover: var(--grey2);
  /* WATCH LIST ASSET MODAL */
  --watch-list-asset-modal-background-color: var(--black11);
  /* COMMUNITY PAGE */
  --community-page-background-secondary: var(--black12);
  --community-page-font: var(--white1);
  --community-page-discord-link-bg: var(--grey2);
  --community-page-top-100-header-bg: var(--black12);
  --community-page-scroll-color: var(--grey14);
  --community-page-scroll-track: var(--black14);
  /* VOTE-BUTTONS */
  --vote-button-background: var(--white1);
  --vote-button-font: var(--grey2);
  --vote-button-background-voted: var(--white1);
  --vote-button-font-voted: var(--grey2);
  /* VOTE MODAL */
  --vote-modal-product-background: var(--grey2);
  --vote-modal-background: var(--grey2);
  --vote-modal-font-primary: var(--white1);
  /* VOTE-PAGE */
  --vote-page-background: var(--black13);
  /* NOTIFICATION CENTER */
  --notification-center-list-background: var(--grey2);
  --notification-center-row-background: var(--grey2);
  --notification-center-row-hover-background: var(--purple8);
  --notification-center-row-border-color: var(--grey11);
  --notification-center-row-text-color: var(--white1);
  --notification-center-row-date-color: var(--grey14);
  --notification-center-row-text-hover-color: var(--white1);
  --notification-center-row-view-all-background: var(--grey2);
  --notification-center-row-view-all-hover-background: var(--black13);
  --notification-center-row-view-all-border-color: var(--white1);
  --notification-center-row-view-all-hover-border-color: var(--black13);
  --notification-center-row-view-all-text-color: var(--white1);
  --notification-center-row-view-all-text-hover-color: var(--white1);
  --notification-center-global-background: var(--purple8);
  --notification-center-specific-user-background: var(--pink7);
  --notification-center-reminder-background: var(--grey2);
  --notification-center-reward-background: var(--grey2);
  --all-notifications-modal-background: var(--black13);
  --all-notifications-modal-font: var(--white1);
  --all-notifications-modal-track-color: var(--grey2);
  --all-notifications-modal-thumb-color: var(--black12);
  --all-notifications-modal-search-background: var(--black11);
  --all-notifications-modal-global-background: var(--purple8);
  --all-notifications-modal-specific-user-background: var(--pink7);
  --all-notifications-modal-reminder-background: var(--grey2);
  --all-notifications-modal-reward-background: var(--blue6);
  --notification-center-no-notifications-font: var(--grey14);
  --notification-center-no-notifications-title: var(--white1);
  --notification-action-button-background: var(--grey2);
  --notification-action-button-color: var(--white1);
  /* CO-OWN */
  --co-own-background: var(--black13);
  --co-own-text-color: var(--white1);
  --co-own-sub-text-color: var(--grey12);
  --co-own-left-part-background: var(--grey2);
  --co-own-text-background: var(--black13);
  /* PAYMENTS-PAGE */
  --payments-body-background: var(--purple13);
  --payments-font-color: var(--white1);
  --paymnets-history-header-background: var(--grey2);
  --payments-history-background: var(--black16);
  --payments-modals-background: var(--black11);
  --payments-modals-info-background: var(--black13);
  /* TERMS OF USE MODAL */
  --terms-of-use-modal-background: var(--grey2);
  --terms-of-use-modal-text: var(--white1);
  --terms-of-use-modal-terms-text: var(--purple8);
  /* IBANERA CRYPTO WIDGET */
  --ibanera-crypto-background: var(--black13);
  --ibanera-term-of-use-text: var(--white1);
  --ibanera-crypto-select-option-color: var(--black13);
}
