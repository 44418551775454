.stub-page-desktop__scroll-container {
  padding: 20px 47px 47px;
  gap: 40px;
}

.stub-page-desktop__wordmark-container {
  flex: 1 1;
  flex-basis: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stub-page-desktop__wordmark {
  width: 376px;
  height: 80px;
}

.stub-page-desktop__content {
  flex: 0.4 1;
  flex-basis: auto;
  display: flex;
}

.stub-page-desktop__left-col,
.stub-page-desktop__right-col {
  width: 50vw;
  display: flex;
  flex-direction: column;
}

.stub-page-desktop__left-col {
  align-items: flex-end;
  padding-right: 16px;
}

.stub-page-desktop__right-col {
  align-items: flex-start;
  justify-content: space-between;
  flex-grow: 1;
  gap: 47px;
  padding-left: 16px;
}

.stub-page-desktop__left-content {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  gap: 47px;
}

.stub-page-desktop__right-content {
  width: 100%;
  max-width: 540px;
  min-height: 462px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.stub-page-desktop__quid {
  max-width: 341px;
  width: 100%;
}

.stub-page-desktop__title {
  font-weight: 700;
  font-size: 120px;
  line-height: 1.5;
  letter-spacing: 0.05em;
  margin-bottom: 10px;
}

.stub-page-desktop__subtitle {
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  margin-bottom: 20px;
}

.stub-page-desktop__text-content {
  max-width: 485px;
  margin-bottom: 50px;
}

.stub-page-desktop__links-container {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  column-gap: 20px;
  row-gap: 10px;
  justify-content: space-evenly;
  align-items: center;
}

.stub-page-desktop__back-button {
  margin-bottom: 16px;
}

.stub-page-desktop__divider-container {
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
}

.stub-page-desktop__copyright {
  align-self: flex-end;
}
