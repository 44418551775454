.deposit-withdraw-modal__modalContainer {
  width: 450px;
}

.deposit-withdraw-modal__modalContainer .deposit-external__footer {
  color: var(--black2);
}
.deposit-withdraw-modal__modalContainer .deposit-external__footer {
  opacity: 0.8;
}

@media (max-width: 576px) {
  .deposit-withdraw-modal__modalContainer {
    width: 100%;
    position: relative;
  }
}
