.place-order-details__image-text-container {
  display: flex;
  flex-direction: row;
  width: 70rem;
  gap: 4rem;
  padding: 4rem;
}

.place-order-details__form-container {
  --side-color: var(--buy-sell-modal__button-border-color);
  all: revert;
}

.place-order-details__form-container--buy {
  --side-color: var(--buy-sell-modal__button-border-color--buy);
}

.place-order-details__fee {
  text-decoration: underline;
}

.place-order-details__info-container {
  border-radius: 0px 2rem 2rem 2rem;
  padding: 1.5rem 1.5rem 1.1rem;
  margin: 0 -1.5rem 2rem -1.5rem;
  background-color: var(--buy-sell-modal-card-info-background-color);
}

.place-order-details__card-image {
  width: 29.1rem;
  flex-shrink: 0;
  box-shadow: -0.64rem 0.64rem 1.2rem #00000033;
  border-radius: 1rem;
  align-self: flex-start;
}

.place-order-details__card-title {
  border: none;
  line-height: 1;
  padding-bottom: 0.5rem;
}

.place-order-details__text-container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.place-order-details__card-attribute-row {
  font-size: var(--buy-sell-modal__font-size);
  line-height: var(--buy-sell-modal__line-height);
  margin-bottom: 0.1rem;
}

.place-order-details__max-quantity {
  color: var(--side-color);
  font-size: var(--buy-sell-modal__font-size);
  line-height: var(--buy-sell-modal__line-height);
}

.place-order-details__warning-wrapper {
  margin-bottom: 2rem;
}

.place-order-details__percentage-slider-container {
  margin: 2rem 0;
}

.place-order-details__percentage-slider-container .spinner__container {
  height: 75px;
}

.buy-sell-modal .percentage-slider__slider {
  margin-top: 35px;
}

.place-order-details__fields-container {
  min-width: 25.4rem;
  margin-bottom: 6rem;
}

.place-order-details__input__input {
  text-align: right;
  font-size: var(--buy-sell-modal__font-size);
  height: 1.6rem;
  border-radius: 0px 0.6rem 0.6rem 0.6rem;
  border-color: var(--purple1);
  padding: 0 1ch;
}

.place-order-details__input__input:invalid {
  background: var(--white1);
  border-color: var(--red3);
}

.place-order-details__input__input-wrapper {
  margin-bottom: 0;
  flex: 0 1 7.3rem;
  max-width: 7.3rem;
  display: flex;
  margin-right: 0.4rem;
}

.place-order-details__field-value--exp {
  color: var(--pink1);
}

.place-order-details__row--highlighted .place-order-details__field-value--exp {
  color: inherit;
}

.place-order-details__submit-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}

.place-order-details__btn {
  padding: unset;
  border-radius: 0 2rem 2rem 2rem;
}

@media (max-width: 740px) {
  .place-order-details__image-text-container {
    width: 100%;
  }

  .place-order-details__quantity-container {
    max-width: 28rem;
    align-self: center;
  }

  .place-order-details__card-title {
    text-align: left;
  }
}

@media (max-width: 693px) {
  .place-order-details__card-image {
    width: 14.5rem;
    margin-bottom: 1rem;
    align-self: center;
  }

  .place-order-details__info-container {
    margin-bottom: 4rem;
  }

  .place-order-details__text-container {
    width: 100%;
  }

  .place-order-details__fields-container {
    min-width: 0;
    margin-bottom: 8rem;
  }
}

@media (max-width: 576px) {
  .place-order-details__card-image,
  .place-order-details__warning-wrapper {
    align-self: center;
  }
}

@media (max-width: 394px) {
  .place-order-details__quantity-container {
    max-width: 18rem;
  }
}

@media (max-width: 360px) {
  .buy-sell-modal .quantity-picker__picker-container {
    flex-direction: column;
    align-items: stretch;
  }

  .buy-sell-modal .quantity-picker__currency-value {
    text-align: left;
  }

  .buy-sell-modal .percentage-slider {
    flex-grow: unset;
  }
}

@media (max-width: 310px) {
  .place-order-details__image-text-container {
    padding: 3rem;
  }
}
