.currencies__wrapper {
  width: 100%;
  margin-top: 20px;
}

.currencies__layout__tab {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.currencies__layout__tab-content {
  width: 100%;
}

.currencies__layout__menu-item {
  flex: none !important;
  margin-bottom: 0px !important;
}

.currencies__layout__menu-item--active:before,
.currencies__layout__menu-item--active:hover:before {
  border: none !important;
}

.currencies__layout__menu {
  background: transparent !important;
  border: none !important;
  justify-content: center;
  background-color: transparent !important;
  width: min-content;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background-color: white !important;
}

.currencies__layout__menu-item {
  border: none !important;
  padding-bottom: 8px;
  color: black;
  background-color: white !important;
}
.currencies__layout__menu-item {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}
.currencies__layout__menu-item--active {
  color: white !important;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(43, 162, 229, 255) 30%,
    rgba(119, 127, 240, 255) 60%,
    rgba(171, 97, 248, 255) 100%
  );
}
.currencies__layout__menu-item--active:first-child {
  border-top-right-radius: 16px;
}

.currencies__layout__menu-item--active:last-child {
  border-top-left-radius: 16px;
}

.currencies__body {
  background: transparent !important;
  color: var(--wallet-summary-currencies-color);
}

.currencies__table-header {
  background: transparent !important;
  border-radius: 3.2px !important;
  width: 100% !important;
  height: auto;
  margin-bottom: 17px;
}

.currencies__row {
  border: none !important;
  background: transparent !important;
  width: 100% !important;
  padding: 0;
}

.currencies__row:hover {
  background: transparent !important;
}

.currencies__column {
  width: calc(100% / var(--col-count));
}

.currencies__column:first-child {
  justify-content: flex-start !important;
  color: var(--wallet-summary-currencies-color);
  text-align: start;
  overflow: hidden;
}

.currencies__columnItem {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  line-height: 1.4;
  color: var(--wallet-summary-currencies-color);
}

.currencies__columnItem--fullWidth {
  width: 100%;
}

.currencies__columnItem-text {
  color: var(--wallet-summary-currencies-color);
  width: 100%;
  line-height: 1.4;
}

.currencies__columnItem-text>div {
  font-size: inherit;
  line-height: inherit;
}

.currencies__currencyColor {
  --size: 12px;
  width: var(--size);
  height: var(--size);
  min-width: var(--size);
  min-height: var(--size);
  border-radius: 50%;
}

.currencies__header-column {
  width: calc(100% / var(--col-count));
}

.currencies__header-column:first-child {
  justify-content: flex-start !important;
}

.currencies__columnHeader {
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: 0px;
  color: var(--blue7);
  text-transform: uppercase;
}

.currencies__actionButton {
  font-weight: 600;
}

.currencies__actionButton:hover {
  text-decoration: underline;
}

.currencies__body .table__row-container {
  overflow-y: auto;
  max-height: 223px;
  gap: 17px;
}

.currencies .table__row-container::-webkit-scrollbar {
  width: 5px;
}

@media (max-width: 1280px) {
  .currencies__body .table__row-container {
    max-height: 103px;
  }
}

@media (max-width: 576px) {
  .currencies__table-header {
    height: auto !important;
  }
  .currencies__body .table__row-container {
    max-height: 82px;
  }
  .currencies__row {
    height: auto !important;
  }
  .currencies__columnHeader {
    font-size: 11.2px;
    text-align: right;
  }
  .currencies__columnItem-text {
    font-size: 11.2px;
  }
  .currencies__header-column {
    white-space: normal !important;
  }
  .currencies__column {
    white-space: normal !important;
  }
  .currencies__columnItem {
    gap: 4.8px;
    font-size: 11.2px;
  }

  @media (max-width: 400px) {
    .currencies__columnHeader,
    .currencies__columnItem-text,
    .currencies__columnItem {
      font-size: 9.6px;
    }
  }
}
