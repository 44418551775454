.ap-label-with-text {
  display: flex;
  justify-content: space-between;
  letter-spacing: 0.5px;
  width: 100%;
  align-items: center;
}

.ap-label-with-text__label {
  color: var(--font__color-secondary);
  font-size: inherit;
}

.ap-label-with-text__link {
  color: var(--font__color-primary) !important;
  font-size: inherit;
}

.ap-label-with-text__text {
  color: var(--font__color-primary);
  font-size: inherit;
}
