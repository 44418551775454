.top-collectible__container {
  border-radius: 0px 25.6px 25.6px 25.6px;
  display: flex;
  padding: 29px 40px;
  min-height: 500px;
  background-position-x: right;
  background-repeat: no-repeat;
  color: var(--offering-title-color);
}

.top-collectible__NoAssetsWrapper {
  min-height: 348.8px;
  background-color: var(--my-collectibles-no-assets-background);
}

.top-collectible__no-assets-wrapper {
  height: 100%;
  width: 100%;
  background-color: var(--my-collectibles-no-assets-background);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 500px;
}

.top-collectible__no-assets-logo {
  position: absolute;
  z-index: 1;
}

.top-collectible__no-assets-text {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  z-index: 2;
  color: var(--my-collectibles-no-assets-color);
}

.top-collectible__noAssets {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.top-collectible__content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.top-collectible__title {
  margin-bottom: 10px;
  line-height: 36px;
  font-size: 24px;
  color: var(--topCollectibleTextColor);
  font-weight: 600;
}

.top-collectible__name {
  line-height: 27px;
  font-size: 18px;
  color: var(--topCollectibleTextColor);
  font-weight: 600;
}

.top-collectible__description {
  margin-top: 20px;
  line-height: 18px;
  font-size: 12px;
  color: var(--topCollectibleTextColor);
  font-weight: 500;
  font-style: italic;
}

.top-collectible__total-value {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 64px;
  box-shadow: 0px 1.6px 12.8px var(--black5);
  border-radius: 0px 20px 20px 20px;
  margin-top: 20px;
}

.top-collectible__total-value-title {
  line-height: 15px;
  font-size: 10px;
  color: var(--topCollectibleTextColor);
  font-weight: 500;
}

.top-collectible__value-number {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: var(--topCollectibleTextColor);
  margin-top: 10px;
}

.top-collectible__footer-info {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}

.top-collectible__footer-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 64px;
  box-shadow: 0px 1.6px 12.8px var(--black5);
  text-align: center;
  border-radius: 0px 20px 20px 20px;
}

.top-collectible__footer-item-title {
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  color: var(--topCollectibleTextColor);
}

.top-collectible__footer-value {
  color: var(--topCollectibleTextColor);
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

@media (max-width: 1100px) {
  .top-collectible__content {
    flex: auto;
    width: 100%;
  }
  .top-collectible__content:last-child {
    display: none;
  }
}

@media (max-width: 576px) {
  .top-collectible__container {
    padding: 20px;
    min-height: max-content;
  }
  .top-collectible__content:last-child {
    display: none;
  }
  .top-collectible__footer-item:last-child {
    margin-left: 9.6px;
  }
  .top-collectible__footer-item {
    padding: 8px 0;
  }
  .top-collectible__title {
    line-height: 21px;
    font-size: 14px;
  }
  .top-collectible__name {
    line-height: 21px;
    font-size: 14px;
  }

  .top-collectible__description {
    line-height: 15px;
    font-size: 10px;
    padding-right: 50%;
  }
  .top-collectible__total-value {
    padding: 8px 0;
  }

  .top-collectible__footer-value {
    margin-top: 9.6px;
  }
}
