.lightweight-interval-button {
  min-width: 4rem;
  color: white;
  height: 1rem;
  margin: 0.3rem;
  padding: 1rem;
  background-color: transparent;
  border-color: transparent;
  text-align: center;
}
.lightweight-interval-button.active {
  background-color: transparent;
}

.lightweight-interval-button > span {
  margin: 0 auto;
}

.lightweight-interval-button:hover {
  background-color: transparent;
  border: none;
  color: var(--lightweight-interval-button-active-color);
}

.lightweight-interval-button:focus {
  border: none;
}

#lightweight-chart {
  background: var(--secondary__bg-color);
  height: 100%;
  min-height: 40rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.lightweight-chart {
  height: 100%;
}

.interval-buttons-wrapper {
  width: 100%;
  display: flex;
  height: 3.5rem;
  align-items: center;
  margin: 0 auto;
  padding: 0 1rem;
  overflow-x: scroll;
  background-color: var(--pro-exchange-chart-timeline-background);
  border-bottom: 1px solid var(--pro-exchange-chart-top-border-color);
}

@media only screen and (max-width: 1208px) {
  .interval-buttons-wrapper {
    border: 1px solid var(--pro-exchange-chart-top-border-color);
  }
}

@media only screen and (min-height: 1080px) {
  #lightweight-chart {
    height: calc(100% - 236px);
  }
}
