.user-summary {
}

.user-summary__container {
  height: 100%;
  display: flex;
  align-items: center;
}

.user-summary__user-tier {
  position: relative;
  overflow: visible;
  height: 73px;
  display: flex;
  align-items: center;
}

.user-summary__user-xp-wrapper {
  position: absolute;
  height: 130px;
  width: 400px;
  right: 45px;
  top: 71px;
  opacity: 0;
  background: linear-gradient(131.67deg, var(--blue2) 0%, var(--purple1) 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  transition: 0.4s ease-in-out;
  pointer-events: none;
}

.user-summary__account-name {
  letter-spacing: 0px;
  margin-right: 10px;
}

.user-summary__profile-image {
  width: 64px;
  height: 64px;
  border-radius: 32px;
  object-fit: cover;
  margin-left: 24px;
  margin-right: 32px;
  cursor: pointer;
}

.user-summary__popover-menu-trigger-triangle {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid;
  display: inline-block;
}

.user-summary__username-in-display {
  font-size: 1.2rem;
  margin-right: 1rem;
}

.user-summary__user-info-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}

.user-summary__spinner-container {
  height: 100%;
  align-items: center;
  padding: 0 1.5rem;
}

.user-summary__spinner-circle {
  margin: 0 40px;
}
