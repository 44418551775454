.kyc-modal {
  min-height: 600px;
  min-width: 500px;
  overflow: auto;
  background-color: white;
}

.kyc-modal__status-wrapper {
  min-height: 210px;
  max-width: 400px;
  overflow: visible;
  background-color: var(--kyc-status-wrapper-background);
  border-radius: 20px;
}

.kyc-modal__status-wrapper__content {
  padding: 15px 20px 25px 25px;
  overflow: auto;
}

.kyc-modal__loader {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}

.kyc-modal__header {
  height: 8rem;
  display: flex;
  position: relative;
  background: var(--purple7);
  align-items: center;
  padding: 1rem 2.5rem;
  color: var(--white1);
}

.kyc-modal__status-header {
  height: 40px;
  padding-left: 25px;
  display: flex;
  align-items: center;
  z-index: 2;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.kyc-modal__header-image {
  width: 7.5rem;
  align-self: stretch;
  background-image: url(../../images/quid_side_success.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.kyc-modal__status-header-title {
  font-weight: 600;
  font-size: 14px;
  color: var(--white1);
}

.kyc-modal__header-title {
  font-size: 2rem;
  font-weight: 600;
  flex: 1 1;
}

.kyc-modal__header-close-button {
  height: 3.2rem;
  position: absolute;
  top: 0;
  right: 0;
  border-bottom-left-radius: 2.55rem;
  background-color: var(--purple1);
  display: inline-flex;
  font-size: 1.1rem;
  gap: 0.6rem;
  align-items: center;
  padding: 0 2rem;
  color: var(--white1);
}

.kyc-modal__header-close-button:hover {
  background-color: var(--blue2);
}

.kyc-modal__header-close-button:focus {
  background-color: var(--blue2);
}

.kyc-modal__header-close-button-icon {
  width: 1rem;
  height: 1rem;
  background-image: url(../../images/icons/cross.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 0.1rem;
}

/* default modal close icon, we don't need it because we use a custom component */
.kyc-modal__close-icon,
.kyc-modal__status-wrapper__close-icon {
  display: none;
}

.kyc-modal__status-absolute-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 100000;
  background-color: white;
}

.kyc-modal__status-text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.kyc-modal__status-text {
  line-height: 16px;
  padding-top: 5px;
}

.kyc-modal__status-button {
  height: 30px;
  width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  align-self: center;
  border-radius: 10px;
  border: 1px solid transparent;
  background: linear-gradient(var(--white1), var(--white1)) padding-box,
    linear-gradient(to right, darkblue, darkorchid) border-box;
}

.kyc-modal__status-button--success {
  background: linear-gradient(
        var(--kyc-status-wrapper-background),
        var(--kyc-status-wrapper-background)
      )
      padding-box,
    linear-gradient(to right, var(--purple8), var(--green3)) border-box;
}

.kyc-modal__status-button--success:hover {
  background: linear-gradient(to right, var(--purple8), var(--green3));
}

.kyc-modal__status-button--manual {
  background: linear-gradient(
        var(--kyc-status-wrapper-background),
        var(--kyc-status-wrapper-background)
      )
      padding-box,
    linear-gradient(to right, var(--blue2), var(--purple1)) border-box;
}

.kyc-modal__status-button--manual:hover {
  background: linear-gradient(to right, var(--blue2), var(--purple1));
}

.kyc-modal__status-button--deny {
  background: linear-gradient(
        var(--kyc-status-wrapper-background),
        var(--kyc-status-wrapper-background)
      )
      padding-box,
    linear-gradient(to right, var(--red2), var(--purple1)) border-box;
}

.kyc-modal__status-button--deny:hover {
  background: linear-gradient(to right, var(--red2), var(--purple1));
}

.kyc-modal__status-button:hover {
  cursor: pointer;
}

.kyc-modal__status-button:hover .kyc-modal__status-button-text {
  color: var(--white1);
}

.kyc-modal__status-icon {
  position: absolute;
  top: -48px;
  left: -12px;
}
