.buy-out-form__overlay {
  justify-content: unset;
  align-items: unset;
  padding: 1.92rem;
  overflow-y: auto;
}

.buy-out-form {
  background: var(--buy-out-form-wrapper-background);
  width: 380px;
  border-radius: 0px 20px 20px 20px;
  max-height: unset;
  margin: auto;
}

.buy-out-form__loading-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-color: var(--modal__overlay-color);
}

.buy-out-form__loading-overlay > .spinner__container {
  width: 30px;
  height: 30px;
  background-color: var(--buy-out-form-wrapper-background);
  border-radius: 9999px;
}

.buy-out-form__header {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 18px;
  background: linear-gradient(131.67deg, #3f8cff 0%, #825af6 100%);
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  font-weight: 600;
}

.buy-out-form__content {
  overflow: hidden;
  padding: 26px 18px 43px;
}

.buy-out-form__input-wrapper {
  margin-bottom: 20px;
  width: 100%;
}

.buy-out-form__input-label {
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: var(--buy-out-form-input-label-color);
  margin-bottom: 10px;
}

.buy-out-form__input,
.buy-out-form__input-area {
  padding: 10px 10px 10px 20px;
  border: 1px solid var(--buy-out-form-input-border-color);
  box-sizing: border-box;
  border-radius: 20px;
  min-height: 43px;
  max-height: 100px;
  width: 100%;
  background: var(--buy-out-form-input-background-color);
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: var(--buy-out-form-input-color);
  resize: none;
}

.buy-out-form__textarea-wrapper {
  border: 1px solid var(--buy-out-form-input-border-color);
  box-sizing: border-box;
  border-radius: 20px;
  background: var(--buy-out-form-input-background-color);
  overflow: hidden;
}

.buy-out-form__input-area {
  border: none;
  width: calc(100% - 10px);
  margin-right: 10px;
}

/* invalid non-empty input */
.buy-out-form__input:invalid.buy-out-form__input:not([value='']),
.buy-out-form__input--input-error {
  border-color: var(--red3);
}

.buy-out-form__input-area::-webkit-scrollbar {
  width: 5px;
  background: transparent;
  border-radius: 1px;
}

.buy-out-form__input-area::-webkit-scrollbar-track {
  margin-top: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.buy-out-form__input-area::-webkit-scrollbar-thumb {
  border: 1px solid var(--community-page-scroll-color);
}

.buy-out-form__input:disabled {
  color: var(--buy-out-form-input-disabled-color) !important;
}

.buy-out-form__buttons {
  display: flex;
  justify-content: center;
  gap: 40px;
}

.buy-out-form__go-back {
  border: 3px solid var(--buy-out-go-back-border-color);
  height: 40px;
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: var(--buy-out-go-back-color);
  background: var(--buy-out-go-back-background);
}

.buy-out-form__submit {
  height: 40px;
  width: 140px;
  border-radius: 20px;
  position: relative;
  box-sizing: border-box;
  background: linear-gradient(
        0deg,
        var(--buy-out-submit-background) 0%,
        var(--buy-out-submit-background) 100%
      )
      padding-box,
    linear-gradient(131.67deg, var(--blue2) 0%, var(--purple1) 100%) border-box;
  background-clip: content-box, border-box;
  padding: 3px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  color: var(--font__color-primary);
}

.buy-out-form__input-warning {
  font-size: 12px;
  color: var(--red1);
  margin-top: 5px;
}
