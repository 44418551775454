.dots {
  width: 100%;
}

.dots-spinner {
  width: 100%;
  justify-content: center;
  display: flex;
}
#dots-flow html {
  overflow-y: auto;
}
