.vote-page {
  display: flex;
  flex-direction: column;
  padding: 0 32px;
  background: var(--liquid-background-primary);
  height: 100%;
}

.vote-page__left {
  height: 100%;
}

.vote-page__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.vote-page__header-item {
  width: calc(33% - 20px);
  height: 80px;
  background: var(--vote-page-background);
  border-radius: 0px 20px 20px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 24px;
  font-weight: 600;
  opacity: 0.5;
  cursor: pointer;
}

.vote-page__header-item--active {
  opacity: 1;
}

.vote-page__info-icon {
  fill: transparent;
  stroke: var(--liquid-primary-font-color);
  width: 30px;
  cursor: pointer;
}

.vote-page__info-icon__point {
  fill: var(--liquid-primary-font-color);
}

.vote-page__info-icon__line {
  fill: var(--liquid-primary-font-color);
}

.vote-page__header-text {
  font-size: 24px;
  line-height: 36px;
}

.vote-page__filters {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  justify-content: space-between;
  font-weight: 600;
  color: var(--liquid-primary-font-color);
  gap: 20px;
}

.vote-select__wrapper {
  min-width: 140px;
  height: 40px;
  display: flex;
  flex-direction: row;
  border-radius: 999px;
  border-width: 3px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 1.5;
  padding: 5px 10px;
  gap: 8px;
  border: 3px solid var(--select-border-color);
  background-color: var(--select-background-color);
  cursor: pointer;
}

.vote-select__wrapper--with-list {
  border-radius: 20px 20px 0 0;
  border-bottom-color: transparent;
}

.vote-select__wrapper:hover > .vote-select__select,
.vote-select__wrapper--with-list > .vote-select__select {
  text-decoration: underline;
}

.vote-select__select {
  all: unset;
}

.vote-select__arrow {
  all: unset;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vote-select__arrow svg:not(:root) {
  width: 12px;
  height: 12px;
}

.vote-select__arrow path {
  fill: var(--select-text-color);
  stroke: var(--select-text-color);
}

.vote-select__list-wrapper {
  top: 34px;
  width: calc(100% + 6px);
  border: 3px solid var(--select-border-color);
  border-top: none;
  border-radius: 0 0 20px 20px;
  background-color: var(--select-background-color);
  padding: 0;
}

.vote-select__list-inner-wrapper {
  padding: 0;
  max-width: unset;
  position: relative;
}

.vote-select__list-inner-wrapper::before {
  content: '';
  width: 100%;
  height: 2px;
  background-color: var(--vote-select-divider-color);
}

.vote-select__list-item {
  cursor: pointer;
  padding: 2.5px 24px;
  color: var(--vote-select-item-color);
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
}

.vote-select__list-item:hover,
.vote-select__list-item--active {
  color: var(--select-text-color);
  background: var(--vote-select-active-item);
}

.vote-select__list-item-divider {
  background: var(--vote-select-divider-color);
  margin: 0;
}

.vote-page__search-input-container {
  width: 240px;
  display: flex;
  flex-direction: row;
  position: relative;
}

.vote-page__search-icon-container {
  position: absolute;
  left: -0.5px;
  top: -2.5px;
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--blue6);
  border-radius: 0px 50% 50% 50%;
  box-shadow: 0px 5px 10px
    var(--instrument-selector-search-input-icon-shadow-color);
}

.vote-page__search-icon {
  height: 22.5px;
  width: 22.5px;
}

.vote-page__search-input {
  width: 100%;
  height: 40px;
  color: var(--instrument-selector-search-input-color);
  background-color: var(--instrument-selector-search-input-background);
  padding-left: 60px;
  padding-right: 14.4px;
  border-radius: 0 999px 999px 999px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  border: 0.5px solid var(--instrument-selector-search-input-border-color);
}

.vote-page__search-input::placeholder {
  color: var(--instrument-selector-search-input-color);
}

.vote-page__cards-fetching__spinner-container {
  position: relative;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
}

.vote-page__cards-container {
  width: 100%;
  height: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.vote-page__card-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vote-page__card {
  width: 100%;
  min-height: 310px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0 20px 20px 20px;
  margin-bottom: 20px;
  position: relative;
}

.vote-page__card--closed {
  backdrop-filter: blur(200px);
  opacity: 0.4;
}

.vote-page__closed-baner {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  font-weight: 600;
  font-size: 64px;
  line-height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vote-page__left-body {
  padding-left: 40px;
}

.vote-page__right {
  width: 240px;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 48px;
}

.vote-page__card-image {
  width: 280px;
  height: 280px;
}

.vote-page__card-image--blured {
  opacity: 0.5;
}

.vote-page__buyout-banner-container {
  width: 100%;
  max-width: 573px;
  height: 62px;
  font-weight: 600;
  font-size: 28px;
  line-height: 62px;
  padding-left: 40px;
}

.vote-page__card-title {
  max-width: 450px;
  margin-top: 28px;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  color: var(--voteTextColor);
  cursor: pointer;
}

.vote-page__card-description {
  max-width: 450px;
  margin-top: 20px;
  font-size: 12px;
  line-height: 18px;
  color: var(--voteTextColor);
  pointer-events: none;
}

.vote-page__card-info-container {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  gap: 40px;
}

.vote-page__card-info-block {
  width: 280px;
  height: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0 20px 20px 20px;
  border: 1px solid var(--liquid-primary-font-color);
}

.vote-page__card-info-block--buy-out {
  width: 180px;
}

.vote-page__card-info-block-title {
  font-size: 10px;
  margin-top: 9px;
  color: var(--voteTextColor);
}

.vote-page__card-info-block-value {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 600;
  color: var(--voteTextColor);
}

.vote-page__card-subtitle {
  margin-top: 40px;
  font-size: 20px;
  font-weight: 600;
  color: var(--voteTextColor);
  margin-bottom: 10px;
}

.vote-page__bottom-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.vote-page__vote-button__on-vote-buttons {
  margin-top: 10px;
  margin-bottom: 10px;
}

.vote-page__vote-button__voted {
  margin-top: 20px;
  margin-bottom: 30px;
  background: var(--grey2);
  color: var(--white1);
}

.vote-page__voting-progress-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.vote-page__voting-progress-line {
  width: 108px;
  height: 12px;
  border-radius: 14px;
  display: flex;
  border: 1px solid var(--white1);
  align-items: center;
  justify-content: center;
  position: relative;
  margin-left: 40px;
}

.vote-page__voting-progress-value {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  margin-left: 10px;
  color: var(--white1);
}

#voting-progress {
  width: 100px;
  height: 6px;
  border: 1px solid var(--white1);
  border-radius: 20px;
}

#voting-progress[value]::-webkit-progress-bar {
  border-radius: 20px;
  background: transparent;
}

#voting-progress[value]::-webkit-progress-value {
  background: var(--white1);
  border-radius: 20px;
}

.vote-page__result-status-description {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: var(--white1);
  margin-bottom: 20px;
}

.vote-page__no-assets-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  position: relative;
  text-align: center;
  color: var(--grey13);
}

.vote-page__no-assets-logo {
  position: absolute;
  height: 100%;
  width: auto;
}

@media (max-width: 1350px) {
  .vote-page__card-image {
    position: absolute;
    right: 0;
  }

  .vote-page__card-info-container--buy-out {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .vote-page__card-info-block--buy-out {
    width: 100%;
  }
}

@media (max-width: 1250px) {
  .vote-page__card-info-block {
    width: 200px;
  }
}

@media (max-width: 1150px) {
  .vote-page__card-image {
    width: 240px;
    height: 240px;
  }
}

@media (max-width: 1100px) {
  .vote-page__vote-button__on-vote-buttons {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .vote-page__right {
    margin-right: 25px;
  }

  .vote-page__card-image {
    position: unset;
  }
}

@media (max-width: 1050px) {
  .vote-page__header-text {
    font-size: 20px;
  }
}

@media (max-width: 1000px) {
  .vote-page__left-body,
  .vote-page__buyout-banner-container {
    padding-left: 25px;
  }

  .vote-page__card-info-block {
    width: 150px;
  }

  .vote-page__card-description {
    max-width: 300px;
  }

  .vote-page__header-item {
    font-size: 20px;
  }

  .vote-page__voting-progress-line {
    margin-left: 15px;
  }

  .vote-page__card-image {
    width: 200px;
    height: 200px;
  }

  .vote-page__card-subtitle {
    font-size: 18px;
  }
}

@media (max-width: 920px) {
  .vote-page__bottom-container {
    flex-direction: column;
  }

  .vote-page__bottom-container {
    margin-bottom: 15px;
  }

  .vote-page__info-icon {
    width: 25px;
  }

  .vote-page__header-item {
    font-size: 18px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 900px) {
  .vote-page__card-info-container {
    gap: 25px;
  }

  .vote-page__card-title {
    font-size: 21px;
  }

  .vote-page__card-subtitle {
    font-size: 18px;
  }

  .vote-page__card-info-block {
    width: 125px;
  }

  .vote-page__card-subtitle {
    max-width: 300px;
  }

  .vote-page__right {
    margin-right: 20px;
  }

  .vote-page__buyout-banner-container {
    position: absolute;
    top: 0;
  }

  .vote-page__left-body {
    margin-top: 72px;
  }
}

@media (max-width: 800px) {
  .vote-page__bottom-container {
    flex-direction: row;
  }

  .vote-page__card-image {
    position: absolute;
    width: 240px;
    height: 240px;
  }
}

@media (max-width: 750px) {
  .vote-page__header {
    padding-left: 15px;
  }

  .vote-page__header-text {
    max-width: 230px;
    line-height: 18px;
    font-size: 12px;
    font-weight: 600;
  }

  .vote-page__left-body,
  .vote-page__buyout-banner-container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .vote-page__card-title {
    font-size: 16px;
    margin-top: 13px;
  }

  .vote-page__card-description {
    font-size: 12px;
    font-weight: 500;
    margin-top: 10px;
  }

  .vote-page__card-info-container {
    flex-direction: row;
    margin-top: 20px;
  }

  .vote-page__card-info-container--buy-out {
    grid-template-columns: 1fr;
  }

  .vote-page__card-info-block {
    margin-bottom: 10px;
    width: 130px;
  }

  .vote-page__card-info-block--buy-out {
    width: 100%;
    max-width: 280px;
  }
}

@media (max-width: 700px) {
  .vote-page__sort-container {
    margin-top: 10px;
  }
}

@media (max-width: 650px) {
  .vote-page__header-item {
    height: 60px;
    font-size: 18px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .vote-page__right {
    max-width: 45%;
  }

  .vote-page__info-icon {
    width: 25px;
  }

  .vote-page__card-info-block {
    margin-bottom: 0;
  }

  .vote-page__card-image {
    width: 200px;
    height: 200px;
  }

  .vote-page__no-assets-logo {
    height: 320px;
    width: 300px;
  }
}

@media (max-width: 576px) {
  .vote-page {
    padding-left: 16px;
    padding-right: 16px;
  }

  .vote-progress__wrapper {
    width: 100%;
  }

  .vote-progress__progress-line-wrapper {
    width: 100%;
  }

  .vote-progress__progress-title {
    min-width: 25px;
  }

  .vote-page__left {
    margin-top: 40px;
    max-width: 100%;
  }

  .vote-page__header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 0;
  }

  .vote-page__header-item {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .vote-page__closed-baner {
    font-size: 48px;
    line-height: 62px;
  }

  .vote-page__card-info-container {
    gap: 10px;
    flex-direction: column;
    align-items: center;
  }

  .vote-page__card-info-block {
    width: 100%;
  }

  .vote-page__right {
    width: 200px;
    max-width: 200px;
    margin-left: 10px;
  }

  .vote-page__card-image {
    width: 190px;
    height: 190px;
    position: unset;
  }

  .vote-page__buyout-banner-container {
    padding-left: 40px;
  }

  .vote-page__filters {
    margin-top: 30px;
    align-items: center;
  }

  .vote-select__wrapper {
    min-width: 100px;
    font-size: 14px;
    gap: 4px;
  }

  .vote-select__arrow {
    width: 16px;
    height: 16px;
  }

  .vote-select__arrow svg:not(:root) {
    width: 8px;
    height: 8px;
  }

  .vote-select__list-item {
    cursor: pointer;
    padding: 2.5px 12px;
    font-size: 12px;
  }

  .vote-page__search-input-container {
    width: 160px;
  }

  .vote-page__search-icon-container {
    left: -1px;
    top: 0;
    height: 26px;
    width: 26px;
  }

  .vote-page__search-icon {
    height: 11px;
    width: 11px;
  }

  .vote-page__search-input {
    width: 100%;
    height: 25px;
    padding-left: 30px;
    padding-right: 5px;
    font-size: 10px;
  }

  .vote-page__card {
    flex-direction: column-reverse;
    background-position: center top 30px;
    align-items: center;
    padding-left: 0;
  }

  .vote-page__right {
    margin: auto;
    margin-top: 110px;
  }

  .vote-page__card-description {
    margin: auto;
    text-align: justify;
  }

  .vote-page__card-subtitle {
    text-align: center;
  }

  .vote-page__bottom-container {
    flex-direction: column-reverse;
  }

  .vote-page__voting-progress-container {
    margin-top: 20px;
  }

}
