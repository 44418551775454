.my-collectibles-page {
  padding: 0px 32px;
  width: 100%;
  background: var(--my-collectibless-background);
}

.my-collectibles-page__footer {
  padding: 0 4px;
}

@media (max-width: 576px) {
  .my-collectibles-page {
    padding: 0px 19.2px;
  }
}
