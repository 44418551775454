.my-collections-section {
  margin-top: 32px;
  background: var(--my-collectibles-section-background);
  border-radius: 0 25.6px 25.6px 25.6px;
  overflow: hidden;
}

.my-collections-section__no-assets-wrapper {
  height: 100%;
  width: 100%;
  background-color: var(--my-collectibles-no-assets-background);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 500px;
}

.my-collections-section__no-assets-logo {
  position: absolute;
  z-index: 1;
}

.my-collections-section__no-assets-text {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  z-index: 2;
  color: var(--my-collectibles-no-assets-color);
}

@media (max-width: 576px) {
  .my-collections-section {
    margin-top: 19.2px;
  }
}
