.checkout-form__form {
  align-self: center;
  border-radius: 7px;
  padding: 40px;
  padding-top: 0;
}

#payment-message {
  color: var(--red1);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
  -webkit-overflow-scrolling: touch;
}

#payment-element iframe {
  zoom: var(--device-stripe-zoom);
}

/* .checkout-form__buttons and links */
.checkout-form__button {
  display: flex;
  width: 250px;
  border-radius: 20px;
  height: 41px;
  margin: auto;
  background: var(--blue6);
  font-family: Arial, sans-serif;
  color: var(--white1);
  border: 0;
  padding: 12px 16px;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  justify-content: center;
  margin-top: 25px;
}

.checkout-form__button:hover {
  filter: contrast(115%);
}

.checkout-form__button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.checkout-form__spinner,
.checkout-form__spinner:before,
.checkout-form__spinner:after {
  border-radius: 50%;
}

.checkout-form__formLoader {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkout-form__spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.checkout-form__spinner:before,
.checkout-form__spinner:after {
  position: absolute;
  content: '';
}

.checkout-form__spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.checkout-form__spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.checkout-form__form #card-number-element,
#card-expiration-element,
#card-cvv-element {
  height: 40px;
  border-radius: 20px;
  border: 1px solid var(--deposit-withdraw-font-primary);
  padding-left: 15px;
}

.checkout-form__form label {
  color: var(--deposit-withdraw-font-primary);
  font-size: 14px;
  font-weight: 300;
  font-family: var(--font__typeface);
}

.checkout-form__form > div > div {
  gap: 20px;
}

.checkout-form__select__wrapper {
  height: 40px;
}

.checkout-form__select__arrow {
  top: 16.5px;
}

.checkout-form__select__list-wrapper {
  top: 39px;
  width: 100%;
}

.checkout-form__country-wrapper {
  margin-top: 20px;
}

.checkout-form__inputWrapper {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.checkout-form__inputWrapper:first-child {
  margin-top: 0;
}

.checkout-form__deposit-input {
  width: 100%;
  height: 40px;
  padding: 4px;
  padding-left: 15px;
  background: transparent;
  border: 1px solid var(--deposit-withdraw-font-primary);
  border-radius: 20px;
  color: var(--deposit-withdraw-font-primary);
  background: var(--deposit-input-background);
}

.checkout-form__deposit-input:disabled {
  background: var(--input__bg-color--disabled);
  border: 1px solid transparent;
}