.settings-enable-two-factor-auth__2fa-container {
  display: flex;
  flex-direction: column;
}

.settings-enable-two-factor-auth__info-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.settings-enable-two-factor-auth__info-row:last-child {
  margin-top: 24px;
}

.settings-enable-two-factor-auth__disabled-button {
  height: 0;
  visibility: hidden;
  pointer-events: none;
}

.settings-enable-two-factor-auth__recovery2FA {
  background: var(--blue2) 0% 0% no-repeat padding-box;
  height: 32px;
  width: 92px;
  box-shadow: 0px 7px 15px #00000029;
  border-radius: 16px;
  opacity: 1;

  text-align: center;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.settings-enable-two-factor-auth__info {
  margin-left: 3rem;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  letter-spacing: 0px;
  color: var(--settings-font-primary);
  opacity: 1;
}

.settings-enable-two-factor-auth__button-wrapper {
  letter-spacing: 0.1rem;
}

.settings-enable-two-factor-auth .ap-switch__toggle {
  background: #e4eff4 0% 0% no-repeat padding-box;
}

.settings-enable-two-factor-auth .ap-switch__toggle--on {
  background: #e4eff4 0% 0% no-repeat padding-box;
}

.settings-enable-two-factor-auth .ap-switch__text {
  color: var(--font__color-secondary);
  font-weight: bolder;
}

.settings-enable-two-factor-auth .ap-switch__text--on {
  color: var(--font__color-primary);
}

.settings-enable-two-factor-auth__btn {
  display: block;
  width: 9rem;
  border: 2px solid var(--border__color);
  border-radius: 16px;
  color: var(--font__color-secondary);
  font-weight: bolder;
  background-color: inherit;
}

.settings-enable-two-factor-auth__btn:hover,
.settings-enable-two-factor-auth__btn:focus {
  background: inherit;
  border: 2px solid var(--border__color);
}

@media (max-width: 576px) {
  .settings-enable-two-factor-auth__info-row {
    flex-direction: column;
  }
  .settings-enable-two-factor-auth__info {
    margin-left: 0;
    margin-top: 16px;
  }

  .settings-enable-two-factor-auth__info {
    line-height: 30px;
  }
}
