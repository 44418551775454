.buy-sell-product-card__card-wrapper {
  min-width: 341px;
  max-width: 341px;
  padding: 16px 18px;
  border-radius: 20px;
  position: relative;
  z-index: 1;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buy-sell-product-card__warning-wrapper {
  align-self: center;
}

.buy-sell-product-card__warning-wrapper--hidden {
  visibility: hidden;
}

.buy-sell-product-card__arrowUp,
.buy-sell-product-card__arrowDown {
  margin-right: 5px;
}

.buy-sell-product-card__arrowDown {
  transform: rotate(180deg);
  padding-top: 1px;
}

.buy-sell-product-card__card-title-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.buy-sell-product-card__card-title {
  color: var(--white1);
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 85%;
}

.buy-sell-product-card__card-price-change {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--white1);
  white-space: nowrap;
}

.buy-sell-product-card__card-image {
  width: 130px;
  height: 185px;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.5s ease-in-out;
  margin-top: 11px;
  filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.15));
}

.buy-sell-product-card__last-price-wrapper {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 10px;
}

.buy-sell-product-card__last-price-title {
  width: 80px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--grey2);
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--white1);
}

.buy-sell-product-card__last-price-value {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--white1);
}

.buy-sell-product-card__last-price-value-currency {
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: var(--white1);
  margin-left: 5px;
}

.buy-sell-product-card__buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 20px;
}

.buy-sell-product-card__button-value {
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  margin-left: 10px;
  margin-top: 4px;
}

@media (max-width: 576px) {
  .buy-sell-product-card__card-wrapper {
    max-width: 341px;
    align-items: center;
    display: flex;
    flex-direction: column;
    min-width: 320px;
    width: auto;
  }
  .buy-sell-product-card__card-title {
    align-self: flex-start;
    color: var(--white1);
    width: 100%;
  }

  .buy-sell-product-card__buttons {
    margin-top: 20px;
    padding-bottom: 0px;
    flex-direction: row;
  }

  .buy-sell-product-card__warning-wrapper {
    padding-left: 10px;
    align-self: flex-start;
  }
}
