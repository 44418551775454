.vote-timer {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vote-timer__time,
.vote-timer__remaining {
  font-size: 32px;
  font-weight: 700;
  color: var(--white1);
  text-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
  white-space: nowrap;
}

.vote-timer__remaining {
  font-size: 18px;
  font-weight: 600;
}

@media (max-width: 900px) {
  .vote-timer__time {
    font-size: 28px;
  }

  .vote-timer__remaining {
    font-size: 16px;
  }
}

@media (max-width: 576px) {
  .vote-timer__time {
    font-size: 18px;
    line-height: 27px;
  }

  .vote-timer__remaining {
    font-size: 14px;
  }
}
