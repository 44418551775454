.public-portfolio {
  width: 100%;
  background: var(--liquid-background-primary);
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  justify-content: space-between;
}

.public-portfolio__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.public-portfolio__header {
  display: flex;
}

.public-portfolio__public-logo-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  pointer-events: none;
}

.public-portfolio__warning-message {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: 600;
}

.public-portfolio__logo {
  width: 88px;
  height: 96px;
  margin: 48px 24px 0 40px;
  background-image: url(../../images/site-logo.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  z-index: 10;
}

.public-portfolio__wordmark {
  width: 352px;
  height: 56px;
  margin-top: 41px;
  background-image: url(../../images/WORDMARK-DARK.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  z-index: 10;
}

.public-portfolio__title-container {
  width: 540px;
  max-width: 540px;
  height: 378px;
  margin: auto;
  margin-bottom: 25px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(131.67deg, #3f8cff 0%, #825af6 100%);
  border-radius: 0px 0px 270px 270px;
  flex-direction: column;
}

.public-portfolio__title {
  position: absolute;
  color: var(--white2);
  left: 50px;
  font-size: 26px;
  font-weight: 600;
}

.public-portfolio__tier-animation,
.public-portfolio__grandmaster-animation {
  width: 350px;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  margin-bottom: -55px;
}

.public-portfolio__grandmaster-animation {
  margin-bottom: -30px;
}

.public-portfolio__collectibles-container {
  width: calc(100% - 84px);
  max-width: 1400px;
  margin: auto;
  display: flex;
  flex-direction: column;
  border-radius: 0px 32px 32px 32px;
  margin-bottom: 43px;
}

.public-portfolio__table-header {
  width: calc(100%-80px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.public-portfolio__table-header {
  color: var(--liquid-primary-font-color);
  padding-bottom: 50px;
  width: calc(100% - 184px);
  margin: auto;
  position: relative;
}

.public-portfolio__account-name {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin-top: 10px;
  color: var(--public-profile-text-color);
}

.public-portfolio__account-tier-color {
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

.public-portfolio__account-tier {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: var(--public-profile-text-color);
}

.public-portfolio__menu-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  width: 100%;
  display: none;
}

.public-portfolio__menu-item {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  height: 36px;
  position: relative;
  width: 120px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  color: var(--public-profile-text-color);
}

.public-portfolio__menu-item:before {
  content: '';
  height: 5px;
  width: 100%;
  position: absolute;
  bottom: 0;
  border-radius: 20px;
}

.public-portfolio__menu-item-cards:before {
  background: #74a1f8;
}

.public-portfolio__menu-item-nft:before {
  background: var(--pink2);
}

.public-portfolio__menu-item-items:before {
  background: var(--purple8);
}

.public-portfolio__menu-item-nft,
.public-portfolio__menu-item-items {
  opacity: 0.3;
  cursor: not-allowed;
}

.public-portfolio__account-tier-wrapper {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-top: 10px;
}

.public-portfolio__joined-date {
  margin-top: 20px;
  font-size: 12px;
  line-height: 18px;
  color: var(--public-profile-text-color);
}

.public-portfolio__sort-select-container {
  position: absolute;
  right: 0;
  top: 95px;
  width: 214px;
  height: 48px;
  border: 2px solid var(--purple1);
  border-radius: 0px 28px 28px 28px;
  display: flex;
  cursor: pointer;
}

.public-portfolio__sort-select {
  width: calc(100% - 10px);
  margin: auto;
  height: 100%;
  font-size: 20px;
  text-align: center;
  border-radius: 0px 28px 28px 28px;
  cursor: pointer;
  z-index: 100;
}

.public-portfolio__cards-container {
  width: calc(100% - 172px);
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, 300px);
  grid-gap: 10px;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 100px;
  padding-top: 20px;
}

.public-portfolio__card__card-wrapper {
  width: 300px;
}

.public-portfolio__card__card {
  padding: 0;
}

.public-portfolio__card__card-title {
  padding-top: 10px;
}
.public-portfolio__card__card-price,
.public-portfolio__card__card-price-change {
  display: none;
}

.public-portfolio__card__card-image {
  width: 100%;
  margin-bottom: 0;
  height: 425px;
}

.public-portfolio__table-footer {
  display: flex;
  align-items: center;
  margin: auto;
  flex-direction: column;
  padding-bottom: 67px;
  cursor: pointer;
}

.public-portfolio__more-button {
  width: 200px;
  height: 52px;
  font-size: 20px;
  font-weight: 600;
  color: var(--liquid-primary-font-color);
  border: 2px solid var(--blue2);
  border-radius: 0px 28px 28px 28px;
  text-transform: uppercase;
}

.public-portfolio__down-icon {
  margin-top: 15px;
  font-size: 30px;
  font-weight: 600;
  height: 24px;
  width: 20px;
  transform: rotate(0.5turn);
}

.public-portfolio__spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media (max-width: 1250px) {
  .public-portfolio__cards-container {
    grid-template-columns: repeat(2, 300px);
  }
}

@media (max-width: 900px) {
  .public-portfolio__cards-container {
    grid-template-columns: 1fr;
  }

  .public-portfolio__card__card-wrapper {
    margin: auto;
  }

  .public-portfolio__sort-select-container {
    position: unset;
    margin-top: 20px;
  }
}

@media (max-width: 576px) {
  .public-portfolio__public-logo-wrapper {
    display: none;
  }
  .public-portfolio__menu-container {
    gap: 30px;
  }
  .public-portfolio__menu-item {
    width: 100px;
  }
  .public-portfolio__header {
    height: 96px;
    box-shadow: 0px 2px 8px var(--black4);
  }

  .public-portfolio__title-container {
    margin: 0;
    margin-bottom: 25px;
  }

  .public-portfolio__title {
    position: relative;
    margin: auto;
    left: 0;
  }

  .public-portfolio__collectibles-container {
    width: 100%;
    background: transparent;
  }
  .public-portfolio__table-header {
    width: calc(100% - 48px);
  }

  .public-portfolio__sort-select-container {
    position: unset;
  }

  .public-portfolio__sort-select {
    background: transparent;
  }

  .public-portfolio__cards-container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .public-portfolio__card__card-wrapper {
    width: calc(100% - 156px);
    max-width: calc(100% - 156px);
    margin-bottom: 50px;
  }

  .public-portfolio__card__card-image {
    width: 100%;
    margin-bottom: 0;
    height: 420px;
    width: 300px;
  }

  .public-portfolio__card__placeholder {
    width: 272px;
  }
  .public-portfolio__card__card-title {
    padding-left: 10px;
  }
  .public-portfolio__card__highlight-line {
    margin-left: 10px;
    align-self: flex-start;
  }
}
