.user-name-tier-badge {
  position: relative;
  width: 304px;
  height: 110.4px;
  overflow: hidden;
}

.user-name-tier-badge__stub-tier {
  position: absolute;
  height: 73.6px;
  width: 236.8px;
  left: 28px;
  top: 12px;
  cursor: pointer;
  z-index: 2;
}

.user-name-tier-badge:hover + .user-summary__user-xp-wrapper {
  opacity: 1;
  pointer-events: all;
}

.user-name-tier-badge__badge {
  position: relative;
  right: 20px;
  bottom: 11.2px;
  pointer-events: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.user-name-tier-badge__name {
  width: 50%;
  max-width: 50%;
  height: 28.8px;
  position: absolute;
  top: 40px;
  left: 20%;
  text-align: center;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  cursor: pointer;
}

.user-name-tier-badge__tier {
  overflow: hidden;
  z-index: 3;
}

.user-name-tier-badge__degree {
  position: absolute;
  height: 52px;
  width: 78px;
  top: 22px;
  right: 49px;
  display: flex;
  color: var(--white1);
  font-size: 8px;
  background: var(--purple8);
  border-radius: 0 25px 50% 50%;
  z-index: -1;
  padding-left: 4px;
  padding-top: 1px;
}

.user-name-tier-badge__degree-ending {
  font-size: 6px;
  text-align: start;
  margin-right: 4px;
}

@media (max-width: 1070px) {
  .user-name-tier-badge {
    width: 240px;
  }
}

@media (max-width: 576px) {
  .user-name-tier-badge {
    width: 200px;
    height: 76.8px;
  }
  .user-name-tier-badge__name {
    top: 24px;
    font-size: 12px;
  }
  .user-name-tier-badge:hover + .user-summary__user-xp-wrapper {
    opacity: 0;
  }
}
