.order-book__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  flex-shrink: 0;
  border-left: 1px solid var(--pro-exchange-chart-container-border-color) !important;
}

.order-book__container .flex-table__title {
  border: 1px solid var(--pro-exchange-order-book-title-border-color) !important;
  border-left: none !important;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--pro-exchange-order-book-title-background-color);
  color: var(--pro-exchange-order-book-title-color);
}

.trading-layout__tabs-container .order-book__container {
  border-left: 1px solid var(--pro-exchange-chart-container-border-color) !important;
}

.orderbook__table-wrapper {
  display: block;
}

.orderbook__table-filler {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.orderbook__table-filler > :first-child {
  margin-top: auto;
}

.orderbook__table-header-price,
.orderbook__table-header-qty {
  cursor: pointer;
}

.orderbook__spread-row {
  padding: 0 0.5rem 0 1.15rem;
  flex-basis: var(--component__header-height);
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--component__color-primary);
  background: var(--orderbook__spread-bg-color);
  border-top: 1px solid var(--orderbook__spread-border-color);
  border-bottom: 1px solid var(--orderbook__spread-border-color);
  padding-left: 15.5px !important;
  height: 34px;
  max-height: 34px;
}

.orderbook__center-button__image {
  margin-bottom: 0;
  height: 12px;
}

.orderbook__body {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.orderbook__header {
  flex-basis: 3rem;
  border: 1px solid var(--pro-exchange-order-book-title-border-color) !important;
  border-left: none !important;
  background: var(--pro-exchange-order-book-header-background);
}

.orderbook__spread-row-title {
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
}

.orderbook__spread-row-value {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-left: 35px;
}

.orderbook__header .flex-table__column {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid var(--pro-exchange-order-book-title-border-color);
  height: 100%;
  min-width: 80px !important;
  width: 80px !important;
  max-width: 80px !important;
  background: var(--pro-exchange-order-book-header-background);
  color: var(--pro-exchange-order-book-title-color);
}

.orderbook__header .flex-table__column:last-child {
  border-right: none !important;
  min-width: 88px !important;
  width: 100% !important;
  max-width: 100% !important;
}

.orderbook__vertical-scroller-body .flex-table__column {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid var(--pro-exchange-order-book-title-border-color);
  border-bottom: 1px solid var(--pro-exchange-order-book-title-border-color);
  border-top: 1px solid var(--pro-exchange-order-book-title-border-color);
  height: 100%;
  background: var(--pro-exchange-order-book-row-background);
}

.orderbook__vertical-scroller-body .flex-table__column:last-child {
  border-right: none !important;
}

.orderbook__vertical-scroller-body .flex-table__column:first-child {
  min-width: 80px !important;
  width: 80px !important;
  max-width: 80px !important;
}

.orderbook__vertical-scroller-body .flex-table__column:nth-child(2) {
  min-width: 80px !important;
  width: 80px !important;
  max-width: 80px !important;
}

.orderbook__vertical-scroller-body {
  background: var(--pro-exchange-order-book-row-background);
}

.orderbook__order-row {
  height: 25px;
  line-height: 25px;
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  border-bottom: none !important;
}

.orderbook__order-row--buy:first-child {
  border-top: 1px solid var(--pro-exchange-order-book-title-border-color);
}

.orderbook__table-my-size {
  border-right: none !important;
}

.ap-padded-decimal--sell,
.ap-padded-decimal,
.ap-padded-decimal--buy {
  text-align: center !important;
}

.orderbook__order-book-body {
  height: calc(100% - 3.5rem);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.orderbook__spinner-container {
  position: relative;
}

.orderbook__spinner-container--sell {
  margin-bottom: 17rem;
}

.orderbook__spinner-container--buy {
  margin-top: 17rem;
}

.orderbook__vertical-scroller-body {
  flex-grow: 1;
  /* without static height property buys and sells blocks will have different heights, flex-grow: 1 without static height doesnt work*/
  /* it doesnt mean component will have 200px, no, it will take all space divided to 2 except spread block, which is expected behavior */
  flex-basis: 200px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  border-right: 1px solid var(--pro-exchange-order-book-title-border-color);
  overflow-y: auto;
}

.orderbook__vertical-scroller-body--firefox {
  overflow: auto;
  overflow-x: hidden;
}

.orderbook__vertical-scroller-body:last-child {
  justify-content: flex-start;
}

.orderbook__table-price {
  min-width: 8rem;
  padding: 0 1rem;
  cursor: pointer;
  text-align: center !important;
}

.orderbook__table-qty {
  flex: 1.2;
  padding: 0 0.3rem;
  min-width: 8rem;
  text-align: center !important;
  cursor: pointer;
}

.orderbook__table-my-size {
  padding: 0 0.3rem;
  flex: 1;
  text-align: center !important;
  min-width: 8rem;
}
.orderbook__cancel-btn {
  padding-right: 0.3rem;
  height: 100%;
  max-width: 2rem;
  min-width: 16px;
}

.orderbook__table-my-size--firefox {
  overflow-x: auto;
}

.orderbook__btn {
  padding: 0;
  border: none;
  height: 100%;
}

.orderbook__image {
  height: 100%;
  width: 16px;
  margin: 0;
}

@media (max-width: 1200px) {
  .orderbook__header .flex-table__column {
    width: 32% !important;
    max-width: 32% !important;
    min-width: 32% !important;
  }
  .orderbook__header .flex-table__column:last-child {
    width: 36% !important;
    max-width: 36% !important;
    min-width: 36% !important;
  }
  .orderbook__vertical-scroller-body .flex-table__column:first-child,
  .orderbook__vertical-scroller-body .flex-table__column:nth-child(2) {
    width: 32% !important;
    max-width: 32% !important;
    min-width: 32% !important;
  }

  .orderbook__vertical-scroller-body {
    border-left: 1px solid var(--pro-exchange-order-book-title-border-color);
  }

  .orderbook__header {
    border-left: 1px solid var(--pro-exchange-order-book-title-border-color) !important;
  }
}
