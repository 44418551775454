.my-assets-image-preview__productPreview {
  position: absolute;
  margin: auto;
  top: 35px;
  bottom: 0;
  right: -24px;
  opacity: 1;
  text-align: center;
  z-index: 11;
  pointer-events: none;
  height: 100%;
  width: 100%;
  background-position: 75% 70%;
  background-size: 150%;
  background-repeat: no-repeat;
}

.my-assets-image-preview__productPreview--startAnimation {
  -webkit-animation: changeImage 1s ease; /* Safari 4+ */
  -moz-animation: changeImage 1s ease; /* Fx 5+ */
  -o-animation: changeImage 1s ease; /* Opera 12+ */
  animation: changeImage 1s ease; /* IE 10+, Fx 29+ */
}

@-webkit-keyframes changeImage {
  from {
    right: -24px;
    opacity: 1;
  }
  47.5% {
    right: 0;
    opacity: 0;
  }
  52.5% {
    right: -48px;
    opacity: 0;
  }
  to {
    right: -24px;
    opacity: 1;
  }
}
@-moz-keyframes changeImage {
  from {
    right: -24px;
    opacity: 1;
  }
  47.5% {
    right: 0;
    opacity: 0;
  }
  52.5% {
    right: -48px;
    opacity: 0;
  }
  to {
    right: -24px;
    opacity: 1;
  }
}
@-o-keyframes changeImage {
  from {
    right: -24px;
    opacity: 1;
  }
  47.5% {
    right: 0;
    opacity: 0;
  }
  52.5% {
    right: -48px;
    opacity: 0;
  }
  to {
    right: -24px;
    opacity: 1;
  }
}
@keyframes changeImage {
  from {
    right: -24px;
    opacity: 1;
  }
  47.5% {
    right: 0;
    opacity: 0;
  }
  52.5% {
    right: -48px;
    opacity: 0;
  }
  to {
    right: -24px;
    opacity: 1;
  }
}

@media (max-width: 1650px) {
  .my-assets-image-preview__productPreview {
    right: -10px;
  }
  @-webkit-keyframes changeImage {
    from {
      right: -10px;
      opacity: 1;
    }
    47.5% {
      right: 0;
      opacity: 0;
    }
    52.5% {
      right: -48px;
      opacity: 0;
    }
    to {
      right: -10px;
      opacity: 1;
    }
  }
  @-moz-keyframes changeImage {
    from {
      right: -10px;
      opacity: 1;
    }
    47.5% {
      right: 0;
      opacity: 0;
    }
    52.5% {
      right: -48px;
      opacity: 0;
    }
    to {
      right: -10px;
      opacity: 1;
    }
  }
  @-o-keyframes changeImage {
    from {
      right: -10px;
      opacity: 1;
    }
    47.5% {
      right: 0;
      opacity: 0;
    }
    52.5% {
      right: -48px;
      opacity: 0;
    }
    to {
      right: -10px;
      opacity: 1;
    }
  }
  @keyframes changeImage {
    from {
      right: -10px;
      opacity: 1;
    }
    47.5% {
      right: 0;
      opacity: 0;
    }
    52.5% {
      right: -48px;
      opacity: 0;
    }
    to {
      right: -10px;
      opacity: 1;
    }
  }
}

@media (max-width: 1280px) {
  .my-assets-image-preview__productPreview {
    right: -24px;
  }
  @-webkit-keyframes changeImage {
    from {
      right: -24px;
      opacity: 1;
    }
    47.5% {
      right: 0;
      opacity: 0;
    }
    52.5% {
      right: -48px;
      opacity: 0;
    }
    to {
      right: -24px;
      opacity: 1;
    }
  }
  @-moz-keyframes changeImage {
    from {
      right: -24px;
      opacity: 1;
    }
    47.5% {
      right: 0;
      opacity: 0;
    }
    52.5% {
      right: -48px;
      opacity: 0;
    }
    to {
      right: -24px;
      opacity: 1;
    }
  }
  @-o-keyframes changeImage {
    from {
      right: -24px;
      opacity: 1;
    }
    47.5% {
      right: 0;
      opacity: 0;
    }
    52.5% {
      right: -48px;
      opacity: 0;
    }
    to {
      right: -24px;
      opacity: 1;
    }
  }
  @keyframes changeImage {
    from {
      right: -24px;
      opacity: 1;
    }
    47.5% {
      right: 0;
      opacity: 0;
    }
    52.5% {
      right: -48px;
      opacity: 0;
    }
    to {
      right: -24px;
      opacity: 1;
    }
  }
}

@media (max-width: 1100px) {
  .my-assets-image-preview__productPreview {
    right: 0;
    background-position: 70% 70%;
  }
  @-webkit-keyframes changeImage {
    from {
      right: 0;
      opacity: 1;
    }
    47.5% {
      right: 0;
      opacity: 0;
    }
    52.5% {
      right: -48px;
      opacity: 0;
    }
    to {
      right: 0;
      opacity: 1;
    }
  }
  @-moz-keyframes changeImage {
    from {
      right: 0;
      opacity: 1;
    }
    47.5% {
      right: 0;
      opacity: 0;
    }
    52.5% {
      right: -48px;
      opacity: 0;
    }
    to {
      right: 0;
      opacity: 1;
    }
  }
  @-o-keyframes changeImage {
    from {
      right: 0;
      opacity: 1;
    }
    47.5% {
      right: 0;
      opacity: 0;
    }
    52.5% {
      right: -48px;
      opacity: 0;
    }
    to {
      right: 0;
      opacity: 1;
    }
  }
  @keyframes changeImage {
    from {
      right: 0;
      opacity: 1;
    }
    47.5% {
      right: 0;
      opacity: 0;
    }
    52.5% {
      right: -48px;
      opacity: 0;
    }
    to {
      right: 0;
      opacity: 1;
    }
  }
}

@media (max-width: 576px) {
  .my-assets-image-preview__productPreview {
    position: absolute;
    top: -120px;
    right: -110px;
    margin: 0;
    transform: scale(0.5);
    height: 450px;
    z-index: 11;
    background-position: center center;
    background-size: cover;
  }

  .my-assets-image-preview__productPreview--startAnimation {
    -webkit-animation: changeImageMobile 1s ease; /* Safari 4+ */
    -moz-animation: changeImageMobile 1s ease; /* Fx 5+ */
    -o-animation: changeImageMobile 1s ease; /* Opera 12+ */
    animation: changeImageMobile 1s ease; /* IE 10+, Fx 29+ */
  }

  @-webkit-keyframes changeImageMobile {
    from {
      right: -80px;
      opacity: 1;
    }
    47.5% {
      right: -40px;
      opacity: 0;
    }
    52.5% {
      right: -120px;
      opacity: 0;
    }
    to {
      right: -80px;
      opacity: 1;
    }
  }

  @-moz-keyframes changeImageMobile {
    from {
      right: -80px;
      opacity: 1;
    }
    47.5% {
      right: -40px;
      opacity: 0;
    }
    52.5% {
      right: -120px;
      opacity: 0;
    }
    to {
      right: -80px;
      opacity: 1;
    }
  }

  @-o-keyframes changeImageMobile {
    from {
      right: -80px;
      opacity: 1;
    }
    47.5% {
      right: -40px;
      opacity: 0;
    }
    52.5% {
      right: -120px;
      opacity: 0;
    }
    to {
      right: -80px;
      opacity: 1;
    }
  }

  @keyframes changeImageMobile {
    from {
      right: -80px;
      opacity: 1;
    }
    47.5% {
      right: -40px;
      opacity: 0;
    }
    52.5% {
      right: -120px;
      opacity: 0;
    }
    to {
      right: -80px;
      opacity: 1;
    }
  }
}

@media (max-width: 430px) {
  .my-assets-image-preview__productPreview {
    top: -120px;
    right: -80px;
    transform: scale(0.6);
  }
}

@media (max-width: 350px) {
  .my-assets-image-preview__productPreview {
    top: -120px;
    right: -60px;
  }
}
