.offerings-page {
  width: 100%;
  padding: 0 32px;
  background: var(--offerings-background);
}

.offerings-page__footer {
  padding: 0 2px;
}

.offerings-page__line-height {
  line-height: 24px;
}

.offerings-page .slick-dots {
  bottom: 5px;
}

.offerings-page .slick-initialized .slick-slide {
  padding: 0 1px;
}

.offerings-page .slick-prev {
  left: 22.5px;
  z-index: 10;
  display: flex;
  align-items: center;
}

.offerings-page .slick-next {
  right: 20px;
  z-index: 10;
  display: flex;
  align-items: center;
}

.offerings-page .card-offering-block__content-wrapper {
  padding-top: 28px;
  padding-bottom: 37px;
  padding-right: 19px;
  padding-left: 60px;
}

.offerings-page .slick-dots li button:before {
  font-size: 10px;
  color: var(--pink2);
  opacity: 0.5;
}

.offerings-page .slick-dots li.slick-active button:before {
  color: var(--pink2);
  opacity: 1;
}

@media (max-width: 576px) {
  .offerings-page .slick-dots {
    bottom: -40px;
  }
  .offerings-page {
    padding: 0 19.2px;
  }
  .offerings-page__footer {
    padding: 0 4px;
  }
  .offerings-page .card-offering-block__content-wrapper {
    padding: 20px;
  }
  .offerings-page .slick-slider {
    margin-bottom: 70px;
  }
}
