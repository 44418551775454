@import './Forms.css';
@import '../../../components/common/APInput/APInput.css';
@import '../../../components/common/APCheckbox/APCheckbox.css';
@import '../../../components/common/APRadio/APRadio.css';
@import './Buttons.css';
@import '../../../components/common/APRadioTab/APRadioTab.css';
@import '../../../components/common/APSnackbar/APSnackbar.css';
@import '../../../components/common/Popover/Popover.css';
@import '../../../components/common/PopoverMenu/PopoverMenu.css';
@import '../../../components/common/APLabelWithText/APLabelWithText.css';
@import '../../../components/common/APSegmentedButton/APSegmentedButton.css';

div.global-mini-spinner__spinner-container{
  position: initial;
  margin: 0;
}
